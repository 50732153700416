import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Toolbar,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorSnackbar from 'components/Snackbar/Error';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './TrainingDialogAffect.css';

class TrainingDialogAffect extends Component {
  state = {
    error: [],
    openError: false,
    selectedTrainings: [],
  };

  computeState() {
    this.setState({
      selectedTrainings: [...this.props.podcastTrainings],
    });
  }

  componentDidMount() {
    this.computeState();
  }

  selectTraining = ({ target }) => {
    const { checked, value } = target;
    const selectedTrainings = [...this.state.selectedTrainings];
    if (checked) {
      selectedTrainings.push(parseInt(value, 10));
    } else {
      const index = selectedTrainings.indexOf(parseInt(value, 10));
      if (index !== -1) {
        selectedTrainings.splice(index, 1);
      }
    }
    this.setState({ selectedTrainings });
  }

  affectTraining = async () => {
    if (!this.state.selectedTrainings[0]) {
      this.setState({
        openError: true,
        error: ['Veuillez choisir au moins une formation'],
      });
    } else {
      this.props.onAffect(this.state.selectedTrainings);
    }
  }

  closeDialog = () => {
    this.props.onClose();
    this.computeState();
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      trainings,
    } = this.props;
    const {
      error,
      openError,
      selectedTrainings,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <ErrorSnackbar
          open={openError}
          onClose={() => this.setState({ openError: false })}
          message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
        />

        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={this.closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            Formations avec accès aux podcasts
          </Typography>
          <Button
            onClick={this.affectTraining}
            color="secondary"
            variant="contained"
            disableElevation          
          >
            Sauvegarder
          </Button>
        </Toolbar>

        <DialogContent className={classes.dialog}>
          <FormGroup className={classes.formGroup}>
            <FormControl className={classes.formControl}>
              {trainings.length ? (
                <div>
                  {trainings.map((t) => (
                    <div key={`training-${t.id}`}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={t.id}
                            onChange={this.selectTraining}
                            checked={selectedTrainings.includes(t.id)}
                          />
                        }
                        value={t.id}
                        label={t.title}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                  <Box mt={1}>
                    <Typography
                      color="inherit"
                      variant="subtitle1"
                    >
                      Aucun formation trouvée
                    </Typography>
                  </Box>
              )}
            </FormControl>
          </FormGroup>
        </DialogContent>
      </Dialog>
    );
  }
}

TrainingDialogAffect.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  trainings: PropTypes.array.isRequired,
  podcastTrainings: PropTypes.array.isRequired,
  onAffect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(withStyles(styles)(
  TrainingDialogAffect
));
