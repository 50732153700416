/**
 * @typedef {import("../../../node_modules/@material-ui/core").StyleRules} StyleRules
 */

/**
 * @returns {StyleRules}
 */

export const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    fontSize: '.9em',
    alignItems: 'center',
  },
  tag: {
    fontSize: 'inherit',
  }
});