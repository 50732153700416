import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Slider,
  Toolbar,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ErrorSnackbar from 'components/Snackbar/Error';
import { styles } from './CorrigeDialogCreate.css';
import TextEditor from 'components/TextEditor/TextEditor';
import Http from 'services/Http';

const initialState = () => ({
  success: '',
  error: [],
  openError: false,
  appreciation: '',
  note: '',
  corrige: {},
});

const createMarks = [...Array(21).keys()].map(value => ({ value, label: `${value}` }));

class CorrigeDialogCreate extends Component {
  state = initialState();

  resetState() {
    this.setState(initialState());
  }

  componentDidMount() {
    this.setState({
      corrige: { ...this.props.corrige },
    });
  }

  onCorrigeSubmit = async () => {
    const { id } = this.props.corrige;
    const { appreciation, note } = this.state.corrige;
    const params = { id, appreciation, note };
    const success = 'Note et appéciation sauvegardées';
    try {
      await Http.put('/homeworks/corrige', params);
      this.resetState();
      this.props.onCreate({
        success,
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  onDialogClose = () => {
    this.resetState();
    this.props.onClose();
  }

  handleNote = (value) => {
    this.setState(prevState => ({
      corrige: { ...prevState.corrige, note: value },
    }));
  }

  handleAppreciation = (appreciation) => {
    this.setState(prevState => ({
      corrige: { ...prevState.corrige, appreciation },
    }));
  }

  render() {
    const { classes, fullScreen, open, corrige } = this.props;
    const {
      error,
      openError,
    } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.onDialogClose}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            Donnez votre note et votre remarque
          </Typography>
          <IconButton
            color="inherit"
            onClick={this.onDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent className={classes.dialog}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Box mt={4}>
                <Slider
                  defaultValue={parseFloat(this.state.corrige.note || corrige.note || 0)}
                  getAriaValueText={value => `${value}`}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="on"
                  step={0.5}
                  min={0}
                  max={20}
                  marks={createMarks}
                  onChange={(_, value) => this.handleNote(value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextEditor
                onChange={html => this.handleAppreciation(html)}
                value={corrige.appreciation || ''}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ErrorSnackbar
            open={openError}
            onClose={() => this.setState({ openError: false })}
            message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
          />
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={this.onCorrigeSubmit}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CorrigeDialogCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default withMobileDialog()(withStyles(styles)(
  CorrigeDialogCreate
));
