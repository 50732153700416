import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonProgress: {
      color: theme.palette.primary.light,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    buttonPayed: {
      backgroundColor: green[500],
    }
  }));
