import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputBase,
  NativeSelect,
  Radio,
  RadioGroup,
  Toolbar,
  Tooltip,
  Typography,
  withTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorSnackbar from 'components/Snackbar/Error';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './TeacherDialogAffect.css';
import { tableStyles } from 'styles/datatable.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { getTeacherIsLateTitle, getTeacherStatusTitle } from 'components/Teachers/Teachers';
import TrafficLight from 'components/TrafficLight/TrafficLight';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ErrorIcon from '@material-ui/icons/Error';
class TeacherDialogAffect extends Component {
  state = {
    error: [],
    openError: false,
    teacher: '',
    teachers: [],
    teacherInput: '',
    status: 'green',
    isLate: '',
  }

  async componentDidMount() {
    try {
      const { data } = await Http.get('/users/teachers/list');
      this.setState({
        teachers: data,
      });
    } catch (e) { }
  }

  selectTeacher = (event) => {
    event.persist();
    this.setState({ teacher: event.target.value });
  }

  affectTeacher = async () => {
    if (!this.state.teacher) {
      this.setState({
        openError: true,
        error: ['Veuillez choisir un professeur'],
      });
    } else {
      const teacher_id = this.state.teacher;
      this.props.onAffect({
        teacher_id,
      });
      this.setState({
        teacher: '',
        teacherInput: '',
      });
    }
  }

  closeDialog = () => {
    this.setState({
      teacher: '',
      teacherInput: '',
    });
    this.props.onClose();
  }

  onChangeTeacher = (event) => {
    const teacherInput = event.currentTarget.value;
    this.setState({ teacherInput });
  };

  onResetTeacher = () => {
    document.querySelector('input[name="selected-teacher"]').value = '';
    this.setState({
      teacherInput: '',
      teacher: '',
    });
  };

  render() {
    const { classes, fullScreen, open, theme, basicMode } = this.props;
    const {
      error,
      openError,
      teacher,
      teachers,
      teacherInput,
      status,
      isLate,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xl"
        classes={{
          paperScrollPaper: classes.paperScrollPaper,
        }}
      >
        <ErrorSnackbar
          open={openError}
          onClose={() => this.setState({ openError: false })}
          message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
        />

        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={this.closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            Affecter à un professeur
          </Typography>
          <Button
            onClick={this.affectTeacher}
            color="secondary"
            variant="contained"
            disableElevation
          >
            Affecter
          </Button>
        </Toolbar>

        <DialogContent className={classes.dialog} style={{ backgroundColor: this.props.theme.palette.background.default }}>
          <Box
            mb={1}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            style={{ gap: theme.spacing(2) }}
          >
            <datalist id="available-teachers">
              {teachers.map(({ first_name, id, last_name, login }) => {
                return (
                  <option key={`${first_name}-${id}`} value={`${id} ${first_name} ${last_name} ${login}`} />
                );
              })}
            </datalist>
            <InputBase
              inputProps={{
                list: 'available-teachers',
                autoComplete: 'off',
              }}
              name="selected-teacher"
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              placeholder={`Filtrer: id%prénom%nom%login%commentaire`}
              onChange={this.onChangeTeacher}
              defaultValue=""
              style={{ flex: 2 }}
            />
            <Fade in={teacherInput.length > 0}>
              <IconButton onClick={this.onResetTeacher}>
                <HighlightOffIcon />
              </IconButton>
            </Fade>
            {basicMode !== true ? (
              <Fragment>
                <Box style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                  <Typography variant="subtitle2">Statut</Typography>
                  <NativeSelect
                    onChange={(event) => {
                      this.setState({
                        status: event.target.value,
                      });
                    }}
                    input={<Input name="status" />}
                    defaultValue={status}
                  >
                    <option value="">Indifférent</option>
                    {['red', 'orange', 'green'].map((value) => (
                      <option key={value} value={value}>{getTeacherStatusTitle(value)}</option>
                    ))}
                  </NativeSelect>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
                  <Typography variant="subtitle2">En retard</Typography>
                  <NativeSelect
                    onChange={(event) => {
                      this.setState({
                        isLate: event.target.value,
                      });
                    }}
                    input={<Input name="is_late" />}
                  >
                    <option value="">Indifférent</option>
                    <option value="yes">Oui</option>
                    <option value="no">Non</option>
                  </NativeSelect>
                </Box>
              </Fragment>
            ) : null}

          </Box>

          <FormGroup className={classes.formGroup}>
            <FormControl className={classes.formControl}>
              {teachers.length ? (
                <RadioGroup
                  aria-label="Teacher"
                  name="teacher"
                  className={classes.group}
                  value={teacher}
                  onChange={this.selectTeacher}
                >
                  <Box
                    display="flex"
                    style={{
                      flexWrap: 'wrap',
                      gap: theme.spacing(2),
                      alignContent: 'flex-start',
                    }}
                  >
                    {teachers
                      .filter(({ first_name, id, last_name, login, teacherInfos }) => {
                        const comment = teacherInfos?.comment ?? ''
                        const jokers = (teacherInput ?? '').split('%');
                        return jokers.length
                          ? jokers.every((joker) => {
                            return `${id} ${first_name} ${last_name} ${login} ${comment}`.toLowerCase().includes(joker.toLowerCase());
                          })
                          : !teacherInput
                            ? true
                            : `${id} ${first_name} ${last_name} ${login} ${comment}`.toLowerCase().includes(teacherInput.toLowerCase());
                      }).filter(({ teacherInfos }) => {
                        if (basicMode) {
                          return true;
                        }
                        let valid = true;
                        if (status) {
                          valid = teacherInfos && teacherInfos.status === status;
                          if (!valid) {
                            return false;
                          }
                        }
                        if (isLate) {
                          valid = teacherInfos && teacherInfos.is_late === isLate;
                          if (!valid) {
                            return false;
                          }
                        }
                        return valid;
                      }).map((t) => {
                        const teacherStatus = t.teacherInfos?.status;
                        return (
                          <Card
                            key={`teacher-${t.id}`}
                            style={{ flex: `0 1 calc(33.33% - ${theme.spacing(2)}px)` }}
                          >
                            <CardContent style={{ padding: theme.spacing(1) }}>
                              <FormControlLabel
                                className={classes.fullWidthFormControlLabel}
                                control={<Radio className={classes.radio} />}
                                value={String(t.id)}
                                label={
                                  <Box
                                    style={{
                                      display: 'flex',
                                      gap: theme.spacing(2),
                                      flex: 1,
                                      justifyContent: 'flex-start'
                                    }}
                                  >
                                    {basicMode !== true ? (
                                      <Box
                                        style={{
                                          display: 'flex',
                                          alignItems: 'flex-start',
                                          gap: theme.spacing(2),
                                        }}
                                      >
                                        <Tooltip title={getTeacherStatusTitle(teacherStatus, 'Statut : ')} placement="top-start">
                                          <Box>
                                            <TrafficLight
                                              RedOn={teacherStatus === 'red'}
                                              GreenOn={teacherStatus === 'green'}
                                              YellowOn={teacherStatus === 'orange'}
                                              Size={15}
                                            />
                                          </Box>
                                        </Tooltip>
                                        <Tooltip title={getTeacherIsLateTitle(t.teacherInfos?.is_late ?? 'no')}>
                                          {t.teacherInfos?.is_late === 'yes' ? (
                                            <ErrorIcon style={{ color: 'red' }} />
                                          ) : (
                                            <CheckBoxIcon style={{ color: 'green' }} />
                                          )}
                                        </Tooltip>
                                      </Box>
                                    ) : null}
                                    <Box
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: theme.spacing(2),
                                      }}
                                    >
                                      <Typography style={{ fontWeight: 500 }}>{`${t.first_name} ${t.last_name}`}</Typography>
                                      {(t.teacherInfos?.comment ?? '').length > 0 && basicMode !== true ? (
                                        <Typography dangerouslySetInnerHTML={{ __html: t.teacherInfos.comment ?? '-' }} />
                                      ) : null}
                                    </Box>
                                  </Box>
                                }
                              />
                            </CardContent>
                          </Card>
                        );
                      })}
                  </Box>
                </RadioGroup>
              ) : (
                <Box mt={1}>
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                  >
                    Aucun professeur trouvé
                  </Typography>
                </Box>
              )}
            </FormControl>
          </FormGroup>
        </DialogContent>
      </Dialog >
    );
  }
}

TeacherDialogAffect.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onAffect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  withTheme(TeacherDialogAffect)
));
