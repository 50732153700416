import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './SnackBarMessage.css';

const SnackBarBase = ({ classes, className, icon, message, onClose, open }) => (
    <Snackbar
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        onClose={onClose}
        autoHideDuration={6000}
        open={open}
    >
        <SnackbarContent
            className={classes[className]}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>,
            ]}
            message={
                <span className={classes.message}>
                    {icon}
                    {message}
                </span>
            }
        />
    </Snackbar>
);

SnackBarBase.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string.isRequired,
    message: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SnackBarBase);