import React from 'react';
import PropTypes from 'prop-types';
import pell from 'pell';
import { withStyles } from '@material-ui/core';

import 'pell/dist/pell.css';
import { styles } from './TextEditor.css';

class TextEditor extends React.Component {

  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    if (this.editorRef && this.editorRef.current) {
      const { actions, value } = this.props;
      const editor = pell.init({
        element: this.editorRef.current,
        onChange: html => this.props.onChange(html),
        actions: actions(this),
      });
      if (value) {
        editor.content.innerHTML = value;
      }
    }
  }

  onChange = () => {
    const fileReader = new FileReader();
    fileReader.addEventListener('load', (e) => {
      pell.exec('insertHTML', `<img src="${fileReader.result}" alt="" style="max-width: 70%; height: auto;" />`)
    });
    fileReader.readAsDataURL(this.fileInputRef.current.files[0]);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div
          ref={this.editorRef}
          className={classes.editorRoot}
        />
        <input
          accept=".jpg,.png.gif"
          type="file"
          style={{ display: 'none' }}
          ref={this.fileInputRef}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

TextEditor.propTypes = {
  actions: PropTypes.func,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

TextEditor.defaultProps = {
  actions: context => ([
    {
      name: 'rouge',
      icon: '🔴',
      title: 'Texte rouge',
      result: () => {
        pell.exec('foreColor', '#850e0e')
      },
    },
    {
      name: 'noir',
      icon: '⚫️',
      title: 'Texte noir',
      result: () => {
        pell.exec('foreColor', '#000000')
      },
    },
    'bold',
    'italic',
    'underline',
    'heading1',
    'heading2',
    'paragraph',
    'ulist',
    'link',
    {
      name: 'iframe',
      icon: '🎥',
      title: 'Vidéo YouTube / code HTML',
      result: () => {
        let html = prompt('Collez votre code HTML');
        console.log(html)
        if (html) {
          pell.exec('insertHTML', html)
        }
      },
    },
    {
      name: 'image',
      result: () => {
        context.fileInputRef.current.dispatchEvent(new MouseEvent('click'));
      }
    },
  ]),
  onChange: (value) => console.log(value),
  value: '',
};

export default withStyles(styles)(TextEditor);
