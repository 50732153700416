import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  withStyles,
  IconButton,
  Tooltip,
  InputBase,
  Box,
  withTheme,
  List,
  ListItemText,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  alpha,
  Backdrop,
  Badge,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import SchoolIcon from '@material-ui/icons/School';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { styles } from './Payzen.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import { formatInTimeZone } from 'date-fns-tz';
import 'react-json-view-lite/dist/index.css';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import EuroIcon from '@material-ui/icons/Euro';
import Http from 'services/Http';
import TransactionsDialog from './TransactionsDialog';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import frLocale from 'date-fns/locale/fr';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import JsonNotificationDialog from './JsonNotificationDialog';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import HistoryIcon from '@material-ui/icons/History';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteIcon from '@material-ui/icons/Delete';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ErrorIcon from '@material-ui/icons/Error';
import BlockIcon from '@material-ui/icons/Block';
import Unnotifieds from './Unnotifieds';
import PaymentStateDialog from './PaymentStateDialog';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PaymentIcon from '@material-ui/icons/Payment';

export const payzenIpnStates = {
  ok: 'Régularisé',
  inprogress: 'En cours',
  mail1: 'Étape 1',
  mail2: 'Étape 2',
  contentieux: 'Contentieux',
};

const sortableCells = [
  { name: 'id', label: '#' },
];

const sortableAndFilterableCells = [
  {
    cellInfo: { name: 'orderId', label: 'ID', cellStyle: { maxWidth: '100px' } },
    filtersInfo: [{ filterBy: 'orderId', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student', label: 'Élève', cellStyle: { maxWidth: '200px' } },
    filtersInfo: [{ filterBy: 'student', placeholder: '' }],
  },
  {
    cellInfo: { name: 'created_at', label: 'Ajouté le' },
    filtersInfo: [
      { filterBy: 'sentFrom', placeholder: 'Début', type: 'datePicker' },
      { filterBy: 'sentTo', placeholder: 'Fin', type: 'datePicker' },
    ],
  },
  // /!\ select options order matters
  {
    cellInfo: { name: 'state', label: 'État' },
    filtersInfo: [{ filterBy: 'state', placeholder: 'Tout', type: 'select', items: Object.values(payzenIpnStates) }],
  },
];

const deletedBackground = 'repeating-linear-gradient(45deg, #ddd, #ddd 10px, #eee 10px, #eee 20px)';

class Payzen extends Component {
  state = {
    transactions: null,
    uuid: null,
    loadingOpacity: false,
    editComment: false,
    snackbar: null,
    jsonNotification: null,
    decisionTreeConfirmDialog: null,
    deleteConfirmDialog: null,
    describeDescisionTree: null,
    orderPaymentState: null,
  }

  async componentDidMount() {
    const { data: describeDescisionTree } = await Http.get('/payzen/describeDescisionTree');
    this.setState({ describeDescisionTree });
  }

  openSnackbar = (message, type) => {
    this.setState({
      snackbar: {
        message,
        type,
      }
    });
  };

  loadTransactions = (orderId, uuid) => {
    return async (event) => {
      this.setState({ loadingOpacity: true });
      try {
        const { data } = await Http.get(`/payzen/orderGet/${orderId}`);
        this.setState({
          transactions: data.answer.transactions,
          uuid,
        });
      } catch (e) {
        this.openSnackbar(`Payzen a répondu : ${e.data.message}`, 'error');
      } finally {
        this.setState({
          loadingOpacity: false,
        });
      }
    };
  }

  saveComment = (id) => {
    return async (event) => {
      this.setState({ loadingOpacity: true });
      try {
        const comment = document.querySelector(`textarea[name='comment_${id}']`).value;
        await Http.put(`/payzen/comment`, {
          id,
          comment,
        });
      } catch (e) {
        this.openSnackbar(`Erreur : ${e.data.message}`, 'error');
      } finally {
        this.props.reload();
        this.setState({ loadingOpacity: false, editComment: false });
      }
    };
  };

  runDecisionTree = async () => {
    const { branch, id, date, to, transaction, orderId, userId, expectedCaptureDate, sequenceNumber } = this.state.decisionTreeConfirmDialog;
    this.setState({ loadingOpacity: true });
    try {
      const duplicateData = {
        uuid: transaction.uuid,
        amount: transaction.amount,
        orderId,
      };
      const { data } = await Http.post(`/payzen/runDecisionTree/${branch}`, {
        month: date,
        to,
        ...duplicateData,
        userId,
        id,
        expectedCaptureDate,
        sequenceNumber,
      });
      if (branch === 'regularize' || branch === 'mail1regularize') {
        this.openSnackbar(`👍 Succès lors de la régularisation`, 'success');
      } else if (data.duplicateSuccess.success) {
        this.openSnackbar(`👍 Succès lors de la représentation de l'échéance`, 'success');
      } else {
        this.openSnackbar(`👎 Erreur lors de la représentation de l'échéance : ${Object.values(data.duplicateSuccess.errors).join(', ')}`, 'error');
      }
    } catch (e) {
      this.openSnackbar(`Erreur : ${e.data ? e.data.message : e.message}`, 'error');
    } finally {
      this.setState({
        loadingOpacity: false,
        decisionTreeConfirmDialog: null,
      });
      this.props.reload();
    }
  };

  openDecisionTreeConfirmDialog = (payzenIpn, answer, branch) => {
    return (event) => {
      this.setState({
        decisionTreeConfirmDialog: {
          branch,
          id: payzenIpn.id,
          date: formatInTimeZone(
            answer.transactions[0].transactionDetails.cardDetails.expectedCaptureDate,
            'Europe/Paris', 'MMMM yyyy',
            { locale: frLocale },
          ).toLocaleUpperCase(),
          to: payzenIpn.order.customer.email,
          transaction: answer.transactions[0],
          orderId: payzenIpn.orderId,
          userId: payzenIpn.order.customer.id,
          dialogMessage: branch === 'represent' || branch === 'mail1represent'
            ? `Êtes-vous sûr de vouloir représenter l'échéance de la commande ${payzenIpn.orderId ?? ''} ?`
            : branch === 'mail1'
              ? `Êtes-vous sûr de vouloir passer la commande ${payzenIpn.orderId ?? ''} en 1ère relance ?`
              : branch === 'mail2'
                ? `Êtes-vous sûr de vouloir passer la commande ${payzenIpn.orderId ?? ''} en 2ème relance ?`
                : branch === 'regularize' || branch === 'mail1regularize'
                  ? `Êtes-vous sûr de vouloir régulariser la commande ${payzenIpn.orderId ?? ''} ?`
                  : 'Erreur merci de cliquer sur NON et de contacter le support',
          expectedCaptureDate: answer.transactions[0].transactionDetails.cardDetails.expectedCaptureDate,
          sequenceNumber: answer.transactions[0].transactionDetails.sequenceNumber,
        }
      });
    };
  };

  openDeleteConfirmDialog = (id) => {
    return (event) => {
      this.setState({
        deleteConfirmDialog: {
          id,
        },
      });
    };
  };

  delete = async () => {
    const { deleteConfirmDialog: { id } } = this.state;
    this.setState({ loadingOpacity: true });
    try {
      await Http.delete(`/payzen/delete/${id}`);
      this.openSnackbar(`👍 Succès lors de la suppression`, 'success');
    } catch (e) {
      this.openSnackbar(`Erreur : ${e.data.message}`, 'error');
    } finally {
      this.setState({
        loadingOpacity: false,
        deleteConfirmDialog: null,
      });
      this.props.reload();
    }
  };

  render() {
    const {
      classes,
      loading,
      renderPagination,
      renderSortCell,
      renderSortableAndFilterable,
      rows,
      theme,
      filter,
      filterState,
    } = this.props;
    const {
      loadingOpacity,
      transactions,
      uuid,
      editComment,
      snackbar,
      jsonNotification,
      decisionTreeConfirmDialog,
      deleteConfirmDialog,
      describeDescisionTree,
      orderPaymentState,
    } = this.state;
    const loadingStyle = { opacity: (loading || loadingOpacity) ? .5 : 1 };

    return (
      <Box>
        <Backdrop
          style={{ zIndex: theme.zIndex.modal }}
          open={loadingOpacity || loading}
        />
        <Paper
          elevation={1}
          className={classes.root}
          style={loadingStyle}
        >

          {describeDescisionTree === null ? (
            <Typography>Chargement...</Typography>
          ) : (
            <Box>
              {orderPaymentState !== null ? (
                <PaymentStateDialog
                  open={orderPaymentState !== null}
                  onClose={() => {
                    this.setState({ orderPaymentState: null });
                  }}
                  orderPaymentState={orderPaymentState}
                  openSnackbar={this.openSnackbar}
                  onSuccess={() => {
                    this.props.reload();
                  }}
                />
              ) : null}
              <ConfirmationDialog
                open={decisionTreeConfirmDialog !== null}
                title="Attention"
                message={decisionTreeConfirmDialog?.dialogMessage ?? ''}
                onClose={() => {
                  this.setState({ decisionTreeConfirmDialog: null });
                }}
                onConfirm={this.runDecisionTree}
              />
              <ConfirmationDialog
                open={deleteConfirmDialog !== null}
                title="Attention"
                message={`Êtes-vous sûr de vouloir supprimer la notification d'id ${deleteConfirmDialog?.id ?? ''} ?`}
                onClose={() => {
                  this.setState({ delete: null });
                }}
                onConfirm={this.delete}
              />
              <ErrorSnackbar
                message={snackbar?.message ?? ''}
                onClose={() => this.setState({ snackbar: null })}
                open={snackbar?.type === 'error'}
              />
              <SuccessSnackbar
                message={snackbar?.message ?? ''}
                onClose={() => this.setState({ snackbar: null })}
                open={snackbar?.type === 'success'}
              />
              <TransactionsDialog
                onClose={() => this.setState({ transactions: null, uuid: null })}
                open={transactions !== null}
                transactions={transactions}
                uuid={uuid}
              />
              <JsonNotificationDialog
                onClose={() => this.setState({ jsonNotification: null })}
                open={jsonNotification !== null}
                data={jsonNotification}
              />
              <Toolbar className={classes.toolbar}>
                {/* <Typography className={classes.title} variant="h6" color="inherit" /> */}
                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-start',
                  }}
                >
                  <Unnotifieds
                    openSnackbar={this.openSnackbar}
                    reload={this.props.reload}
                  />
                </Box>
                <Box>
                  <FormControl
                    component="div"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <FormLabel component="legend">Afficher :</FormLabel>
                    <RadioGroup
                      aria-label="format"
                      name="fileType"
                      value={filterState?.processed ?? '-1'}
                      onChange={(event) => {
                        filter({
                          processed: event.target.value,
                        }, true);
                      }}
                      style={{ flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        labelPlacement="start"
                        value="-1"
                        control={<Radio />}
                        label="Tous"
                      />
                      <FormControlLabel
                        labelPlacement="start"
                        value="0"
                        control={<Radio />}
                        label="Actifs"
                      />
                      <FormControlLabel
                        labelPlacement="start"
                        value="1"
                        control={<Radio />}
                        label="Supprimés"
                        style={{
                          background: deletedBackground,
                          paddingLeft: theme.spacing(1),
                          borderRadius: '7px',
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Toolbar>
              <Table size="small" className={classes.table}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    {sortableCells.map(cell => renderSortCell(cell))}
                    {sortableAndFilterableCells.map(({ cellInfo, filtersInfo }) => renderSortableAndFilterable(cellInfo, filtersInfo))}
                    <TableCell>Commentaire</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((payzenIpn) => {
                    const hasComment = (payzenIpn.comment ?? '').length > 0;
                    const answer = JSON.parse(payzenIpn.answer);
                    const stateBackground = payzenIpn.state === 'mail1'
                      ? theme.palette.warning.main
                      : payzenIpn.state === 'mail2'
                        ? theme.palette.error.light
                        : payzenIpn.state === 'ok'
                          ? theme.palette.success.main
                          : payzenIpn.state === 'contentieux'
                            ? theme.palette.error.dark
                            : theme.palette.info.light;

                    const studentComments = payzenIpn.order.customer.studentComments.filter(({ comment }) => {
                      return comment.includes(payzenIpn.orderId);
                    });

                    const disableMail1 = ['mail1', 'mail2', 'contentieux', 'ok'].includes(payzenIpn.state);
                    const disableMail2 = [null, 'mail2', 'contentieux', 'ok'].includes(payzenIpn.state);
                    const disableRepresent = ['contentieux', 'ok'].includes(payzenIpn.state);
                    const disableRegularize = ['ok', 'contentieux'].includes(payzenIpn.state);

                    const duplicateOrdersNotOk = payzenIpn.duplicateOrders
                      .filter(({ id, state }) => {
                        return state !== 'ok';
                      });

                    return (
                      <TableRow
                        key={payzenIpn.id}
                        style={{
                          opacity: payzenIpn.processed ? .5 : 1,
                          background: payzenIpn.processed
                            ? deletedBackground
                            : 'inherit',
                        }}
                      >
                        <TableCell>
                          {payzenIpn.id}
                        </TableCell>
                        <TableCell>
                          <Badge
                            badgeContent={duplicateOrdersNotOk.length > 1
                              ? duplicateOrdersNotOk.length
                              : 0
                            }
                            color="error"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                          >
                            <ButtonRouter
                              target="_blank"
                              size="small"
                              to={`/orders/${payzenIpn.orderId}`}
                              label={payzenIpn.orderId}
                              startIcon={<ShoppingCartIcon fontSize="inherit" />}
                            />
                          </Badge>
                        </TableCell>
                        <TableCell>
                          <Box justifyContent="space-around" alignItems="center" display="flex" style={{ gap: theme.spacing(1) }}>
                            <Box flex="1">
                              <ButtonRouter
                                color="primary"
                                target="_blank"
                                size="small"
                                to={`/students/${payzenIpn.order.customer.id}`}
                                label={`${payzenIpn.order.customer.last_name} ${payzenIpn.order.customer.first_name}`}
                                startIcon={<SchoolIcon fontSize="inherit" />}
                              />
                            </Box>
                            {payzenIpn.order.customer.studentMeta && payzenIpn.order.customer.studentMeta.do_not_send === 1 ? (
                              <Tooltip title="Attention : élève banni">
                                <BlockIcon fontSize="small" color="secondary" />
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '2px' }}>
                            <Typography variant="body2">
                              Notifié le : {formatInTimeZone(payzenIpn.created_at, 'Etc/Utc', 'dd/MM/yyyy HH:mm')}
                            </Typography>
                            <Typography variant="caption">
                              Date remise : {formatInTimeZone(payzenIpn.expectedCaptureDate, 'Etc/Utc', 'dd/MM/yyyy HH:mm')}
                              {(payzenIpn.daysDiff > 2 && payzenIpn.state !== 'ok') ? (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  display="inline"
                                  style={{
                                    marginLeft: theme.spacing(1),
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Depuis {payzenIpn.daysDiff}j.
                                </Typography>
                              ) : null}
                            </Typography>
                            {payzenIpn.payment_order_created_at ? (
                              <Typography
                                variant="caption"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: theme.spacing(1),
                                  backgroundColor: payzenIpn.validPaymentLink ? theme.palette.warning.main : theme.palette.error.main,
                                  color: theme.palette.getContrastText(payzenIpn.validPaymentLink ? theme.palette.warning.main : theme.palette.error.main),
                                  padding: `1px ${theme.spacing(1)}px`,
                                  borderRadius: '6px',
                                }}
                              >
                                <PaymentIcon fontSize="inherit" />
                                Lien paiement {formatInTimeZone(new Date(payzenIpn.payment_order_created_at), 'Etc/Utc', 'dd/MM/yyyy HH:mm')}
                                {payzenIpn.validPaymentLink ? null : <strong>(expiré)</strong>}
                              </Typography>
                            ) : null}
                          </Box>
                        </TableCell>
                        <TableCell style={{ background: alpha(stateBackground, .1), padding: 0 }}>
                          <Box display="flex" justifyContent="center">
                            <Box
                              py={1}
                              px={2}
                              style={{
                                background: stateBackground,
                                color: theme.palette.getContrastText(stateBackground),
                                borderRadius: theme.shape.borderRadius,
                                textAlign: 'center',
                                fontSize: '10px',
                              }}
                            >
                              {`${payzenIpn.stateLabel.length ? payzenIpn.stateLabel : 'A traiter'}`.toLocaleUpperCase()}
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            style={{
                              flexDirection: 'row',
                              gap: theme.spacing(1),
                              maxWidth: '20vw',
                              justifyContent: 'center',
                            }}
                          >
                            {(editComment && editComment === payzenIpn.id) ? (
                              <Fragment>
                                <InputBase
                                  style={{ width: '100%', flex: 1 }}
                                  name={`comment_${payzenIpn.id}`}
                                  classes={{
                                    root: classes.field,
                                    input: classes.fieldInput,
                                  }}
                                  defaultValue={payzenIpn.comment}
                                  placeholder="Commentaire"
                                  multiline
                                />
                                <Tooltip title="Enregistrer">
                                  <IconButton
                                    onClick={this.saveComment(payzenIpn.id)}
                                  >
                                    <SaveIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Fermer">
                                  <IconButton
                                    onClick={() => this.setState({ editComment: false })}
                                  >
                                    <CancelIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <Tooltip title={
                                  hasComment ? (
                                    <Typography variant="caption" style={{ whiteSpace: 'pre-line', flex: 1 }}>
                                      {payzenIpn.comment}
                                    </Typography>
                                  ) : ''
                                }>
                                  <span>
                                    <IconButton
                                      disabled={!hasComment}
                                      color={hasComment ? 'secondary' : 'inherit'}>
                                      <ChatBubbleIcon fontSize={hasComment ? 'large' : 'inherit'} />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <IconButton
                                  onClick={() => this.setState({ editComment: payzenIpn.id })}
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </Fragment>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box
                            display="flex"
                            style={{
                              gap: theme.spacing(1),
                            }}
                          >
                            <Paper
                              variant="outlined"
                              style={{ backgroundColor: theme.palette.background.default }}
                            >
                              <Box
                                display="flex"
                                style={{
                                  flexDirection: 'row',
                                  gap: theme.spacing(2),
                                  justifyContent: 'space-evenly',
                                  padding: theme.spacing(1),
                                }}
                              >
                                {studentComments.length ? (
                                  <Tooltip title={
                                    <List dense>
                                      {studentComments.map(({ id, comment }, key) => {
                                        return (
                                          <ListItemText key={id}>{`${key + 1} : ${comment}`}</ListItemText>
                                        );
                                      })}
                                    </List>
                                  }>
                                    <IconButton
                                      size="small"
                                      style={{ color: theme.palette.getContrastText(theme.palette.background.default) }}
                                    >
                                      <HistoryIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <IconButton
                                    size="small"
                                    disabled
                                    style={{ color: alpha(theme.palette.getContrastText(theme.palette.background.default), .5) }}
                                  >
                                    <HistoryIcon fontSize="inherit" />
                                  </IconButton>
                                )}
                                <Tooltip title={`Afficher la notification de la commande ${payzenIpn.orderId}`}>
                                  <IconButton
                                    style={{ color: theme.palette.getContrastText(theme.palette.background.default) }}
                                    size="small"
                                    onClick={() => {
                                      this.setState({
                                        jsonNotification: JSON.parse(payzenIpn.answer)
                                      });
                                    }}
                                  >
                                    <AccountBalanceIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={`Afficher les transactions de la commande ${payzenIpn.orderId}`}>
                                  <IconButton
                                    style={{ color: theme.palette.getContrastText(theme.palette.background.default) }}
                                    size="small"
                                    onClick={this.loadTransactions(payzenIpn.orderId, answer.transactions[0].uuid)}
                                  >
                                    <EuroIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={`Échéancier ${payzenIpn.orderId}`}>
                                  <IconButton
                                    style={{ color: theme.palette.getContrastText(theme.palette.background.default) }}
                                    size="small"
                                    onClick={async () => {
                                      this.setState({ loadingOpacity: true });
                                      try {
                                        const { data } = await Http.get(`/payzen/getInstallmentOptionsFromOrderId/${payzenIpn.orderId}`);
                                        this.setState({ orderPaymentState: data });
                                      } catch (e) {
                                        this.openSnackbar(e?.data?.message ?? 'Erreur inconnue');
                                      } finally {
                                        this.setState({ loadingOpacity: false });
                                      }
                                    }}
                                  >
                                    <AccountBalanceWalletIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Paper>
                            <Paper
                              variant="outlined"
                              style={{ backgroundColor: theme.palette.success.dark }}
                            >
                              <Box
                                display="flex"
                                style={{
                                  flexDirection: 'row',
                                  gap: theme.spacing(2),
                                  justifyContent: 'space-evenly',
                                  padding: theme.spacing(1),
                                }}
                              >
                                <Tooltip
                                  title={
                                    <Box className={classes.listContainer}>
                                      <Typography variant="h6">Passer en 1ère étape</Typography>
                                      <Typography variant="caption">Que va-t-il se passer ?</Typography>
                                      <ul>
                                        <li>
                                          <strong>SI OUI</strong>
                                          <ul>
                                            {describeDescisionTree.mail1.success.map((description) => {
                                              return (
                                                <li key={description}>{description}</li>
                                              );
                                            })}
                                          </ul>
                                        </li>
                                        <li>
                                          <strong>SI NON</strong>
                                          <ul>
                                            {describeDescisionTree.mail1.failure.map((description) => {
                                              return (
                                                <li key={description}>{description}</li>
                                              );
                                            })}
                                          </ul>
                                        </li>
                                      </ul>
                                    </Box>
                                  }
                                >
                                  <span>
                                    <IconButton
                                      style={{ color: alpha(theme.palette.getContrastText(theme.palette.success.dark), disableMail1 ? .5 : 1) }}
                                      size="small"
                                      disabled={disableMail1}
                                      onClick={this.openDecisionTreeConfirmDialog(payzenIpn, answer, 'mail1')}
                                    >
                                      <AddAlertIcon fontSize="inherit" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <Box className={classes.listContainer}>
                                      <Typography variant="h6">Passer en 2ème étape</Typography>
                                      <Typography variant="caption">Que va-t-il se passer ?</Typography>
                                      <ul>
                                        <li>
                                          <strong>SI OUI</strong>
                                          <ul>
                                            {describeDescisionTree.mail2.success.map((description) => {
                                              return (
                                                <li key={description}>{description}</li>
                                              );
                                            })}
                                          </ul>
                                        </li>
                                        <li>
                                          <strong>SI NON</strong>
                                          <ul>
                                            {describeDescisionTree.mail2.failure.map((description) => {
                                              return (
                                                <li key={description}>{description}</li>
                                              );
                                            })}
                                          </ul>
                                        </li>
                                      </ul>
                                    </Box>
                                  }
                                >
                                  <span>
                                    <IconButton
                                      style={{ color: alpha(theme.palette.getContrastText(theme.palette.success.dark), disableMail2 ? .5 : 1) }}
                                      size="small"
                                      disabled={disableMail2}
                                      onClick={this.openDecisionTreeConfirmDialog(payzenIpn, answer, 'mail2')}
                                    >
                                      <ErrorIcon fontSize="inherit" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={
                                    <Box className={classes.listContainer}>
                                      <Typography variant="h6">Représenter l'échéance</Typography>
                                      <Typography variant="caption">Que va-t-il se passer ?</Typography>
                                      <ul>
                                        <li>
                                          <strong>SI OUI</strong>
                                          {payzenIpn.state === null ? (
                                            <ul>
                                              {describeDescisionTree.represent.success.map((description) => {
                                                return (
                                                  <li key={description}>{description}</li>
                                                );
                                              })}
                                            </ul>
                                          ) : (
                                            <ul>
                                              {describeDescisionTree.mail1represent.success.map((description) => {
                                                return (
                                                  <li key={description}>{description}</li>
                                                );
                                              })}
                                            </ul>
                                          )}
                                        </li>
                                        <li>
                                          <strong>SI NON</strong>
                                          {payzenIpn.state === null ? (
                                            <ul>
                                              {describeDescisionTree.represent.failure.map((description) => {
                                                return (
                                                  <li key={description}>{description}</li>
                                                );
                                              })}
                                            </ul>
                                          ) : (
                                            <ul>
                                              {describeDescisionTree.mail1represent.failure.map((description) => {
                                                return (
                                                  <li key={description}>{description}</li>
                                                );
                                              })}
                                            </ul>
                                          )}
                                        </li>
                                      </ul>
                                    </Box>
                                  }
                                >
                                  <span>
                                    <IconButton
                                      style={{ color: alpha(theme.palette.getContrastText(theme.palette.success.dark), disableRepresent ? .5 : 1) }}
                                      size="small"
                                      onClick={this.openDecisionTreeConfirmDialog(payzenIpn, answer, payzenIpn.state === null ? 'represent' : 'mail1represent')}
                                      disabled={disableRepresent}
                                    >
                                      <ReplayIcon fontSize="inherit" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                            </Paper>
                            <Paper
                              variant="outlined"
                              style={{ backgroundColor: theme.palette.warning.dark }}
                            >
                              <Box
                                display="flex"
                                style={{
                                  flexDirection: 'row',
                                  gap: theme.spacing(2),
                                  justifyContent: 'space-evenly',
                                  padding: theme.spacing(1),
                                }}
                              >
                                <Tooltip
                                  title={
                                    <Box className={classes.listContainer}>
                                      <Typography variant="h6">Régulariser</Typography>
                                      <Typography variant="caption">Que va-t-il se passer ?</Typography>
                                      {payzenIpn.state === null ? (
                                        <ul>
                                          {describeDescisionTree.regularize.success.map((description) => {
                                            return (
                                              <li key={description}>{description}</li>
                                            );
                                          })}
                                        </ul>
                                      ) : payzenIpn.state === 'mail1' ? (
                                        <ul>
                                          {describeDescisionTree.mail1regularize.success.map((description) => {
                                            return (
                                              <li key={description}>{description}</li>
                                            );
                                          })}
                                        </ul>
                                      ) : null}
                                    </Box>
                                  }
                                >
                                  <span>
                                    <IconButton
                                      style={{ color: alpha(theme.palette.getContrastText(theme.palette.warning.dark), disableRegularize ? .5 : 1) }}
                                      size="small"
                                      disabled={disableRegularize}
                                      onClick={this.openDecisionTreeConfirmDialog(payzenIpn, answer, payzenIpn.state === null ? 'regularize' : 'mail1regularize')}
                                    >
                                      <ThumbUpIcon fontSize="inherit" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                                <Tooltip
                                  title={`Supprimer (soft delete) ?`}
                                >
                                  <span>
                                    <IconButton
                                      style={{ color: alpha(theme.palette.getContrastText(theme.palette.warning.dark), payzenIpn.processed ? .5 : 1) }}
                                      size="small"
                                      disabled={payzenIpn.processed}
                                      onClick={this.openDeleteConfirmDialog(payzenIpn.id)}
                                    >
                                      <DeleteIcon fontSize="inherit" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </Box>
                            </Paper>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {renderPagination()}
            </Box>
          )}
        </Paper>
      </Box>
    );
  }
}

Payzen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(Payzen, 'payzen', 'id', 'desc')
));
