import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './StudentHome.css';
import Http from 'services/Http';
import OrderAddress from 'components/Orders/OrderAddress';
import StudentTags from 'components/StudentTags/StudentTags';

export const shipmentLabels = {
  postal: 'poste',
  mail: 'démat.',
  onsite: 'retrait',
  special: 'spécial',
};
class DoNotSend extends Component {

  onChange = async (event) => {
    const { reload, studentMeta, id } = this.props;
    this.setState({
      disabled: true,
      doNotSend: event.target.checked
    });
    await Http.post('/users/insertOrUpdateStudentMeta', {
      ...studentMeta,
      user_id: id,
      do_not_send: event.target.checked ? 1 : 0,
    });
    this.setState({ disabled: false });
    reload();
  }

  componentDidMount() {
    const { studentMeta } = this.props;
    const doNotSend = studentMeta && studentMeta.do_not_send === 1;
    const shipment = studentMeta && studentMeta.shipment && shipmentLabels[studentMeta.shipment]
      ? studentMeta.shipment
      : 'postal';
    this.setState({
      doNotSend,
      shipment,
    });
  }

  state = {
    doNotSend: false,
    disabled: false,
    shipment: '',
  };

  onShipmentChange = async (event) => {
    const { reload, studentMeta, id } = this.props;
    await Http.post('/users/insertOrUpdateStudentMeta', {
      ...studentMeta,
      user_id: id,
      shipment: event.target.value,
    });
    this.setState({ shipment: event.target.value });
    reload();
  }

  render() {
    const { disabled, doNotSend, shipment } = this.state;
    const disableShipmentRadios = shipment === '' || doNotSend;
    return (
      <Fragment>
        <Box flex="1">
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6">Envoi polys</Typography>
              <FormControl>
                <RadioGroup
                  row
                  onChange={this.onShipmentChange}
                  value={shipment}
                >
                  {Object.entries(shipmentLabels).map(([key, label]) => {
                    return (
                      <FormControlLabel
                        disabled={disableShipmentRadios}
                        key={key}
                        value={key}
                        label={label}
                        control={<Radio />}
                        labelPlacement="bottom"
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </CardContent>
          </Card>
        </Box>
        <Box flex="1">
          <Card variant="outlined" style={{ height: '100%' }}>
            <CardContent>
              <Typography variant="h6">Bloquer</Typography>
              <FormControlLabel
                disabled={disabled}
                label={
                  disabled
                    ? 'Chargement...'
                    : (
                      <Alert
                        severity={doNotSend ? 'error' : 'success'}
                      >
                        {doNotSend ? 'Cet(te) élève est bloqué(e)' : 'Cet(te) élève est activé(e)'}
                      </Alert>
                    )
                }
                control={
                  <Switch
                    checked={doNotSend ?? false}
                    onChange={this.onChange}
                  />
                }
              />
            </CardContent>
          </Card>
        </Box>
      </Fragment>
    );
  }
}

const StudentHome = ({
  reload,
  data,
}) => {
  const theme = useTheme();
  const { login, first_name, last_name, registeredAt, studentMeta, id, tags } = data;
  return (
    <Box textAlign="left" px={3} pt={3}>
      <Box
        display="flex"
        alignItems="stretch"
        justifyContent="stretch"
        mb={4}
        style={{
          gap: theme.spacing(4),
        }}
      >
        <Box flex="1" style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Typography variant="h4">
            {`Élève ${first_name} ${last_name}`}
          </Typography>
          <Typography variant="body2">
            {`${login} - inscrit(e) le ${format(new Date(registeredAt), 'dd MMMM yyyy', { locale: frLocale })}`}
          </Typography>

          <Card
            variant="outlined"
            style={{
              flex: 1,
              marginTop: '.5rem',
              padding: '.2rem',
            }}
          >
            <StudentTags
              tags={tags}
              userId={id}
              allowDeletion
              allowCreation
              onUpdate={reload}

            />
          </Card>
        </Box>
        <DoNotSend
          id={id}
          studentMeta={studentMeta}
          reload={reload}
        />
      </Box>
      <Box
        display="flex"
        style={{
          gap: theme.spacing(4),
        }}
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Card
          variant="outlined"
          style={{
            flex: 1,
          }}
        >
          <CardHeader title="Général" />
          <CardContent>
            <OrderAddress student={data} kind="general" />
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          style={{
            flex: 1,
          }}
        >
          <CardHeader title="Facturation" />
          <CardContent>
            <OrderAddress student={data} kind="billing" />
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          style={{
            flex: 1,
          }}
        >
          <CardHeader title="Expédition" />
          <CardContent>
            <OrderAddress student={data} kind="shipping" />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

StudentHome.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(StudentHome);
