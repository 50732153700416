import {
  Backdrop,
  Badge,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Timetable/Timetable.css';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';
import { useEffect, useRef, useState } from 'react';
import Http from 'services/Http';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DomainIcon from '@material-ui/icons/Domain';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddIcon from '@material-ui/icons/AddCircle';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

export const groupCartBySession = (cart) => {
  return cart.reduce((prev, current) => {
    return {
      ...prev,
      [current.session_id ?? 0]: !prev[current.session_id ?? 0]
        ? [current]
        : [...prev[current.session_id ?? 0], current],
    };
  }, {});
};

const useStyles = makeStyles((theme) => tableStyles(theme));
const timetableStyles = makeStyles((theme) => styles(theme));

const DisciplineList = ({ disciplines, handleCartAdd, title, cart, showEveryDisciplineButton, selectedSession }) => {
  const theme = useTheme();
  return (
    <List
      style={{ width: '100%' }}
      dense
      component="ul"
      role="list"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {title}
        </ListSubheader>
      }
    >
      {disciplines.length === 0 ? (
        <ListItem
          role="listitem"
          style={{ marginLeft: theme.spacing(1) }}
        >
          -
        </ListItem>
      ) : null}
      {disciplines.map((discipline) => {
        const labelId = `discipline-${discipline.id}`;
        const disabled = cart.find(({ discipline_id }) => {
          return discipline_id === discipline.id;
        }) !== undefined;
        const disabledSession = cart.find(({ discipline_id, session_id }) => {
          return discipline_id === discipline.id && session_id === selectedSession?.id;
        }) !== undefined;
        return (
          <ListItem
            role="listitem"
            key={labelId}
            style={{ marginLeft: theme.spacing(1) }}
            disabled={disabled}
          >
            <ListItemText id={labelId} primary={discipline.name} />
            <ListItemSecondaryAction disabled>
              <Tooltip title="Ajouter pour chaque matière de cette session">
                <span>
                  <IconButton
                    disabled={disabled}
                    onClick={handleCartAdd(discipline, false)}
                  >
                    <AllInclusiveIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Ajouter pour ce couple matière / session uniquement">
                <span>
                  <IconButton
                    disabled={disabledSession}
                    onClick={handleCartAdd(discipline, true)}
                  >
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
              {showEveryDisciplineButton ? (
                <Tooltip title="Ajouter pour chaque matière de cette session ET ajouter pour ce couple matière / session uniquement">
                  <span>
                    <IconButton
                      onClick={handleCartAdd(discipline, false, true)}
                    >
                      <AccountTreeIcon color="primary" />
                    </IconButton>
                  </span>
                </Tooltip>
              ) : null}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

const DisciplineSessionDialog = ({
  open,
  onClose,
  onSubmit,
  initialCart,
  submitLabel = 'Enregistrer',
  dialogTitle = 'Affecter',
  showEveryDisciplineButton = false,
}) => {
  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...timetableStyles(),
  };
  const inputRef = useRef();
  const timeoutRef = useRef();
  const [blockUi, setBlockUi] = useState(false);
  const [allSessions, setAllSessions] = useState([]);
  const [allTrainings, setAllTrainings] = useState([]);
  const [selectedTraining, setSeletectedTraining] = useState(null);
  const [disciplines, setDisciplines] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [cart, setCart] = useState(initialCart);

  useEffect(() => {
    const load = async () => {
      setBlockUi(true);
      const [{ data: allSessions }, { data: { rows: allTrainings } }] = await Promise.all([
        Http.get('/sessions/list?include=discipline'),
        Http.get('/trainings?publishedOnly=true')
      ]);
      setAllSessions(allSessions.filter((session) => {
        return !session.name.endsWith(' - ');
      }));
      setAllTrainings(allTrainings);
      setBlockUi(false);
    };
    load();
  }, []);

  useEffect(() => {
    if (allTrainings.length && allSessions.length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        if (inputRef.current) {
          const el = inputRef.current.querySelector('#trainings-combo');
          try {
            el.focus();
          } catch (e) { }
        }
      }, 250)
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [allTrainings.length, allSessions.length]);

  const filterTrainings = async (selectedTraining) => {
    if (selectedTraining) {
      setSeletectedTraining(selectedTraining);
      setBlockUi(true);
      const sessionIds = filterSessionsByTrainingId(selectedTraining.id).map(({ id }) => id);
      const { data: { rows } } = await Http.get(`/sessions/full?limit=100&ids=${sessionIds.join(',')}`);
      const disciplines = rows.reduce((prev, current) => {
        // const newDisciplines = current.disciplines.filter(({ id: disciplineId }) => {
        //   return prev.some(({ id }) => {
        //     return id === disciplineId;
        //   }) === false;
        // });
        // return (newDisciplines.length)
        //   ? [...prev, ...newDisciplines]
        //   : prev;
        return [...prev, ...current.disciplines];
      }, []);
      setBlockUi(false);
      setDisciplines(disciplines);
      // setSelectedSession(null);
    }
  };

  const filterSessionsByTrainingId = (trainingId) => {
    return allSessions.filter(({ training_id }) => {
      return training_id === trainingId;
    });
  };

  const cartItemEquals = (c1, c2) => {
    return c1.discipline_id === c2.discipline_id && c1.session_id === c2.session_id && c1.everyDiscipline === c2.everyDiscipline;
  };

  const handleCartAdd = (discipline, saveDisciplineAndSession, everyDiscipline = false) => {
    return (event) => {
      const payload = {
        discipline_id: discipline.id,
        sessionName: saveDisciplineAndSession ? selectedSession.name : 'Toutes les sessions',
        disciplineName: discipline.name,
        session_id: saveDisciplineAndSession ? selectedSession.id : null,
        everyDiscipline,
      };

      setCart((state) => {
        const found = state.some((cartItem) => {
          // return JSON.stringify(cartItem) === JSON.stringify(payload);
          return cartItemEquals(cartItem, payload);
        });
        return found ? state : [...state, payload];
      });
    };
  };

  const sessions = selectedTraining
    ? filterSessionsByTrainingId(selectedTraining.id)
    : [];

  const cartGroupBySession = groupCartBySession(cart);

  const handleCartDelete = (deletableCartItem) => {
    return (event) => {
      setCart((state) => {
        return state.filter((cartItem) => {
          // return JSON.stringify(cartItem) !== JSON.stringify(deletableCartItem);
          return cartItemEquals(cartItem, deletableCartItem) === false;
        });
      });
    };
  };

  const onInternalSubmit = () => {
    onSubmit(cart);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    // fullScreen
    >
      <Backdrop className={classes.backdrop} open={blockUi} style={{ zIndex: 999999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          {dialogTitle}
        </Typography>
        <Box ml={2}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            disableElevation
            onClick={onInternalSubmit}
            disabled={cart.length === 0}
          >
            {submitLabel}
          </Button>
        </Box>
      </Toolbar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        {
          (allTrainings.length === 0)
            ? (
              <Box>
                <Typography>Chargement des formations...</Typography>
              </Box>
            )
            : (allSessions.length === 0)
              ? (
                <Box>
                  <Typography>Chargement des sessions...</Typography>
                </Box>
              )
              : (
                <Box
                  style={{
                    gap: theme.spacing(1),
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  ref={inputRef}
                >
                  <Card>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ gap: theme.spacing(1) }}
                      m={1}
                    >
                      <BookmarkIcon />
                      <Autocomplete
                        id="trainings-combo"
                        getOptionLabel={(option) => option.title}
                        options={allTrainings}
                        value={selectedTraining}
                        size="small"
                        style={{ width: '100%' }}
                        filterOptions={(options, state) => {
                          const { inputValue } = state;
                          if (inputValue.length === 0) {
                            return options;
                          }
                          const jokers = inputValue.split(' ');
                          if (jokers.length) {
                            return options.filter((option) => {
                              return jokers.every((joker) => {
                                return option.title.toLowerCase().includes(joker.toLowerCase());
                              });
                            });
                          }
                          return options.filter((option) => {
                            return option.title.toLowerCase().includes(inputValue.toLowerCase());
                          });
                        }}
                        renderInput={(params) => <TextField {...params} label="Formations" variant="outlined" />}
                        onChange={(_evt, selectedTraining) => {
                          filterTrainings(selectedTraining);
                        }}
                        disableClearable
                      />
                    </Box>
                  </Card>
                  {selectedTraining !== null ? (
                    <Box
                      style={{
                        display: 'flex',
                        gap: theme.spacing(1),
                      }}
                    >
                      <Card style={{ flex: 1 }}>
                        <CardHeader
                          subheader={
                            <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                              <DomainIcon />
                              <Typography>Sessions</Typography>
                            </Box>
                          }
                        />
                        <Divider />
                        <List
                          dense
                          component="ul"
                          role="list"
                          disablePadding
                        >
                          {sessions.map((session) => {
                            const labelId = `session-${session.id}`;
                            return (
                              <ListItem
                                selected={selectedSession?.id === session.id}
                                divider
                                role="listitem"
                                button
                                onClick={() => setSelectedSession(session)}
                                key={labelId}
                              >
                                <ListItemText
                                  id={labelId}
                                  primary={
                                    <Badge color="secondary" badgeContent={cartGroupBySession[session.id] ? cartGroupBySession[session.id].length : 0}>{session.name}</Badge>
                                  }
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Card>
                      {selectedSession !== null ? (
                        <Card style={{ flex: 1 }}>
                          <CardHeader
                            subheader={
                              <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                                <ListAltIcon />
                                <Typography>{`Matières pour la session ${selectedSession.name}`}</Typography>
                              </Box>
                            }
                          />
                          <Divider />
                          <List
                            dense
                            component="ul"
                            role="list"
                          >
                            <ListItem>
                              <DisciplineList
                                selectedSession={selectedSession}
                                showEveryDisciplineButton={showEveryDisciplineButton}
                                cart={cart}
                                handleCartAdd={handleCartAdd}
                                disciplines={
                                  selectedTraining.disciplines.filter((discipline) => {
                                    return discipline.session_only === null;
                                  })
                                }
                                title="Tronc commun"
                              />
                            </ListItem>
                            <ListItem>
                              <DisciplineList
                                showEveryDisciplineButton={showEveryDisciplineButton}
                                cart={cart}
                                handleCartAdd={handleCartAdd}
                                disciplines={
                                  disciplines.filter((discipline) => {
                                    return selectedSession.id === discipline.disciplineSession.session_id;
                                  })
                                }
                                title="Matières spécifiques"
                              />
                            </ListItem>
                          </List>
                        </Card>
                      ) : null}
                    </Box>
                  ) : null}
                  {cart.length > 0 ? (
                    <Card>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ gap: theme.spacing(1) }}
                        m={1}
                        mx={2}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: theme.spacing(1),
                          }}
                        >
                          {Object.values(cartGroupBySession).map((cartItems) => {
                            const firstItem = cartItems.at(0);
                            return (
                              <Box key={firstItem.session_id} mb={1}>
                                <Box
                                  display="flex"
                                  style={{ gap: theme.spacing(1) }}
                                >
                                  <DomainIcon />
                                  <Typography variant="body1">{firstItem.sessionName}</Typography>
                                </Box>
                                <Box
                                  style={{
                                    display: 'flex',
                                    gap: theme.spacing(1),
                                    marginTop: theme.spacing(1),
                                    paddingTop: theme.spacing(1),
                                    paddingBottom: theme.spacing(1),
                                  }}
                                >
                                  {cartItems.map(({
                                    discipline_id,
                                    // sessionName,
                                    disciplineName,
                                    session_id,
                                    everyDiscipline,
                                  }, index) => {
                                    return (
                                      <Chip
                                        color={everyDiscipline ? 'primary' : 'secondary'}
                                        size="small"
                                        key={`${session_id}-${discipline_id}`}
                                        avatar={<ListAltIcon />}
                                        label={
                                          <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1) }}>
                                            <Typography>
                                              {`${disciplineName}`}
                                            </Typography>
                                            {everyDiscipline ? <AccountTreeIcon /> : null}
                                          </Box>
                                        }
                                        onDelete={handleCartDelete(cartItems.at(index))}
                                      />
                                    )
                                  })}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Card>
                  ) : null}
                </Box>
              )
        }
      </DialogContent>
    </Dialog >
  );
};

export default DisciplineSessionDialog;
