export const styles = theme => ({
  cardContent: {
    overflowY: 'scroll',
    scrollBehavior: 'smooth',
    transition: theme.transitions.create('height'),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});
