import React from 'react';
import SortableCell from 'components/Datagrid/SortableCell';
import FilterInput from 'components/Datagrid/FilterInput';

const withLocalSortAndFilter = (WrappedComponent, defaultSortBy, defaultSortDir = 'asc', defaultIsString = false) => (
    class extends React.Component {
        state = {
            // sort
            direction: defaultSortDir,
            sortBy: defaultSortBy,
            // data
            data: [],
            rows: [],
            filter: {},
            isString: defaultIsString,
        };

        sortBy(sortBy, direction, isString = false, customGetValue = null) {
            const rows = this._filter(this.state.data, this.state.filter)
            const nested = sortBy.split('.');
            const getValue = (row) => {
                try {
                    if (customGetValue && typeof customGetValue === 'function') {
                        return customGetValue(row, sortBy);
                    }

                    switch (nested.length) {
                        case 2: return row[nested[0]][nested[1]];
                        case 3: return row[nested[0]][nested[1]][nested[2]];
                        case 4: return row[nested[0]][nested[1]][nested[2]][nested[3]];
                        default: return row[sortBy] ?? '';
                    }
                } catch (e) {
                    return isString ? '' : 0;
                }
            }
            if (direction === 'asc') {
                rows.sort((a, b) => (isString ? getValue(a).localeCompare(getValue(b)) : getValue(a) - getValue(b)));
            } else {
                rows.sort((a, b) => (isString ? getValue(b).localeCompare(getValue(a)) : getValue(b) - getValue(a)));
            }
            this.setState({
                rows,
                sortBy,
                direction,
                isString,
            });

        }

        sortByCurrent = (data) => {
            const { sortBy, direction, isString } = this.state;
            const nested = sortBy.split('.');
            const getValue = (row) => {
                try {
                    switch (nested.length) {
                        case 2: return row[nested[0]][nested[1]];
                        case 3: return row[nested[0]][nested[1]][nested[2]];
                        case 4: return row[nested[0]][nested[1]][nested[2]][nested[3]];
                        default: return row[sortBy] ?? '';
                    }
                } catch (e) {
                    return isString ? '' : 0;
                }
            }
            const rows = [...data];
            if (direction === 'asc') {
                rows.sort((a, b) => (isString ? getValue(a).localeCompare(getValue(b)) : getValue(a) - getValue(b)));
            } else {
                rows.sort((a, b) => (isString ? getValue(b).localeCompare(getValue(a)) : getValue(b) - getValue(a)));
            }
            this.setState({ data, rows });
        }

        _filter(data, filter, filterExpression = null) {
            const key = Object.keys(filter).pop();

            if (key === undefined || filter[key] === undefined) return data;

            const value = filter[key];

            const re = new RegExp(value, 'i');
            const nested = key.split('.'); // nested filter, example: student.login
            return data.filter(d => {
                let searchIn;
                switch (nested.length) {
                    case 2: searchIn = d[nested[0]][nested[1]]; break;
                    case 3: searchIn = d[nested[0]][nested[1]][nested[2]]; break;
                    default: searchIn = d[key]; break;
                }
                return filterExpression === null ? re.test(searchIn) : Boolean(filterExpression(value, searchIn));
            })
        }

        filter(filter, filterExpression = null) {
            this.setState(state => ({
                rows: this._filter(state.data, filter, filterExpression),
                filter,
            }));
        }

        render() {
            const { sortBy, direction, rows } = this.state;

            return (
                <WrappedComponent
                    {...this.props}
                    filter={(newFilter, merge = true) => {
                        const { filter } = this.state;
                        this.filter(merge ? { ...filter, ...newFilter } : newFilter);
                    }}
                    setData={(data, preserveFilter = false) => {
                        if (preserveFilter) {
                            this.sortByCurrent(data);
                        } else {
                            this.setState({ data, rows: data });
                        }
                    }}
                    sortByCurrent={this.sortByCurrent}
                    rows={rows}
                    renderSortCell={(cell) => (
                        <SortableCell
                            key={cell.name}
                            sortBy={sortBy}
                            direction={direction}
                            {...cell}
                            onClick={(sortBy, direction) => { this.sortBy(sortBy, direction, cell.isString, cell?.getValue); }}
                        />
                    )}
                    renderFilter={(placeholder, key, filterExpression = null, inputBaseProps = undefined) => (
                        <FilterInput
                            filter={(filter) => this.filter(filter, filterExpression)}
                            filterBy={key}
                            placeholder={placeholder}
                            inputBaseProps={inputBaseProps}
                        />
                    )}
                />
            );
        }
    }
);

export default withLocalSortAndFilter;
