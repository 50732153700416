import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import { withStyles } from '@material-ui/core/styles';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  NativeSelect,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Http from 'services/Http';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Trainings.css';
import CopyDisciplinesDialog from 'components/CopyDisciplinesDialog/CopyDisciplinesDialog';
import { TrainingProductStatus, mapTrainingAndProductStatusToLabel, trainingProductStatuses } from './TrainingStatus';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';

const options = trainingProductStatuses.filter(({ trainingStatus, productStatus }) => {
  const search = [
    ['publish', 'publish'],
    ['publish', 'draft'],
    ['private', 'draft'],
    ['draft', 'draft'],
  ];
  return search.some(([a, b]) => {
    return a === trainingStatus && b === productStatus;
  });
});

const SEPARATOR = '____';
class Trainings extends Component {
  state = {
    copyDisciplinesDialog: false,
    disciplinesForCopy: [],
    trainings: [],
    trainingTitleForCopy: '',
    confirmStatusDialog: null,
    hideClosedTrainings: true,
    loading: true,
    loadingRemoveFiles: false,
    removeFilesReport: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const { hideClosedTrainings } = this.state;
    this.setState({
      loading: true,
    });
    const trainings = await Http.get('/trainings?publishedOnly=false');
    // for perf reason, make the data computation before storing it in the state
    this.setState({
      loading: false,
      trainings: trainings.data.rows.filter((row) => {
        return hideClosedTrainings
          ? ['publish', 'private'].includes(row.status)
          : true;
      }),
    });
  }

  filterByStatus = (event) => {
    this.setState({ hideClosedTrainings: event.target.checked });
    setTimeout(() => {
      this.fetchData();
    }, 0);
  };

  onOpenCopyDisciplinesDialog = (trainingTitleForCopy, disciplinesForCopy) => {
    this.setState({
      copyDisciplinesDialog: true,
      disciplinesForCopy,
      trainingTitleForCopy,
    });
  }

  onCloseCopyDisciplinesDialog = () => {
    this.setState({ copyDisciplinesDialog: false });
  }

  updateTrainingStatus = async (id, trainingStatus, productStatus) => {
    await Http.post('/trainings/updateTrainingStatus', {
      id,
      trainingStatus,
      productStatus,
    });
    this.setState({ confirmStatusDialog: null });
    this.fetchData();
  }

  render() {
    const { classes } = this.props;
    const {
      copyDisciplinesDialog,
      disciplinesForCopy,
      trainings,
      trainingTitleForCopy,
      confirmStatusDialog,
      hideClosedTrainings,
      loading,
      loadingRemoveFiles,
      removeFilesReport,
    } = this.state;

    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper
        elevation={1}
        className={classes.root}
        style={loadingStyle}
      >
        {removeFilesReport.length ? (
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '6px', textAlign: 'left', padding: '8px', alignItems: 'flex-start' }}
          >
            <Button onClick={() => this.setState({ removeFilesReport: [] })}>
              Fermer
            </Button>
            {removeFilesReport.map((s, key) => {
              return (
                <pre key={key} style={{ background: '#fff', textAlign: 'left' }}>
                  {`Batch : ${key + 1}/${removeFilesReport.length}
${s.stdout}
------------------------------------------
`}
                </pre>
              );
            })}
            <Button onClick={() => this.setState({ removeFilesReport: [] })}>
              Fermer
            </Button>
          </Box>
        ) : null}
        <Backdrop
          open={loadingRemoveFiles}
          style={{ zIndex: 999999, backdropFilter: 'blur(1.5px)' }}
        >
          <Box
            style={{
              display: 'flex',
              gap: '6px',
              flexDirection: 'column',
            }}
          >
            <Typography style={{ color: '#fff' }} variant="h4">Nettoyage des serveurs en cours...</Typography>
            <Typography style={{ color: '#fff' }}>Merci de ne pas fermer cette fenêtre</Typography>
          </Box>
        </Backdrop>
        <ConfirmationDialog
          open={confirmStatusDialog !== null}
          title="Votre attention"
          message={confirmStatusDialog ? `Êtes-vous sûr de vouloir changer le statut de la formation ${confirmStatusDialog.title} de "${mapTrainingAndProductStatusToLabel(...confirmStatusDialog.prevStatus.split(SEPARATOR))}" à "${mapTrainingAndProductStatusToLabel(...confirmStatusDialog.newStatus.split(SEPARATOR))}" ?` : ''}
          onClose={() => {
            this.setState({ confirmStatusDialog: null });
          }}
          onConfirm={() => {
            this.updateTrainingStatus(confirmStatusDialog.id, ...confirmStatusDialog.newStatus.split(SEPARATOR));
          }}
        />
        <CopyDisciplinesDialog
          disciplines={disciplinesForCopy}
          trainingTitle={trainingTitleForCopy}
          onClose={this.onCloseCopyDisciplinesDialog}
          open={copyDisciplinesDialog}
        />
        <Box
          display="flex"
          p={2}
          pb={0}
        >
          <FormControlLabel
            label={
              hideClosedTrainings ? 'Cliquez pour afficher toutes les formations' : 'Cliquez pour masquer les formations fermées'
            }
            control={
              <Switch
                checked={hideClosedTrainings}
                onChange={this.filterByStatus}
              />
            }
          />
        </Box>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Concours</TableCell>
              <TableCell>Matières</TableCell>
              <TableCell>Créé le</TableCell>
              {/* <TableCell>Publié</TableCell> */}
              <TableCell colSpan="2">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trainings.map(t => (
              <TableRow key={t.id}>
                <TableCell component="th" scope="row">
                  {t.id}
                </TableCell>
                <TableCell>
                  <Box>
                    <Box display="flex" style={{ gap: '8px' }} alignItems="center">
                      <Typography>{t.title}</Typography>
                      {/* <TrainingStatus status={t.status} /> */}
                      <TrainingProductStatus
                        trainingStatus={t.status}
                        productStatus={t.product?.post_status}
                      />
                    </Box>
                    {t.product ? (
                      <Typography
                        variant="caption"
                        style={{ opacity: .75 }}
                      >
                        Produit : {t.product.title}
                      </Typography>
                    ) : null}
                  </Box>
                </TableCell>
                <TableCell>{t.exam?.title}</TableCell>
                <TableCell>
                  <Chip
                    className={classes.chip}
                    size="small"
                    color="primary"
                    label={t.disciplines.length}
                  />
                </TableCell>
                <TableCell>{format(new Date(t.created_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                {/* <TableCell><CheckCircleIcon style={{ color: t.status === 'publish' ? green[400] : grey[200] }} /></TableCell> */}
                <TableCell>
                  <Box
                    style={{
                      display: 'flex',
                      gap: '6px',
                      alignItems: 'flex-start',
                    }}
                  >
                    {t.disciplines[0] ? (
                      <Tooltip title="Dupliquer">
                        <IconButton
                          color="primary"
                          onClick={() => this.onOpenCopyDisciplinesDialog(t.title, t.disciplines)}
                          disableElevation
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {(t.status === 'draft' || t.status === 'trash') ? (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}
                      >
                        <Tooltip title="Supprimer les fichiers liés à cette formation">
                          <IconButton
                            color="secondary"
                            onClick={async () => {
                              const confirmResult = global.confirm('⚠️ VOTRE ATTENTION ⚠️\nVous êtes sur le point de supprimer tous les fichiers liés à cette formation. Si vous doutez, cliquez immédiatement sur ANNULER.');
                              if (confirmResult) {
                                const text = 'oui, je le veux';
                                const promptResult = global.prompt(`Veuillez écrire le texte entre "" pour confirmer la demande de suppression : "${text}"`);
                                if (promptResult === text) {
                                  this.setState({ loadingRemoveFiles: true });
                                  const { data } = await Http.get(`/trainings/runRemoveFilesFromServers/${t.title}`);
                                  this.setState({
                                    loadingRemoveFiles: false,
                                    removeFilesReport: data,
                                  });
                                  global.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                  this.fetchData();
                                }
                              }
                            }}
                            disableElevation
                          >
                            <LocalLaundryServiceIcon />
                          </IconButton>
                        </Tooltip>
                        {t.cleaned_at ? (
                          <Typography variant="caption" align="center">
                            {format(new Date(t.cleaned_at), 'dd/MM/yyyy', { locale: frLocale })}
                            <br />
                            {format(new Date(t.cleaned_at), 'HH:mm', { locale: frLocale })}
                          </Typography>
                        ) : null}
                      </Box>
                    ) : null}



                  </Box>
                </TableCell>
                <TableCell>
                  <NativeSelect
                    style={{
                      fontSize: '12px',
                      minWidth: '300px',
                    }}
                    value={`${t.status}${SEPARATOR}${t.product?.post_status}`}
                    onChange={(event) => {
                      this.setState({
                        confirmStatusDialog: {
                          newStatus: event.target.value,
                          prevStatus: `${t.status}${SEPARATOR}${t.product?.post_status ?? ''}`,
                          title: t.title,
                          id: t.id,
                        },
                      });
                    }}
                  >
                    <option>Changer le statut</option>
                    {options.map((option) => {
                      return (
                        <option
                          key={`${option.trainingStatus}${SEPARATOR}${option.productStatus}`}
                          value={`${option.trainingStatus}${SEPARATOR}${option.productStatus}`}
                        >
                          {option.label}
                        </option>
                      );
                    })}
                  </NativeSelect>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

Trainings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(Trainings);
