import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  InputBase,
  Toolbar,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styles } from './StudentComments.css';
import { tableStyles } from 'styles/datatable.css';

class StudentCommentsDialog extends Component {

  state = {
    data: false,
    openSuccess: false,
    openError: false,
    message: '',
  };

  onDialogClose = () => {
    this.props.onClose();
  }

  onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    this.props.onSubmit({
      ...this.props.comment,
      comment: formData.get('comment'),
    });
  }

  render() {
    const {
      open,
      classes,
      comment,
    } = this.props;
    return (
      <Dialog
        scroll="paper"
        open={open}
        onClose={this.onDialogClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            {`${comment ? 'Editer' : 'Ajouter'} un commentaire`}
          </Typography>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            onClick={this.onDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent style={{ padding: '12px 12px 12px 12px' }}>
            <Box width="100%">
              <form id="form-comment" onSubmit={this.onSubmit}>
                <InputBase
                  required
                  name="comment"
                  classes={{
                    root: classes.field,
                    input: classes.fieldInput,
                  }}
                  placeholder="Commentaire"
                  defaultValue={comment ? comment.comment : ''}
                  fullWidth
                  multiline
                />
              </form>
            </Box>
        </DialogContent>
        <DialogActions style={{ padding: '0px 12px 12px 12px' }}>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            type="submit"
            form="form-comment"
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

StudentCommentsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  comment: PropTypes.object,
};

export default withMobileDialog()(withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  StudentCommentsDialog
));
