import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    TableCell,
    TableSortLabel,
    Tooltip,
} from '@material-ui/core';

const SortableCell = ({
    direction,
    label,
    name,
    onClick,
    sortBy,
    children = null,
    cellStyle = null,
}) => {
    return (
        <TableCell style={cellStyle}>
            <Tooltip
                title={`Trier par ${label}`}
                enterDelay={300}
            >
                <TableSortLabel
                    active={name === sortBy}
                    direction={direction}
                    onClick={() => onClick(
                        name,
                        direction === 'asc' ? 'desc' : 'asc',
                    )}
                >
                    {label}
                </TableSortLabel>
            </Tooltip>
            {children ? (
                <Box mb={1}>
                    {children}
                </Box>
            ) : null}
        </TableCell>
    );
};

SortableCell.propTypes = {
    direction: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    sortBy: PropTypes.string,
    children: PropTypes.element,
};

SortableCell.defaultProps = {
    direction: 'asc',
    label: 'id',
    name: 'id',
    onClick: (sortBy, direction) => console.log(sortBy, direction),
    sortBy: 'id',
    children: null,
};

export default SortableCell;
