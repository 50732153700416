import { FormControl, MenuItem, Select } from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

type Categorie = {
  name: string
  bgColor: string
  color: string
}

type VideoCategorySelectProps = {
  category: Categorie['name']
  categories: Array<Categorie>
  onChange?: SelectInputProps['onChange']
}
export default function VideoCategorySelect({
  category,
  categories,
  onChange,
  ...props
}: VideoCategorySelectProps) {
  const lowerCasedCategory = (category ?? '').toLocaleLowerCase();
  const selected = categories.find(cat => cat.name.toLocaleLowerCase() === lowerCasedCategory);
  const size = categories.reduce((prev, curr) => {
    return Math.max(prev, curr.name.length);
  }, 0);

  return <FormControl>
    <Select
      value={lowerCasedCategory}
      style={{
        fontSize: '.9em',
        backgroundColor: selected?.bgColor ?? 'inherit',
        color: selected?.color ?? 'inherit',
        padding: '.2em .4em',
        borderRadius: '.4em',
        width: `calc(${size} * 1em)`,
      }}
      onChange={onChange}
      disableUnderline
    >
      <MenuItem value="">
        <em>Aucune</em>
      </MenuItem>
      {categories.map(({ name, bgColor, color }) => {
        return <MenuItem
          key={name}
          value={name.toLocaleLowerCase()}
          style={{
            backgroundColor: bgColor,
            color: color,
          }}
        >{name}</MenuItem>;
      })}
    </Select>
  </FormControl>;

}