import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => {
  return {
    '@keyframes myEffect': {
      "0%": {
        opacity: 0,
        transform: "translateY(-25%)"
      },
      "100%": {
        opacity: 1,
        transform: "translateY(25%)"
      }
    },
    mergeIndicator: {
      animation: `2500ms ${theme.transitions.easing.easeInOut} infinite alternate $myEffect`,
      position: 'absolute',
      bottom: '-34px',
      left: 'calc(50% - 24px)',
      width: '50px',
      height: '48px',
      padding: '10px',
      background: '#fff',
      borderRadius: '100%',
      textAlign: 'center',
      border:' 1px solid #eee',
      zIndex: 1,
    },
    droppableZone: {
      padding: theme.spacing(2),
      flex: 1,
      display: 'flex',
      gap: theme.spacing(2),
      position: 'relative',
      border: `1px solid ${alpha(theme.palette.primary.main, .5)}`,
      transition: theme.transitions.create('border'),
      background: theme.palette.grey['200'],
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'center',
      alignContent: 'center',
    },
    droppableZoneOver: {
      border: `1px solid ${theme.palette.primary.main}`,
      background: theme.palette.grey['300'],
    },
    draggableSession: {
      cursor: 'grab',
      maxWidth: `calc(50% - ${theme.spacing(1)}px)`,
    },
    draggableSessionDragging: {
      opacity: .5,
    },
    dragOverlay: {
      boxShadow: theme.shadows[5],
      maxWidth: 'none',
    },
    droppableZoneBackground: {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      backgroundColor: theme.palette.primary.main,
    },
  };
});

export default useStyles;
