import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
} from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { withStyles } from '@material-ui/core/styles';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import { styles } from './TopicHome.css';

const TopicHome = ({ classes, data, openTopicDialogNote, showTab }) => (
    <Box textAlign="left" px={3} pt={3}>
        <div className={classes.dashboard}>
            <div className={classes.dashboardItem} onClick={() => showTab(1)}>
                <ListAltIcon fontSize="inherit" />
                <Typography variant="h5">Copies</Typography>
                <Typography variant="h4" color="primary">{data.homework.filter(h => h.name).length}</Typography>
            </div>
            <div className={classes.dashboardItem} onClick={() => showTab(1)}>
                <ListAltIcon fontSize="inherit" />
                <Typography variant="h5">Corrections</Typography>
                <Typography variant="h4" color="primary">{data.homework.filter(h => h.corrige_url).length}</Typography>
            </div>
            <div className={classes.dashboardItem} notes={data} onClick={() => openTopicDialogNote()}>
                <ListAltIcon fontSize="inherit" />
                <Typography variant="h5">Notes</Typography>
                <Typography variant="subtitle2" color="primary">Min : {data.min || 0} / 20</Typography>
                <Typography variant="subtitle2" color="primary">Max : {data.max || 0} / 20</Typography>
                <Typography variant="subtitle2" color="primary">Moy : {data.average || 0} / 20</Typography>
                {data.ispAverage && (<Typography variant="subtitle2" color="primary">Moy ISP : {data.ispAverage} / 20</Typography>)}
            </div>
        </div>
        <ButtonRouter
            title="Retour"
            size="small"
            color="secondary"
            variant="contained"
            label="Retour"
            to={'/topics'}
        />
    </Box>
);

TopicHome.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    showTab: PropTypes.func.isRequired,
};

export default withStyles(styles)(TopicHome);
