import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Select,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorSnackbar from 'components/Snackbar/Error';
import Http from 'services/Http';

const styles = (_theme) => ({
  formControl: {
    minWidth: 120,
  },
});

class Statistics extends Component {
  state = {
    error: '',
    loading: false,
    openError: false,
    trainings: [],
    trainingId: null,
  }

  async componentDidMount() {
    const trainings = await Http.get('/trainings/list');
    this.setState({
      trainings: trainings.data,
    });
  }

  onTrainingSelected = ({ target }) => {
    const trainingId = parseInt(target.value, 10);
    if (trainingId) {
      this.setState({
        trainingId,
      });
    }
  };

  downloadBuffer = (buffer, filename) => {
    const url = URL.createObjectURL(new Blob([buffer.data]));
    const link = global.document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    global.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  onCsvExport = async (context, exportType) => {
    const { trainingId } = this.state;
    if (!trainingId) {
      this.setState({
        error: ['Veuillez d\'abord choisir une formation'],
        openError: true,
      });
      return;
    }

    this.setState({ loading: true });
    try {
      const buffer = await Http.post(`/statistics/${context}/csvExport`, { trainingId, exportType }, {
        responseType: 'blob',
      });
      this.downloadBuffer(buffer, `statistics-${context}-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.${exportType}`);
    } catch ({ data }) {
      this.setState({
        error: ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loading: false });
  };

  render() {
    const {
      classes,
    } = this.props;
    const {
      error,
      loading,
      openError,
      trainings,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />

        <Box align="left" p={3}>
          <FormControl
            className={classes.formControl}
            required
          >
            <InputLabel shrink htmlFor="training">
              Formation
            </InputLabel>
            <Select
              onChange={this.onTrainingSelected}
              input={<Input name="training" />}
              native
            >
              <option value="">Choix</option>
              {trainings && trainings.map((training) => (
                <option key={training.id} value={training.id}>{training.title}</option>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box px={3} pb={3}>
          <Box
            pb={2}
            display="flex"
            flex="1"
            justifyContent="flex-start"
            alignItems="flex-start"
            maxWidth="700px"
          >
            <Box display="flex" flex="1" pr={1}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => this.onCsvExport('copies', 'csv')}
              >
                Export CSV : copies et vidéos
              </Button>
            </Box>
            <Box display="flex" flex="1" pl={1}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disableElevation
                onClick={() => this.onCsvExport('copies', 'xlsx')}
              >
                Export XLS : copies et vidéos
              </Button>
            </Box>
          </Box>
          <Box
            pb={2}
            display="flex"
            flex="1"
            justifyContent="flex-start"
            alignItems="flex-start"
            maxWidth="700px"
          >
            <Box display="flex" flex="1" pr={1}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => this.onCsvExport('notes', 'csv')}
              >
                Export CSV : notes
              </Button>
            </Box>
            <Box display="flex" flex="1" pl={1}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                disableElevation
                onClick={() => this.onCsvExport('notes', 'xlsx')}
              >
                Export XLS : notes
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }
}

Statistics.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Statistics);
