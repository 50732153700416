import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormGroup,
  IconButton,
  InputBase,
  Toolbar,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './TagDialogManage.css';

class TagDialogManage extends Component {
  state = {
    error: [],
    openError: false,
    openSuccess: false,
    newTag: '',
    success: '',
    tags: [],
    tagsById: {},
  };

  computeState(state = {}) {
    this.setState({
      ...state,
      newTag: '',
      tagsById: this.props.tags.reduce((prev, { id, name }) => {
        prev[`key-${id}`] = { id, name };
        return prev;
      }, {}),
    });
  }

  componentDidMount() {
    this.computeState();
  }

  handleTagNew = ({ target }) => {
    this.setState({
      newTag: target.value,
    });
  }

  handleTagChange = ({ target }, tagId) => {
    const tagsById = { ...this.state.tagsById };
    if (tagsById[`key-${tagId}`]) {
      tagsById[`key-${tagId}`].name = target.value;
      this.setState({ tagsById });
    }
  }

  createTag = async () => {
    if (this.state.newTag) {
      try {
        await Http.post(
          `/tags`,
          { name: this.state.newTag },
        );
        await this.props.loadTags();
        this.computeState();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
        });
      }
    }
  }

  changeTag = async (tagId) => {
    const tag = this.state.tagsById[`key-${tagId}`];
    if (!tag) {
      this.setState({
        error: [`Aucun tag trouvé pour l'id ${tagId}`],
        openError: true,
      });
      return;
    }

    try {
      await Http.put(
        `/tags/${tagId}`,
        { name: tag.name },
      );
      await this.props.loadTags();
      await this.props.reload();
      this.computeState({
        success: 'Le tag a été mis à jour',
        openSuccess: true,
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  deleteTag = async (tagId) => {
    try {
      await Http.delete(`/tags/${tagId}`);
      await this.props.loadTags();
      await this.props.reload();
      this.computeState();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  closeDialog = () => {
    this.props.onClose();
    this.computeState();
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      tags,
    } = this.props;
    const {
      error,
      openError,
      openSuccess,
      success,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <ErrorSnackbar
          open={openError}
          onClose={() => this.setState({ openError: false })}
          message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
        />
        <SuccessSnackbar
          open={openSuccess}
          onClose={() => this.setState({ openSuccess: false })}
          message={success}
        />
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={this.closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            Gestion des tags
          </Typography>
        </Toolbar>

        <DialogContent className={classes.dialog}>
          <FormGroup className={classes.formGroup}>
            {tags.length ? (
              <div>
                {tags.map((t) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    key={`tag-${t.id}`}
                    mb={1}
                  >
                    <InputBase
                      classes={{
                          root: classes.field,
                          input: classes.fieldInput,
                      }}
                      defaultValue={t.name}
                      onChange={evt => this.handleTagChange(evt, t.id)}
                      onKeyPress={({ key }) => {
                        if (key === 'Enter') {
                          this.changeTag(t.id);
                        }
                      }}
                    />
                    <Box pl={1}>
                      <IconButton
                        size="small"
                        onClick={() => this.changeTag(t.id)}
                      >
                        <SaveIcon />
                      </IconButton>
                    </Box>
                    <Box pl={6}>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        disableElevation
                        onClick={() => this.deleteTag(t.id)}
                      >
                        supprimer
                      </Button>
                    </Box>
                  </Box>
                ))}
              </div>
            ) : null}
            <Box
              display="flex"
              alignItems="center"
            >
              <InputBase
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                placeholder="Ajouter un tag"
                onChange={this.handleTagNew}
                value={this.state.newTag}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    this.createTag();
                  }
                }}
              />
              <Box pl={1}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={this.createTag}
                >
                  <AddCircleIcon />
                </IconButton>
              </Box>
            </Box>
          </FormGroup>
        </DialogContent>
      </Dialog>
    );
  }
}

TagDialogManage.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  loadTags: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withMobileDialog()(withStyles(styles)(
  TagDialogManage
));
