import React from 'react';
import PropTypes from 'prop-types';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './SnackBarMessage.css';
import SnackBarBase from './SnackBarBase';

const SuccessSnackbar = ({ classes, message, onClose, open }) => (
    <SnackBarBase
        className="success"
        icon={<SuccessIcon className={classes.icon} />}
        message={message}
        onClose={onClose}
        open={open}
    />
);

SuccessSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SuccessSnackbar);