import { Box, Fade, IconButton, InputBase, Typography } from '@material-ui/core';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Http from 'services/Http';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { forwardRef, useState } from 'react';
import { tableStyles } from 'styles/datatable.css';
import { makeStyles } from '@material-ui/core/styles';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import fr from 'date-fns/locale/fr';
import isValid from 'date-fns/isValid';
import { Alert } from '@material-ui/lab';
import { format } from 'date-fns';
import { getSentLabel } from 'components/Polycopies/Polycopies';
registerLocale('fr', fr);
setDefaultLocale('fr');

const useStyles = makeStyles((theme) => tableStyles(theme));

const CustomDateInput = forwardRef(({
  onClick,
}, ref) => {
  return (
    <IconButton onClick={onClick}>
      <CalendarIcon fontSize="small" />
    </IconButton>
  );
});

const PolycopieStatusDialog = ({
  open,
  onClose,
  onConfirm,
  polycopieLabel,
  polycopieId,
  studentId,
  polycopieSent, // 0 | 1 | 2
  polycopieSentAt,
  bulkMode = false,
}) => {
  const [date, setDate] = useState(polycopieSentAt ? new Date(polycopieSentAt) : new Date());
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(format(date, 'dd/MM/yyyy'));
  const [validDate, setValidDate] = useState(true);

  const onCloseHandler = () => {
    onClose?.();
  };

  const onConfirmHandler = async () => {
    if (!bulkMode) {
      await Http.post('/polycopies/setSentStateByStudentId', {
        value: polycopieSent,
        studentId,
        polycopiesIds: [polycopieId],
        polycopieSentAt: format(date, 'yyyy-MM-dd'),
      });
    }
    onConfirm?.(format(date, 'yyyy-MM-dd'));
    setDate(new Date());
    setInputValue(format(new Date(), 'dd/MM/yyyy'));
  };

  return (
    <ConfirmationDialog
      open={open}
      title="Attention"
      message={
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: '16px' }}
        >
          {[1, 2].includes(polycopieSent) ? (
            <Box>
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: '8px' }}
              >
                <Typography>Date d'envoi : </Typography>
                <InputBase
                  classes={{
                    root: classes.field,
                    input: classes.fieldInput,
                  }}
                  placeholder="dd/mm/yyyy"
                  onChange={(event) => {
                    const v = event.target.value;
                    const [day, month, year] = v.split('/');
                    const date = new Date(`${year}-${month}-${day}`);
                    const validDate = isValid(date);
                    setValidDate(validDate);
                    if (validDate) {
                      setDate(new Date(`${year}-${month}-${day}`));
                    }
                    setInputValue(v);
                  }}
                  value={inputValue}
                  onKeyPress={({ key }) => {
                    if (key === 'Enter') {
                        onConfirmHandler();
                    }
                  }}
                />
                <ReactDatePicker
                  withPortal
                  selected={date}
                  onChange={(date) => {
                    setDate(date);
                    setInputValue(format(date, 'dd/MM/yyyy'));
                  }}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <CustomDateInput />
                  }
                />
              </Box>
              <Fade in={!validDate} appear unmountOnExit>
                <Alert variant="filled" severity="error">Date invalide</Alert>
              </Fade>
            </Box>
          ) : null}
          <Typography>
            {bulkMode
              ? `Êtes-vous sûr de vouloir passer à l'état "${getSentLabel(polycopieSent)}" ?`
              : `Êtes-vous sûr de vouloir passer le polycopié ${polycopieLabel} à l'état "${getSentLabel(polycopieSent)}" ?`
            }
          </Typography>
        </Box>
      }
      onClose={onCloseHandler}
      onConfirm={onConfirmHandler}
    />
  );
};

export default PolycopieStatusDialog;
