import { alpha } from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
    editorRoot: {
        '& .pell-actionbar': {
            border: 0,
            backgroundColor: alpha(theme.palette.common.black, 0.05),
            '&:hover': {
                backgroundColor: alpha(theme.palette.common.black, 0.1),
            },
        },
        '& .pell-button': {
            margin: theme.spacing(1)
        },
        '& .pell-content': {
            border: `1px solid ${alpha(theme.palette.common.black, 0.05)}`,
            borderTop: 0,
        },
    }
});
