import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const ConfirmationDialog = React.memo(({
  message,
  onClose,
  onConfirm,
  open,
  title,
  yesLabel = 'Oui',
  noLabel = 'Non',
  disableYes = false,
  disableNo = false,
}) => (
    <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          {typeof message === 'string' ? (
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
          ) : message}
        </DialogContent>
        <DialogActions>
          <Button disabled={disableNo} onClick={onClose} color="primary">
            {noLabel}
          </Button>
          <Button disabled={disableYes} onClick={onConfirm} color="primary" autoFocus>
            {yesLabel}
          </Button>
        </DialogActions>
    </Dialog>
));

ConfirmationDialog.propTypes = {
    message: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    yesLabel: PropTypes.string,
    noLabel: PropTypes.string,
};

export default ConfirmationDialog;
