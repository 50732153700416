import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputBase,
    withMobileDialog,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './TopicEditDialog.css';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';

class TopicEditDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            error: [],
            file: null,
            name: '',
            openError: false,
            percentCompleted: 0,
        }
    }

    componentDidUpdate(prevProps) {
        const lastName = prevProps.topic ? prevProps.topic.name : '';
        const name = this.props.topic ? this.props.topic.name : '';
        if (lastName !== name) {
            this.setState({ name });
        }
    }

    resetState() {
        this.setState({
            error: [],
            file: null,
            name: '',
            percentCompleted: 0,
        });
    }

    handleFile = (event) => {
        event.preventDefault();
        this.setState({
            file: this.fileInput.current.files[0],
        });
    }

    onChange = ({ target }) => {
        const { name, value } = target;
        this.setState({ [name]: value });
    };

    save = async () => {
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ percentCompleted });
            },
        };
        const formData = new FormData();
        formData.append('name', this.state.name);
        if (this.state.file) {
            formData.append('file', this.state.file);
        }
        try {
            if (this.props.topic) {
                // update mode
                await Http.post(`/topics/upload/${this.props.topic.id}`, formData, config);
            } else {
                // create mode
                await Http.post('/topics/upload', formData, config);
            }
            this.resetState();
            this.props.onSave();
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
                percentCompleted: 0,
            });
        }
    }

    closeDialog = () => {
        this.resetState();
        this.props.onClose();
    }

    render() {
        const { classes, open, topic } = this.props;
        const { error, file, name, openError, percentCompleted } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    {topic ? 'Modification d\'un sujet' : 'Ajout d\'un sujet'}
                </DialogTitle>
                <DialogContent>
                    <Box mb={2}>
                        <InputBase
                            classes={{
                                root: classes.field,
                                input: classes.fieldInput,
                            }}
                            placeholder="Nom"
                            name="name"
                            value={name}
                            onChange={this.onChange}
                        />
                    </Box>
                    <DialogContentText
                        id="alert-dialog-description"
                        component="div"
                    >
                        {file ? (
                            <div key={file.name}>
                                <IconButton
                                    className={classes.button}
                                    onClick={() => this.setState({
                                        file: null,
                                    })}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {file.name}
                            </div>
                        ) : 'Choisissez un fichier'}
                    </DialogContentText>
                    <DialogContentText>
                        <input
                            accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.pdf"
                            style={{ display: 'none' }}
                            id="button-file"
                            type="file"
                            ref={this.fileInput}
                            onChange={this.handleFile}
                            multiple={false}
                        />
                        <label htmlFor="button-file">
                            <Button
                                color="primary"
                                component="span" 
                                variant="contained"
                            >
                                Parcourir
                            </Button>
                        </label>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ErrorSnackbar
                        open={openError}
                        onClose={() => this.setState({ openError: false })}
                        message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
                    />
                    <Button onClick={this.closeDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={this.save} color="primary" disabled={name.length === 0}>
                        {percentCompleted ? `${percentCompleted}%` : 'Sauvegarder'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TopicEditDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    topic: PropTypes.object,
};

TopicEditDialog.defaultProps = {
    topic: {},
};

export default withMobileDialog()(withStyles(styles)(
    TopicEditDialog
));
