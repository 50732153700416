import TrainingsDnd from './TrainingsDnd';
import { Box, Collapse } from '@material-ui/core';
import { useState } from 'react';
import SearchResults from './SearchResults';
import SearchResultChart from './SearchResultChart';

const SearchExclusionType = () => {
  const [submitResult, setSubmitResult] = useState(null);

  const getChartData = () => {
    const data = [
      {
        xAxisOver: submitResult.sources.map((id) => {
          return submitResult.allTrainings.find(({ id: trainingId }) => trainingId === id).title;
        }),
        name: 'Source',
        pv: submitResult.trainingSources.length,
      },
      {
        xAxisOver: submitResult.targets.map((id) => {
          return submitResult.allTrainings.find(({ id: trainingId }) => trainingId === id).title;
        }),
        name: 'Cible',
        pv: submitResult.trainingTargets.length,
      },
      {
        xAxisOver: null,
        name: 'Différence',
        pv: submitResult.difference.length,
      },
    ];
    return data;
  };

  return (
    <Box>
      <Collapse in={submitResult === null} appear>
        <TrainingsDnd
          onClear={() => {
            /** ... */
          }}
          onSubmit={(result) => {
            setSubmitResult(result);
          }}
        />
      </Collapse>
      {submitResult !== null ? (
        <Box
          display="flex"
          style={{ gap: '8px' }}
          flexDirection="column"
        >
          <Box mt={3}>
            <SearchResultChart chartData={getChartData()} />
          </Box>
          <SearchResults
            duplicatesUsers={[]}
            searchSummary={{
              type: 'Différence',
              labels: ['Tous les étudiants des matières "source" qui ne sont pas dans "cible"'],
            }}
            searchParams={{
              trainingIds: [...submitResult.sources, ...submitResult.targets],
            }}
            students={submitResult.difference}
            studentsCount={submitResult.difference.length}
            backToSearchHandler={() => {
              setSubmitResult(null);
            }}
          />
        </Box>
      ) : null}
    </Box>
  )
};

export default SearchExclusionType;
