import DateTimeDialog from './DateTimeDialog';
import { parseDates, saveDate } from './helpers';
import DescriptionDialog from './DescriptionDialog';
import Http from 'services/Http';
import TeacherDialogAffect from 'components/TeacherDialogAffect/TeacherDialogAffect';
import CustomTeacherDialog from './CustomTeacherDialog';
import { withTheme } from '@material-ui/core';
import CalendarActionDrawer from './CalendarActionDrawer';

const CalendarActions = ({
  action,
  reload,
  onClose,
  setSnackbar,
  timetable,
  setLoading,
  theme,
  onEventClick,
}) => {
  const notifySuccess = async (message, close = true) => {
    setSnackbar({
      type: 'success',
      message,
    });
    const { rows } = await reload();
    onEventClick({
      data: rows.find(({ id }) => id === timetable.id),
    }, action);
    if (close) {
      onClose();
    }
  };

  if (action === 'detailsDrawer') {
    return (
      <CalendarActionDrawer
        onClose={onClose}
        open={action === 'detailsDrawer'}
        timetable={timetable}
        notifySuccess={notifySuccess}
        setLoading={setLoading}
        setSnackbar={setSnackbar}
      />
    );
  }

  if (action === 'openCustomTeacherDialog') {
    return (
      <CustomTeacherDialog
        open
        onClose={onClose}
        timetable={timetable}
        onSubmit={async ({ teacherCustomName, id }) => {
          setLoading(true);
          await Http.post('/timetable', { teacherCustomName, id });
          notifySuccess('Professeur mis à jour !');
        }}
      />
    );
  }

  if (action === 'openTeacherDialog') {
    return (
      <TeacherDialogAffect
        basicMode
        open
        onClose={onClose}
        onAffect={async ({ teacher_id }) => {
          setLoading(true);
          await Http.post('/timetable/bulkUpdateTimetableTeacher', {
            teacherId: teacher_id,
            timetableIds: [timetable.id],
          });
          notifySuccess('Professeur affecté !');
        }}
      />
    );
  }

  if (action === 'openDescriptionDialog') {
    return (
      <DescriptionDialog
        onClose={onClose}
        defaultValue={timetable?.description ?? ''}
        onSave={async (html) => {
          setLoading(true);
          await Http.put(`/timetable/${timetable.id}`, {
            description: html,
          });
          notifySuccess('Description enregistrée !');
        }}
      />
    );
  }

  if (action === 'openDateDialog') {
    const dates = {
      startDate: timetable.start_date,
      endDate: timetable.end_date,
    };

    return (
      <DateTimeDialog
        open
        title="Créer une entrée"
        dateString={parseDates(dates).startDate.dateString}
        hourMinutesStart={parseDates(dates).startDate.hourMinutes}
        hourMinutesEnd={parseDates(dates).endDate.hourMinutes}
        onClose={onClose}
        onConfirm={async (startDate, endDate) => {
          setLoading(true);
          await saveDate({
            id: timetable.id,
            startDate,
            endDate,
          });
          notifySuccess('Plage horaire enregistrée !');
        }}
      />
    )
  }
  return null;
};

export default withTheme(CalendarActions);
