export const styles = theme => ({
  dashboard: {
    display: 'flex',
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  dashboardItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    padding: theme.spacing(2),
    width: 150,
    textAlign: 'center',
    fontSize: 64,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.background.default}`,
    transition: theme.transitions.create(
      ['border'],
      { duration: theme.transitions.duration.standard }
    ),
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.primary.main,
    },
  },
  wpIframe: {
    border: 'none',
    width: '100%',
    height: '75vh',
  },
  dialogToolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  dialogTitle: {
    flex: 1,
  },
  dialog: {
    padding: theme.spacing(3),
  },
  paperScrollPaperRoot: {
    margin: 0,
    maxHeight: '90vh',
  },
  caption: {
    display: 'block',
    marginTop: theme.spacing(1),
    fontStyle: 'italic',
  },
});
