import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Box,
  Button,
  Checkbox,
  Fade,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
  IconButton,
  Typography,
  Tooltip,
  NativeSelect,
  Toolbar,
} from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { tableStyles as styles } from 'styles/datatable.css';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import PolycopieStatusDialog from 'components/PolycopieStatusDialog/PolycopieStatusDialog';
import { Alert } from '@material-ui/lab';
import { getSentLabel } from 'components/Polycopies/Polycopies';
import CancelIcon from '@material-ui/icons/Cancel';
import PanToolIcon from '@material-ui/icons/PanTool';
import PolycopieDialog from 'components/PolycopieDialog/PolycopieDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import axios from 'axios';

const sortableCells = [
  { name: 'polycopie_name', label: 'Polycopié', isString: true },
  { name: 'discipline_name', label: 'Matière', isString: true },
  { name: 'training_name', label: 'Formation', isString: true },
  { name: 'sent', label: 'État', isString: false },
  { name: 'sent_at', label: 'Envoyé le', isString: true },
];

const initialState = () => {
  return {
    error: '',
    openError: false,
    openSuccess: false,
    success: '',
    loading: false,
    selectAll: false,
    polycopiesIds: [],
    statusDialog: null,
    doNotSend: false,
    setSentStateDialog: null,
    polycopieDialog: null,
    deleteDialog: false,
    polycopieManualToDelete: null,
    mostRecentTrainings: [],
    trainingIdFilter: -1,
  };
};

class StudentPolycopies extends Component {
  state = initialState();

  source = null;

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel(`Operation canceled by componentWillUnmount lifecycle method.`);
    }
  }

  async componentDidMount() {
    this.loadPolycopies();
  }

  loadPolycopies = async () => {
    this.setState({ loading: true });
    const { data } = await Http.get(`/polycopies?limit=1000&studentIds=${this.props.data.id}`);
    // get related trainings ordered by newest creation date
    const trainingIds = [...new Set(data.rows.map(({ training_id }) => training_id))];
    if (this.source) {
      this.source.cancel(`Operation canceled by the user.`);
    }
    this.source = axios.CancelToken.source();
    const { data: mostRecentTrainings } = await Http.get(`/trainings/mostRecent?ids=${trainingIds}`, {
      cancelToken: this.source.token,
    });
    this.setState({
      mostRecentTrainings,
      trainingIdFilter: this.state.trainingIdFilter === -1
        ? mostRecentTrainings.length > 1
          ? mostRecentTrainings.at(0).id
          : 0
        : this.state.trainingIdFilter,
    });
    this.props.setData(data.rows, true);
    const doNotSend = this.props.data.studentMeta && this.props.data.studentMeta.do_not_send === 1;
    this.setState({ loading: false, doNotSend });
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    this.setState({
      selectAll: checked,
      polycopiesIds: checked
        ? this.filterPolycopiesIdsByTrainingId(this.state.trainingIdFilter, this.props.rows.map(({ polycopie_id }) => polycopie_id))
        : [],
    });
  }

  onSelectOne = ({ target }) => {
    const { value } = target;
    const polycopieId = parseInt(value, 10);
    const { polycopiesIds } = this.state;
    const index = polycopiesIds.findIndex((id) => polycopieId === id);
    if (index !== -1) {
      polycopiesIds.splice(index, 1);
    } else {
      polycopiesIds.push(polycopieId);
    }
    this.setState({
      polycopiesIds,
      selectAll: polycopiesIds.length === this.props.rows.length,
    });
  }

  filterPolycopiesIdsByTrainingId = (trainingIdFilter, polycopiesIds) => {
    const { rows } = this.props;

    if (trainingIdFilter === 0) {
      return polycopiesIds;
    }

    return polycopiesIds.filter((polycopieId) => {
      const row = rows.find(({ polycopie_id }) => polycopie_id === polycopieId);
      if (row) {
        return row.training_id === trainingIdFilter;
      }
      return false;
    });
  };

  setSentState = async (value, sentAt) => {
    this.setState({ setSentStateDialog: null, loading: true });
    const { polycopiesIds } = this.state;
    const studentId = this.props.data.id;
    try {
      await Http.post('/polycopies/setSentStateByStudentId', {
        value,
        studentId,
        polycopiesIds,
        polycopieSentAt: sentAt,
      });
      this.loadPolycopies();
      this.setState({
        success: `La sélection a été marquée comme "${getSentLabel(value)}"`,
        loading: false,
        openSuccess: true,
        // polycopiesIds: [],
        // selectAll: false,
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        loading: false,
        openError: true,
      });
    }
  };

  onPolycopieManualDelete = async () => {
    this.setState({ loading: true });
    const { polycopieManualToDelete } = this.state;
    const payload = {
      user_id: this.props.data.id,
      polycopie_id: polycopieManualToDelete.polycopie_id,
      discipline_id: polycopieManualToDelete.discipline_id,
    };
    try {
      await Http.delete('/polycopies/deletePolycopieManual', { data: payload });
      this.setState({
        success: 'Le polycopié a été retiré',
        loading: false,
        openSuccess: true,
        deleteDialog: false,
        polycopieManualToDelete: null,
      });
      this.loadPolycopies();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        loading: false,
        openError: true,
        deleteDialog: false,
        polycopieManualToDelete: null,
      });
    }
  };

  render() {
    const {
      classes,
      renderSortCell,
      rows,
      goToProfileTab,
    } = this.props;
    const {
      loading,
      selectAll,
      polycopiesIds,
      error,
      openError,
      success,
      openSuccess,
      statusDialog,
      doNotSend,
      setSentStateDialog,
      polycopieDialog,
      deleteDialog,
      polycopieManualToDelete,
      mostRecentTrainings,
      trainingIdFilter,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper elevation={0} className={classes.root} style={loadingStyle}>
        <ConfirmationDialog
          open={deleteDialog}
          title="Attention"
          message={`Êtes-vous sûr de vouloir retirer le polycopié ${polycopieManualToDelete ? polycopieManualToDelete.polycopie_name : '-'} pour cet étudiant ?`}
          onClose={() => {
            this.setState({
              deleteDialog: false,
            });
          }}
          onConfirm={this.onPolycopieManualDelete}
        />
        <PolycopieDialog
          open={polycopieDialog !== null}
          onClose={() => {
            this.setState({ polycopieDialog: null });
          }}
          {...polycopieDialog}
          reload={() => {
            this.loadPolycopies();
          }}
        />
        <PolycopieStatusDialog
          open={statusDialog !== null}
          {...statusDialog}
          onConfirm={() => {
            this.loadPolycopies();
            this.setState({ statusDialog: null });
          }}
          onClose={() => {
            this.setState({ statusDialog: null });
          }}
        />
        <PolycopieStatusDialog
          open={setSentStateDialog !== null}
          bulkMode
          {...setSentStateDialog}
          onClose={() => {
            this.setState({ setSentStateDialog: null })
          }}
          onConfirm={(date) => {
            this.setSentState(setSentStateDialog.polycopieSent, date);
          }}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar>

          {doNotSend ? (
            <Alert
              severity="error"
              variant="filled"
              style={{ textAlign: 'left' }}
            >
              <Typography variant="inherit" component="p">
                Cet(te) élève <strong>ne peut pas</strong> recevoir des polycopiés : mode lecture seule, pas d'édition possible.<br />
                Vous pouvez modifier cet état dans
                <Button
                  style={{ marginLeft: '8px' }}
                  onClick={goToProfileTab}
                  color="primary"
                  size="small"
                  variant="contained">
                  l'onglet profil
                </Button>
              </Typography>
            </Alert>
          ) : (
            <Box
              display="flex"
              flex="1"
              justifyContent="flex-end"
              alignContent="center"
              style={{ gap: '6px' }}
              p={1}
            >
              {mostRecentTrainings.length > 1 ? (
                <NativeSelect
                  value={trainingIdFilter}
                  className={classes.selectInput}
                  onChange={(event) => {
                    this.setState({
                      trainingIdFilter: parseInt(event.currentTarget.value, 10),
                      polycopiesIds: this.filterPolycopiesIdsByTrainingId(parseInt(event.target.value, 10), polycopiesIds),
                    })
                  }}
                >
                  {[
                    { id: 0, title: 'Tout afficher' },
                    ...mostRecentTrainings,
                  ].map(({ id, title }) => {
                    return (
                      <option
                        key={id}
                        value={id}
                      >
                        {title}
                      </option>
                    );
                  })}
                </NativeSelect>
              ) : null}
              <Button
                size="small"
                variant="contained"
                color="primary"
                disableElevation
                onClick={() => {
                  this.setState({
                    polycopieDialog: {
                      studentId: this.props.data.id,
                    }
                  });
                }}
              >
                Créer un polycopié
              </Button>
              <Fade in={polycopiesIds.length > 0}>
                <Fragment>
                  <Button
                    size="small"
                    variant="outlined"
                    color="default"
                    disableElevation
                    onClick={() => {
                      this.setState({
                        setSentStateDialog: {
                          polycopieSent: 1,
                        },
                      });
                    }}
                  >
                    ENVOYE(S)
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      this.setState({
                        setSentStateDialog: {
                          polycopieSent: 2,
                        },
                      });
                    }}
                  >
                    ANNULE(S)
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => {
                      this.setState({
                        setSentStateDialog: {
                          polycopieSent: 0,
                        },
                      });
                    }}
                  >
                    PAS ENVOYE(S)
                  </Button>
                </Fragment>
              </Fade>
            </Box>
          )}
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox disabled={doNotSend} onChange={this.onSelectAllClick} checked={selectAll} />
              </TableCell>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.filter(({ training_id }) => {
              return trainingIdFilter === 0 || training_id === trainingIdFilter;
            }).map((p) => (
              <TableRow key={`${p.polycopie_id}-${p.student_id}-${p.training_id}-${p.session_name}`}>
                <TableCell padding="checkbox">
                  <Checkbox
                    disabled={doNotSend}
                    value={p.polycopie_id}
                    onChange={this.onSelectOne}
                    checked={polycopiesIds.includes(p.polycopie_id)}
                  />
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    style={{ gap: '8px' }}
                    alignItems="center"
                  >
                    <Typography size="inherit">{p.polycopie_name}</Typography>
                    {p.origin === 'manual' ? (
                      <Tooltip title="Polycopié ajouté manuellement">
                        <PanToolIcon style={{ opacity: .25 }} fontSize="small" />
                      </Tooltip>
                    ) : null}
                    {p.origin === 'manual' && p.sent === null ? (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        flex={1}
                      >
                        <Tooltip title="Supprimer le polycopié">
                          <IconButton
                            size="small"
                            onClick={() => {
                              this.setState({
                                polycopieManualToDelete: p,
                                deleteDialog: true,
                              });
                            }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ) : null}
                  </Box>
                </TableCell>
                <TableCell>{p.discipline_name}</TableCell>
                <TableCell>{p.training_name}</TableCell>
                <TableCell>
                  {(p.sent === 1 || !p.sent)
                    ? (
                      <IconButton
                        disabled={doNotSend}
                        edge="start"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({
                            statusDialog: {
                              polycopieLabel: p.polycopie_name,
                              polycopieId: p.polycopie_id,
                              studentId: p.student_id,
                              polycopieSent: p.sent === 1 ? 0 : 1,
                              polycopieSentAt: p.sent_at,
                            },
                          });
                        }}
                      >
                        <CheckCircleIcon style={{ color: p.sent ? green[400] : grey[200] }} />
                      </IconButton>
                    ) : p.sent === 2
                      ? (
                        <IconButton
                          disabled={doNotSend}
                          edge="start"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            this.setState({
                              statusDialog: {
                                polycopieLabel: p.polycopie_name,
                                polycopieId: p.polycopie_id,
                                studentId: p.student_id,
                                polycopieSent: 0,
                                polycopieSentAt: p.sent_at,
                              },
                            });
                          }}
                        >
                          <CancelIcon style={{ color: red[400] }} />
                        </IconButton>
                      ) : null
                  }
                </TableCell>
                <TableCell>{p.sent_at ? format(new Date(p.sent_at), 'dd MMM yyyy', { locale: frLocale }) : null}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

StudentPolycopies.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default withStyles(styles)(
  withLocalSortAndFilter(StudentPolycopies, 'polycopie_name')
);
