import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import CloseIcon from '@material-ui/icons/Close';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { formatAmount } from 'components/Orders/OrdersPayments';

const useStyles = makeStyles((theme) => tableStyles(theme));

export const TransactionsSummary = ({ transactions, uuid }) => {
  const classes = {
    ...useStyles(),
  };
  const theme = useTheme();

  if (!transactions) {
    return null;
  }
  return (
    <Table size="small" className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Réf. unique</TableCell>
          <TableCell>Date création</TableCell>
          <TableCell>Statut</TableCell>
          <TableCell>Date remise</TableCell>
          <TableCell>Montant</TableCell>
          <TableCell>Fin carte</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transactions.map((t) => {
          return (
            <TableRow
              key={t.uuid}
              style={{
                background: (uuid ?? '') === t.uuid
                  ? theme.palette.action.selected
                  : 'inherit',
              }}
            >
              <TableCell>
                <Typography variant="caption">{t.uuid}</Typography>
              </TableCell>
              <TableCell>{formatInTimeZone(t.creationDate, 'Europe/Paris', 'dd/MM/yyyy HH:mm')}</TableCell>
              <TableCell>
                <Typography
                  variant="subtitle2"
                  style={{
                    color: ['PAID', 'RUNNING'].includes(t.status) ? theme.palette.success.main : theme.palette.error.main,
                  }}
                >
                  {`${t.status} ${t.detailedStatus}`}
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    color: ['PAID', 'RUNNING'].includes(t.status) ? theme.palette.success.main : theme.palette.error.main,
                  }}
                >
                  {`${t.errorMessage ?? ''} ${t.detailedErrorMessage ?? ''}`}
                </Typography>
              </TableCell>
              <TableCell>{formatInTimeZone(t.transactionDetails.cardDetails.expectedCaptureDate, 'Europe/Paris', 'dd/MM/yyyy HH:mm')}</TableCell>
              <TableCell>{formatAmount(t.amount / 100)}</TableCell>
              <TableCell>{`${String(t.transactionDetails.cardDetails.expiryMonth).padStart(2, '0')}/${t.transactionDetails.cardDetails.expiryYear}`}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const TransactionsDialog = ({
  onClose,
  open,
  transactions,
  uuid,
}) => {
  const classes = {
    ...useStyles(),
  };
  const theme = useTheme();

  if (!transactions) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="xl"
      fullWidth
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Liste des transactions
          <Typography variant="caption"> (triées par date de remise)</Typography>
        </Typography>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <Box
          display="flex"
          style={{ gap: theme.spacing(2) }}
          flexDirection="column"
          my={2}
        >
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Réf. unique</TableCell>
                <TableCell>Date création</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Date remise</TableCell>
                <TableCell>Montant</TableCell>
                <TableCell>Fin carte</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((t) => {
                return (
                  <TableRow
                    key={t.uuid}
                    style={{
                      background: uuid === t.uuid
                        ? theme.palette.action.selected
                        : 'inherit',
                    }}
                  >
                    <TableCell>
                      <Typography variant="caption">{t.uuid}</Typography>
                    </TableCell>
                    <TableCell>{formatInTimeZone(t.creationDate, 'Europe/Paris', 'dd/MM/yyyy HH:mm')}</TableCell>
                    <TableCell>
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: ['PAID', 'RUNNING'].includes(t.status) ? theme.palette.success.main : theme.palette.error.main,
                        }}
                      >
                        {`${t.status} ${t.detailedStatus}`}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          color: ['PAID', 'RUNNING'].includes(t.status) ? theme.palette.success.main : theme.palette.error.main,
                        }}
                      >
                        {`${t.errorMessage ?? ''} ${t.detailedErrorMessage ?? ''}`}
                      </Typography>
                    </TableCell>
                    <TableCell>{formatInTimeZone(t.transactionDetails.cardDetails.expectedCaptureDate, 'Europe/Paris', 'dd/MM/yyyy HH:mm')}</TableCell>
                    <TableCell>{formatAmount(t.amount / 100)}</TableCell>
                    <TableCell>{`${String(t.transactionDetails.cardDetails.expiryMonth).padStart(2, '0')}/${t.transactionDetails.cardDetails.expiryYear}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </Dialog>
  )
};

export default TransactionsDialog;
