import { Box, Fade, IconButton, InputBase } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Http from 'services/Http';
import { useStyles } from './styles.css';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import axios from 'axios';

const TrainingAutocomplete = ({
  onChange,
  defaultValue,
  placeholder,
}) => {
  const classes = useStyles();
  const [allTrainings, setAllTrainings] = useState([]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    (async () => {
      try {
        const { data } = await Http.get('/trainings?publishedOnly=true', {
          cancelToken: source.token,
        });
        setAllTrainings(data.rows);
      } catch (e) {}
    })();
    return () => {
      source.cancel('Operation /trainings?publishedOnly=true canceled');
    };
  }, []);

  return (
    <Box style={{ position: 'relative', display: 'flex' }}>
      <datalist id="available-trainings">
        {allTrainings.map(({ title, id }) => {
          return (
            <option key={`${title}-${id}`} value={title} />
          );
        })}
      </datalist>
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      <InputBase
        inputProps={{
          list: 'available-trainings',
          autoComplete: 'off',
          enterKeyHint: 'search',
          inputMode: 'search',
        }}
        name="training"
        classes={{
          root: classes.field,
          input: classes.fieldInput,
        }}
        placeholder={placeholder ?? `Formation`}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onChange(event.target.value);
          }
        }}
        onChange={(event) => {
          const { value } = event.target;
          const result = allTrainings.find(({ title }) => value === title);
          if (result) {
            onChange(value);
          }
        }}
        defaultValue={defaultValue ?? ''}
      />
      <Fade
        in={(defaultValue ?? '').length > 0}
        style={{
          width: (defaultValue ?? '').length === 0 ? '0px' : 'auto'
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            onChange('');
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </Fade>
    </Box>
  );

};

export default TrainingAutocomplete;
