import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import { tableStyles } from 'styles/datatable.css';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import RattrapageDialog from 'components/RattrapageDialog/RattrapageDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import FilterInput from 'components/Datagrid/FilterInput';
import Http from 'services/Http';
import SuccessSnackbar from 'components/Snackbar/Success';
import { styles } from './Rattrapages.css';
import VideoDialogCreate from 'components/VideoDialogCreate/VideoDialogCreate';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';

const sortableCells = [
  { name: 'id', label: '#' },
  { name: 'inserted_at', label: 'Date d\'ajout' },
];

class Rattrapages extends Component {
  state = {
    createVideoDialog: false,
    deleteDialog: false,
    deleteVideo: null,
    affectationDialog: false,
    error: '',
    openError: false,
    openSuccess: false,
    previewVideo: '',
    showVideoDialog: false,
    success: '',
    rattrapageId: false,
  }

  openAffectationDialog = (id = null) => {
    const rattrapage = this.props.rows.find(rattrapage => rattrapage.id === id);
    this.setState({
      affectationDialog: true,
      rattrapage,
    });
  }

  closeAffectationDialog = () => {
    this.props.reload();
    this.setState({ affectationDialog: false });
  }

  openCreateVideoDialog = () => {
    this.setState({ createVideoDialog: true });
  }

  closeCreateVideoDialog = () => {
    this.setState({ createVideoDialog: false });
  }

  openShowVideoDialog = (previewVideo) => {
    this.setState({
      previewVideo,
      showVideoDialog: true,
    });
  }

  closeShowVideoDialog = () => {
    this.setState({
      previewVideoId: 0,
      showVideoDialog: false,
    });
  }

  openDeleteDialog = (deleteVideo) => {
    this.setState({
      deleteDialog: true,
      deleteVideo,
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      deleteVideo: null,
    });
  }

  onVideoCreate = ({ success }) => {
    this.setState({
      success,
      openSuccess: true,
      createVideoDialog: false,
    });
    this.props.reload();
  };

  deleteVideo = async () => {
    if (this.state.deleteVideo) {
      try {
        await Http.delete(`/rattrapages/${this.state.deleteVideo}`);
        this.setState({
          success: 'Le rattrapage a été supprimé',
          openSuccess: true,
          deleteDialog: false,
          deleteVideo: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          deleteVideo: null,
        });
      }
    }
  };

  affectRattrapagesStudent = async ({ success }) => {
      this.setState({
        success,
        openSuccess: true,
        affectationDialog: false,
      });
      this.props.reload();
  };

  render() {
    const {
      classes,
      filter,
      filterState,
      loading,
      renderPagination,
      renderSortCell,
      renderFilter,
      rows,
    } = this.props;
    const {
      createVideoDialog,
      deleteDialog,
      affectationDialog,
      error,
      openError,
      openSuccess,
      previewVideo,
      showVideoDialog,
      success,
      rattrapage,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        {affectationDialog ? (
          <RattrapageDialog
            open
            rattrapage={rattrapage}
            onClose={this.closeAffectationDialog}
            onAffect={this.affectRattrapagesStudent}
          />
        ) : null}
        {showVideoDialog && previewVideo ? (
          <Dialog
            open={showVideoDialog}
            onClose={this.closeShowVideoDialog}
          >
            <DialogTitle>Aperçu vidéo</DialogTitle>
            <Box px={3} pb={3}>
              <div
                className={classes.landscapeContainer}
                dangerouslySetInnerHTML={{ __html: previewVideo }}
              />
            </Box>
          </Dialog>) : null
        }
        <ConfirmationDialog
          open={deleteDialog}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer ce rattrapage ?"
          onClose={this.closeDeleteDialog}
          onConfirm={this.deleteVideo}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <Box ml={2}>
            <Button
              className={classes.spacedButton}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.openCreateVideoDialog}
            >
              Ajouter une vidéo
            </Button>
          </Box>
          <VideoDialogCreate
            open={createVideoDialog}
            onClose={this.closeCreateVideoDialog}
            onCreate={this.onVideoCreate}
            bulkCreateEndpoint="/rattrapages/bulkCreate"
          />
          <FilterInput
            filter={filter}
            filterBy="discipline"
            placeholder="Filter par matière"
            defaultValue={filterState.discipline}
          />
          {renderFilter('Filtrer par vidéo')}
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell align="center">Elèves</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell align="center">Info</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(n => (
              <TableRow key={n.id}>
                <TableCell>{n.id}</TableCell>
                <TableCell>{format(new Date(n.inserted_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                <TableCell align="center">
                  <Typography color="primary" variant="subtitle2">
                    {n.students.length}
                  </Typography>
                </TableCell>
                <TableCell>{n.name}</TableCell>
                <TableCell align="center">
                  {n.description ? (
                    <Tooltip title={n.description}>
                      <HelpIcon fontSize="small" />
                    </Tooltip>
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  <img
                    className={classes.thumbnail}
                    src={n.picture}
                    alt=""
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => this.openAffectationDialog(n.id)}
                  >
                    Affecter
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    onClick={() => this.openShowVideoDialog(n.embed)}
                  >
                    Voir
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => this.openDeleteDialog(n.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

Rattrapages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(Rattrapages, 'rattrapages', 'inserted_at', 'desc')
);
