import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Fade,
  FormControlLabel,
  IconButton,
  InputBase,
  LinearProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HelpIcon from '@material-ui/icons/Help';
import { Alert } from '@material-ui/lab';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import StudentTags from 'components/StudentTags/StudentTags';
import StudentTagsCreation, { AvailableTagsDatalist } from 'components/StudentTags/StudentTagsCreation';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import { format } from 'date-fns';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Http from 'services/Http';
import { tableStyles } from 'styles/datatable.css';
import { ExportMenu } from './Menus/ExportMenu';
import InvalidAddressesDialog from 'components/Polycopies/InvalidAddressesDialog';

const sortableCells = [
  { name: 'id', label: '#', isString: false },
  { name: 'last_name', label: 'Nom', isString: true },
  { name: 'first_name', label: 'Prénom', isString: true },
  { name: 'email', label: 'Email', isString: true },
  { name: 'billing_city', label: 'Ville', isString: true },
  {
    name: 'tags', label: 'Tags', isString: true, getValue: (row, sortBy) => {
      return row?.tags?.join(',') ?? String.fromCharCode(0);
    }
  },
  { name: 'session_name', label: 'Session', isString: true },
  { name: 'completed_at', label: 'Date (commande)', isString: true },
  { name: 'created_at', label: 'Date (session)', isString: true },
];

class SearchResults extends Component {
  state = {
    selectedStudentIds: [],
    openSuccess: false,
    openError: false,
    message: '',
    exportedEmails: null,
    tags: '',
    availableTags: [],
    invalidAddressesDialog: null,
    showDuplicateUsers: false,
  };


  componentDidMount() {
    this.props.setData(this.props.students, true);
    this.castAvailableTags(this.props.students);
  }

  componentDidUpdate(prevProps) {
    const { students } = this.props;
    const { students: prevStudents } = prevProps;
    if (isEqual(students, prevStudents) === false) {
      this.props.setData(students, true);
      this.castAvailableTags(students);
    }
  }

  castAvailableTags = (students) => {
    const uniq = new Map();
    if (Array.isArray(students)) {
      students.forEach(student => {
        (student.tags ?? []).forEach(tag => {
          if (!uniq.has(tag.toLowerCase())) {
            uniq.set(tag.toLowerCase(), tag);
          }
        });
      });
    }
    this.setState({ availableTags: Array.from(uniq.values()) })
  }

  onToggleSelectAll = () => {
    const { rows } = this.props;
    const { selectedStudentIds } = this.state;
    if (selectedStudentIds.length === rows.length) {
      this.setState({
        selectedStudentIds: [],
      });
    } else {
      this.setState({
        selectedStudentIds: rows.map(({ id }) => id),
      });
    }
  };

  toggleStudent = (event) => {
    const { checked, value } = event.target;
    const valueInteger = parseInt(value, 10);
    const selectedStudentIds = [...this.state.selectedStudentIds];
    if (checked) {
      selectedStudentIds.push(valueInteger);
    } else {
      const index = selectedStudentIds.indexOf(valueInteger);
      if (index !== -1) {
        selectedStudentIds.splice(index, 1);
      }
    }
    this.setState({ selectedStudentIds });
  }

  downloadBuffer = (buffer, filename) => {
    const url = URL.createObjectURL(new Blob([buffer.data]));
    const link = global.document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    global.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  colishipCheckExportValidity = async () => {
    this.setState({ loadingOpacity: true });
    const { selectedStudentIds } = this.state;
    const { searchParams } = this.props;
    try {
      const { data } = await Http.post('/search/colishipCheckExportValidity', {
        studentIds: selectedStudentIds,
        ...searchParams,
      });
      if (data.length > 0) {
        return {
          invalidAddressesDialog: data,
        };
      } else {
        return true;
      }
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  }


  genericExport = async (event, label) => {
    const { selectedStudentIds } = this.state;
    const { searchParams } = this.props;

    let exportUrl;
    let variant;
    let ext = '';
    switch (label) {
      case 'EMAILS':
        return this.exportMails();
      case 'XLS':
        variant = ''
        exportUrl = 'exportXls';
        ext = 'xlsx';
        break
      case 'COLISHIP':
        const validityCheck = await this.colishipCheckExportValidity();
        if (validityCheck === true) {
          variant = 'coliship-';
          exportUrl = 'exportColiship';
          ext = 'csv';
        } else if (validityCheck.invalidAddressesDialog) {
          return this.setState({ invalidAddressesDialog: validityCheck.invalidAddressesDialog });
        }
        break
      case 'STICKERS':
        variant = 'stickers-';
        exportUrl = 'exportStickers';
        ext = 'docx';
        break
      default:
        return;
    }

    try {
      const buffer = await Http.post(
        `/search/${exportUrl}`,
        {
          studentIds: selectedStudentIds,
          ...searchParams,
        },
        {
          responseType: 'blob',
        },
      );
      this.downloadBuffer(buffer, `etudiants-${variant ?? ''}${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.${ext}`);
    } catch ({ data }) {
      this.setState({
        message: ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  exportMails = async () => {
    const { selectedStudentIds } = this.state;
    const { searchParams } = this.props;
    try {
      const { data } = await Http.post(
        `/search/exportMails`,
        {
          studentIds: selectedStudentIds,
          ...searchParams,
        },
      );
      this.setState({
        exportedEmails: data.join(';'),
      });
    } catch ({ data }) {
      this.setState({
        message: ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  exportStickers = async () => {
    const { selectedStudentIds } = this.state;
    const { searchParams } = this.props;
    try {
      const { data } = await Http.post(
        `/search/exportMails`,
        {
          studentIds: selectedStudentIds,
          ...searchParams,
        },
      );
      this.setState({
        exportedEmails: data.join(';'),
      });
    } catch ({ data }) {
      this.setState({
        message: ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  copyEmailsToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(this.state.exportedEmails);
      this.setState({
        message: 'Emails copiés !',
        openSuccess: true,
      });
    } catch (err) {
      this.setState({
        message: ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({
      exportedEmails: null,
    });
  }

  bulkTagStudent = async (tags) => {
    const { selectedStudentIds } = this.state;
    try {
      await Http.post(
        `/users/bulk/tags`,
        {
          studentIds: selectedStudentIds,
          tags,
        },
      );
      this.askForReload();

      this.setState({
        tags: '',
        message: ['Tags modifiés'],
        openSuccess: true,
      });
    } catch ({ data }) {
      this.setState({
        message: ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  renderSnackbar() {
    const { openError, openSuccess, message } = this.state;
    return openError ? (
      <ErrorSnackbar
        message={message}
        onClose={() => this.setState({ openError: false })}
        open={openError}
      />
    ) : openSuccess ? (
      <SuccessSnackbar
        message={message}
        onClose={() => this.setState({ openSuccess: false })}
        open={openSuccess}
      />
    ) : null;
  }

  askForReload = () => {
    const { reload = () => { } } = { ...this.props }
    reload();
  }

  handleUpdate = (updatedStudents) => {
    this.askForReload();
  }

  filterByTags = (value, searchIn) => {
    const arraySearchIn = (Array.isArray(searchIn) ? searchIn : [searchIn]).map(i => String(i).toLowerCase());

    if (typeof value === 'string' && value.trim().length > 0) {
      if (['AUCUN', 'VIDE', 'NULL'].includes(value.toUpperCase()) || arraySearchIn.length === 0) {
        return searchIn === null;
      } else if (arraySearchIn.length > 0) {
        const values = value.split(/\s+/gi).map(String).filter(s => s.trim().length > 0).map(s => s.toLowerCase());
        return arraySearchIn.some(asi => {

          return values.some(v => {
            if (v.includes('%')) {
              const vpruned = v.replaceAll('%', '');
              return values.some(v => asi.includes(vpruned));
            }
            return asi.toLocaleLowerCase() === v.toLocaleLowerCase();
          });
        });
      }
    }
    return true;
  }

  render() {
    const { duplicatesUsers, classes, renderSortCell, renderFilter, rows, students, studentsCount, searchSummary } = this.props;
    const { exportedEmails, selectedStudentIds, invalidAddressesDialog, showDuplicateUsers } = this.state;

    return (
      <Paper style={{ marginTop: '16px' }}>
        {this.renderSnackbar()}
        {invalidAddressesDialog !== null ? (
          <InvalidAddressesDialog
            onClose={() => {
              this.setState({ invalidAddressesDialog: null });
            }}
            open={invalidAddressesDialog !== null}
            invalidAddresses={invalidAddressesDialog}
            onSuccess={() => this.genericExport(null, 'COLISHIP')}
          />
        ) : null}

        <Toolbar>
          <Box mr={1}>
            <Typography variant="h5">{`${rows.length} étudiant${rows.length > 1 ? 's' : ''}`}{rows.length !== students.length ? ` (${studentsCount} initialement)` : undefined} </Typography>
          </Box>
          <Box
            mr={1}
            display="flex"
            style={{ gap: '6px' }}
            alignItems="center"
          >
            <Chip
              color="primary"
              size="small"
              label={searchSummary.type}
            />
            <Tooltip
              title={searchSummary.labels.map((label) => {
                return (
                  <Typography variant="caption" key={label}>{`${label} `}</Typography>
                )
              })}
            >
              <HelpIcon fontSize="small" />
            </Tooltip>
          </Box>
          <Fade in={selectedStudentIds.length > 0}>
            <Box
              flexGrow="1"
              display="flex"
              alignItems="center"
            >
              <Box flexGrow=".75" pr={1}>
                <LinearProgress
                  variant="determinate"
                  value={selectedStudentIds.length * 100 / rows.length}
                  classes={{
                    root: classes.linearProgress,
                  }}
                />
              </Box>
              <Typography>
                {`${selectedStudentIds.length} / ${rows.length} étudiant${selectedStudentIds.length > 1 ? 's' : ''} sélectionné${selectedStudentIds.length > 1 ? 's' : ''}`}
              </Typography>
              <ExportMenu items={[
                {
                  label: 'COLISHIP',
                },
                {
                  label: 'EMAILS',
                },
                {
                  label: 'XLS',
                },
                {
                  label: 'STICKERS',
                },
              ]}

                onClick={this.genericExport}
              />
              <Box pl={1}>
                <StudentTagsCreation
                  title={`Affecter aux ${selectedStudentIds.length} fiches sélectionnées`}
                  label={'Tags étudiants'}
                  onSubmit={(tags) => {
                    this.bulkTagStudent(tags);
                  }}
                />
              </Box>
            </Box>
          </Fade>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.props.backToSearchHandler}
            >
              Retour
            </Button>
          </Box>
        </Toolbar>
        <Toolbar>
          {renderFilter('Filter par Tags', 'tags', this.filterByTags, {
            inputProps: {
              list: "filterByTag",
            }
          })}
          <AvailableTagsDatalist id="filterByTag" existingTags={this.state.availableTags} />
        </Toolbar>
        {exportedEmails ? (
          <Box
            m={1}
            display="flex"
            alignItems="center"
            style={{ gap: '12px' }}
          >
            <InputBase
              fullWidth
              multiline
              value={exportedEmails}
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              id="copy-emails"
            />
            <IconButton onClick={this.copyEmailsToClipboard}>
              <FileCopyIcon />
            </IconButton>
          </Box>
        ) : null}

        {duplicatesUsers.length ? (
          <Alert
            variant="filled"
            severity="warning"
            style={{ borderRadius: 0 }}
          >
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ gap: '6px' }}
            >
              <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>{`${duplicatesUsers.length} doublon${duplicatesUsers.length > 1 ? 's' : ''}`} </Typography>
              <FormControlLabel
                style={{ marginLeft: '20px' }}
                label={showDuplicateUsers ? 'Afficher la réponse API' : 'Masquer la réponse API'}
                control={
                  <Switch
                    checked={showDuplicateUsers}
                    onChange={() => {
                      this.setState({ showDuplicateUsers: !showDuplicateUsers });
                    }}
                  />
                }
              />
            </Box>


            <Collapse in={showDuplicateUsers}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                style={{ gap: '6px' }}
              >
                {duplicatesUsers.map((user, index) => {
                  return (
                    <Typography key={user.id}>{`${user.id} ${user.first_name} ${user.last_name}`}</Typography>
                  )
                })}
              </Box>
            </Collapse>
          </Alert>
        ) : null}
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={students.length === selectedStudentIds.length}
                  onClick={this.onToggleSelectAll}
                />
              </TableCell>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((n, i) => (
              <TableRow key={n.id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedStudentIds.includes(n.id)}
                    value={n.id}
                    onClick={this.toggleStudent}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {n.id}
                </TableCell>
                <TableCell>
                  {n.last_name}
                </TableCell>
                <TableCell>
                  {n.first_name}
                </TableCell>
                <TableCell>
                  {n.email}
                </TableCell>
                <TableCell>
                  {n.billing_city}
                </TableCell>
                <TableCell>
                  <StudentTags tags={n.tags} userId={n.id} allowDeletion onUpdate={this.handleUpdate} />
                </TableCell>
                <TableCell>
                  {n.session_name}
                </TableCell>
                <TableCell>
                  {n.completed_at ? format(new Date(n.completed_at), 'dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>
                  {n.created_at ? format(new Date(n.created_at), 'dd/MM/yyyy') : ''}
                </TableCell>
                <TableCell>
                  <ButtonRouter
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    size="small"
                    label="Détail"
                    to={`/students/${n.id}`}
                    target="_blank"
                    disableElevation
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

SearchResults.propTypes = {
  classes: PropTypes.object.isRequired,
  students: PropTypes.array.isRequired,
  backToSearchHandler: PropTypes.func.isRequired,
  searchParams: PropTypes.any.isRequired,
  searchSummary: PropTypes.object.isRequired,
  duplicatesUsers: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
  // ...styles(theme),
  ...tableStyles(theme),
}))(
  withLocalSortAndFilter(SearchResults, 'last_name', 'asc', true)
);
