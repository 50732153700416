import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './SnackBarMessage.css';
import SnackBarBase from './SnackBarBase';

const ErrorSnackbar = ({ classes, message, onClose, open }) => (
    <SnackBarBase
        className="error"
        icon={<ErrorIcon className={classes.icon} />}
        message={message}
        onClose={onClose}
        open={open}
    />
);

ErrorSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ErrorSnackbar);