import React, { Fragment } from 'react';
import fetchPage from 'services/fetchPage';
import Pagination from 'components/Datagrid/Pagination';
import SortableCell from 'components/Datagrid/SortableCell';
import FilterDatePicker from 'components/FilterDatePicker/FilterDatePicker';
import FilterInput from 'components/Datagrid/FilterInput';
import FilterSelect from 'components/FilterSelect/FilterSelect';
import FilterAutocomplete from 'components/FilterAutocomplete/FilterAutocomplete';
import {
    parse,
    stringify,
} from 'querystring';

const withSortAndPagination = (WrappedComponent, endpoint, defaultSortBy, defaultDirection = 'asc', defaultFilter = undefined) => (
    class extends React.Component {

        defaultQuery = {
            limit: 50,
            loading: false,
            page: 0,
            direction: defaultDirection,
            sortBy: defaultSortBy,
            filter: {},
        };

        constructor(props) {
            super(props);
            const search = parse(props.location.search.substring(1));
            this.state = {
                // pagination
                limit: parseInt(search.limit, 10) || this.defaultQuery.limit,
                page: parseInt(search.page, 10) || this.defaultQuery.page,
                // sort
                direction: search.direction || this.defaultQuery.direction,
                sortBy: search.sortBy || this.defaultQuery.sortBy,
                // data
                count: 0,
                rows: [],
                filter: search.filter ? parse(search.filter, '---', '_') : {},
                // events
                loading: true,
            };
        }

        async componentDidMount() {
            if (typeof defaultFilter === 'function' && Object.keys(this.state.filter).length === 0) {
                const filter = await defaultFilter();
                this.setState({ filter });
            }
            this.paginate(this.state.page);
        }

        sortBy = async (sortBy, direction) => {
            const { limit, filter } = this.state;
            this.setState({ loading: true });
            const data = await fetchPage(endpoint, { limit, page: 0, sortBy, direction, ...filter });
            this.setState({
                ...data,
                sortBy,
                direction,
                loading: false,
            });
            this.updateUrl();
        }

        updateSortState = (sortBy, direction) => {
            this.setState({
                sortBy,
                direction,
            });
        }

        paginate = async (page, updateLimit = false) => {
            const { sortBy, direction, filter } = this.state;
            const limit = updateLimit ? updateLimit : this.state.limit;
            this.setState({ loading: true });
            const data = await fetchPage(endpoint, { limit, page, sortBy, direction, ...filter });
            this.setState({
                ...data,
                limit,
                page,
                loading: false,
            });
            this.updateUrl();
        }

        filter = async (newFilter, merge = false) => {
            const mergedFilter = merge ? { ...this.state.filter, ...newFilter } : newFilter;
            // clean the filters with no values (ex: when selecting all orderStatus)
            const filter = Object.entries(mergedFilter).reduce((prev, [key, val]) => {
                return (val)
                    ? {
                        ...prev,
                        [key]: val,
                    } : prev;
            }, {});
            const { limit, sortBy, direction } = this.state;
            this.setState({ loading: true });
            const data = await fetchPage(endpoint, { limit, page: 0, sortBy, direction, ...filter });
            this.setState({
                ...data,
                filter,
                page: 0,
                loading: false,
            });
            this.updateUrl();
        }

        reload = async () => {
            const { filter, limit, page, sortBy, direction } = this.state;
            this.setState({ loading: true });
            const data = await fetchPage(endpoint, { limit, page, sortBy, direction, ...filter });
            this.setState({ ...data, loading: false });
        }

        // forcedPathname in case we want to change path while keeping querystring in sync
        updateUrl = (forcedPathname = null) => {
            const { location: { pathname } } = this.props;
            const { filter, limit, page, sortBy, direction } = this.state;
            const queryString = stringify({
                filter: stringify(filter, '---', '_'),
                limit,
                page,
                sortBy,
                direction,
            });
            this.props.history.push(`${forcedPathname ?? pathname}?${queryString}`);
        }

        render() {
            const { count, filter, loading, limit, page, sortBy, direction, rows, ...extra } = this.state;
            return (
                <WrappedComponent
                    extra={extra}
                    {...this.props}
                    updateUrl={this.updateUrl}
                    loading={loading}
                    reload={this.reload}
                    filter={this.filter}
                    filterState={this.state.filter}
                    rows={rows}
                    count={count}
                    page={page}
                    sortBy={sortBy}
                    updateSortState={this.updateSortState}
                    direction={direction}
                    limit={limit}
                    renderSortCell={(cell) => (
                        <SortableCell
                            key={cell.name}
                            sortBy={sortBy}
                            direction={direction}
                            {...cell}
                            onClick={this.sortBy}
                        />
                    )}
                    renderPagination={() => (
                        <Pagination
                            count={count}
                            limit={limit}
                            page={page}
                            paginate={this.paginate}
                        />
                    )}
                    renderFilter={(placeholder, filterBy = 'filter', merge = true) => {
                        return (
                            <FilterInput
                                defaultValue={filter[filterBy] || ''}
                                filter={this.filter}
                                filterBy={filterBy}
                                placeholder={placeholder}
                                merge={merge}
                            />
                        );
                    }}
                    renderFilterWithtoutMargin={(placeholder, filterBy = 'filter', merge = true) => {
                        return (
                            <FilterInput
                                defaultValue={filter[filterBy] || ''}
                                filter={this.filter}
                                filterBy={filterBy}
                                placeholder={placeholder}
                                merge={merge}
                                style={{ marginLeft: 0 }}
                            />
                        );
                    }}
                    renderSortableAndFilterable={(cellInfo, filtersInfo) => {

                        const Wrapper = cellInfo.wrapper ? cellInfo.wrapper : ({ children }) => <Fragment>{children}</Fragment>;

                        return (
                            <SortableCell
                                key={cellInfo.name}
                                sortBy={sortBy}
                                direction={direction}
                                {...cellInfo}
                                onClick={this.sortBy}
                            >
                                <Wrapper>
                                    {filtersInfo.map((filterInfo) => (
                                        <div key={filterInfo.filterBy} style={{ ...filterInfo.style ?? null }}>
                                            {filterInfo.type === 'custom' ? (
                                                filterInfo.custom({
                                                    state: this.state,
                                                    filterInfo,
                                                    filter: this.filter,
                                                })
                                            ) : null}
                                            {filterInfo.type === 'autocomplete' ? (
                                                <FilterAutocomplete
                                                    filterState={this.state.filter}
                                                    defaultValue={filter[filterInfo.filterBy] || ''}
                                                    filter={this.filter}
                                                    filterBy={filterInfo.filterBy}
                                                    fetcher={filterInfo.fetcher}
                                                    placeholder={filterInfo.placeholder}
                                                    multiple={filterInfo.multiple === true}
                                                    hocLoading={loading}
                                                    checkbox={filterInfo.checkbox ? {
                                                        ...filterInfo.checkbox,
                                                        defaultChecked: filter[`${filterInfo.filterBy}Checked`] !== undefined ? filter[`${filterInfo.filterBy}Checked`] === '1' : false
                                                    } : null}
                                                />
                                            ) : null}
                                            {filterInfo.type === 'datePicker' ? (
                                                <FilterDatePicker
                                                    defaultValue={filter[filterInfo.filterBy] || ''}
                                                    filter={this.filter}
                                                    filterBy={filterInfo.filterBy}
                                                    placeholder={filterInfo.placeholder}
                                                />
                                            ) : null}
                                            {filterInfo.type === 'select' ? (
                                                <FilterSelect
                                                    defaultValue={filter[filterInfo.filterBy] || ''}
                                                    filter={this.filter}
                                                    filterBy={filterInfo.filterBy}
                                                    items={filterInfo.items}
                                                    placeholder={filterInfo.placeholder}
                                                />
                                            ) : null}
                                            {filterInfo.type === undefined ? (
                                                <FilterInput
                                                    defaultValue={filter[filterInfo.filterBy] || ''}
                                                    filter={this.filter}
                                                    filterBy={filterInfo.filterBy}
                                                    placeholder={filterInfo.placeholder}
                                                    merge
                                                    outlined
                                                    variant="dense"
                                                />
                                            ) : null}
                                        </div>
                                    ))}
                                </Wrapper>
                            </SortableCell>
                        );
                    }}
                />
            );
        }
    }
);

export default withSortAndPagination;
