import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import Http from 'services/Http';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ErrorSnackbar from 'components/Snackbar/Error';
import { setAccessToken } from 'services/AuthService';
import { styles } from './SignIn.css';

class SignIn extends Component {
    state = {
        error: '',
        openError: false,
        loading: true,
        login: null,
        password: null,
    };

    async componentDidMount() {
        try {
            // get jwtData on server side : better security as we revalidate the token
            const { data } = await Http.get('/auth/jwt');
            if (data.jwtData) {
                this.setState({ loading: false });
                this.props.onSuccess(data.jwtData);
            }
        } catch (_) {
            this.setState({ loading: false });
        }
    }

    onChange = ({ target }) => {
        const { name, value } = target;
        this.setState({ [name]: value });
    };

    onSubmit = async (event) => {
        event.preventDefault();
        const { login, password } = this.state;
        try {
            const { data } = await Http.post('/auth/wp', {
                login,
                password,
            });
            setAccessToken(data.token);
            const { data: jwt } = await Http.get('/auth/jwt');
            this.props.onSuccess(jwt.jwtData);
        } catch ({ data }) {
            this.setState({
                error: get(data, 'message', 'Une erreur est survenue'),
                openError: true,
            });
        }
    };

    render() {
        const { classes } = this.props;
        const { error, loading, openError } = this.state;

        return (
            <main className={classes.main}>
                <CssBaseline />
                <ErrorSnackbar
                    message={error}
                    onClose={() => this.setState({ openError: false })}
                    open={openError}
                />
                <Paper className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        CRM ISP
                    </Typography>
                    {loading ? 
                        <Fragment>
                            <CircularProgress className={classes.loader} />
                            <Typography component="p" variant="subtitle1">
                            Veuillez patienter...
                            </Typography>
                        </Fragment>
                        :
                        <form
                            className={classes.form}
                            onSubmit={this.onSubmit}
                        >
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="login">Login</InputLabel>
                                <Input
                                    id="login"
                                    name="login"
                                    autoComplete="username"
                                    autoFocus
                                    onChange={this.onChange}
                                />
                            </FormControl>
                            <FormControl margin="normal" required fullWidth>
                                <InputLabel htmlFor="password">Mot de passe</InputLabel>
                                <Input
                                    name="password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={this.onChange}
                                />
                            </FormControl>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Se connecter
                            </Button>
                        </form>
                    }
                </Paper>
            </main>
        );
    }
}

SignIn.propTypes = {
    classes: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignIn);