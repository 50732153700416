import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, InputBase, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Http from 'services/Http';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Polycopies/Polycopies.css';
import DroppableZone from 'components/SessionDialogMerge/DroppableZone';
import DraggableSession from 'components/SessionDialogMerge/DraggableSession';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import useDndStyles from 'components/SessionDialogMerge/MergeDnd.css';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => tableStyles(theme));
const polycopiesStyles = makeStyles((theme) => styles(theme));

const TrainingsDnd = ({
  onSubmit,
  onClear,
}) => {
  const classes = {
    ...useStyles(),
    ...polycopiesStyles(),
    ...useDndStyles()
  };
  const theme = useTheme();

  const [allTrainings, setAllTrainings] = useState([]);
  const [selectedTraining, setSelectedTraining] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [trainingInput, setTrainingInput] = useState(null);
  const [sources, setSources] = useState([]);
  const [targets, setTargets] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await Http.get('/trainings?publishedOnly=false');
      setAllTrainings(data.rows);
    })();
  }, []);

  const onResetTraining = () => {
    document.querySelector('input[name="training"]').value = '';
    setSelectedTraining(null);
  };

  const onChangeTraining = (event) => {
    const trainingInput = event.currentTarget.value;
    const filtered = allTrainings.filter((training) => {
      return training.title === trainingInput;
    });
    const trainings = trainingInput.length !== 0 ? filtered : [];
    setSelectedTraining(trainings.length === 1 ? trainings.pop() : null);
    setTrainingInput(trainingInput);
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, collisions } = event;
    if (collisions.length >= 1) {
      const collision = collisions.at(0);
      if (collision.id === 'trainings') {
        const indexTargets = targets.findIndex((trainingId) => {
          return trainingId === active.id;
        });
        const indexSources = sources.findIndex((trainingId) => {
          return trainingId === active.id;
        });
        if (indexTargets !== -1) {
          setTargets((state) => {
            return state.filter((trainingId) => {
              return trainingId !== active.id;
            });
          });
        }
        if (indexSources !== -1) {
          setSources((state) => {
            return state.filter((trainingId) => {
              return trainingId !== active.id;
            });
          });
        }
      } else if (collision.id === 'targets') {
        setTargets((state) => {
          const index = targets.findIndex((trainingId) => {
            return trainingId === active.id;
          });
          if (index === -1) {
            return [
              ...state,
              active.id,
            ];
          }
          return state;
        });
        setSources((state) => {
          return state.filter((trainingId) => trainingId !== active.id);
        });
      } else if (collision.id === 'sources') {
        setSources((state) => {
          const index = sources.findIndex((trainingId) => {
            return trainingId === active.id;
          });
          if (index === -1) {
            return [
              ...state,
              active.id,
            ];
          }
          return state;
        });
        setTargets((state) => {
          return state.filter((trainingId) => trainingId !== active.id);
        });
      }
    }
    setActiveId(null);
  };

  const renderTraining = (training, children = null) => {
    return (
      <Box>
        <Box
          display="flex"
          alignItems="center"
          style={{ gap: '8px' }}
        >
          <DragIndicatorIcon fontSize="small" />
          {training.title}

        </Box>
        {children}
      </Box>
    );
  };

  if (allTrainings.length === 0) {
    return (
      <Box>
        <Typography>Chargement des formations...</Typography>
      </Box>
    );
  }

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <DragOverlay>
        {(activeId) ? (
          <Card
            className={classNames(
              classes.dragOverlay,
              classes.draggableSession,
            )}
          >
            <CardContent>
              {renderTraining(allTrainings.find(({ id }) => id === activeId))}
            </CardContent>
          </Card>
        ) : null}
      </DragOverlay>
      <Grid
        container
        spacing={2}
      // style={{ opacity: loadingStudents ? .5 : 1 }}
      >
        <Grid
          item
          xs={12}
        >
          <datalist id="available-trainings">
            {allTrainings.map(({ title, id }) => {
              return (
                <option key={`${title}-${id}`} value={title} />
              );
            })}
          </datalist>
          <Card>
            <CardHeader
              subheader={
                <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                  <BookmarkIcon />
                  <Typography>Formations</Typography>
                  <InputBase
                    fullWidth
                    inputProps={{
                      list: 'available-trainings',
                      autoComplete: 'off',
                    }}
                    name="training"
                    classes={{
                      root: classes.field,
                      input: classes.fieldInput,
                    }}
                    placeholder={`Ex: enm%${new Date().getFullYear()}`}
                    onChange={onChangeTraining}
                    defaultValue={selectedTraining ? selectedTraining.title : ''}
                  />
                  <IconButton onClick={onResetTraining}>
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
              }
            />
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DroppableZone id="trainings">
            {(selectedTraining
              ? [selectedTraining]
              : allTrainings.filter(({ title }) => {
                const jokers = (trainingInput ?? '').split('%');
                return jokers.length
                  ? jokers.every((joker) => {
                    return title.toLowerCase().includes(joker.toLowerCase());
                  })
                  : !trainingInput
                    ? true
                    : title.toLowerCase().includes(trainingInput.toLowerCase());
              })
            )
              .filter((training) => {
                return !targets.includes(training.id) && !sources.includes(training.id);
              })
              .map((training) => {
                return (
                  <DraggableSession
                    key={`trainings-${training.id}`}
                    id={training.id}
                  >
                    <Card>
                      <CardContent>
                        {renderTraining(training)}
                      </CardContent>
                    </Card>
                  </DraggableSession>
                );
              })}
          </DroppableZone>
        </Grid>
        <Grid
          item
          xs={6}
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{ gap: theme.spacing(2) }}
          >
            <DroppableZone id="sources">
              {sources.length === 0 ? (
                <Box className={classes.droppableZoneBackground}>
                  <Typography variant="subtitle2">Déposez des formations (source)</Typography>
                </Box>
              ) : null}
              {sources
                // .filter((trainingId) => !targets.includes(trainingId))
                .map((trainingId) => {
                  const training = allTrainings.find(({ id }) => id === trainingId);
                  return (
                    <DraggableSession
                      key={`sources-${training.id}`}
                      id={training.id}
                    >
                      <Card>
                        <CardContent>
                          {renderTraining(training)}
                        </CardContent>
                      </Card>
                    </DraggableSession>
                  );
                })}
            </DroppableZone>
            <DroppableZone id="targets">
              {targets.length === 0 ? (
                <Box className={classes.droppableZoneBackground}>
                  <Typography variant="subtitle2">Déposez des formations (cible)</Typography>
                </Box>
              ) : null}
              {targets
                // .filter((trainingId) => !sources.includes(trainingId))
                .map((trainingId) => {
                  const training = allTrainings.find(({ id }) => id === trainingId);
                  return (
                    <DraggableSession
                      key={`targets-${training.id}`}
                      id={training.id}
                    >
                      <Card>
                        <CardContent>
                          {renderTraining(training)}
                        </CardContent>
                      </Card>
                    </DraggableSession>
                  );
                })}
            </DroppableZone>
            <Box
              display="flex"
              style={{ gap: '16px' }}
            >
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={targets.length === 0 || sources.length === 0}
                onClick={async () => {
                  const dataTargets = await Promise.all(targets.map((trainingId) => {
                    return Http.post('/search', { trainingId })
                  }));
                  const dataSources = await Promise.all(sources.map((trainingId) => {
                    return Http.post('/search', { trainingId })
                  }));
                  const trainingTargets = dataTargets.reduce((prev, current) => {
                    return [
                      ...prev,
                      ...current.data.rows,
                    ];
                  }, []);
                  const trainingSources = dataSources.reduce((prev, current) => {
                    return [
                      ...prev,
                      ...current.data.rows,
                    ];
                  }, []);
                  onSubmit({
                    allTrainings,
                    targets,
                    sources,
                    trainingTargets,
                    trainingSources,
                    intersection: trainingSources.filter(({ id }) => {
                      return trainingTargets.some(({ id: targetId }) => {
                        return targetId === id;
                      })
                    }),
                    difference: trainingSources.filter(({ id }) => {
                      return !trainingTargets.some(({ id: targetId }) => {
                        return targetId === id;
                      })
                    }),
                  });
                }}
              >
                Calculer
              </Button>

              <Button
                onClick={() => {
                  setSources([]);
                  setTargets([]);
                  onClear();
                }}
                fullWidth
                color="secondary"
                variant="contained"
              >
                Effacer
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </DndContext>
  )
};

export default TrainingsDnd;
