import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  Fade,
  Grid,
  IconButton,
  InputBase,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EuroIcon from '@material-ui/icons/Euro';
import { styles } from './OrdersDialog.css';
import Http from 'services/Http';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import SchoolIcon from '@material-ui/icons/School';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';
import {
  mapStatusLabel,
  mapStatusToColor,
} from 'components/Orders/Orders';
import { Alert, Skeleton } from '@material-ui/lab';
import OrderRetractation from 'components/Orders/OrderRetractation';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import OrderAddress from 'components/Orders/OrderAddress';
import { tableStyles } from 'styles/datatable.css';
import ProductCard from 'components/ProductCard/ProductCard';
import OrdersPayments, { formatAmount } from 'components/Orders/OrdersPayments';
import OrdersInvoice from 'components/Orders/OrdersInvoice';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { utcToZonedTime } from 'date-fns-tz';
import OrdersTransaction from 'components/Orders/OrdersTransaction';
import { Shipment } from 'components/Shipment/Shipment';
import { mapPaymentsLabels } from 'components/Orders/OrdersPaymentsItem';

export const sponsoredOrder = [
  { label: 'NON', value: 0 },
  { label: 'CPF', value: 1 },
  { label: 'POLE EMPLOI', value: 2 },
];
class OrdersDialog extends Component {

  state = {
    data: false,
    openSuccess: false,
    openError: false,
    message: '',
    orderDoesNotExist: false,
    changePaymentMethodForm: null,
    amountDialog: null,
  };

  async componentDidUpdate(prevProps) {
    const { orderId } = this.props;
    if (orderId && (orderId !== prevProps.orderId || !this.state.data || prevProps.view !== this.props.view)) {
      this.loadOrder(orderId);
    }
  }

  loadOrder = async (orderId) => {
    const { data } = await Http.get(`/users/customers?orderId=${orderId}`);
    this.setState({
      orderDoesNotExist: data.count === 0,
      data,
    });
  }

  renderSnackbar() {
    const { openError, openSuccess, message } = this.state;
    return openError ? (
      <ErrorSnackbar
        message={message}
        onClose={() => this.setState({ openError: false })}
        open={openError}
      />
    ) : openSuccess ? (
      <SuccessSnackbar
        message={message}
        onClose={() => this.setState({ openSuccess: false })}
        open={openSuccess}
      />
    ) : null;
  }

  async componentDidMount() {
    const { orderId } = this.props;
    if (!this.state.data && orderId) {
      const { data } = await Http.get(`/users/customers?orderId=${orderId}`);
      this.setState({
        orderDoesNotExist: data.count === 0,
        data,
      });
    }
  }

  onDialogClose = () => {
    this.setState({
      data: false,
    });
    this.props.onClose();
  }

  render() {
    const {
      open,
      classes,
      orderId,
      baseUrl,
    } = this.props;
    const { amountDialog, changePaymentMethodForm, data, orderDoesNotExist } = this.state;

    if (!data || !orderId) {
      return (
        <Dialog
          open={open}
          maxWidth="lg"
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              color="inherit"
              variant="h6"
              className={classes.title}
            >
              {`Commande #${orderId ?? ''}`}
            </Typography>
            <IconButton
              color="inherit"
              onClick={this.onDialogClose}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <DialogContent className={classes.dialog}>
            <Skeleton variant="rect" width="60vw" height="70vh" />
          </DialogContent>
        </Dialog>
      );
    }
    if (orderDoesNotExist) {
      return (
        <Dialog
          open={open}
          maxWidth="lg"
          onClose={this.onDialogClose}
        >
          <Fade in appear>
            <Alert variant="filled" severity="error">
              {`La commande #${orderId} n'existe pas. Veuillez vérifier votre saisie.`}
            </Alert>
          </Fade>
        </Dialog>
      );
    }

    const row = data.rows[0];
    const order = row.orders[0];

    return (
      <Dialog
        scroll="paper"
        open={open}
        onClose={this.onDialogClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        {this.renderSnackbar()}
        <Toolbar className={classes.toolbar}>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            {`Commande #${orderId}`}
          </Typography>
          <IconButton
            color="inherit"
            onClick={this.onDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {this.props.view === 'invoice' ? (
          <OrdersInvoice
            order={order}
            row={row}
            close={() => {
              if (this.props.history) {
                this.props.history.push(`${baseUrl}/${order.id}`);
              }
            }}
          />
        ) : this.props.view === 'payments' ? (
          <OrdersPayments
            order={order}
            close={() => {
              if (this.props.history) {
                this.props.history.push(`${baseUrl}/${order.id}`);
              }
            }}
          />
        ) : this.props.view === 'transactions' ? (
          <OrdersTransaction
            order={order}
            close={() => {
              if (this.props.history) {
                this.props.history.push(`${baseUrl}/${order.id}`);
              }
            }}
          />
        ) : (
          <DialogContent className={classes.dialog}>
            <Box display="flex" flexDirection="row" style={{ gap: '18px' }} alignItems="flex-start" mb={2}>
              <Card variant="outlined" style={{ flex: '1', padding: '12px' }}>
                {order.orderItems.map(orderItem => (
                  <ProductCard
                    compact={false}
                    editable
                    variant="h5"
                    key={`${order.id}-${orderItem.id}`} {...orderItem}
                    onSuccess={() => {
                      this.setState({
                        openSuccess: true,
                        message: 'Année comptable mise à jour'
                      });
                    }}
                    onError={(error) => {
                      this.setState({
                        openError: true,
                        message: 'Erreur lors de la mise à jour de l\'année comptable'
                      });
                    }}
                  />
                ))}
                <Box
                  display="flex"
                  style={{ gap: '6px' }}
                  alignItems="center"
                >
                  {amountDialog ? (
                    <Fade in unmountOnExit appear>
                      <form
                        id="update-total-form"
                        onSubmit={async (event) => {
                          event.preventDefault();
                          const formData = new FormData(event.target);
                          const total = formData.get('total');
                          try {
                            await Http.put('/orders/updateOrderTotal', {
                              total,
                              orderId,
                            });
                            this.setState({
                              openSuccess: true,
                              message: 'Montant mis à jour, pensez à vérifier les règlements !',
                              amountDialog: null,
                            });
                            this.loadOrder(orderId);
                          } catch (e) {

                          }
                        }}
                      >
                        <Box
                          display="flex"
                          style={{ gap: '6px' }}
                          alignItems="center"
                          bgcolor="background.default"
                          p={1}
                          borderRadius={4}
                        >
                          <InputBase
                            name="total"
                            classes={{
                              root: classes.field,
                              input: classes.fieldInput,
                            }}
                            defaultValue={order.total}
                            placeholder={order.total}
                            inputProps={{
                              type: 'number',
                              min: 0,
                              max: 99999,
                            }}
                            style={{ width: '80px' }}
                          />
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="small"
                          >
                            Mettre à jour le montant
                          </Button>
                          <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            onClick={() => {
                              this.setState({ amountDialog: null });
                            }}
                          >
                            Annuler
                          </Button>
                        </Box>
                      </form>
                    </Fade>
                  ) : (
                    <Typography variant="subtitle1">{formatAmount(order.total)}</Typography>
                  )}
                  {amountDialog === null ? (
                    <Tooltip title="Changer le montant">
                      <IconButton
                        onClick={() => {
                          this.setState({
                            amountDialog: { orderId },
                          });
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  {(order.total > 0 && !changePaymentMethodForm) ? (
                    <Typography
                      variant="caption"
                      component="span"
                      style={{ marginLeft: '12px' }}
                    >
                      {`${order.payment_title}${order.payment_method === 'cheque-several' ? ` (${order.payment_periods} x)` : ''}`}
                    </Typography>
                  ) : changePaymentMethodForm ? (
                    <Fade in unmountOnExit appear>
                      <form
                        onSubmit={async (event) => {
                          event.preventDefault();
                          const formData = new FormData(event.target);
                          const periods = formData.get('periods');
                          const payment_method = formData.get('payment_method');
                          try {
                            await Http.put('/orders/updateOrderPayment', {
                              periods,
                              orderId,
                              payment_method,
                            });
                            this.setState({
                              openSuccess: true,
                              message: 'Moyen de paiement mis à jour, pensez à vérifier les règlements !',
                              changePaymentMethodForm: null,
                            });
                            this.loadOrder(orderId);
                          } catch (e) {
                            this.setState({
                              openError: true,
                              message: e.data.message,
                              changePaymentMethodForm: null,
                            });
                          }
                        }}
                      >
                        <Box
                          display="flex"
                          style={{ gap: '12px' }}
                          alignItems="stretch"
                          bgcolor="background.default"
                          flexDirection="column"
                          p={1}
                          borderRadius={4}
                        >
                          <Box
                            style={{
                              display: 'flex',
                              gap: '12px',
                              alignItems: 'center',
                            }}
                          >
                            <Typography style={{ flex: 1 }}>Moyen de paiment</Typography>
                            <NativeSelect
                              defaultValue={order.payment_method}
                              style={{
                                fontSize: '14px',
                                height: 'auto',
                              }}
                              name="payment_method"
                            >
                              {Object.keys(mapPaymentsLabels).map((k) => {
                                return (
                                  <option key={k} value={k}>{mapPaymentsLabels[k]}</option>
                                );
                              })}
                            </NativeSelect>
                          </Box>
                          <Box
                            style={{
                              display: 'flex',
                              gap: '12px',
                              alignItems: 'center',
                            }}
                          >
                            <Typography style={{ flex: 1 }}>Mensualités</Typography>
                            <NativeSelect
                              defaultValue={order.payment_periods}
                              style={{
                                fontSize: '14px',
                                height: 'auto',
                              }}
                              name="periods"
                            >
                              {[1, 2, 4, 6, 8, 10].map((period) => (
                                <option key={period} value={period}>{period}</option>
                              ))}
                            </NativeSelect>
                          </Box>
                          <Box
                            display="flex"
                            style={{ gap: '6px' }}
                            alignItems="center"
                            p={1}
                            justifyContent="center"
                          >
                            <Button
                              type="submit"
                              color="primary"
                              variant="contained"
                              size="small"
                            >
                              Enregistrer
                            </Button>
                            <Button
                              color="secondary"
                              variant="contained"
                              size="small"
                              onClick={() => {
                                this.setState({ changePaymentMethodForm: null });
                              }}
                            >
                              Annuler
                            </Button>
                          </Box>
                        </Box>
                      </form>
                    </Fade>
                  ) : null}
                  {(changePaymentMethodForm === null) ? (
                    <Tooltip
                      title={
                        <Typography>
                          Changer le moyen de paiement
                          <strong>
                            {` ${mapPaymentsLabels[order.payment_method]}`}
                          </strong>
                        </Typography>
                      }
                    >
                      <IconButton
                        onClick={() => {
                          this.setState({
                            changePaymentMethodForm: { orderId },
                          });
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </Box>
              </Card>
              {this.props.history ? (
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  flexDirection="column"
                  style={{ gap: '12px' }}
                >
                  <ButtonRouter
                    fullWidth
                    color="secondary"
                    size="small"
                    variant="outlined"
                    endIcon={<EuroIcon fontSize="small" />}
                    label="Transactions"
                    to={`${baseUrl}/${order.id}/transactions`}
                  />
                  <ButtonRouter
                    fullWidth
                    size="small"
                    variant="outlined"
                    endIcon={<ReceiptIcon fontSize="small" />}
                    label="Facture"
                    to={`${baseUrl}/${order.id}/invoice`}
                  />
                  <ButtonRouter
                    fullWidth
                    size="small"
                    variant="outlined"
                    color="primary"
                    to={`${baseUrl}/${order.id}/payments`}
                    label="Règlements"
                    endIcon={<AccountBalanceIcon fontSize="small" />}
                  />
                </Box>
              ) : null}
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={4} style={{ display: 'flex' }}>
                <Card variant="outlined" style={{ flex: '1' }}>
                  <CardHeader
                    style={{ paddingBottom: 0 }}
                    title={
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="h5">Général</Typography>
                        <ButtonRouter
                          title="Accéder au profil de l'étudiant(e)"
                          target="_blank"
                          variant="outlined"
                          size="small"
                          to={`/students/${row.id}`}
                          label={`${row.last_name} ${row.first_name}`}
                          startIcon={<SchoolIcon fontSize="inherit" />}
                          endIcon={<OpenInNewIcon fontSize="small" />}
                        />
                      </Box>
                    }
                  />
                  <CardContent>
                    <Box mb={2} border="1px solid #eee">
                      <OrderAddress student={row} kind="general" />
                    </Box>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>Date de création</TableCell>
                          <TableCell>
                            {
                              format(
                                utcToZonedTime(order.created_at, 'Etc/UTC'),
                                'dd MMM yyyy HH:mm',
                                { locale: frLocale },
                              )
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>État</TableCell>
                          <TableCell>
                            <Chip
                              className={classes.chip}
                              size="small"
                              label={mapStatusLabel[order.status]}
                              style={{
                                backgroundColor: mapStatusToColor[order.status],
                              }}
                            />
                            {
                              (order.status === 'wc-completed' && order.completed_at)
                                ? (
                                  <Typography variant="caption">
                                    {
                                      ` le ${format(
                                        utcToZonedTime(order.completed_at, 'Etc/UTC'),
                                        'dd/MM/yyyy HH:mm',
                                        { locale: frLocale },
                                      )}`
                                    }
                                  </Typography>
                                )
                                : null
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Paiement</TableCell>
                          <TableCell>
                            {order.payment_title}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Polycopiés</TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center" style={{ gap: '6px' }}>
                              <Shipment
                                shipment={order.polycopies}
                                showText
                              />
                            </Box>
                            {order.polycopies_info ? (
                              <Box
                                display="flex"
                                style={{ gap: '6px' }}
                                alignItems="flex-start"
                                flexDirection="column"
                                pt={1}
                              >
                                <Chip
                                  size="small"
                                  label="DÉPRÉCIÉ"
                                  color="secondary"
                                />
                                <Typography variant="caption">{order.polycopies_info}</Typography>
                              </Box>
                            ) : null}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Commentaire</TableCell>
                          <TableCell>
                            {order.infos ?? 'Aucun'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Rétractation</TableCell>
                          <TableCell>
                            <OrderRetractation post={order} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Élève subventionné</TableCell>
                          <TableCell>
                            <NativeSelect
                              defaultValue={order.sponsored ?? 0}
                              onChange={async (event) => {
                                try {
                                  await Http.put('/orders/updateOrderSponsored', {
                                    id: order.id,
                                    sponsored: parseInt(event.target.value, 10),
                                  });
                                  this.setState({
                                    openSuccess: true,
                                    message: 'Enregistrement effectué',
                                  });
                                } catch (e) {
                                  this.setState({
                                    openError: true,
                                    message: 'Enregistrement impossible',
                                  });
                                }
                              }}
                            >
                              {sponsoredOrder.map(({ label, value }) => (
                                <option key={value} value={value}>{label}</option>
                              ))}
                            </NativeSelect>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} style={{ display: 'flex' }}>
                <Card variant="outlined" style={{ flex: '1' }}>
                  <CardHeader style={{ paddingBottom: 0 }} title="Facturation" />
                  <CardContent>
                    <OrderAddress student={row} kind="billing" />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} style={{ display: 'flex' }}>
                <Card variant="outlined" style={{ flex: '1' }}>
                  <CardHeader style={{ paddingBottom: 0 }} title="Expédition" />
                  <CardContent>
                    <OrderAddress student={row} kind="shipping" />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    );
  }
}

OrdersDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
};

export default withMobileDialog()(withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  OrdersDialog
));
