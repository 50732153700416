import { Component, Fragment } from 'react';
import Http from 'services/Http';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { Backdrop, Box, Button, CircularProgress, Fade, IconButton, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Search.css';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DomainIcon from '@material-ui/icons/Domain';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Alert, Autocomplete } from '@material-ui/lab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchResults from './SearchResults';
import { withRouter } from 'react-router-dom';

class SearchQueryType extends Component {

  state = {
    // all server loaded
    allSessions: [],
    allTrainings: [],
    // selection
    disciplines: [],
    // selected ids
    selectedTraining: null,
    selectedSessionIds: [],
    selectedDisciplinesIds: [],
    selectedTrainingId: null,
    blockUi: false,
    students: [],
    studentsCount: 0,
    duplicatesUsers: [],
  };

  async componentDidMount() {
    this.setState({ blockUi: true });
    const { data: allSessions } = await Http.get('/sessions/list?include=discipline');
    const { data: { rows: allTrainings } } = await Http.get('/trainings?publishedOnly=false');
    this.setState({
      allSessions: allSessions.filter((session) => {
        return /*!session.name.endsWith('Option produit') && */!session.name.endsWith(' - ');
      }),
      allTrainings,
    });
    setTimeout(() => {
      this.setState({
        blockUi: false,
      });
    }, 500);

    const qs = new URLSearchParams(this.props.location.search);
    if (qs.has('training')) {
      const training = allTrainings.find(({ title }) => title === qs.get('training'));
      if (training) {
        this.filterTrainings(training);
      }
      if (qs.has('selectedSessionIds') && !qs.has('selectedDisciplinesIds')) {
        this.setState({ selectedSessionIds: qs.get('selectedSessionIds').split(',').map((v) => parseInt(v, 10)) });
      }
    }
  }

  filterTrainings = async (selectedTraining) => {
    if (selectedTraining) {
      this.setState({ selectedTraining });
      this.setState({ blockUi: true });
      const { disciplines } = await this.getDisciplinesByTrainingId(selectedTraining.id);
      this.setState({ blockUi: false, disciplines });
      const qs = new URLSearchParams(this.props.location.search);
      if (!qs.has('selectedSessionIds') && qs.has('selectedDisciplinesIds')) {
        this.setState({ selectedDisciplinesIds: qs.get('selectedDisciplinesIds').split(',').map((v) => parseInt(v, 10)) });
      }
    }
  }

  getDisciplinesByTrainingId = async (trainingId) => {
    const sessionIds = this.getSessionsByTrainingId(trainingId).map(({ id }) => id);
    const { data: { rows } } = await Http.get(`/sessions/full?limit=100&ids=${sessionIds.join(',')}`);
    const disciplines = rows.reduce((prev, current) => {
      const newDisciplines = current.disciplines.filter(({ id: disciplineId }) => {
        return prev.some(({ id }) => {
          return id === disciplineId;
        }) === false;
      });
      if (newDisciplines.length) {
        return [
          ...prev,
          ...newDisciplines,
        ];
      }
      return prev;
    }, []);
    // Tronc commun mais n'a pas de sens ?
    // const disciplinesSessionsAndTraining = rows.reduce((prev, current) => {
    //   const newDisciplines = current.training.disciplines.filter(({ id: disciplineId }) => {
    //     return prev.some(({ id }) => {
    //       return id === disciplineId;
    //     }) === false;
    //   });
    //   if (newDisciplines.length) {
    //     return [
    //       ...prev,
    //       ...newDisciplines,
    //     ];
    //   }
    //   return prev;
    // }, []);

    return {
      disciplines: [...disciplines/*, ...disciplinesSessionsAndTraining*/],
    };
  };

  getSessionsByTrainingId = (trainingId) => {
    return this.state.allSessions.filter(({ training_id }) => {
      return training_id === trainingId;
    });
  }

  handleToggleSession = (session) => () => {
    const { selectedSessionIds } = this.state;
    let nextSelectedSessionIds = [];
    if (selectedSessionIds.includes(session.id)) {
      nextSelectedSessionIds = selectedSessionIds.filter((id) => id !== session.id);
      this.setState({
        selectedSessionIds: nextSelectedSessionIds,
      });
    } else {
      nextSelectedSessionIds = [
        ...selectedSessionIds,
        session.id,
      ];
      this.setState({
        selectedSessionIds: nextSelectedSessionIds,
      });
    }
    const qs = new URLSearchParams(this.props.location.search);
    if (nextSelectedSessionIds.length === 0) {
      qs.delete('selectedSessionIds');
    } else {
      qs.set('selectedSessionIds', nextSelectedSessionIds);
    }
    this.props.history.push(`/search/recherche?${qs}`);
  };

  handleToggleDiscipline = (disciplineId) => () => {
    const { selectedDisciplinesIds } = this.state;
    let nextSelectedDisciplinesIds = [];
    if (selectedDisciplinesIds.includes(disciplineId)) {
      nextSelectedDisciplinesIds = selectedDisciplinesIds.filter((id) => id !== disciplineId);
      this.setState({
        selectedDisciplinesIds: nextSelectedDisciplinesIds,
      });
    } else {
      nextSelectedDisciplinesIds = [
        ...selectedDisciplinesIds,
        disciplineId,
      ];
      this.setState({
        selectedDisciplinesIds: nextSelectedDisciplinesIds,
      });
    }
    const qs = new URLSearchParams(this.props.location.search);
    if (nextSelectedDisciplinesIds.length === 0) {
      qs.delete('selectedDisciplinesIds');
    } else {
      qs.set('selectedDisciplinesIds', nextSelectedDisciplinesIds);
    }
    this.props.history.push(`/search/recherche?${qs}`);
  };

  handleToggleTraining = () => {
    const { selectedTrainingId, selectedTraining } = this.state;
    if (selectedTraining) {
      this.setState({
        selectedTrainingId: selectedTrainingId === null
          ? selectedTraining.id
          : null,
      });
    }
  };

  handleToggleSessions = () => {
    let nextSelectedSessionIds = [];
    const { selectedSessionIds, selectedTraining } = this.state;
    const sessionsByTraining = this.getSessionsByTrainingId(selectedTraining.id);
    if (selectedSessionIds.length === sessionsByTraining.length) {
      nextSelectedSessionIds = [];
      this.setState({ selectedSessionIds: nextSelectedSessionIds });
    } else {
      nextSelectedSessionIds = sessionsByTraining.map(({ id }) => id);
      this.setState({
        selectedSessionIds: nextSelectedSessionIds,
      })
    }
    const qs = new URLSearchParams(this.props.location.search);
    if (nextSelectedSessionIds.length === 0) {
      qs.delete('selectedSessionIds');
    } else {
      qs.set('selectedSessionIds', nextSelectedSessionIds);
    }
    this.props.history.push(`/search/recherche?${qs}`);
  };

  handleToggleDisciplines = () => {
    let nextSelectedDisciplinesIds = [];
    const { selectedDisciplinesIds, disciplines } = this.state;
    if (selectedDisciplinesIds.length === disciplines.length) {
      nextSelectedDisciplinesIds = [];
      this.setState({ selectedDisciplinesIds: nextSelectedDisciplinesIds });
    } else {
      nextSelectedDisciplinesIds = disciplines.map(({ id }) => id);
      this.setState({
        selectedDisciplinesIds: nextSelectedDisciplinesIds,
      })
    }
    const qs = new URLSearchParams(this.props.location.search);
    if (nextSelectedDisciplinesIds.length === 0) {
      qs.delete('selectedDisciplinesIds');
    } else {
      qs.set('selectedDisciplinesIds', nextSelectedDisciplinesIds);
    }
    this.props.history.push(`/search/recherche?${qs}`);
  }

  reset = () => {
    this.setState({
      selectedTraining: null,
      selectedSessionIds: [],
      selectedDisciplinesIds: [],
      disciplines: [],
    });
    this.props.history.push('/search/recherche');
  }

  disableTraining = () => {
    return this.state.selectedSessionIds.length > 0
      || this.state.selectedDisciplinesIds.length > 0;
  }

  disableSessions = () => {
    return this.state.selectedTrainingId !== null
      || this.state.selectedDisciplinesIds.length > 0;
  }

  disableDisciplines = () => {
    return this.state.selectedTrainingId !== null
      || this.state.selectedSessionIds.length > 0
      || this.state.disciplines.length === 0;
  }

  getSearchParams = () => {
    let searchParams = {};
    const qs = new URLSearchParams(this.props.location.search);
    const training = this.state.allTrainings.find(({ title }) => title === qs.get('training'));
    if (qs.has('selectedDisciplinesIds')) {
      searchParams.disciplinesIds = qs.get('selectedDisciplinesIds').split(',');
    } else if (qs.has('selectedSessionIds')) {
      searchParams.sessionIds = qs.get('selectedSessionIds').split(',');
    } else if (training) {
      searchParams.trainingId = training.id;
    };
    return searchParams;
  }

  loadStudents = async (event) => {
    this.setState({ loadingStudents: true });
    const { y } = event?.target?.getBoundingClientRect() ?? { y: 0 };
    const searchParams = this.getSearchParams();
    const { data: { count, rows, duplicatesUsers } } = await Http.post('/search', {
      ...searchParams,
    });
    [...document.getElementsByTagName('main')].at(0).scrollTo({
      top: y,
      left: 0,
      behavior: 'smooth'
    });
    this.setState({
      students: rows,
      studentsCount: count,
      loadingStudents: false,
      duplicatesUsers,
    });
  };

  renderStudents() {
    const { duplicatesUsers, students, studentsCount } = this.state;
    return (
      <Fade in appear>
        <SearchResults
          duplicatesUsers={duplicatesUsers}
          searchSummary={this.getSearchSummary()}
          searchParams={this.getSearchParams()}
          students={students}
          studentsCount={studentsCount}
          reload={() => this.loadStudents()}
          backToSearchHandler={() => {
            this.setState({
              students: [],
            });
          }}
        />
      </Fade>
    );
  }

  getSearchSummary = () => {
    const { disciplines, allSessions, selectedSessionIds, selectedDisciplinesIds, selectedTrainingId, selectedTraining } = this.state;
    const searchType = {
      type: '',
      labels: [],
    };
    if (selectedTrainingId) {
      searchType.type = 'Formations';
      searchType.labels.push(selectedTraining.title);
    } else if (selectedSessionIds.length > 0) {
      searchType.type = 'Sessions';
      searchType.labels = selectedSessionIds.map((sessionId) => {
        const session = allSessions.find(({ id }) => id === sessionId);
        return session.name;
      });
    } else if (selectedDisciplinesIds.length > 0) {
      searchType.type = 'Matières';
      searchType.labels = selectedDisciplinesIds.map((disciplineId) => {
        const discipline = disciplines.find(({ id }) => id === disciplineId);
        return discipline.name;
      });
    }
    return searchType;
  }

  render() {
    const {
      allSessions,
      allTrainings,
      blockUi,
      disciplines,
      selectedDisciplinesIds,
      selectedSessionIds,
      selectedTraining,
      selectedTrainingId,
      students,
      loadingStudents,
    } = this.state;
    const { classes } = this.props;

    if (allTrainings.length === 0) {
      return (
        <Box>
          <Typography>Chargement des formations...</Typography>
        </Box>
      );
    }

    if (allSessions.length === 0) {
      return (
        <Box>
          <Typography>Chargement des sessions...</Typography>
        </Box>
      );
    }

    if (students.length) {
      return this.renderStudents();
    }

    const sessions = selectedTraining
      ? this.getSessionsByTrainingId(selectedTraining.id)
      : [];

    return (
      <Grid container spacing={2} style={{ opacity: loadingStudents ? .5 : 1 }}>
        <Backdrop className={classes.backdrop} open={blockUi} style={{ zIndex: 999999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid
          item
          xs={12}
        >
          <Card>
            <CardHeader
              avatar={
                <Checkbox
                  onClick={this.handleToggleTraining}
                  checked={selectedTrainingId !== null}
                  disabled={this.disableTraining() || selectedTraining === null}
                />
              }
              subheader={
                <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                  <BookmarkIcon />
                  <Autocomplete
                    id="trainings-combo"
                    getOptionLabel={(option) => option.title}
                    options={allTrainings}
                    value={selectedTraining}
                    size="small"
                    style={{ width: '100%' }}
                    filterOptions={(options, state) => {
                      // const searchRegex = new RegExp(`^${state.inputValue.replace(/%/g, '.*')}.*$`, 'i');
                      // return options.filter(obj => obj.title && searchRegex.test(obj.title));
                      const { inputValue } = state;
                      if (inputValue.length === 0) {
                        return options;
                      }
                      const jokers = inputValue.split(' ');
                      if (jokers.length) {
                        return options.filter((option) => {
                          return jokers.every((joker) => {
                            return option.title.toLowerCase().includes(joker.toLowerCase());
                          });
                        });
                      }
                      return options.filter((option) => {
                        return option.title.toLowerCase().includes(inputValue.toLowerCase());
                      });
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField {...params} label="Formations" variant="outlined" />
                      );
                    }}
                    onChange={(_evt, selectedTraining) => {
                      this.filterTrainings(selectedTraining);
                      this.props.history.push(`/search/recherche?training=${selectedTraining.title}`);
                    }}
                    disableClearable
                  />
                  <IconButton onClick={this.reset}>
                    <HighlightOffIcon />
                  </IconButton>
                </Box>
              }
            />
          </Card>
        </Grid>
        {selectedTraining !== null ? (
          <Fragment>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card>
                <CardHeader
                  avatar={
                    <Checkbox
                      onClick={this.handleToggleSessions}
                      checked={this.getSessionsByTrainingId(selectedTraining.id).length > 0 && selectedSessionIds.length === this.getSessionsByTrainingId(selectedTraining.id).length}
                      disabled={this.disableSessions()}
                    />
                  }
                  subheader={
                    <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                      <DomainIcon />
                      <Typography>Sessions</Typography>
                    </Box>
                  }
                />
                <Divider />
                <Box
                  className={classes.cardContent}
                  style={{
                    height: '40vh',
                  }}
                >
                  <List
                    dense
                    component="div"
                    role="list"
                  >
                    {sessions.map((session) => {
                      const labelId = `session-${session.id}`;
                      return (
                        <ListItem
                          role="listitem"
                          button
                          onClick={this.handleToggleSession(session)}
                          key={labelId}
                          disabled={this.disableSessions()}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedSessionIds.includes(session.id)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={session.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card>
                <CardHeader
                  avatar={
                    <Checkbox
                      onClick={this.handleToggleDisciplines}
                      checked={selectedDisciplinesIds.length === disciplines.length && disciplines.length > 0}
                      disabled={this.disableDisciplines()}
                    />
                  }
                  subheader={
                    <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                      <ListAltIcon />
                      <Typography>Matières</Typography>
                    </Box>
                  }
                />
                <Divider />
                <Box
                  className={classes.cardContent}
                  style={{
                    height: '40vh',
                  }}
                >
                  <List
                    dense
                    component="div"
                    role="list"
                  >
                    {disciplines.map((discipline) => {
                      const labelId = `discipline-${discipline.id}`;
                      return (
                        <ListItem
                          role="listitem"
                          button
                          onClick={this.handleToggleDiscipline(discipline.id)}
                          key={labelId}
                          disabled={this.disableDisciplines()}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedDisciplinesIds.includes(discipline.id)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={discipline.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
              </Card>
            </Grid>
          </Fragment>
        ) : (
          <Grid
            item
            xs={12}
          >
            <Alert
              variant="filled"
              severity="warning"
            >
              Veuillez choisir une formation
            </Alert>
          </Grid>
        )
        }
        {(selectedTraining !== null && students.length === 0)
          ? (
            <Grid
              item
              xs={12}
            >
              <Paper>
                <Button
                  fullWidth
                  disabled={loadingStudents || (!selectedTrainingId && !selectedSessionIds.length && !selectedDisciplinesIds.length)}
                  onClick={this.loadStudents}
                >
                  Afficher les étudiants
                </Button>
              </Paper>
            </Grid>
          ) : null
        }
        {(Object.keys(this.getSearchParams() ?? {}).length !== 0 && students.length === 0) ? (
          <Alert style={{ flex: 1 }} severity="info">Aucun étudiant pour vos critères de recherche</Alert>
        ) : null}
      </Grid >
    )
  }
}

export default withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(withRouter(SearchQueryType));
