export const styles = theme => ({
  boldDivider: {
    '& .MuiTableBody-root .MuiTableCell-root': {
      borderBottomWidth: '2px',
      borderBottomColor: theme.palette.grey[400],
      paddingTop: theme.spacing(2),
    },
    '& .MuiTableBody-root .MuiTableRow-root': {
      // boxShadow: theme.shadows[1],
    },
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: 4,
    marginBottom: 4,
    whiteSpace: 'nowrap',
    minWidth: 'auto',
  },
  alertRoot: {
    marginTop: 4,
    marginBottom: 4,
    alignItems: 'center',
    padding:`${theme.spacing(.5)}px ${theme.spacing(1)}px`,
    fontSize: theme.typography.caption.fontSize,
  },
  alertMessage: {
    padding: theme.spacing(0),
  },
  alertIcon: {
    fontSize: theme.typography.caption.fontSize,
    padding: 0,
    marginRight: theme.spacing(1),
  },
  smallButton: {
    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: '12px',
    },
    '& .MuiButton-outlined': {
      fontSize: '12px',
      padding: `2px ${theme.spacing(1)}px`,
    },
  },
});
