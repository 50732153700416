import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    IconButton,
    InputBase,
    Slide,
    Toolbar,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './SessionDialogDiscipline.css';
import Http from 'services/Http';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SessionDialogDiscipline extends Component {
    state = {
        error: '',
        openError: false,
        success: '',
        openSuccess: false,
        selectedSessions: [],
        disciplinesWithCount: [],
        hasMixedTraining: false,
        deleteDialog: false,
        deleteDiscipline: null,
        newDiscipline: '',
    }

    componentDidUpdate(prevProps) {
        const { selectedIds } = this.props;
        const { selectedIds: prevSelectedIds } = prevProps;
        if (selectedIds[0] && selectedIds.join('-') !== prevSelectedIds.join('-')) {
            this.getDisciplines(selectedIds);
        }
    }

    reload() {
        const { selectedIds } = this.props;
        this.getDisciplines(selectedIds);
    }

    async getDisciplines(selectedIds) {
        const { data: { rows: sessions } } = await Http.get(`/sessions/?ids=${selectedIds}&offset=0&limit=${selectedIds.length}`);
        const selectedSessions = sessions.map(session => ({ id: session.id, name: session.name }));
        const hasMixedTraining = new Set(sessions.map(session => session.training_id)).size > 1;
        let disciplinesWithCount = [];
        if (!hasMixedTraining) {
            const { data: { rows: allDisciplines } } = await Http.get(`/disciplines/${sessions[0].training_id}`);
            const total = sessions.length;
            disciplinesWithCount = allDisciplines.map(discipline => {
                const count = sessions.reduce((prev, { disciplines }) => prev + ((disciplines || '').split(',').includes(`${discipline.id}`) ? 1 : 0), 0);
                return {
                    ...discipline,
                    label: `${count} / ${total}`,
                    color: count === 0 ? 'secondary' : (count === total ? 'primary' : 'default'),
                    variant: count !== 0 && count !== total ? 'outlined' : 'default',
                };
            });
        }

        this.setState({
            hasMixedTraining,
            selectedSessions,
            disciplinesWithCount,
        });
    }

    // DIALOGS
    onOpenDeleteDialog = (deleteDiscipline) => {
        this.setState({
            deleteDialog: true,
            deleteDiscipline,
        });
    }

    onCloseDeleteDialog = () => {
        this.setState({
            deleteDialog: false,
            deleteDiscipline: null,
        });
    }

    onCloseDialog = () => {
        this.props.onClose();
    }

    // INPUT EVENTS
    handleNewChange = ({ target }) => {
        this.setState({
            newDiscipline: target.value,
        });
    }
    
    // CRUD
    onDisciplineUnassign = async (id, name) => {
        try {
            await Http.delete(
                `/disciplines/${id}`,
                { data: { session_ids: this.props.selectedIds } }
            );
            this.setState({
                success: `La matière "${name}" a été supprimée des sessions sélectionnées`,
                openSuccess: true,
            });
            this.reload();
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
            });
        }
    }

    onDisciplineAssign = async (name) => {
        try {
            await Http.post(
                `/disciplines`,
                { session_ids: this.props.selectedIds, name }
            );
            this.setState({
                success: `La matière "${name}" a été affectée aux sessions sélectionnées`,
                openSuccess: true,
                newDiscipline: '',
            });
            this.reload();
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
            });
        }
    }

    onDisciplineDelete = async () => {
        if (this.state.deleteDiscipline) {
            try {
                const { data } = await Http.delete(`/disciplines/${this.state.deleteDiscipline}`);
                this.setState({
                    success: `La matière "${data.name}" a été supprimée`,
                    openSuccess: true,
                    deleteDialog: false,
                    deleteDiscipline: null,
                });
                this.reload();
            } catch ({ data }) {
                this.setState({
                    error: data ? Object.values(data) : ['Une erreur est survenue'],
                    openError: true,
                    deleteDialog: false,
                    deleteDiscipline: null,
                });
            }
        }
    }

    render() {
        const { classes, open } = this.props;
        const {
            error,
            openError,
            openSuccess,
            success,
            disciplinesWithCount,
            selectedSessions,
            hasMixedTraining,
            deleteDialog,
            newDiscipline,
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.onCloseDialog}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="lg"
            >
                <ConfirmationDialog
                    open={deleteDialog}
                    title="Attention"
                    message="Êtes-vous sûr de vouloir supprimer définitivement cette matière ?"
                    onClose={this.onCloseDeleteDialog}
                    onConfirm={this.onDisciplineDelete}
                />
                <ErrorSnackbar
                    message={error}
                    onClose={() => this.setState({ openError: false })}
                    open={openError}
                />
                <SuccessSnackbar
                    message={success}
                    onClose={() => this.setState({ openSuccess: false })}
                    open={openSuccess}
                />

                <Toolbar className={classes.toolbar}>
                    <IconButton
                        className={classes.closeButton}
                        edge="start"
                        color="inherit"
                        onClick={this.onCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        color="inherit"
                        variant="h6"
                        className={classes.title}
                    >
                        Affecter une matière aux {selectedSessions.length} sessions
                    </Typography>
                </Toolbar>

                <DialogContent className={classes.dialog}>
                    <Box p={1} className={classes.chips}>
                        {selectedSessions.map(({ id, name }) => (
                            <Chip key={id} className={classes.chip} size="small" label={name} />
                        ))}
                    </Box>
                    {hasMixedTraining ? (
                        <Box px={2}>
                            <Typography component="div" variant="body1">
                                <Box display="flex" bgcolor="error.main" color="error.contrastText" p={2} my={2}>
                                    <WarningIcon />&nbsp;&nbsp;Merci de ne sélectionner que des sessions appartenant à la même formation
                                </Box>
                            </Typography>
                        </Box>
                    ) : (
                        <Box p={2}>
                            {disciplinesWithCount.map(({ color, id, label, name, variant }) => (
                                <div key={id}>
                                    <Box display="inline-block" className={classes.label}>
                                        {name}
                                    </Box>
                                    <Chip
                                        color={color}
                                        label={label}
                                        size="small"
                                        variant={variant}
                                    />
                                    <Button
                                        className={classes.button}
                                        variant="outlined"
                                        size="small"
                                        onClick={() => this.onDisciplineAssign(name)}
                                    >   
                                        Affecter à tous
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => this.onDisciplineUnassign(id, name)}
                                    >
                                        Enlever à tous
                                    </Button>
                                    <IconButton
                                        color="secondary"
                                        className={classes.button}
                                        onClick={() => this.onOpenDeleteDialog(id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))}

                            <Box pt={2}>
                                <InputBase
                                    classes={{
                                        root: classes.field,
                                        input: classes.fieldInput,
                                    }}
                                    placeholder="Nouvelle matière"
                                    onChange={this.handleNewChange}
                                    value={newDiscipline}
                                    onKeyPress={({ key }) => {
                                        if (key === 'Enter') {
                                            this.onDisciplineAssign(newDiscipline);
                                        }
                                    }}
                                />
                                <IconButton
                                    color="secondary"
                                    onClick={() => this.onDisciplineAssign(newDiscipline)}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

SessionDialogDiscipline.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedIds: PropTypes.array.isRequired,
};

export default withStyles(styles)(
    SessionDialogDiscipline
);
