import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import {
  Backdrop,
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  InputBase,
  // List,
  // ListItem,
  // ListItemIcon,
  // ListItemText,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Orders.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import ProductCard from 'components/ProductCard/ProductCard';
import { FilterButton, FilterButtonGroup } from 'components/Datagrid/FilterButtons';
import FilterInput from 'components/Datagrid/FilterInput';
import { theme } from 'theme';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import HelpIcon from '@material-ui/icons/Help';
import OrdersDialog from 'components/OrdersDialog/OrdersDialog';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import SchoolIcon from '@material-ui/icons/School';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import OrderRetractation from './OrderRetractation';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { TimelineOppositeContent } from '@material-ui/lab';
import { formatAmount } from './OrdersPayments';
import SearchIcon from '@material-ui/icons/Search';
import BlockIcon from '@material-ui/icons/Block';
import { utcToZonedTime } from 'date-fns-tz';
import classNames from 'classnames';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { Shipment } from 'components/Shipment/Shipment';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const sortableCells = [
  { name: 'id', label: '#' },
  // registeredAt permet de laisser le user trier, a l'affichage on affiche la date de la premiere commande (la plus récente)
  { name: 'registeredAt', label: 'Date' },
  { name: 'lastOrderedAt', label: 'Commandes' },
];

export const mapStatusToColor = {
  'wc-completed': '#c8d7e1',
  'wc-refunded': '#ffff77',
  'wc-pending': '#e5e5e5',
  'wc-processing': '#c6e1c6',
  'wc-on-hold': '#f8dda7',
  'wc-cancelled': '#e5e5e5',
  'wc-failed': '#eba3a3',
  'trash': '#eba3a3',
};

export const mapStatusLabel = {
  'wc-completed': 'Terminée',
  'wc-refunded': 'Remboursée',
  'wc-pending': 'Attente paiement',
  'wc-processing': 'En cours',
  'wc-on-hold': 'En attente',
  'wc-cancelled': 'Annulée',
  'wc-failed': 'Échouée',
  'trash': 'Corbeille',
};

export const isRetractable = (post) => {
  const { created_at, renonce_droit_retractation } = post;
  if (renonce_droit_retractation === null || renonce_droit_retractation === true) {
    return false;
  }
  return isAfter(new Date(), addDays(new Date(created_at), 14)) === false;
};

class Orders extends Component {
  state = {
    // query filter
    orderStatus: '',
    // record data
    selectedOrderRecord: false,
    newOrderStatus: '',
    // confirm dialog
    openedConfirm: false,
    openedConfirmProgressToCompleted: false,
    // orders in progress
    ordersInProgress: [],
    // checkboxes
    selectAllInProgressOrders: false,
    selectedIds: [],
    // snackbars
    error: '',
    openError: false,
    success: '',
    openSuccess: false,
    blockUi: false,
    // chequeValidationErrors: [],
    // queuedOrderStatuses: [],
  }

  componentDidMount() {
    this.reloadValidableOrders();
  }

  reloadValidableOrders = async () => {
    const { data } = await Http.get('/users/customers?orderValidable=1&direction=desc&offset=0&limit=100');
    this.setState({ validableOrdersLength: data.rows.length })
  }

  processUpdateOrderStatuses = async (orderStatuses) => {
    this.setState({ blockUi: true });
    try {
      /*const { data: { success, chequeValidationErrors } } = */await Http.post('/orders/updateOrderStatus', {
        orderStatus: orderStatuses,
      });

      // if (!success && chequeValidationErrors.length > 0) {
      //   this.setState({
      //     queuedOrderStatuses: orderStatuses,
      //     chequeValidationErrors,
      //     openError: true,
      //     error: `Erreur : il y a ${chequeValidationErrors.length} commande${chequeValidationErrors.length > 1 ? 's' : ''} par chèque en erreur`,
      //   });
      // } else {
        this.setState({
          openSuccess: true,
          success: orderStatuses.length === 1
            ? 'Statut de la commande mis à jour !'
            : 'Statuts des commandes mis à jour !',
        });
      // }
    } catch (e) {
      this.setState({
        openError: true,
        error: `Erreur ${e.message}`,
      });
    }
    this.setState({
      orderRecord: false,
      newOrderStatus: '',
      openedConfirm: false,
      blockUi: false,
    });
    this.props.reload();
    this.reloadValidableOrders();
  };

  async updateOrdersStatus() {
    const { orderRecord, newOrderStatus } = this.state;
    this.processUpdateOrderStatuses([
      {
        orderStatus: newOrderStatus,
        orderId: orderRecord.id,
      }
    ]);
  }

  async bulkUpdateOrdersStatus() {
    const { rows } = this.props;
    const { selectedIds } = this.state;
    const orderStatuses = rows.filter(({ id }) => selectedIds.includes(String(id))).map(({ orders }) => {
      return {
        orderId: orders[0].id,
        orderStatus: 'wc-completed',
      };
    });
    this.processUpdateOrderStatuses(orderStatuses);
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? this.props.rows
      .filter((n) => {
        if (this.props.filterState.orderValidable === '1') {
          return true;
        }
        return n.orders.length === 1 && !isRetractable(n.orders[0]);
      })
      .map(({ id }) => String(id)) : [];
    this.setState({
      selectAllInProgressOrders: checked,
      selectedIds,
    });
  }

  onSelectOne = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
      selectedIds.push(value);
    } else {
      const index = selectedIds.indexOf(value);
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({ selectedIds });
  }

  renderOrder = (order) => {
    const { classes } = this.props;
    return (
      <Box
        display="flex"
        flexDirection="row"
        key={order.id}
        alignItems="center"
        justifyContent="flex-start"
        style={{
          gap: theme.spacing(2),
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: '2px' }}
        >
          <NativeSelect
            value={order.status}
            onChange={(event) => {
              this.setState({
                orderRecord: order,
                openedConfirm: true,
                newOrderStatus: event.target.value,
              });
            }}
            style={{
              backgroundColor: mapStatusToColor[order.status],
              paddingLeft: '10px',
              flex: 1,
              fontSize: '12px',
              height: 'auto',
            }}
          >
            {Object.entries(mapStatusLabel).map(([optionValue, optionLabel]) => {
              return (
                <option key={optionValue} value={optionValue}>{optionLabel}</option>
              );
            })}
          </NativeSelect>
          {
            (order.status === 'wc-completed' && order.completed_at)
              ? (
                <Typography variant="caption">
                  {
                    ` le ${format(
                      utcToZonedTime(order.completed_at, 'Etc/UTC'),
                      'dd/MM/yyyy HH:mm',
                      { locale: frLocale },
                    )}`
                  }
                </Typography>
              )
              : null
          }
        </Box>
        <Shipment shipment={order.polycopies} />
        <Box flex={4}>
          <Box
            display="flex"
            alignItems="center"
            style={{
              gap: theme.spacing(1),
            }}
            className={classes.smallButton}
          >
            <ButtonRouter
              variant="outlined"
              size="small"
              to={`/orders/${order.id}`}
              label={`#${order.id}`}
              startIcon={<ShoppingCartIcon fontSize="small" />}
            />
            {order.infos ? (
              <Tooltip title={order.infos}>
                <FeedbackIcon color="error" />
              </Tooltip>
            ) : null}
            <Box>
              <OrderRetractation post={order} />
            </Box>
            <Box style={{ marginTop: '-6px' }}>
              {order.orderItems.map(orderItem => (
                <ProductCard variant="caption" key={`${order.id}-${orderItem.id}`} {...orderItem} />
              ))}
              <Typography
                variant="caption"
              >
                {`${order.payment_title ?? ''}${order.payment_method === 'cheque-several' ? ` (${order.payment_periods} x)` : ''} ${formatAmount(order.total)}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  onOrderIdSearch = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    this.props.history.push(`/orders/${formData.get('inputOrderId')}`);
  };

  render() {
    const {
      classes,
      filter,
      filterState,
      loading,
      match,
      rows,
      renderPagination,
      renderSortCell,
      renderFilter,
      reload,
    } = this.props;
    const {
      openedConfirm,
      orderRecord,
      newOrderStatus,
      validableOrdersLength,
      selectAllInProgressOrders,
      selectedIds,
      openedConfirmProgressToCompleted,
      error,
      openError,
      success,
      openSuccess,
      blockUi,
      // chequeValidationErrors,
      // queuedOrderStatuses,
    } = this.state;

    const { orderId, view } = match.params;
    const loadingStyle = { opacity: loading ? .5 : 1 };
    const bulkEditOrdersStatus = filterState.orderInProgress === '1' || filterState.orderValidable === '1';
    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        <Backdrop
          open={blockUi}
          style={{ zIndex: 999999 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <OrdersDialog
          history={this.props.history}
          open={orderId !== undefined}
          onClose={() => {
            this.props.updateUrl('/orders');
          }}
          onCreate={() => { }}
          // below props from router
          orderId={orderId}
          view={view}
          baseUrl="/orders"
        />
        <ErrorSnackbar
          message={<Box>{error}</Box>}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <ConfirmationDialog
          message={`Êtes-vous sûr de vouloir passer les ${selectedIds.length} commandes séléctionnées à l'état terminé ?`}
          onClose={() => {
            this.setState({
              openedConfirmProgressToCompleted: false,
              selectAllInProgressOrders: false,
              selectedIds: [],
            });
          }}
          onConfirm={async () => {
            await this.bulkUpdateOrdersStatus();
            this.setState({
              openedConfirmProgressToCompleted: false,
              selectAllInProgressOrders: false,
              selectedIds: [],
            });
            reload();
          }}
          open={openedConfirmProgressToCompleted}
          title="Votre attention"
        />
        {/* <ConfirmationDialog
          message={
            <Box>
              <Alert severity="error">
                Merci de saisir les règlements des ces commandes pour pouvoir les passer en état "terminée"
              </Alert>
              <Box mt={2}>
                <List>
                  {chequeValidationErrors.map(({ orderId, paymentsTotal, orderTotal }) => {
                    return (
                      <ListItem
                        key={orderId}
                        button
                        onClick={() => window.open(`${process.env.REACT_APP_CRM_URL}/orders/${orderId}/payments`)}
                      >
                        <ListItemIcon>
                          <OpenInNewIcon />
                        </ListItemIcon>
                        <ListItemText>
                          {`Saisir ${orderId} (${paymentsTotal} / ${orderTotal} Euros)`}
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
          }
          noLabel="Annuler"
          yesLabel="J'ai saisi les règlements : valider"
          onClose={() => {
            this.setState({
              chequeValidationErrors: [],
            });
          }}
          onConfirm={async () => {
            this.setState({
              chequeValidationErrors: [],
            });
            this.processUpdateOrderStatuses(queuedOrderStatuses);
            // await this.updateOrdersStatus();
            // reload();
          }}
          open={chequeValidationErrors.length > 0}
          title="Votre attention"
        /> */}
        <ConfirmationDialog
          message={
            <Box>
              <Typography>
                {`Cliquez sur OUI pour passer le statut de "${mapStatusLabel[orderRecord ? orderRecord.status : '']}" à "${mapStatusLabel[newOrderStatus]}"`}
              </Typography>
              {orderRecord && isRetractable(orderRecord) && newOrderStatus === 'wc-completed' ? (
                <Box mt={2}>
                  <Alert severity="warning">
                    Cette commande est encore en période de rétractation ! Cliquez sur OUI si vous souhaitez passer outre cet avertissement.
                  </Alert>
                </Box>
              ) : null}
            </Box>
          }
          onClose={() => {
            this.setState({
              orderRecord: false,
              newOrderStatus: '',
              openedConfirm: false,
            });
            reload();
          }}
          onConfirm={async () => {
            await this.updateOrdersStatus();
            reload();
          }}
          open={openedConfirm}
          title="Votre attention"
        />
        <Toolbar>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <form onSubmit={this.onOrderIdSearch}>
            <Box
              display="flex"
              alignItems="center"
            >
              <InputBase
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                style={{ width: '75px' }}
                placeholder="ID commande"
                name="inputOrderId"
              />
              <Tooltip title="Afficher le détail de la commande">
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation color="primary"
                  style={{
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                    minWidth: 0,
                  }}
                >
                  <SearchIcon fontSize="small" />
                </Button>
              </Tooltip>
            </Box>
          </form>
          <Fade in={selectedIds.length > 0}>
            <Box
              flex={100}
              display="flex"
              justifyContent="flex-start"
              ml={1}
            >
              <Badge color="primary" badgeContent={selectedIds.length}>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    this.setState({
                      openedConfirmProgressToCompleted: true,
                    });
                  }}
                >
                  Valider {selectedIds.length} commande{selectedIds.length > 1 ? 's' : ''} ?
                </Button>
              </Badge>
            </Box>
          </Fade>
          <FilterButtonGroup
            filter={(filterBy) => filter(filterBy, true)}
            selectedIndex={
              this.props.filterState.orderInProgress === '1'
                ? 3
                : this.props.filterState.orderValidable === '1'
                  ? 4
                  : this.props.filterState.orderStatus === undefined
                    ? 0
                    : this.props.filterState.orderStatus === '1'
                      ? 1
                      : 2
            }>
            <FilterButton filterBy={{ orderStatus: '', orderInProgress: '', orderValidable: '' }}>Toutes</FilterButton>
            <FilterButton filterBy={{ orderStatus: '1', orderInProgress: '', orderValidable: '' }}>Validées</FilterButton>
            <FilterButton filterBy={{ orderStatus: '0', orderInProgress: '', orderValidable: '' }}>Non validées</FilterButton>
            <FilterButton
              filterBy={{ orderInProgress: '1', orderValidable: '', orderStatus: '' }}
              decorator={(children) => {
                return (
                  <Badge
                    color="error"
                    invisible={rows.length === 0 || filterState.orderInProgress !== '1'}
                    badgeContent={`⚠️ ${rows.length}`}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {children}
                  </Badge>
                );
              }}
            >
              En cours
            </FilterButton>
            <FilterButton
              filterBy={{ orderValidable: '1', orderInProgress: '', orderStatus: '' }}
              decorator={(children) => {
                return (
                  <Badge
                    color="primary"
                    invisible={!validableOrdersLength}
                    badgeContent={`✔️ ${validableOrdersLength}`}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {children}
                  </Badge>
                );
              }}
            >
              Validable
            </FilterButton>
          </FilterButtonGroup>
          <FilterInput
            filter={filter}
            filterBy="product"
            placeholder="Filter par produit"
          />
          {renderFilter('Filtrer par nom client')}
        </Toolbar>
        <Table size="small" className={classNames(classes.table, classes.boldDivider)}>
          <TableHead>
            <TableRow>
              {bulkEditOrdersStatus ? (
                <TableCell align="center">
                  <Checkbox
                    onChange={this.onSelectAllClick}
                    checked={selectAllInProgressOrders}
                  />
                </TableCell>
              ) : null}
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell>Etudiant</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(n => (
              <TableRow key={n.id}>
                {bulkEditOrdersStatus ? (
                  <TableCell align="center">
                    {(filterState.orderValidable !== '1' && (n.orders.length > 1 || isRetractable(n.orders[0]))) ? (
                      <Tooltip
                        title="⚠️ Non elligible : étudiant en rétractation ou avec plusieurs commandes. A valider unitairement."
                      >
                        <HelpIcon fontSize="small" />
                      </Tooltip>
                    ) : (
                      <Checkbox
                        value={String(n.id)}
                        onChange={this.onSelectOne}
                        checked={selectedIds.includes(String(n.id))}
                      />
                    )}
                  </TableCell>
                ) : null}
                <TableCell component="th" scope="row">
                  {n.id}
                </TableCell>
                <TableCell>
                  <Typography variant="caption">
                    {
                      format(
                        utcToZonedTime(n.orders.at(0).created_at, 'Etc/UTC'),
                        'dd/MM/yyyy HH:mm',
                        { locale: frLocale },
                      )
                    }
                  </Typography>
                </TableCell>
                <TableCell>
                  {n.orders.slice(0, 1).map((order) => (
                    this.renderOrder(order)
                  ))}
                  {
                    n.orders.length > 1 ? (
                      <Box>
                        <Timeline>
                          {n.orders.slice(1).map((order) => (
                            <TimelineItem key={order.id}>
                              <TimelineOppositeContent style={{ flex: 1, paddingTop: 0, paddingBottom: 0 }}>
                                <Typography color="textSecondary" variant="caption">
                                  {
                                    format(
                                      utcToZonedTime(order.created_at, 'Etc/UTC'),
                                      'dd/MM/yyyy HH:mm',
                                      { locale: frLocale },
                                    )
                                  }
                                </Typography>
                              </TimelineOppositeContent>
                              <TimelineSeparator>
                                <TimelineDot />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent style={{ flex: 100, paddingTop: 0, paddingBottom: 0 }}>
                                {this.renderOrder(order)}
                                <Divider />
                              </TimelineContent>
                            </TimelineItem>
                          ))}
                        </Timeline>
                      </Box>
                    ) : null
                  }
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ gap: '12px' }}
                  >
                    <Box flex="1">
                      <ButtonRouter
                        fullWidth
                        variant="outlined"
                        size="small"
                        to={`/students/${n.id}`}
                        label={`${n.last_name} ${n.first_name}`}
                        startIcon={<SchoolIcon fontSize="inherit" />}
                      />
                    </Box>
                    <Box>
                      {n.studentMeta ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          style={{ gap: '6px' }}
                        >
                          <Shipment shipment={n.studentMeta.shipment} />
                          {n.studentMeta.do_not_send === 1 ? (
                            <Tooltip title="Attention : élève banni">
                              <BlockIcon fontSize="small" color="secondary" />
                            </Tooltip>
                          ) : null}
                        </Box>
                      ) : (
                        <Shipment shipment={null} />
                      )}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <Tooltip title={n.billing_email}>
                      <IconButton
                        href={`mailto: ${n.billing_email}`}
                        target="_blank"
                      >
                        <EmailIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={n.billing_phone}>
                      <IconButton
                        href={`tel: ${n.billing_phone}`}
                        target="_blank"
                      >
                        <PhoneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  withSortAndPagination(Orders, 'users/customers', 'lastOrderedAt', 'desc')
);
