import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  List,
  ListItem,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './SessionDialogMerge.css';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { tableStyles } from 'styles/datatable.css';
import MergeDnd from './MergeDnd';
import { Alert } from '@material-ui/lab';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SessionDialogMerge extends Component {
  state = {
    allSessions: [],
    allTrainings: [],
    selectedTraining: null,
    blockUi: false,
    sessions: [],
    targetSources: null,
    noSessions: false,
    moveableDisciplines: [],
    studentsToUpdate: [],
    openError: false,
    openSuccess: false,
    error: [],
    success: '',
    confirmDialog: false,
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.setState({ blockUi: true });
    const { data: allSessions } = await Http.get('/sessions/list?include=discipline');
    const { data: { rows: allTrainings } } = await Http.get('/trainings?publishedOnly=false');
    this.setState({
      allSessions: allSessions.filter((session) => {
        return !session.name.endsWith('Option produit') && !session.name.endsWith(' - ');
      }),
      allTrainings,
    });
    setTimeout(() => {
      this.setState({
        blockUi: false,
      });
    }, 500);
  }

  onSessionMerge = (dryRun) => async () => {
    const { targetSources } = this.state;
    try {
      const { data } = await Http.post('/sessions/merge', {
        ...targetSources,
        dryRun,
      });
      if (dryRun) {
        this.setState({
          moveableDisciplines: data.moveableDisciplines,
          studentsToUpdate: data.studentsToUpdate,
        });
      } else {
        this.setState({
          success: 'La session a été fusionnée',
          openSuccess: true,
          sessions: [],
        });
        const { title } = this.state.selectedTraining;
        await this.initialize();
        await this.filterTrainings({
          currentTarget: {
            value: title,
          }
        });
        this.setState({ confirmDialog: false });
      }
    } catch (e) {
      this.setState({
        error: [e.message],
        openError: true,
      });
    }
  }

  onDialogClose = () => {
    this.props.onClose();
  }

  reset = () => {
    document.querySelector('input[name="training"]').value = '';
    this.setState({
      selectedTraining: null,
      sessions: [],
      targetSources: null,
    });
  }

  filterTrainings = async (event) => {
    const { allTrainings } = this.state;
    const filter = event.currentTarget.value;
    const filtered = allTrainings.filter((training) => {
      return training.title === filter;
    });
    const trainings = filter.length !== 0 ? filtered : [];
    const selectedTraining = trainings.length >= 1 ? trainings.pop() : null;
    if (selectedTraining) {
      this.setState({ blockUi: true });
      const sessionIds = this.state.allSessions.filter(({ training_id }) => {
        return training_id === selectedTraining.id;
      }).map(({ id }) => id);
      if (!sessionIds.length) {
        this.setState({
          noSessions: true,
          sessions: [],
          selectedTraining,
          blockUi: false,
        })
      } else {
        const { data: { rows } } = await Http.get(`/sessions/full?limit=100&ids=${sessionIds.join(',')}`);//limit
        this.setState({
          sessions: rows,
          selectedTraining,
          blockUi: false,
          noSessions: false,
        })
      }
    }
  }

  render() {
    const { classes, open } = this.props;
    const {
      blockUi,
      allTrainings,
      selectedTraining,
      sessions,
      noSessions,
      moveableDisciplines,
      studentsToUpdate,
      targetSources,
      openSuccess,
      openError,
      success,
      error,
      confirmDialog,
    } = this.state;

    const disabledMergeActions = targetSources === null || (targetSources && targetSources.sources.length === 0) || (targetSources && targetSources.target === null);

    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={blockUi} style={{ zIndex: 999999 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ConfirmationDialog
            open={confirmDialog}
            title="Attention"
            message="Êtes-vous sûr de vouloir fusionner ?"
            onClose={() => {
              this.setState({ confirmDialog: false });
            }}
            onConfirm={this.onSessionMerge(false)}
        />
        <Dialog
          open={open}
          onClose={this.onDialogClose}
          TransitionComponent={Transition}
          aria-labelledby="form-dialog-title"
          fullScreen
        >
          <SuccessSnackbar
            open={openSuccess}
            onClose={() => this.setState({ openSuccess: false })}
            message={success}
          />
          <ErrorSnackbar
            open={openError}
            onClose={() => this.setState({ openError: false })}
            message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
          />
          <Toolbar className={classes.toolbar}>
            <Typography
              color="inherit"
              variant="h6"
              className={classes.title}
            >
              Fusionner une session
            </Typography>
            <Box
              display="flex"
              style={{ gap: '16px' }}
              alignItems="center"
            >
              <Button
                onClick={this.onSessionMerge(true)}
                variant="contained"
                disableElevation
                disabled={disabledMergeActions}
              >
                Prévisualiser
              </Button>
              <Button
                onClick={() => {
                  this.setState({ confirmDialog: true });
                }}
                variant="contained"
                color="secondary"
                disableElevation
                disabled={disabledMergeActions}
              >
                Fusionner
              </Button>
              <IconButton
                className={classes.closeButton}
                edge="start"
                color="inherit"
                onClick={this.onDialogClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Toolbar>
          <DialogContent className={classes.dialog}>
            <Box className={classes.stack}>
              <Collapse in={moveableDisciplines.length > 0 || studentsToUpdate.length > 0} unmountOnExit appear>
                <Paper>
                  <Box p={2}>
                    <Box display="flex" style={{ gap: '8px' }}>
                      <Typography variant="h5">Matières</Typography>
                      <Chip label={moveableDisciplines.length} />
                    </Box>
                    <List>
                      {moveableDisciplines.map(({ name, id }) => {
                        return (
                          <ListItem key={id}>{name}</ListItem>
                        );
                      })}
                    </List>
                    <Box display="flex" style={{ gap: '8px' }}>
                      <Typography variant="h5">Etudiants</Typography>
                      <Chip label={studentsToUpdate.length} />
                    </Box>
                    <List>
                      {studentsToUpdate.map((student) => {
                        return (
                          <ListItem key={student.id}>
                            {`${student['user.billing_last_name']} ${student['user.billing_first_name']}`}
                          </ListItem>
                        );
                      })}
                    </List>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        this.setState({
                          moveableDisciplines: [],
                          studentsToUpdate: [],
                        });
                      }}
                    >
                      Fermer
                    </Button>
                  </Box>
                </Paper>
              </Collapse>
              <Card>
                <CardHeader
                  className={classes.denseCard}
                  subheader={
                    <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                      <BookmarkIcon />
                      <Typography>Formations</Typography>
                      <datalist id="available-trainings">
                        {allTrainings.map(({ title, id }) => {
                          return (
                            <option key={`${title}-${id}`} value={title} />
                          );
                        })}
                      </datalist>
                      <InputBase
                        fullWidth
                        inputProps={{
                          list: 'available-trainings',
                          autoComplete: 'off',
                        }}
                        name="training"
                        classes={{
                          root: classes.field,
                          input: classes.fieldInput,
                        }}
                        placeholder={`Ex: ${allTrainings.length ? allTrainings[0].title : ''}`}
                        onChange={this.filterTrainings}
                        defaultValue={selectedTraining ? selectedTraining.title : ''}
                      />
                      <IconButton onClick={this.reset}>
                        <HighlightOffIcon />
                      </IconButton>
                    </Box>
                  }
                />
              </Card>
              {noSessions ? (
                <Alert severity="info" variant="filled">
                  Pas de session dans cette formation
                </Alert>
              ) : null}
              {sessions.length ? (
                <MergeDnd
                  sessions={sessions}
                  onChange={(targetSources) => {
                    this.setState({
                      targetSources,
                    });
                  }}
                />
              ) : null}
            </Box>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

SessionDialogMerge.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  SessionDialogMerge
);
