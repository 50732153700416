import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Fade,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import UploadDialogWithCustomName from 'components/UploadDialogWithCustomName/UploadDialogWithCustomName';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import FilterInput from 'components/Datagrid/FilterInput';
import Http from 'services/Http';
import { grey, yellow } from '@material-ui/core/colors';
import DisciplineDialogAffectRadio from 'components/DisciplineDialogAffectRadio/DisciplineDialogAffectRadio';

const sortableCells = [
  { name: 'id', label: '#' },
  { name: 'name', label: 'Nom' },
  { name: 'uploaded_at', label: 'Date d\'ajout' },
];

class Documents extends Component {
  state = {
    error: '',
    openError: false,
    success: '',
    openSuccess: false,
    documentId: null,
    disciplineDialog: false,
    deleteDialog: false,
    deleteDocument: null,
    selectAll: false,
    selectedIds: [],
    uploadDialog: false,
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? this.props.rows.map(({ id }) => String(id)) : [];
    this.setState({ selectAll: checked, selectedIds });
  }

  onSelectOne = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
      selectedIds.push(value);
    } else {
      const index = selectedIds.indexOf(value);
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({ selectedIds });
  }

  // DIALOGS
  openDisciplineDialogForId = (documentId) => {
    this.setState({
      documentId,
      disciplineDialog: true,
    });
  }

  openDisciplineDialog = () => {
    this.setState({
      disciplineDialog: true,
    });
  }

  closeDisciplineDialog = () => {
    this.setState({ disciplineDialog: false, documentId: null });
  }

  openDeleteDialog = (deleteDocument) => {
    this.setState({
      deleteDialog: true,
      deleteDocument,
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      deleteDocument: null,
    });
  }

  openUploadDialog = () => {
    this.setState({ uploadDialog: true });
  }

  closeUploadDialog = () => {
    this.setState({ uploadDialog: false });
  }

  // CRUD
  deleteDocument = async () => {
    if (this.state.deleteDocument) {
      try {
        await Http.delete(`/documents/${this.state.deleteDocument}`);
        this.setState({
          success: 'Le document a été supprimé',
          openSuccess: true,
          deleteDialog: false,
          deleteDocument: null,
          selectedIds: [],
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          deleteDocument: null,
        });
      }
    }
  }

  uploadCompleted = () => {
    this.setState({
      success: 'Fichiers déposés sur le serveur',
      openSuccess: true,
      uploadDialog: false,
    });
    this.props.reload();
  }

  isCloneMode = (documentId) => {
    return documentId === null
    ||
      (
        this.props.rows.find(({ id }) => {
          return documentId === id;
        }) ?? { discipline: null }
      ).discipline === null;
  }

  affectDisciplines = async ({ disciplineIds, discipline_id }) => {
    const { selectedIds, documentId } = this.state;
    const cloneMode = this.isCloneMode(documentId);
    const document_ids = documentId ? [documentId] : selectedIds;
    try {
      await Http.put('/documents', {
        discipline_ids: disciplineIds ?? [discipline_id],
        document_ids,
        cloneMode,
      });
      this.setState({
        success: 'Les affectations de matière ont été mises à jour',
        openSuccess: true,
        disciplineDialog: false,
        documentId: null,
      });
      this.props.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
        disciplineDialog: false,
        documentId: null,
      });
    }
  }

  onEditName = async (evt, { id, bookmark }) => {
    if (evt.key === 'Enter') {
      await Http.put(`/documents/${id}`, { name: evt.target.value, bookmark });
    }
  }

  onToggleBookmark = async ({ id, name, bookmark }) => {
    await Http.put(`/documents/${id}`, { name, bookmark: bookmark ? null : 1 });
    this.props.reload();
  }

  render() {
    const {
      classes,
      filter,
      filterState,
      loading,
      renderPagination,
      renderSortCell,
      renderFilter,
      rows,
    } = this.props;
    const {
      deleteDialog,
      disciplineDialog,
      error,
      openError,
      openSuccess,
      selectAll,
      selectedIds,
      success,
      uploadDialog,
      documentId,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        <ConfirmationDialog
          open={deleteDialog}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer ce document ?"
          onClose={this.closeDeleteDialog}
          onConfirm={this.deleteDocument}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <Fade in={selectedIds.length > 0}>
            <Badge color="primary" badgeContent={selectedIds.length}>
              <Button
                size="small"
                variant="contained"
                disableElevation
                onClick={this.openDisciplineDialog}
              >
                Affecter aux matières
              </Button>
            </Badge>
          </Fade>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.openUploadDialog}
            >
              Ajouter des documents
            </Button>
          </Box>
          {disciplineDialog ? (
            <DisciplineDialogAffectRadio
              open={disciplineDialog}
              multiple={this.isCloneMode(documentId)}
              onAffect={this.affectDisciplines}
              onClose={this.closeDisciplineDialog}
            />
          ) : null}
          <UploadDialogWithCustomName
            context="documents"
            open={uploadDialog}
            onClose={this.closeUploadDialog}
            onUploadCompleted={this.uploadCompleted}
          />
          <FilterInput
            filter={filter}
            filterBy="discipline"
            placeholder="Filter par matière"
            defaultValue={filterState.discipline}
          />
          {renderFilter('Filtrer par nom')}
        </Toolbar>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left"><Checkbox onChange={this.onSelectAllClick} checked={selectAll} /></TableCell>
              <TableCell>Fav.</TableCell>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell>Fichier</TableCell>
              <TableCell>Formation</TableCell>
              <TableCell colSpan="2">Matière</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(n => (
              <TableRow key={n.id}>
                <TableCell>
                  <Checkbox
                    value={String(n.id)}
                    onChange={this.onSelectOne}
                    checked={selectedIds.includes(String(n.id))} />
                </TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => this.onToggleBookmark(n)}
                  >
                    <StarIcon style={{ color: n.bookmark === 1 ? yellow[800] : grey[300] }} />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {n.id}
                </TableCell>
                <TableCell>
                  <InputBase
                    classes={{
                      root: classes.field,
                      input: classes.fieldInput,
                    }}
                    defaultValue={n.name}
                    onKeyPress={evt => this.onEditName(evt, n)}
                  />
                </TableCell>
                <TableCell>{format(new Date(n.uploaded_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                <TableCell>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/uploads/documents${n.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={`/icons/${n.extension}.png`}
                      width="32"
                      height="32"
                      alt={n.extension}
                    />
                  </a>
                </TableCell>
                <TableCell>{n.discipline && n.discipline.training ? n.discipline.training.title : 'Aucune'}</TableCell>
                <TableCell>{n.discipline ? n.discipline.name : 'Aucune'}</TableCell>
                <TableCell>
                  <Button
                    disabled={selectedIds.length > 0}
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => this.openDisciplineDialogForId(n.id)}
                  >
                    {this.isCloneMode(n.id) ? 'Affecter aux matières' : 'Mettre à jour la matière'}
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => this.openDeleteDialog(n.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

Documents.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(tableStyles)(
  withSortAndPagination(Documents, 'documents', 'uploaded_at', 'desc')
);
