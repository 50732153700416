// module.exports because it has to be isomorphic
// admin could always be allowed to everything in check call, 
// but the declarative way give us a map of all ressources (to be discussed)
module.exports = {
    administrator: {
        statics: [
            // React routes
            'view:timetable',
            'view:logs',
            'view:search',
            'view:trainings',
            'view:sessions',
            'view:customers',
            'view:reglements',
            'view:students',
            'view:teachers',
            'view:videos',
            'view:rattrapages',
            'view:documents',
            'view:topics',
            'view:homeworks',
            'view:news',
            'view:podcasts',
            'view:polycopies',
            'view:statistics',
            'view:settings',
            'view:payzen',
            'view:proofreading',
            'view:bookmark',
            // Express routes
            'xhr:timetable',
            'xhr:logs',
            'xhr:search',
            'xhr:disciplines',
            'xhr:documents',
            'xhr:exams',
            'xhr:homeworks',
            'xhr:meta',
            'xhr:news',
            'xhr:orders',
            'xhr:podcasts',
            'xhr:polycopies',
            'xhr:products',
            'xhr:rattrapages',
            'xhr:sessions',
            'xhr:settings',
            'xhr:statistics',
            'xhr:tags',
            'xhr:topics',
            'xhr:trainings',
            'xhr:users',
            'xhr:videos',
            'xhr:videoStudent',
            'xhr:vimeo',
            'xhr:reglements',
            'xhr:payzen',
            'xhr:proofreading',
            'xhr:bookmark',
        ],
        dynamics: {
            'homeworks:delete': () => true,
            'homeworks:affect': () => true,
            'homeworks:filter': () => true,
            'homeworks:filterCopies': () => true,
            'user:loginAs': () => true,
        }
    },
    teacher: {
        statics: [
            // React routes
            'view:homeworks',
            'view:timetable',
            // Express routes
            'xhr:exams',
            'xhr:trainings',
            'xhr:homeworks',
            'xhr:teacher',
            'xhr:timetable',
            'xhr:sessions',
        ],
        dynamics: {
            'homeworks:delete': () => false,
            'homeworks:affect': () => false,
            'homeworks:filter': () => false,
            'homeworks:filterCopies': () => true,
        },
    },
    customer: {
        statics: [
            // Express routes
            'xhr:student',
        ],
    },
    subscriber: {
        statics: [
            // Express routes
            'xhr:student',
        ],
    },
};
