import { Box, Tooltip, Typography, useTheme } from '@material-ui/core';
import { shipmentLabels } from 'components/StudentHome/StudentHome';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import BusinessIcon from '@material-ui/icons/Business';
import SendIcon from '@material-ui/icons/Send';
import BuildIcon from '@material-ui/icons/Build';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';

export const Shipment = ({ shipment, showText = false }) => {
  const theme = useTheme();

  const label = shipmentLabels[shipment]
    ? shipmentLabels[shipment]
    : shipment === 'custom'
      ? 'Ancien choix personnalisé'
      : shipmentLabels.postal;

  // 'normal' and 'custom' are legacy values
  const getIcon = () => {
    return (shipment === 'normal' || shipment === 'postal' || !shipment)
      ? (
        <SendIcon fontSize="small" />
      ) : shipment === 'mail' ? (
        <AlternateEmailIcon fontSize="small" />
      ) : shipment === 'onsite' ? (
        <BusinessIcon fontSize="small" />
      ) : shipment === 'special' ? (
        <SmsFailedIcon fontSize="small" color="secondary" />
      ) : (
        <BuildIcon fontSize="small" />
      );
  };

  return showText ? (
    <Box
      display="flex"
      style={{ gap: theme.spacing(1) }}
      flexDirection="row"
      alignItems="center"
    >
      <Tooltip title={label}>
        {getIcon()}
      </Tooltip>
      <Typography variant="caption">({label})</Typography>
    </Box>
  ) : (
    <Tooltip title={label}>
      {getIcon()}
    </Tooltip>
  );
};

