import { Box, Fade, IconButton, InputBase } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Http from 'services/Http';
import { useStyles } from './styles.css';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import axios from 'axios';

const TeacherAutocomplete = ({
  onChange,
  defaultValue,
  placeholder,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const source = axios.CancelToken.source();
    (async () => {
      try {
        const { data } = await Http.get('/users/teachers/list', {
          cancelToken: source.token,
        });
        setOptions(data.map(({ last_name, first_name }) => `${last_name} ${first_name}`));
      } catch (e) {}
    })();
    return () => {
      source.cancel('Operation /users/teachers/list');
    };
  }, []);

  return (
    <Box style={{ position: 'relative', display: 'flex' }}>
      <datalist id="available-teachers">
        {options.map((teacher) => {
          return (
            <option key={`${teacher}`} value={teacher} />
          );
        })}
      </datalist>
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      <InputBase
        inputProps={{
          list: 'available-teachers',
          autoComplete: 'off',
          enterKeyHint: 'search',
          inputMode: 'search',
        }}
        name="teachers"
        classes={{
          root: classes.field,
          input: classes.fieldInput,
        }}
        placeholder={placeholder ?? `Professeur`}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onChange(event.target.value);
          }
        }}
        onChange={(event) => {
          const { value } = event.target;
          const result = options.find((teacher) => value === teacher);
          if (result) {
            onChange(value);
          }
        }}
        defaultValue={defaultValue ?? ''}
      />
      <Fade
        in={(defaultValue ?? '').length > 0}
        style={{
          width: (defaultValue ?? '').length === 0 ? '0px' : 'auto'
        }}
      >
        <IconButton
          size="small"
          onClick={() => {
            onChange('');
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      </Fade>
    </Box>
  );

};

export default TeacherAutocomplete;
