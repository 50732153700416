import { useDroppable } from '@dnd-kit/core';
import { Paper } from '@material-ui/core';
import useStyles from 'components/SessionDialogMerge/MergeDnd.css';
import classNames from 'classnames';


const DroppableZone = (props) => {
  const classes = useStyles();
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  return (
    <Paper
      ref={setNodeRef}
      className={classNames(
        classes.droppableZone,
        {
          [classes.droppableZoneOver]: isOver,
        }
      )}
      style={props.style ?? null}
    >
      {props.children}
    </Paper>
  );
};

export default DroppableZone;
