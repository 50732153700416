import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import {
    Box,
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles as styles } from 'styles/datatable.css';
import SessionDialogAffect from 'components/SessionDialogAffect/SessionDialogAffect';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import Http from 'services/Http';

const sortableCells = [
    { name: 'id', label: '#' },
    { name: 'name', label: 'Nom' },
    { name: 'ref', label: 'Référence ISP' },
];

class StudentSessions extends Component {
    state = {
        error: '',
        openError: false,
        success: '',
        openSuccess: false,
        deleteDialog: false,
        deleteSession: null,
        addDialog: false, 
    }

    componentDidMount() {
        this.props.setData(this.props.data.sessions);
    }

    componentDidUpdate(prevProps) {
        const { data: { sessions } } = this.props;
        const { data: { sessions: prevSessions } } = prevProps;
        if (sessions.length !== prevSessions.length) {
            this.props.setData(sessions);
        }
    }

    // DIALOGS
    onOpenDeleteDialog = (deleteSession) => {
        this.setState({
            deleteDialog: true,
            deleteSession,
        });
    }

    onCloseDeleteDialog = () => {
        this.setState({
            deleteDialog: false,
            deleteSession: null,
        });
    }

    onOpenAddDialog = () => {
        this.setState({ addDialog: true });
    }

    onCloseAddDialog = () => {
        this.setState({ addDialog: false });
    }

    // CRUD
    onSessionDelete = async () => {
        if (this.state.deleteSession) {
            try {
                await Http.delete(`/sessions/${this.state.deleteSession}/students/${this.props.data.id}`);
                this.setState({
                    success: 'L\'étudiant a été supprimé de cette session',
                    openSuccess: true,
                    deleteDialog: false,
                    deleteSession: null,
                });
                this.props.reload();
            } catch ({ data }) {
                this.setState({
                    error: get(data, 'message', 'Une erreur est survenue'),
                    openError: true,
                    deleteDialog: false,
                    deleteSession: null,
                });
            }
        }
    }

    render() {
        const { classes, rows, reload, renderSortCell, renderFilter } = this.props;
        const { addDialog, deleteDialog, error, openError, openSuccess, success } = this.state;
        return (
            <div>
                <ConfirmationDialog
                    open={deleteDialog}
                    title="Attention"
                    message="Êtes-vous sûr de vouloir supprimer cet étudiant de la session ?"
                    onClose={this.onCloseDeleteDialog}
                    onConfirm={this.onSessionDelete}
                />
                <ErrorSnackbar
                    message={error}
                    onClose={() => this.setState({ openError: false })}
                    open={openError}
                />
                <SuccessSnackbar
                    message={success}
                    onClose={() => this.setState({ openSuccess: false })}
                    open={openSuccess}
                />
                <SessionDialogAffect
                    open={addDialog}
                    onClose={this.onCloseAddDialog}
                    onAffect={reload}
                    studentId={this.props.data.id}
                />
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}></div>
                    {renderFilter('Filtrer par nom', 'name')}
                </Toolbar>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {sortableCells.map(cell => renderSortCell(cell))}
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(n => (
                            <TableRow key={n.id}>
                                <TableCell component="th" scope="row">
                                    {n.id}
                                </TableCell>
                                <TableCell>{n.name}</TableCell>
                                <TableCell>{n.ref}</TableCell>
                                <TableCell>
                                    <ButtonRouter
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        size="small"
                                        label="Détail"
                                        to={`/sessions/${n.id}`}
                                        disableElevation
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => this.onOpenDeleteDialog(n.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Box pt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={this.onOpenAddDialog}
                    >
                        Affecter à une session
                    </Button>
                </Box>
            </div>
        );
    }

}

StudentSessions.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
};

export default withStyles(styles)(
    withLocalSortAndFilter(StudentSessions, 'name')
);
