import { Avatar, Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography, makeStyles, withTheme } from '@material-ui/core';
import { Fragment, useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import UpdateIcon from '@material-ui/icons/Update';
import EditIcon from '@material-ui/icons/Edit';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SchoolIcon from '@material-ui/icons/School';
import { getColorsFromText, hasCustomTeacher, hasRegisteredTeacher } from './helpers';
import { formatHoursMinutes } from './TimetableAcl';

const useStyles = makeStyles((theme) => {
  return {
    label: {
      padding: `1px 0px`,
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    badge: {
      width: '20px',
      height: '20px',
      fontSize: '10px',
    },
  };
});

const ContextualMenu = ({
  timetable,
  handleClose,
  anchorEl,
  isAdmin,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose()}
    >
      <MenuItem
        dense
        onClick={handleClose('detailsDrawer')}
      >
        <ListItemIcon>
          <VisibilityIcon />
        </ListItemIcon>
        <ListItemText primary="Vue détaillée" />
      </MenuItem>
      {isAdmin ? (
        <MenuItem
          dense
          onClick={handleClose('openDateDialog')}
        >
          <ListItemIcon>
            <UpdateIcon />
          </ListItemIcon>
          <ListItemText primary="Modifier la date" />
        </MenuItem>
      ) : null}
      <MenuItem
        dense
        onClick={handleClose('openDescriptionDialog')}
      >
        <ListItemIcon>
          <TextFieldsIcon />
        </ListItemIcon>
        <ListItemText primary={`${timetable.description ? 'Éditer' : 'Ajouter'} le détail`} />
      </MenuItem>
      <MenuItem
        dense
        onClick={handleClose('openTeacherDialog')}
      >
        <ListItemIcon>
          <SchoolIcon />
        </ListItemIcon>
        <ListItemText
          primary={timetable.teacher ? `Éditer ${timetable?.teacher?.last_name} ${timetable?.teacher?.first_name}` : 'Affecter'}
        />
      </MenuItem>
      <MenuItem
        dense
        onClick={handleClose('openCustomTeacherDialog')}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText
          primary={hasCustomTeacher(timetable) ? `Éditer ${timetable?.teacher_custom_name}` : 'Nom prof. personnalisé'}
        />
      </MenuItem>
    </Menu>
  );
};

const Avatars = ({
  theme,
  event,
}) => {
  const classes = useStyles();
  const timetable = event.data;
  const timetableDisciplines = timetable?.timetableDisciplines ?? [];
  return (
    <Box
      style={{
        flex: '1',
        display: 'flex',
        flexDirection: 'row',
        // flexWrap: 'wrap',
        justifyContent: 'flex-end',
        gap: '2px',
        background: 'rgba(0, 0, 0, .25)',
        borderRadius: '6px',
        padding: `2px`,
      }}
    >
      <Tooltip
        title={
          timetableDisciplines.length > 0
            ? <ul style={{ margin: 0, padding: 0, paddingLeft: '10px' }}>{timetableDisciplines.map(({ discipline }) => <li style={{ margin: 0, padding: 0 }} key={discipline.name}>{discipline.name}</li>)}</ul>
            : ''
        }
      >
        <Avatar
          className={classes.badge}
          style={{
            color: theme.palette.getContrastText(theme.palette.primary.main),
            backgroundColor: theme.palette.primary.main,
          }}
        >
          {timetableDisciplines.length}
        </Avatar>
      </Tooltip>
      <Tooltip
        title={hasRegisteredTeacher(timetable)
          ? `${timetable?.teacher?.last_name} ${timetable?.teacher?.first_name} ${timetable?.teacher_custom_name ?? ''}`
          : 'Pas de professeur affecté'}
      >
        <Avatar
          className={classes.badge}
          style={{
            color: theme.palette.getContrastText(theme.palette.secondary.main),
            backgroundColor: theme.palette.secondary.main,
          }}
        >
          {hasRegisteredTeacher(timetable)
            ? `${String(timetable?.teacher?.first_name ?? '-').charAt(0).toLocaleUpperCase()}${String(timetable?.teacher?.last_name ?? '-').charAt(0).toLocaleUpperCase()}`
            : '?'
          }
        </Avatar>
      </Tooltip>
      {timetable.meeting_id ? (
        <Tooltip title="Zoom">
          <Avatar
            className={classes.badge}
            style={{
              color: theme.palette.getContrastText(theme.palette.info.main),
              backgroundColor: theme.palette.info.main,
            }}
          >
            Z
          </Avatar>
        </Tooltip>
      ) : (
        <Tooltip title="Présentiel">
          <Avatar
            className={classes.badge}
            style={{
              color: theme.palette.getContrastText(theme.palette.success.main),
              backgroundColor: theme.palette.success.main,
            }}
          >
            P
          </Avatar>
        </Tooltip>
      )}
    </Box>
  );
};

const CalendarCustomEventWrapper = ({
  theme,
  onClick,
  event,
  view = 'month',
  isAdmin,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    return () => {
      setAnchorEl(null);
      if (action) {
        onClick(event, action);
      }
    };
  };

  const timetable = event.data;
  const timetableDisciplines = timetable?.timetableDisciplines ?? [];

  const firstDiscipline = timetableDisciplines.length > 0
    ? timetable?.timetableDisciplines.at(0).discipline.name : null;

  const formattedHoursMinutes = `${formatHoursMinutes(timetable.start_date)}-${formatHoursMinutes(timetable.end_date)}`;
  const formattedHoursMinutesShort = `${formatHoursMinutes(timetable.start_date)}-${formatHoursMinutes(timetable.end_date)}`.replaceAll(/:00/g, 'h');

  const styles = getColorsFromText(firstDiscipline ?? formattedHoursMinutes, theme.palette.getContrastText);

  if (view === 'week') {
    return (
      <Fragment>
        <ContextualMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          isAdmin={isAdmin}
          timetable={timetable}
        />
        <Box
          onClick={handleClick}
          style={{
            ...styles,
            width: '100%',
            height: '100%',
            padding: theme.spacing(1) / 2,
            borderRadius: '4px',
            border: `1px dotted ${styles.color}`,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1) / 2,
          }}
        >
          <Typography style={{ fontSize: '11px', flex: 10 }}>
            {firstDiscipline ?? formattedHoursMinutesShort}
          </Typography>
          <Avatars
            event={event}
            theme={theme}
          />
        </Box>
      </Fragment>
    );
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1) / 2,
      }}
    >
      <ContextualMenu
        anchorEl={anchorEl}
        handleClose={handleClose}
        isAdmin={isAdmin}
        timetable={timetable}
      />
      <Button
        disableElevation
        color="primary"
        size="small"
        variant="contained"
        style={{
          flex: 2,
          padding: theme.spacing(1) / 2,
          ...styles,
          whiteSpace: 'no-wrap',
          fontSize: 11,
          fontWeight: 400,
        }}
        onClick={handleClick}
        classes={{
          label: classes.label,
        }}
      >
        <Box style={{ lineHeight: '12px' }} className={classes.ellipsis}>
          {`${formattedHoursMinutesShort} ${firstDiscipline ?? ''}`}
        </Box>
      </Button>
      <Avatars
        event={event}
        theme={theme}
      />
    </Box >
  );
};

export default withTheme(CalendarCustomEventWrapper);
