import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './Home.css';
import { Redirect } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';

const Users = (props) => {
  const { classes, user } = props;

  if (user.role === 'teacher') {
    return <Redirect to="/homeworks" />;
  }
  return (
    <Box
      className={classes.root}
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h2">{`👋 ${user.nickname}`}</Typography>
    </Box>
  );
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Users);
