import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Paper,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './TeacherDetails.css';
import TeacherHome from 'components/TeacherHome/TeacherHome';
import TeacherPayment from 'components/TeacherPayment/TeacherPayment';

class TeacherDetails extends Component {
    state = {
        teacher: null,
        tab: 0,
    };

    async componentDidMount() {
        const { teacherId } = this.props.match.params;
        const { data } = await Http.get(`/users/teachers/${teacherId}`);
        this.setState({
            teacher: data,
        });
    }

    reload = async () => {
        const { data } = await Http.get(`/users/teachers/${this.state.teacher.id}`);
        this.setState({
            teacher: data,
        });
    }

    onTabChange = (_, tab) => {
        this.setState({ tab });
    }

    render() {
        const { tab, teacher } = this.state;

        if (!teacher) {
            return 'En cours de chargement...'
        }

        return (
            <Paper>
                <Box pt={2} pb={3}>
                    <Box pb={2}>
                        <Typography variant="h6">
                            {teacher.first_name} {teacher.last_name}
                        </Typography>
                    </Box>
                    <AppBar position="static" color="default" elevation={0}>
                        <Tabs value={tab} onChange={this.onTabChange}>
                            <Tab label="Profil" />
                            <Tab label="Facturation" />
                        </Tabs>
                    </AppBar>
                    {tab === 0 && <TeacherHome data={teacher} />}
                    {tab === 1 && <TeacherPayment data={teacher} />}
                </Box>
            </Paper>
        );
    }
}

TeacherDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeacherDetails);
