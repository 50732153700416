export const styles = (theme) => {
  return {
    forceColorBlack: {
      '& *': {
        color: '#000 !important',
        backgroundColor: 'rgb(242, 244, 245) !important',
      },
      '& img': {
        display: 'none !important',
      },
    },
    chip: {
      width: 38,
    },
    titleEllipsis: {
      maxWidth: '30vw',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    tableHead: {
      background: 'rgba(0, 0, 0, .02)',
    },
    listContainer: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      '& ul, & ol': {
        fontSize: `${theme.typography.fontSize}px`,
        padding: '4px',
        margin: '4px',
      }
    },
  };
};
