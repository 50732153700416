import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  // Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/CheckCircle';
import AddIcon from '@material-ui/icons/AddCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Homeworks.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import TeacherDialogAffect from 'components/TeacherDialogAffect/TeacherDialogAffect';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { FilterButton, FilterButtonGroup } from 'components/Datagrid/FilterButtons';
import Http from 'services/Http';
import UploadDialog from 'components/UploadDialog/UploadDialog';
import CorrigeDialogCreate from 'components/CorrigeDialogCreate/CorrigeDialogCreate';
import Can from 'shared/Can/Can';
import { JwtContext } from '../../App';
import { Alert, AlertTitle } from '@material-ui/lab';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TeacherToBePaid from './TeacherToBePaid';

const sortableAndFilterableCells = [
  {
    cellInfo: { name: 'uploaded_at', label: 'Date dépôt copie' },
    filtersInfo: [
      { filterBy: 'uploadedAtFrom', placeholder: 'Début', type: 'datePicker' },
      { filterBy: 'uploadedAtTo', placeholder: 'Fin', type: 'datePicker' },
    ],
  },
  {
    cellInfo: { name: 'discipline', label: 'Matière' },
    filtersInfo: [{ filterBy: 'discipline', placeholder: 'Matière' }],
  },
  {
    cellInfo: { name: 'topic', label: 'Sujet' },
    filtersInfo: [{ filterBy: 'topic', placeholder: 'Sujet' }],
  },
  {
    cellInfo: { name: 'student', label: 'Étudiant' },
    filtersInfo: [{ filterBy: 'student', placeholder: 'Étudiant' }],
  },
  {
    cellInfo: { name: 'teacher', label: 'Professeur' },
    filtersInfo: [{ filterBy: 'teacher', placeholder: 'Professeur' }],
  },
  {
    cellInfo: { name: 'corrige_uploaded_at', label: 'Date dépôt correction' },
    filtersInfo: [
      { filterBy: 'corrigeUploadedAtFrom', placeholder: 'Début', type: 'datePicker' },
      { filterBy: 'corrigeUploadedAtTo', placeholder: 'Fin', type: 'datePicker' },
    ],
  },
];

const hasCorrigeUrl = ({ corrige_url }) => corrige_url && corrige_url.length > 0;

const hasAnnexeUrl = ({ annexe_url }) => annexe_url && annexe_url.length > 0;

const hasCorrigeNoteAppreciation = ({ appreciation, note }) => appreciation && appreciation.length > 0 && note && note.length > 0;

const hasBeenAffected = ({ teacher_id }) => teacher_id;

const homeworksUploads = `${process.env.REACT_APP_API_URL}/uploads/homeworks`;

const getCorrigeFilterIndex = (filterState) => {
  if (filterState && 'corrige' in filterState) {
    return filterState.corrige === '0' ? 1 : 2;
  }
  return 0;
};

const getAffectedFilterIndex = (filterState) => {
  if (filterState && 'affected' in filterState) {
    return filterState.affected === '0' ? 2 : 1;
  }
  return 0;
};

class Homeworks extends Component {
  state = {
    deleteDialog: false,
    deleteHomework: null,
    error: '',
    existingFile: null,
    homeworkId: null,
    openError: false,
    openSuccess: false,
    selectAll: false,
    selectedIds: [],
    success: '',
    teacherDialog: false,
    uploadAnnexeDialog: false,
    uploadCorrigeDialog: false,
    corrigeDialog: false,
    corrige: {},
  }

  openUploadCorrigeDialog = (homeworkId, existingFile) => {
    this.setState({ uploadCorrigeDialog: true, homeworkId, existingFile });
  }

  closeUploadCorrigeDialog = () => {
    this.setState({ uploadCorrigeDialog: false, existingFile: null });
  }

  openUploadAnnexeDialog = (homeworkId, existingFile) => {
    this.setState({ uploadAnnexeDialog: true, homeworkId, existingFile });
  }

  closeUploadAnnexeDialog = () => {
    this.setState({ uploadAnnexeDialog: false, existingFile: null });
  }

  openCorrigeDialog = (corrige = {}) => {
    this.setState({ corrigeDialog: true, corrige });
  }

  closeCorrigeDialog = () => {
    this.setState({ corrigeDialog: false });
  }

  onCorrigeCreate = ({ success }) => {
    this.setState({
      success,
      openSuccess: true,
      corrigeDialog: false,
    });
    this.props.reload();
  };

  uploadCorrigeCompleted = () => {
    this.setState({
      success: 'Corrigé déposé sur le serveur',
      openSuccess: true,
      uploadCorrigeDialog: false,
    });
    this.props.reload();
  }

  uploadAnnexeCompleted = () => {
    this.setState({
      success: 'Annexe déposé sur le serveur',
      openSuccess: true,
      uploadAnnexeDialog: false,
    });
    this.props.reload();
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? this.props.rows.map(({ id }) => String(id)) : [];
    this.setState({ selectAll: checked, selectedIds });
  }

  onSelectOne = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
      selectedIds.push(value);
    } else {
      const index = selectedIds.indexOf(value);
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({ selectedIds });
  }

  // DIALOGS
  openTeacherDialogForId = (homeworkId) => {
    this.setState({
      homeworkId,
      teacherDialog: true,
    });
  }

  openTeacherDialog = () => {
    this.setState({
      teacherDialog: true,
    });
  }

  closeTeacherDialog = () => {
    this.setState({ teacherDialog: false });
  }

  openDeleteDialog = (deleteHomework) => {
    this.setState({
      deleteDialog: true,
      deleteHomework,
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      deleteHomework: null,
    });
  }

  // CRUD
  deleteHomework = async () => {
    if (this.state.deleteHomework) {
      try {
        await Http.delete(`/homeworks/${this.state.deleteHomework}`);
        this.setState({
          success: 'La copie a été supprimée',
          openSuccess: true,
          deleteDialog: false,
          deleteHomework: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          deleteHomework: null,
        });
      }
    }
  }

  affectTeacher = async ({ teacher_id }) => {
    const { selectedIds, homeworkId } = this.state;
    const homework_ids = homeworkId ? [homeworkId] : selectedIds;
    try {
      await Http.put('/homeworks', { teacher_id, homework_ids });
      this.setState({
        success: 'Les affectations de professeur ont été mises à jour',
        openSuccess: true,
        teacherDialog: false,
        selectedIds: [],
        selectAll: false,
        homeworkId: null,
      });
      this.props.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
        deleteDialog: false,
      });
    }
  }

  render() {
    const {
      classes,
      filter,
      loading,
      renderPagination,
      renderSortableAndFilterable,
      rows,
      filterState,
    } = this.props;
    const {
      corrige,
      corrigeDialog,
      deleteDialog,
      error,
      existingFile,
      homeworkId,
      openError,
      openSuccess,
      selectAll,
      selectedIds,
      success,
      teacherDialog,
      uploadAnnexeDialog,
      uploadCorrigeDialog,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    const dupes = this.props.extra.dupes ?? [];

    return (
      <JwtContext.Consumer>
        {({ role, id }) => {
          const isLate = role === 'teacher' && rows.length > 0 && rows.at(0).teacher.teacherInfos?.is_late === 'yes';
          return (
            <Paper elevation={1} className={classes.root} style={loadingStyle}>
              {dupes.length ? (
                <Alert variant="filled" severity="warning" style={{ textAlign: 'left' }}>
                  <AlertTitle>{`Votre attention : il y a ${dupes.length} doublon${dupes.length > 1 ? 's' : ''}`}</AlertTitle>
                  <List dense>
                    {dupes.map(({ id, dupes, first_name, last_name, topicName }) => {
                      return (
                        <ListItem key={id}>
                          <Box
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '12px',
                            }}
                          >
                            <Typography>
                              {`Elève : ${last_name} ${first_name}, matière : ${topicName}`}
                            </Typography>
                            <Badge badgeContent={dupes} color="secondary">
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  this.props.filter({
                                    student: last_name,
                                    topic: topicName,
                                  });
                                }}
                              >
                                Afficher
                              </Button>
                            </Badge>
                          </Box>
                          <Divider />
                        </ListItem>
                      )
                    })}
                  </List>
                </Alert>
              ) : null}
              {isLate ? (
                <Alert severity="error" variant="filled" style={{ margin: '6px' }}>
                  <AlertTitle style={{ textAlign: 'left' }}>Votre attention</AlertTitle>
                  Vous semblez avoir du retard dans la correction des copies
                </Alert>
              ) : null}
              <ConfirmationDialog
                open={deleteDialog}
                title="Attention"
                message="Êtes-vous sûr de vouloir supprimer cette copie ?"
                onClose={this.closeDeleteDialog}
                onConfirm={this.deleteHomework}
              />
              <ErrorSnackbar
                message={error}
                onClose={() => this.setState({ openError: false })}
                open={openError}
              />
              <SuccessSnackbar
                message={success}
                onClose={() => this.setState({ openSuccess: false })}
                open={openSuccess}
              />
              <Toolbar className={classes.responsiveToolbarWrapper}>
                {/* <Typography className={classes.title} variant="h6" color="inherit" /> */}
                <Box className={classes.responsiveToolbar}>
                  <Can
                    role={role}
                    action="homeworks:affect"
                    yes={() =>
                      <Fade in={selectedIds.length > 0}>
                        <Badge
                          color="primary"
                          badgeContent={selectedIds.length}
                          style={{ display: selectedIds.length > 0 ? 'block' : 'none' }}>
                          <Button
                            className={classes.buttonBadge}
                            size="small"
                            variant="contained"
                            disableElevation
                            onClick={this.openTeacherDialog}
                          >
                            Affecter au professeur
                          </Button>
                        </Badge>
                      </Fade>
                    }
                    no={() => null}
                  />
                  <TeacherDialogAffect
                    open={teacherDialog}
                    onClose={this.closeTeacherDialog}
                    onAffect={this.affectTeacher}
                  />
                  <UploadDialog
                    existingFile={existingFile ? homeworksUploads + existingFile : null}
                    context="homeworks"
                    open={uploadCorrigeDialog}
                    onClose={this.closeUploadCorrigeDialog}
                    onUploadCompleted={this.uploadCorrigeCompleted}
                    singleUpload
                    endpoint="upload-corrige"
                    params={{ id: homeworkId }}
                  />
                  <UploadDialog
                    existingFile={existingFile ? homeworksUploads + existingFile : null}
                    context="homeworks"
                    open={uploadAnnexeDialog}
                    onClose={this.closeUploadAnnexeDialog}
                    onUploadCompleted={this.uploadAnnexeCompleted}
                    singleUpload
                    endpoint="upload-annexe"
                    params={{ id: homeworkId }}
                  />
                  {
                    corrigeDialog ? (
                      <CorrigeDialogCreate
                        corrige={corrige}
                        open={this.state.corrigeDialog}
                        onClose={this.closeCorrigeDialog}
                        onCreate={this.onCorrigeCreate}
                      />
                    ) : null
                  }
                  <Can
                    role={role}
                    action="homeworks:filter"
                    yes={() =>
                      <FilterButtonGroup style={{ marginLeft: 0 }} filter={(filterBy) => filter(filterBy, true)} selectedIndex={getAffectedFilterIndex(filterState)}>
                        <FilterButton filterBy={{ affected: '' }}>Toutes</FilterButton>
                        <FilterButton filterBy={{ affected: '1' }}>Affectées</FilterButton>
                        <FilterButton filterBy={{ affected: '0' }}>Non affectées</FilterButton>
                      </FilterButtonGroup>
                    }
                    no={() => null}
                  />
                  <Can
                    role={role}
                    action="homeworks:filterCopies"
                    yes={() =>
                      <FilterButtonGroup style={{ marginLeft: 0 }} filter={(filterBy) => filter(filterBy, true)} selectedIndex={getCorrigeFilterIndex(filterState)}>
                        <FilterButton filterBy={{ corrige: '' }}>Toutes</FilterButton>
                        <FilterButton filterBy={{ corrige: '0' }}>Non corrigées</FilterButton>
                        <FilterButton filterBy={{ corrige: '1' }}>Corrigées</FilterButton>
                      </FilterButtonGroup>
                    }
                    no={() => null}
                  />
                </Box>
                {role === 'teacher' ? (
                  <TeacherToBePaid teacherId={id} />
                ) : null}
              </Toolbar>
              <Table size="small" padding="none" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left"><Checkbox onChange={this.onSelectAllClick} checked={selectAll} /></TableCell>
                    {
                      sortableAndFilterableCells
                        .map(({ cellInfo, filtersInfo }) => {
                          return renderSortableAndFilterable(cellInfo, filtersInfo);
                        })
                    }
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(n => /*console.log(n) ||*/(
                    <Fragment key={`${n.id}-${n.student?.id}-${n.topic_id}`}>
                      <TableRow className={classes.rowWithoutBorder}>
                        <TableCell>
                          <Checkbox
                            disabled={!n.url}
                            value={String(n.id)}
                            onChange={this.onSelectOne}
                            checked={selectedIds.includes(String(n.id))} />
                        </TableCell>
                        <TableCell>{format(new Date(n.uploaded_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                        <TableCell>{n.topic && n.topic.discipline ? n.topic.discipline.name : 'Aucune'}</TableCell>
                        <TableCell>{n.topic ? n.topic.name : 'Aucun'}</TableCell>
                        <TableCell>
                          {n.student ? (
                            <Box display="flex" style={{ gap: '4px' }} alignItems="center" justifyContent="center">
                              <Typography variant="inherit">
                                {n.student ? `${n.student.first_name} ${n.student.last_name}` : 'Aucun(e)'}
                              </Typography>
                              <Tooltip title={`Copier le mail de l'étudiant ${n.student?.email}`}>
                                <IconButton
                                  onClick={async () => {
                                    try {
                                      await navigator.clipboard.writeText(n.student?.email);
                                      this.setState({
                                        openSuccess: true,
                                        success: 'Email copié !',
                                      });
                                    } catch (err) {
                                      this.setState({
                                        openError: true,
                                        error: 'Une erreur est survenue',
                                      });
                                    }
                                  }}
                                >
                                  <FileCopyIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {n.teacher ? `${n.teacher.first_name} ${n.teacher.last_name}` : 'Aucun(e)'}
                        </TableCell>
                        <TableCell>
                          {
                            n.corrige_uploaded_at
                              ? format(new Date(n.corrige_uploaded_at), 'dd MMM yyyy', { locale: frLocale })
                              : '-'
                          }
                        </TableCell>
                        <TableCell>
                          <Can
                            role={role}
                            action="homeworks:delete"
                            yes={() =>
                              <IconButton
                                onClick={() => this.openDeleteDialog(n.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            }
                            no={() => null}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell />
                        <TableCell colSpan={9}>
                          <Box display="flex">
                            {n.url ? (
                              <a
                                href={`${process.env.REACT_APP_API_URL}/uploads/homeworks${n.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                              >
                                <Button
                                  className={classes.buttonCopie}
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  disableElevation
                                >
                                  Copie {n.extension}
                                </Button>
                              </a>
                            ) : (
                              <Button
                                className={classes.buttonCopie}
                                size="small"
                                variant="contained"
                                disableElevation
                              >
                                Pas de copie
                              </Button>
                            )}
                            <Button
                              disabled={!n.url}
                              className={classes.button}
                              size="small"
                              variant="outlined"
                              disableElevation
                              onClick={() => this.openUploadCorrigeDialog(n.id, n.corrige_url)}
                              endIcon={hasCorrigeUrl(n)
                                ? <DoneIcon color="primary" />
                                : <AddIcon color={!n.url ? 'disabled' : 'secondary'} />
                              }
                            >
                              Correction
                            </Button>
                            <Button
                              disabled={!n.url}
                              className={classes.button}
                              size="small"
                              variant="outlined"
                              disableElevation
                              onClick={() => this.openUploadAnnexeDialog(n.id, n.annexe_url)}
                              endIcon={hasAnnexeUrl(n)
                                ? <DoneIcon color="primary" />
                                : <AddIcon color={!n.url ? 'disabled' : 'secondary'} />
                              }
                            >
                              Annexe
                            </Button>
                            <Button
                              className={classes.button}
                              size="small"
                              variant="outlined"
                              disableElevation
                              onClick={() => this.openCorrigeDialog(n)}
                              endIcon={hasCorrigeNoteAppreciation(n)
                                ? <DoneIcon color="primary" />
                                : <AddIcon color="secondary" />
                              }
                            >
                              Note
                            </Button>
                            <Can
                              role={role}
                              action="homeworks:affect"
                              yes={() =>
                                <Button
                                  disabled={!n.url}
                                  className={classes.button}
                                  size="small"
                                  variant="outlined"
                                  disableElevation
                                  onClick={() => this.openTeacherDialogForId(n.id)}
                                  endIcon={hasBeenAffected(n)
                                    ? <DoneIcon color={!n.url ? 'disabled' : 'primary'} />
                                    : <AddIcon color="secondary" />
                                  }
                                >
                                  Professeur
                                </Button>
                              }
                              no={() => null}
                            />
                            {n.topic.corrige_type_url ? (
                              <a
                                href={`${process.env.REACT_APP_API_URL}/uploads/topics${n.topic.corrige_type_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                              >
                                <Button
                                  className={classes.button}
                                  size="small"
                                  variant="contained"
                                  disableElevation
                                >
                                  Corrigé type
                                </Button>
                              </a>
                            ) : (
                              <Button
                                className={classes.button}
                                size="small"
                                variant="contained"
                                disableElevation
                                endIcon={<ErrorIcon color="error" />}
                              >
                                Corrigé type
                              </Button>
                            )}
                            {n.topic.url ? (
                              <a
                                href={`${process.env.REACT_APP_API_URL}/uploads/topics${n.topic.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={classes.link}
                              >
                                <Button
                                  className={classes.button}
                                  size="small"
                                  variant="contained"
                                  disableElevation
                                >
                                  Énoncé
                                </Button>
                              </a>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  ))}
                </TableBody>
              </Table>
              {renderPagination()}
            </Paper>
          );
        }}
      </JwtContext.Consumer>
    );
  }
}

Homeworks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  withSortAndPagination(Homeworks, 'homeworks', 'uploaded_at', 'desc')
);
