import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, InputBase, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Toolbar, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Timetable/Timetable.css';
import Http from 'services/Http';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PersonIcon from '@material-ui/icons/Person';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => tableStyles(theme));
const timetableStyles = makeStyles((theme) => styles(theme));

const ZoomInviteDialog = ({
  onClose,
  meetingId,
  openSnackbar,
}) => {
  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...timetableStyles(),
  };
  const [attendees, setAttendees] = useState('');
  const [invitations, setInvitations] = useState(null);
  const [loading, setLoading] = useState(false);

  const onCreateInvitations = async (attendees) => {
    setLoading(true);
    const { data } = await Http.post('/timetable/zoomCreateInviteLinks', {
      meetingId,
      attendees,
      ttl: 60 * 60 * 24 * 7,
    });
    setLoading(false);
    setInvitations(data);
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      fullWidth
      style={{ opacity: loading ? .5 : 1 }}
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Inviter des participants
        </Typography>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent>
        <Box
          style={{
            display: 'flex',
            gap: theme.spacing(2),
            flexDirection: 'column',
          }}
        >
          <Box
            style={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                display: 'flex',
                gap: theme.spacing(1),
                alignItems: 'center',
                flex: 1,
              }}
            >
              <Typography style={{ width: 200 }}>Participants</Typography>
              <InputBase
                multiline
                fullWidth
                style={{ width: '100%' }}
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                placeholder="Participants"
                value={attendees ?? ''}
                onChange={(event) => {
                  setAttendees(event.target.value);
                }}
              />
            </Box>
          </Box>
          <Alert size="small" severity="info">
            <Typography>Saisissez 1 participant par ligne puis appuyez la touche entrée. A la fin de votre saisie, cliquez sur "générer" pour obtenir les liens d'invitation. Exemple de saisie :</Typography>
            <pre>
              {`Julie\nStéphane`}
            </pre>
            </Alert>
          {invitations?.attendees?.length ? (
            <List dense>
              {invitations.attendees.map(({ name, join_url }) => {
                return (
                  <ListItem key={name}>
                    <ListItemIcon><PersonIcon /></ListItemIcon>
                    <ListItemText>{name}</ListItemText>
                    <ListItemSecondaryAction>
                      <Tooltip title={`Copier l'invitation de ${name}`}>
                        <IconButton
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(join_url);
                              openSnackbar('Invitation copiée !', 'success');
                            } catch (err) {
                              openSnackbar('Une erreur est survenue', 'error');
                            }
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={attendees.length === 0}
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            onCreateInvitations(attendees.split('\n'));
          }}
        >
          Générer les invitations
        </Button>
      </DialogActions>
    </Dialog>
  );

};

export default ZoomInviteDialog;
