import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    withMobileDialog,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './UploadDialog.css';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';

class UploadDialog extends React.Component {
    state = {
        error: [],
        files: [],
        openError: false,
        percentCompleted: 0,
    }

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    resetState() {
        this.setState({
            error: [],
            files: [],
            percentCompleted: 0,
        });
    }

    handleFiles = (event) => {
        event.preventDefault();
        this.setState(prevState => ({
            files: this.props.singleUpload
                ? Array.from(this.fileInput.current.files)
                : prevState.files.concat(Array.from(this.fileInput.current.files)),
        }));
    }

    deleteFile(index) {
        this.setState(prevState => ({
            files: prevState.files.filter((_, i) => i !== index),
        }));
    }

    uploadDocuments = async () => {
        const { endpoint, params } = this.props;
        const formData = new FormData();
        this.state.files.forEach((file) => {
            formData.append('file', file);
        });
        Object.keys(params).forEach((k) => {
            formData.append(k, params[k]);
        });
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ percentCompleted });
            },
        }
        try {
            await Http.post(`/${this.props.context}/${endpoint}`, formData, config);
            this.resetState();
            this.props.onUploadCompleted();
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
                percentCompleted: 0,
            });
        }
    }

    closeDialog = () => {
        this.resetState();
        this.props.onClose();
    }

    render() {
        const { classes, existingFile, open, singleUpload } = this.props;
        const { error, files, openError, percentCompleted } = this.state;

        const title = singleUpload ? 'Ajout de document' : 'Ajout de documents';
        const description = singleUpload ? 'Choisissez un fichier' : 'Choisissez un ou plusieurs fichiers';

        return (
            <Dialog
                open={open}
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        component="div"
                    >
                        {files[0]
                            ? files.map((file, index) => (
                                <div key={file.name}>
                                    <IconButton
                                        className={classes.button}
                                        onClick={() => this.deleteFile(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    {file.name}
                                </div>
                            ))
                            : description
                        }
                    </DialogContentText>
                    <DialogContentText>
                        <input
                            accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.pdf"
                            style={{ display: 'none' }}
                            id="button-file"
                            type="file"
                            ref={this.fileInput}
                            onChange={this.handleFiles}
                            multiple={!singleUpload}
                        />
                        <label htmlFor="button-file">
                            <Button
                                color="primary"
                                component="span" 
                                variant="contained"
                            >
                                Parcourir
                            </Button>
                        </label>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ErrorSnackbar
                        open={openError}
                        onClose={() => this.setState({ openError: false })}
                        message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
                    />
                    {existingFile && (
                        <Button onClick={(event) => { event.preventDefault(); global.open(existingFile); }} color="primary">
                            Voir le fichier
                        </Button>
                    )}
                    <Button onClick={this.closeDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={this.uploadDocuments} color="primary">
                        {percentCompleted ? `${percentCompleted}%` : 'Sauvegarder'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

UploadDialog.propTypes = {
    context: PropTypes.string.isRequired,
    existingFile: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onUploadCompleted: PropTypes.func.isRequired,
    singleUpload: PropTypes.bool,
    endpoint: PropTypes.string,
    params: PropTypes.object,
};

UploadDialog.defaultProps = {
    existingFile: null,
    singleUpload: false,
    endpoint: 'upload',
    params: {},
};

export default withMobileDialog()(withStyles(styles)(
    UploadDialog
));
