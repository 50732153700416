import { useState } from 'react';
import { Badge, Box, Button, IconButton, withTheme } from '@material-ui/core';
import DisciplineSessionDialog from './DisciplineSessionDialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SearchIcon from '@material-ui/icons/Search';

const DisciplineSessionDialogFilter = (props) => {
  // const { theme } = props;
  const [open, setOpen] = useState(false);

  const disciplineSession = props.state?.filter?.disciplineSession;
  const cart = disciplineSession
    ? JSON.parse(disciplineSession)
    : [];

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <DisciplineSessionDialog
        showEveryDisciplineButton
        open={open}
        initialCart={cart}
        dialogTitle="Filtrer les résultats"
        submitLabel="Filtrer"
        onClose={() => setOpen(false)}
        onSubmit={(cart) => {
          props.filter({
            disciplineSession: JSON.stringify(cart),
          }, true);
        }}
      />
      <Badge
        badgeContent={cart.length}
        color="primary"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Button
          size="small"
          variant="outlined"
          endIcon={<SearchIcon />}
          onClick={() => {
            setOpen((state) => !state);
          }}
        >
          Session / matière
        </Button>
      </Badge>
      {disciplineSession ? (
        <IconButton
          size="small"
          onClick={() => {
            props.filter({
              disciplineSession: ''
            }, true);
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default withTheme(DisciplineSessionDialogFilter);
