import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import ErrorIcon from '@material-ui/icons/Error';
import { Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    fontSizeLarge: {
        fontSize: '100px'
    },
});

const Forbidden = ({ classes }) => (
    <div className={classes.root}>
        <Card className={classes.card}>
            <Typography gutterBottom variant="h5" component="h2">
                Vous n'avez pas les droits nécessaires
                <br/>
                pour accéder à ce contenu
            </Typography>
            <ErrorIcon
                classes={{ fontSizeLarge: classes.fontSizeLarge }}
                color="error" 
                fontSize="large"
            />
        </Card>
    </div>
);


export default withStyles(styles)(Forbidden);
