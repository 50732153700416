import grey from '@material-ui/core/colors/grey';

export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  secondToolbar: {
    backgroundColor: grey[300],
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
  dialog: {
    padding: theme.spacing(3),
  },
  title: {
    flex: 1,
  },
  label: {
    display: 'block',
    paddingBottom: theme.spacing(1),
  },
  spaceRight: {
    marginRight: theme.spacing(1),
  },
  card: {
    backgroundColor: theme.palette.background.default,
  },
  flexGrow0: {
    flexGrow: 0,
  },
  flexGrow1: {
    flexGrow: 1,
  },
  flexGrow2: {
    flexGrow: 2,
  },
  flexBasis100: {
    flexBasis: 100,
  },
  noMargin: {
    margin: 0,
  },
});
