import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  NativeSelect,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './CopyDisciplinesDialog.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CopyDisciplinesDialog extends Component {
  state = {
    copyVideos: false,
    error: '',
    openError: false,
    openSuccess: false,
    saving: false,
    success: '',
    training: null,
    trainingList: [],
  }

  async componentDidMount() {
    const trainings = await Http.get('/trainings/list?include=discipline');
    this.setState({ trainingList: trainings.data });
  }

  resetState() {
    this.setState({
      copyVideos: false,
      error: '',
      openError: false,
      openSuccess: false,
      saving: false,
      success: '',
      training: null,
    });
  }

  onChangeTraining = ({ target }) => {
    const value = parseInt(target.value, 10);
    if (value) {
      const { title, disciplines } = this.state.trainingList.find((o) => o.id === value);
      this.setState({ training: { id: value, title, disciplines } });
    } else {
      this.resetState();
    }
  }

  onCopyVideos = ({ target }) => {
    this.setState({ copyVideos: target.checked });
  }

  copyDisciplines = async () => {
    const { id, title } = this.state.training;
    const message = `La copie des ${this.props.disciplines.length} matières (+ polycopiés${this.state.copyVideos ? ' et vidéos' : ''}) sur la formation ${title} a `;
    try {
      this.setState({ saving: true });
      await Http.post('/disciplines/copy', {
        training_id: id,
        discipline_ids: this.props.disciplines.map((d) => d.id),
        copyVideos: this.state.copyVideos,
      });
      this.setState({
        saving: false,
        success: `${message} réussi`,
        openSuccess: true,
      });
    } catch (_err) {
      this.setState({
        saving: false,
        error: `${message} échoué`,
        openError: true,
      });
    }
  }

  closeDialog = () => {
    this.resetState();
    this.props.onClose();
  }

  render() {
    const { classes, disciplines, open, trainingTitle } = this.props;
    const {
      copyVideos,
      error,
      openError,
      openSuccess,
      saving,
      success,
      training,
      trainingList,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={this.closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            Copier les matières et polycopiés vers une formation
          </Typography>
          {training ? (
            <Button
              onClick={this.copyDisciplines}
              color="secondary"
              disabled={saving}
              variant="contained"
              disableElevation
            >
              {saving ? (<CircularProgress size={24} />) : 'Copier'}
            </Button>
          ) : null}
        </Toolbar>

        <DialogContent className={classes.dialog}>
          <Box mx={3} mt={3}>
            <Typography variant="caption">Formation source</Typography>
            <Typography color="primary">{trainingTitle}</Typography>
          </Box>

          <Box mx={3} mt={3}>
            <Typography variant="subtitle2">
              Tronc commun
            </Typography>
            <Grid container spacing={0}>
              {disciplines.filter((d) => !d.session_only).map((d) => (
                <Grid
                  key={`from-${d.id}`}
                  xs={6}
                  md={4}
                  item
                >
                  <Typography>{d.name}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box mx={3} mt={3}>
            <Typography variant="subtitle2">
              Matières supplémentaires
            </Typography>
            <Grid container spacing={0}>
              {disciplines.filter((d) => !!d.session_only).map((d) => (
                <Grid
                  key={`from-${d.id}`}
                  xs={6}
                  md={4}
                  item
                >
                  <Typography>{d.name}</Typography>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Box my={3}>
            <Divider />
          </Box>

          <Box mx={3} mt={3}>
            <FormControl className={classes.formControl} required>
              <InputLabel shrink htmlFor="training">
                Formation cible
              </InputLabel>
              <NativeSelect
                onChange={this.onChangeTraining}
                input={<Input name="training" />}
                className={classes.selectEmpty}
              >
                <option value="">Choix</option>
                {trainingList && trainingList.map((training) => (
                  <option key={training.id} value={training.id}>{training.title}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>

          <Box mx={3} mt={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={copyVideos}
                  onChange={this.onCopyVideos}
                />
              }
              label="Copier également les vidéos"
            />
          </Box>

          {training?.disciplines[0] && (
            <Box mx={3} mt={3}>
              <Typography
                color="inherit"
                variant="subtitle2"
              >
                Matières existantes
              </Typography>
              <Grid container spacing={0}>
                {training.disciplines.map((d) => (
                  <Grid
                    key={`to-${d.id}`}
                    xs={6}
                    md={4}
                    item
                  >
                    <Typography>{d.name}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

CopyDisciplinesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  disciplines: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  trainingTitle: PropTypes.string.isRequired,
};

export default withStyles(styles)(
  CopyDisciplinesDialog
);
