import { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, withStyles } from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Search.css';
import { FilterButton, FilterButtonGroup } from 'components/Datagrid/FilterButtons';
import { withRouter } from 'react-router-dom';
import SearchQueryType from './SearchQueryType';
import SearchIntersectionType from './SearchIntersectionType';
import SearchExclusionType from './SearchExclusionType';

export const searchTypes = {
  'recherche': 'Recherche / requête',
  'intersection': 'Intersection',
  'difference': 'Différence',
};

class Search extends Component {

  render() {
    const { type } = this.props;
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" style={{ gap: '6px' }} pr={2}>
              <FilterButtonGroup
                filter={({ type }) => {
                  this.props.history.push(`/search/${type}`);
                }}
                selectedIndex={searchTypes[type] ? Object.keys(searchTypes).indexOf(type) : 0}
              >
                {Object.entries(searchTypes).map(([key, label]) => {
                  return (
                    <FilterButton
                      key={key}
                      filterBy={{ type: key }}
                    >
                      {label}
                    </FilterButton>
                  );
                })}
              </FilterButtonGroup>
            </Box>
          </Grid>
        </Grid >
        <Box mt={1}>
          {type === 'recherche'
            ? <SearchQueryType />
            : type === 'intersection'
              ? <SearchIntersectionType />
              : type === 'difference'
                ? <SearchExclusionType />
                : null}
        </Box>
      </Box>
    );
  }
}

export default withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(withRouter(Search));