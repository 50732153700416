import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './SessionDetails.css';
import SessionStudents from 'components/SessionStudents/SessionStudents';
import Disciplines from 'components/Disciplines/Disciplines';
import SessionHome from 'components/SessionHome/SessionHome';
import Metas from 'components/Metas/Metas';

class SessionDetails extends Component {
  state = {
    session: null,
  };

  async componentDidMount() {
    const { sessionId } = this.props.match.params;
    const { data } = await Http.get(`/sessions/${sessionId}`);
    this.setState({
      session: data,
      tab: 0,
    });
  }

  reload = async () => {
    const { data } = await Http.get(`/sessions/${this.state.session.id}`);
    this.setState({
      session: data,
    });
  }

  onTabChange = (_, tab) => {
    this.setState({ tab });
  }

  render() {
    const { session, tab } = this.state;

    if (!session) {
      return 'En cours de chargement...'
    }

    return (
      <Paper>
        <Box pt={2} pb={3}>
          <Box pb={2}>
            <Typography variant="h6">
              {session.name}
            </Typography>
          </Box>
          <AppBar position="static" color="default" elevation={0}>
            <Tabs value={tab} onChange={this.onTabChange}>
              <Tab label="Stats" />
              <Tab label="Étudiants" />
              <Tab label="Tronc commun" />
              <Tab label="Matières suppl." />
              <Tab label="Métadonnées" />
              <Tab label="Métadonnées suppl." />
            </Tabs>
          </AppBar>
          {tab === 0 && <SessionHome data={session} showTab={tab => this.onTabChange(null, tab)} />}
          {tab === 1 && <SessionStudents data={session} reload={this.reload} />}
          {tab === 2 && <Disciplines
            data={session.training}
            context="training"
            reload={this.reload}
            messages={{
              info: "Impacte toutes les sessions de cette formation",
              add: "toutes les sessions de cette formation",
              delete: "toutes les sessions de cette formation",
            }}
          />}
          {tab === 3 && <Disciplines
            data={{ id: session.id, disciplines: session.disciplines }}
            context="session"
            reload={this.reload}
            messages={{
              info: "Impacte toutes les sessions de cette formation partageant la même matière",
              add: "cette session",
              delete: "les sessions de cette formation la proposant",
            }}
          />}
          {tab === 4 && (
            <Metas
              data={session.training}
              context="training"
              reload={this.reload}
              messages={{
                info: "Impacte toutes les sessions de cette formation",
                add: "toutes les sessions de cette formation",
                delete: "toutes les sessions de cette formation",
              }}
            />
          )}
          {tab === 5 && (
            <Metas
              data={{ id: session.id, metas: session.metas }}
              context="session"
              reload={this.reload}
              messages={{
                info: "Impacte toutes les sessions de cette formation partageant la même métadonnée",
                add: "cette session",
                delete: "les sessions de cette formation la proposant",
              }}
            />
          )}
        </Box>
      </Paper>
    );
  }
}

SessionDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SessionDetails);
