export const styles = theme => ({
    chip: {
        width: 38,
    },
    titleEllipsis: {
        maxWidth: '30vw',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
});
