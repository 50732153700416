import { alpha } from '@material-ui/core/styles/colorManipulator';

export const tableStyles = theme => ({
  responsiveToolbarWrapper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      marginTop: 0,
      paddingTop: 0,
    },
  },
  responsiveToolbar: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginTop: theme.spacing(1),
    },
  },
  visibleForSmallScreen: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.lg + 400)]: {
      display: 'flex',
    },
  },
  visibleForBigScreen: {
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.lg + 400)]: {
      display: 'none',
    },
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
  },
  avatar: {
    borderRadius: 0,
    marginRight: '20px',
    backgroundColor: theme.palette.primary.main,
  },
  table: {
    minWidth: 700,
    boxShadow: 'none',
  },
  field: {
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
    color: 'inherit',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    padding: theme.spacing(1),
  },
  fieldInput: {
    padding: 0,
    fontSize: '14px',
  },
  tableWithPadding: {
    '& > thead > tr > th:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
    '& > thead > tr > td:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
  },
  greenRow: {
    backgroundColor: theme.palette.success.main,
    '& > th, & > td': {
      color: theme.palette.success.contrastText,
    },
  },
  redRow: {
    backgroundColor: theme.palette.error.main,
    '& > th, & > td': {
      color: theme.palette.error.contrastText,
    },
  },
  selectInput: {
    borderRadius: theme.shape.borderRadius,
    color: 'inherit',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    paddingLeft: theme.spacing(1),
    '&::before': {
      border: 0,
    }
  }
});
