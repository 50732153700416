import { useState } from 'react';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { Box, Card, CardContent, Chip, Typography, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import DroppableZone from './DroppableZone';
import DraggableSession from './DraggableSession';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import useStyles from 'components/SessionDialogMerge/MergeDnd.css';
import { Alert } from '@material-ui/lab';
import MergeTypeIcon from '@material-ui/icons/MergeType';

const MergeDnd = ({
  sessions = [],
  onChange,
}) => {
  const classes = useStyles();
  const [target, setTarget] = useState(null);
  const [sources, setSources] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const theme = useTheme();

  const findSessionById = (id) => {
    let sessionId = id;
    if (`${id}`.includes('-')) {
      sessionId = parseInt(id.split('-')[1], 10);
    }
    return sessions.find((session) => {
      return session.id === sessionId;
    });
  };

  const handleDragStart = (event) => {
    setActiveId(findSessionById(event.active.id).id);
  };

  const renderSession = (id, children = null) => {
    const session = findSessionById(id);
    return (
      <Box>
        <Box
          display="flex"
          alignItems="center"
          style={{ gap: '8px' }}
        >
          <DragIndicatorIcon fontSize="small" />
          {session.name}
          <Chip
            // className={classes.chip}
            size="small"
            label={session.students.length}
          />
        </Box>
        {children}
      </Box>
    )
  };

  const handleDragEnd = (event) => {
    const { active, collisions } = event;
    let nextState = {
      target,
      sources,
    };
    if (collisions.length >= 1) {
      const collision = collisions.at(0);
      const session = findSessionById(active.id);
      if (collision.id === 'sessions') {
        if (`${active.id}`.startsWith('target-')) {
          nextState.target = null;
          setTarget(nextState.target);
        } else if (`${active.id}`.startsWith('source-')) {
          nextState.sources = sources.filter((source) => session.id !== source.id);
          setSources(nextState.sources);
        }
      } else if (collision.id === 'target') {
        nextState.target = session;
        setTarget(nextState.target);
      } else if (collision.id === 'sources') {
        nextState.sources = sources.find(({ id }) => id === session.id)
          ? sources
          : [
            ...sources,
            session,
          ];
        setSources(nextState.sources);
      }
    }
    onChange(nextState);
    setActiveId(null);
  }
  return (
    <DndContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <DragOverlay>
        {(activeId && findSessionById(activeId) !== undefined) ? (
          <Card
            className={classNames(
              classes.dragOverlay,
              classes.draggableSession,
            )}
          >
            <CardContent>
              {renderSession(activeId)}
            </CardContent>
          </Card>
        ) : null}
      </DragOverlay>
      <Box
        display="flex"
        style={{ gap: theme.spacing(2) }}
      >
        <DroppableZone id="sessions">
          {sessions
            .filter((session) => {
              return sources.find((source) => {
                return source.id === session.id
              }) === undefined
                && session.id !== target?.id;
            })
            .map((session) => {
              return (
                <DraggableSession
                  key={session.id}
                  id={session.id}
                >
                  <Card>
                    <CardContent>
                      {renderSession(session.id)}
                    </CardContent>
                  </Card>
                </DraggableSession>
              );
            })
          }
        </DroppableZone>
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: theme.spacing(2) }}
          flex={1}
        >
          <DroppableZone id="target">
            {target
              ? (
                <DraggableSession
                  id={`target-${target.id}`}
                >
                  <Card className={classes.draggableCard}>
                    <CardContent>
                      {
                        renderSession(
                          target.id,
                          <Box mt={2}>
                            <Alert
                              severity="info"
                              variant="filled"
                            >
                              <Typography>{`Etudiants déplacés : ${sources.reduce((prev, current) => prev + current.students.length, 0)}`}</Typography>
                              <Typography>{`Sessions supprimées : ${sources.length}`}</Typography>

                            </Alert>
                          </Box>
                        )
                      }
                    </CardContent>
                  </Card>
                </DraggableSession>
              ) : (
                <Box className={classes.droppableZoneBackground}>
                  <Typography variant="subtitle2">Session maître</Typography>
                </Box>
              )
            }
            <Box className={classes.mergeIndicator}>
              <MergeTypeIcon />
            </Box>
          </DroppableZone>
          <DroppableZone id="sources">
            {sources.length ? sources.map((session) => {
              return (
                <DraggableSession
                  key={`source-${session.id}`}
                  id={`source-${session.id}`}
                >
                  <Card className={classes.draggableCard}>
                    <CardContent>
                      {renderSession(session.id)}
                    </CardContent>
                  </Card>
                </DraggableSession>
              )
            }) : (
              <Box className={classes.droppableZoneBackground}>
                <Typography variant="subtitle2">Session à fusionner</Typography>
              </Box>
            )}
          </DroppableZone>
        </Box>
      </Box>
    </DndContext>
  );
};

export default MergeDnd;
