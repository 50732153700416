import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Fade,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CastForEducationIcon from '@material-ui/icons/CastForEducation';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HelpIcon from '@material-ui/icons/Help';
import { JwtContext } from 'App';
import classNames from 'classnames';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import Kbd from 'components/Kbd/Kbd';
import { sponsoredOrder } from 'components/OrdersDialog/OrdersDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { studentTabs } from 'components/StudentDetails/StudentDetails';
import StudentTags from 'components/StudentTags/StudentTags';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Http from 'services/Http';
import Can from 'shared/Can/Can';
import { tableStyles } from 'styles/datatable.css';

export const isDev = [
  'crm.isp',
  'localhost:3000'
].includes(window.location.host);

const sortableAndFilterableCells = [
  {
    cellInfo: { name: 'order_completed_at', label: 'Commande' },
    filtersInfo: [{ filterBy: 'orderCompletedAt', placeholder: 'Date', type: 'datePicker' }],
  },
  {
    cellInfo: { name: 'product_name', label: 'Formation' },
    filtersInfo: [{ filterBy: 'productName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'order_sponsored', label: 'Subventionné' },
    filtersInfo: [{
      filterBy: 'orderSponsored',
      placeholder: 'Tout',
      type: 'select',
      items: [
        'SUBVENTIONNÉS',
        ...sponsoredOrder.map(({ label }) => label),
      ],
    }],
  },
  {
    cellInfo: { name: 'student_name', label: 'Prénom' },
    filtersInfo: [{ filterBy: 'studentName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student_familyName', label: 'Nom' },
    filtersInfo: [{ filterBy: 'studentFamilyName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student_login', label: 'Login' },
    filtersInfo: [{ filterBy: 'studentLogin', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student_email', label: 'Email' },
    filtersInfo: [{ filterBy: 'studentEmail', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student_tags', label: 'Tags' },
    filtersInfo: [{ filterBy: 'studentTags', placeholder: '' }],
  },
];

const initialState = () => ({
  message: '',
  openError: false,
  selectAll: false,
  selectedIds: [],
  wpDialog: false,
  wpDialogUrl: '',
  exportedEmails: null,
});

class Students extends Component {
  state = initialState();

  componentDidUpdate(prevProps, prevState) {
    const filtersDidUpdate = isEqual(prevProps.filterState, this.props.filterState) === false;
    if (filtersDidUpdate) {
      this.setState(initialState());
    }
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? Array.from(new Set(this.props.rows.map((row) => `${row.student_id}`))) : [];
    this.setState({ selectAll: checked, selectedIds });
  }

  onSelectOne = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
      selectedIds.push(value);
    } else {
      const index = selectedIds.indexOf(value);
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({ selectedIds });
  }

  downloadBuffer = (buffer, filename) => {
    const url = URL.createObjectURL(new Blob([buffer.data]));
    const link = global.document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    global.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  // mailtoHack = (mails) => {
  //   const link = global.document.createElement('a');
  //   link.href = `mailto:no-reply@prepa-isp.fr?subject=&bcc=${mails}`;
  //   global.document.body.appendChild(link);
  //   link.click();
  //   link.parentNode.removeChild(link);
  // };

  exportXls = async () => {
    const { selectedIds } = this.state;
    try {
      const buffer = await Http.post(
        `/search/exportXls`,
        {
          studentIds: selectedIds.map(Number),
        },
        {
          responseType: 'blob',
        },
      );
      this.downloadBuffer(buffer, `etudiants-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`);
      this.setState({
        message: <>Le fichier est disponible (en haut à droite ou <Kbd>Ctrl</Kbd>+<Kbd>J</Kbd>)</>,
        openSuccess: true,
      });
    } catch ({ data }) {
      this.setState({
        message: ['Une erreur est survenue'],
        openError: true,
      });
    }
  };

  exportMails = async () => {
    const { selectedIds } = this.state;
    try {
      const { data } = await Http.post(
        `/search/exportMails`,
        {
          studentIds: selectedIds.map(Number),
        },
      );
      this.setState({
        exportedEmails: data.join(';'),
      });
    } catch ({ data }) {
      this.setState({
        message: ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  copyEmailsToClipboard = async (event) => {
    try {
      if (event && 'ctrlKey' in event && event.ctrlKey === true) {
        this.mailtoHack(this.state.exportedEmails);
      } else {
        await navigator.clipboard.writeText(this.state.exportedEmails);
        this.setState({
          message: 'Emails copiés !',
          openSuccess: true,
        });
      }
    } catch (err) {
      this.setState({
        message: [`Une erreur est survenue => ${err?.message}`],
        openError: true,
      });
    }
    this.setState({
      exportedEmails: null,
    });
  }

  renderSnackbar() {
    const { openError, openSuccess, message } = this.state;
    return openError ? (
      <ErrorSnackbar
        message={message}
        onClose={() => this.setState({ openError: false })}
        open={openError}
      />
    ) : openSuccess ? (
      <SuccessSnackbar
        message={message}
        onClose={() => this.setState({ openSuccess: false })}
        open={openSuccess}
      />
    ) : null;
  }


  render() {
    const {
      classes,
      loading,
      renderPagination,
      renderSortableAndFilterable,
      rows,
    } = this.props;
    const {
      selectAll,
      selectedIds,
      exportedEmails,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <JwtContext.Consumer>
        {(user) => {
          return (
            <Paper elevation={1} className={classes.root} style={loadingStyle}>
              {this.renderSnackbar()}
              <Toolbar className={classes.toolbar}>
                <Typography className={classes.title} variant="h6" color="inherit" />
                <Fade in={selectedIds.length > 0}>
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '.5em'
                  }}>
                    <Box pr={1}>
                      <Badge color="primary" badgeContent={selectedIds.length}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="default"
                          disableElevation
                          onClick={this.exportMails}
                        >
                          Emails
                        </Button>
                      </Badge>
                    </Box>

                    <Box pr={1}>
                      <Badge color="primary" badgeContent={selectedIds.length}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="default"
                          disableElevation
                          onClick={this.exportXls}
                        >
                          XLS
                        </Button>
                      </Badge>
                    </Box>
                    <Badge color="primary" badgeContent={selectedIds.length}>
                      <ButtonRouter
                        variant="contained"
                        color="primary"
                        size="small"
                        label="Voir les polycopiés"
                        className={classes.button}
                        to={`/polycopies?student_ids=${selectedIds.join('-')}`}
                        disableElevation
                      />
                    </Badge>
                  </Box>
                </Fade>
              </Toolbar>

              {exportedEmails ? (
                <Box
                  m={1}
                  display="flex"
                  alignItems="center"
                  style={{ gap: '12px' }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    flexGrow={1}
                  >
                    <InputBase
                      fullWidth
                      multiline
                      value={exportedEmails}
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      id="copy-emails"
                    />
                  </Box>
                  <IconButton onClick={this.copyEmailsToClipboard}>
                    <FileCopyIcon />
                  </IconButton>
                </Box>
              ) : null}
              <Table size="small" className={classNames(classes.table, classes.denseTable)}>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox onChange={this.onSelectAllClick} checked={selectAll} />
                    </TableCell>
                    {sortableAndFilterableCells.map(({ cellInfo, filtersInfo }) => renderSortableAndFilterable(cellInfo, filtersInfo))}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((n) => {
                    const sponsored = sponsoredOrder.find(({ value }) => {
                      return value === (n.order_sponsored ?? 0);
                    }) ?? sponsoredOrder.at(0);
                    return (
                      <TableRow key={`${n.student_id}-${n.order_id}-${n.order_item_id}`}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            title={`${n.student_id}`}
                            value={`${n.student_id}`}
                            onChange={this.onSelectOne}
                            checked={selectedIds.includes(`${n.student_id}`)}
                          />
                        </TableCell>
                        <TableCell>{n.order_completed_at ? format(new Date(n.order_completed_at), 'dd MMM yyyy', { locale: frLocale }) : '-'}</TableCell>
                        <TableCell>{n.product_name}</TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            color={sponsored.value === 0 ? 'primary' : 'secondary'}
                            label={sponsored.label}
                          />
                        </TableCell>
                        <TableCell>{n.student_name}</TableCell>
                        <TableCell>{n.student_familyName}</TableCell>
                        <TableCell align="center">
                          <Tooltip title={n.student_login} interactive>
                            <HelpIcon fontSize="small" />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={n.student_email} interactive>
                            <HelpIcon fontSize="small" />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <StudentTags
                            tags={n.student_tags}
                            none={"Aucun"}
                          />
                        </TableCell>
                        <TableCell>
                          <Box display="flex" style={{ gap: '10px', alignItems: 'center' }}>
                            <ButtonRouter
                              variant="contained"
                              size="small"
                              label="Détail"
                              to={`/students/${n.student_id}/${studentTabs.at(0).value}`}
                              disableElevation
                            />
                            <Can
                              role={user.role}
                              action="user:loginAs"
                              yes={() => {
                                return (
                                  <Tooltip title={`Se connecter en tant que ${n.student_login} (nouvelle fenêtre)`}>
                                    <IconButton
                                      variant="contained"
                                      color="primary"
                                      size="small"
                                      onClick={async () => {
                                        try {
                                          const { data: { token } } = await Http.get(`/users/loginAs/${n.student_login}`);
                                          window.open(`${process.env.REACT_APP_WP_URL}/la/?crm_student_access_token=${token}`);
                                        } catch (e) {
                                          console.log(e);
                                        }
                                      }}
                                    >
                                      <CastForEducationIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                );
                              }}
                              no={() => null}
                            />
                            {isDev ? (
                              <Tooltip title={`DEVONLY Se connecter en tant que ${n.student_login} (nouvelle fenêtre)`}>
                                <IconButton
                                  variant="contained"
                                  color="secondary"
                                  size="small"
                                  onClick={async () => {
                                    try {
                                      const { data: { token } } = await Http.get(`/users/loginAs/${n.student_email}`);
                                      window.open(`http://customer.isp/?access_token=${token}`);
                                    } catch (e) {
                                      console.log(e);
                                    }
                                  }}
                                >
                                  <CastForEducationIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {renderPagination()}
            </Paper>
          );
        }}
      </JwtContext.Consumer>
    );
  }
}

Students.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(tableStyles)(
  withSortAndPagination(Students, 'users/students', 'registeredAt', 'desc')
);
