import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    IconButton,
    InputBase,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Disciplines.css';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import Http from 'services/Http';

const sortableCells = [
    { name: 'id', label: '#', isString: false },
    { name: 'name', label: 'Nom', isString: true },
];

const disciplinesReducer = (prev, { id, name }) => { prev[`key-${id}`] = { id, name }; return prev; };

class Disciplines extends Component {
    state = {
        error: '',
        openError: false,
        success: '',
        openSuccess: false,
        selectAll: false,
        disciplines: (this.props.data && this.props.data.disciplines)
            ? this.props.data.disciplines.reduce(disciplinesReducer, {})
            : {},
        deleteDialog: false,
        deleteDiscipline: null,
        newDiscipline: '',
    }

    componentDidMount() {
        if (this.props.data && this.props.data.disciplines) {
            this.props.setData(this.props.data.disciplines);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data && this.props.data.disciplines && prevProps.data && prevProps.data.disciplines) {
            const { data: { disciplines } } = this.props;
            const { data: { disciplines: prevDisciplines } } = prevProps;
            if (disciplines.length !== prevDisciplines.length) {
                this.props.setData(disciplines);
                this.setState({
                    disciplines: disciplines.reduce(disciplinesReducer, {}),
                });
            }
        }
    }

    // DIALOGS
    onOpenDeleteDialog = (deleteDiscipline) => {
        this.setState({
            deleteDialog: true,
            deleteDiscipline,
        });
    }

    onCloseDeleteDialog = () => {
        this.setState({
            deleteDialog: false,
            deleteDiscipline: null,
        });
    }

    // INPUT EVENTS
    handleNameChange = (evt, id) => {
        const disciplines = { ...this.state.disciplines };
        if (disciplines[`key-${id}`]) {
            disciplines[`key-${id}`].name = evt.target.value;
            this.setState({ disciplines });
        }
    }

    handlePublicNameChange = (evt, id) => {
        const disciplines = { ...this.state.disciplines };
        if (disciplines[`key-${id}`]) {
            disciplines[`key-${id}`].public_name = evt.target.value;
            this.setState({ disciplines });
        }
    }

    handleNewChange = ({ target }) => {
        this.setState({
            newDiscipline: target.value,
        });
    }

    // CRUD
    onDisciplineEdit = async (id) => {
        const discipline = this.state.disciplines[`key-${id}`];
        if (!discipline) {
            return Promise.reject({ data: { message: `Aucune matière trouvée pour l'id ${id}` } });
        }

        try {
            let filter;
            switch (this.props.context) {
                case 'training':
                    filter = { training_id: this.props.data.id };
                    break;
                case 'session':
                    filter = { session_id: this.props.data.id };
                    break;
                default:
                    filter = null;
                    break;
            }
            if (filter) {
                await Http.put(
                    `/disciplines/${id}`,
                    { ...filter, name: discipline.name, public_name: discipline.public_name }
                );
                this.setState({
                    success: `La matière a été mise à jour pour ${this.props.messages.add}`,
                    openSuccess: true,
                });
                this.props.reload();
            }
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
            });
        }
    }

    onDisciplineCreate = async () => {
        if (this.state.newDiscipline) {
            try {
                let filter;
                switch (this.props.context) {
                    case 'training':
                        filter = { training_id: this.props.data.id };
                        break;
                    case 'session':
                        filter = { session_id: this.props.data.id };
                        break;
                    case 'sessions':
                        filter = { session_ids: this.props.data.ids };
                        break;
                    default:
                        filter = null;
                        break;
                }
                if (filter) {
                    const { data } = await Http.post(
                        `/disciplines`,
                        { ...filter, name: this.state.newDiscipline }
                    );
                    this.setState({
                        success: `La matière "${data.name}" a été ajoutée à ${this.props.messages.add}`,
                        openSuccess: true,
                        newDiscipline: '',
                    });
                    this.props.reload();
                }
            } catch ({ data }) {
                this.setState({
                    error: data ? Object.values(data) : ['Une erreur est survenue'],
                    openError: true,
                });
            }
        }
    }

    onDisciplineDelete = async () => {
        if (this.state.deleteDiscipline) {
            try {
                let filter;
                switch (this.props.context) {
                    case 'student':
                        filter = { data: { user_id: this.props.data.id } };
                        break;
                    default:
                        filter = null;
                        break;
                }
                const { data } = await Http.delete(
                    `/disciplines/${this.state.deleteDiscipline}`,
                    { ...filter });
                this.setState({
                    success: `La matière "${data.name}" a été supprimée de ${this.props.messages.delete}`,
                    openSuccess: true,
                    deleteDialog: false,
                    deleteDiscipline: null,
                });
                this.props.reload();
            } catch ({ data }) {
                this.setState({
                    error: data ? Object.values(data) : ['Une erreur est survenue'],
                    openError: true,
                    deleteDialog: false,
                    deleteDiscipline: null,
                });
            }
        }
    }

    render() {
        const { classes, context, messages, rows, renderSortCell, renderFilter } = this.props;
        const { deleteDialog, error, openError, openSuccess, success } = this.state;

        return (
            <div className={classes.root}>
                <ConfirmationDialog
                    open={deleteDialog}
                    title="Attention"
                    message={`Êtes-vous sûr de vouloir supprimer cette matière pour ${messages.delete} ?`}
                    onClose={this.onCloseDeleteDialog}
                    onConfirm={this.onDisciplineDelete}
                />
                <ErrorSnackbar
                    message={error}
                    onClose={() => this.setState({ openError: false })}
                    open={openError}
                />
                <SuccessSnackbar
                    message={success}
                    onClose={() => this.setState({ openSuccess: false })}
                    open={openSuccess}
                />
                <Toolbar>
                    <div className={classes.message}>
                        {messages.info ? (
                            <Fragment>
                                <ErrorIcon className={classes.icon} />
                                <span>{messages.info}</span>
                            </Fragment>
                        ) : null}
                    </div>
                    {renderFilter('Filtrer par nom', 'name')}
                </Toolbar>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {sortableCells.map(cell => renderSortCell(cell))}
                            {this.props.context === 'student' || this.props.context === 'training' ? (
                                <TableCell></TableCell>
                            ) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(n => (
                            <TableRow key={n.id}>
                                <TableCell component="th" scope="row">
                                    {n.id}
                                </TableCell>
                                <TableCell>
                                    {context === 'student'
                                        ? n.name
                                        : (<Box display="flex" style={{ gap: '8px' }} alignItems="center" justifyContent="flex-start">
                                            <InputBase
                                                classes={{
                                                    root: classes.fieldLarge,
                                                    input: classes.fieldInput,
                                                }}
                                                defaultValue={n.name}
                                                onChange={evt => this.handleNameChange(evt, n.id)}
                                                onKeyPress={({ key }) => {
                                                    if (key === 'Enter') {
                                                        this.onDisciplineEdit(n.id);
                                                    }
                                                }}
                                                placeholder="Nom technique"
                                            />
                                            <InputBase
                                                classes={{
                                                    root: classes.fieldLarge,
                                                    input: classes.fieldInput,
                                                }}
                                                defaultValue={n.public_name}
                                                onChange={evt => this.handlePublicNameChange(evt, n.id)}
                                                onKeyPress={({ key }) => {
                                                    if (key === 'Enter') {
                                                        this.onDisciplineEdit(n.id);
                                                    }
                                                }}
                                                placeholder="Nom public"
                                            />
                                            <IconButton
                                                onClick={() => this.onDisciplineEdit(n.id)}
                                            >
                                                <SaveIcon />
                                            </IconButton>
                                        </Box>
                                        )}
                                </TableCell>
                                {this.props.context === 'student' || this.props.context === 'training' ? (
                                    <TableCell>
                                        <IconButton
                                            onClick={() => this.onOpenDeleteDialog(n.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                ): null}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {context !== 'student' && (
                    <Box pt={2}>
                        <InputBase
                            classes={{
                                root: classes.field,
                                input: classes.fieldInput,
                            }}
                            placeholder="Ajouter une matière"
                            onChange={this.handleNewChange}
                            value={this.state.newDiscipline}
                            onKeyPress={({ key }) => {
                                if (key === 'Enter') {
                                    this.onDisciplineCreate();
                                }
                            }}
                        />
                        <IconButton
                            color="secondary"
                            onClick={this.onDisciplineCreate}
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Box>
                )}
            </div>
        );
    }
}

Disciplines.propTypes = {
    classes: PropTypes.object.isRequired,
    context: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    messages: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
    ...styles(theme),
    ...tableStyles(theme),
}))(
    withLocalSortAndFilter(Disciplines, 'id')
);
