export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  titleEllipsis: {
    maxWidth: '30vw',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  landscapeContainer: {
    paddingTop: '56.25%',
    position: 'relative',
    width: '100%',
    minWidth: '400px',
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }
  },
});
