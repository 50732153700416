import { Box, Button, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography, makeStyles, useTheme } from '@material-ui/core';
import Http from 'services/Http';
import { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './TopicHierarchyDialog.css';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import InfoIcon from '@material-ui/icons/Info';
import TopicCard from './TopicCard';
import TopicSortableItem from './TopicSortableItem';

const useStyles = makeStyles((theme) => tableStyles(theme));
const polycopiesStyles = makeStyles((theme) => styles(theme));

const TopicHierarchyDialog = ({
  open,
  onClose,
  discipline,
  onSave,
}) => {
  const classes = {
    ...useStyles(),
    ...polycopiesStyles(),
  };
  const theme = useTheme();
  const [colsTopics, setColsTopics] = useState([]);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    if (open && discipline) {
      setColsTopics([]);
      (async () => {
        setActiveId(null);
        const { data } = await Http.get(`/topics/getHierarchy/${discipline}`);

        const rowsSlots = Array(data.rows.length).fill(true);
        const totalCols = data.rows.reduce((prev, { topicHierarchy }) => {
          return Math.max(prev, topicHierarchy?.topic_weight ?? 0);
        }, 0) + 1;

        const colsTopics = Array(totalCols)
          .fill(true)
          .map((_, col) => {
            return rowsSlots.map((_, row) => {
              if (data.rows.some(({ topicHierarchy }) => topicHierarchy === null)) {
                return {
                  ...data.rows[row],
                  type: 'topic',
                  topicHierarchy: {
                    topic_id: data.rows[row].topic_id,
                    topic_weight: col,
                    group_weight: row,
                  },
                };
              }

              const topic = data.rows.find(({ topicHierarchy }) => {
                return topicHierarchy
                  && topicHierarchy.topic_weight === col
                  && topicHierarchy.group_weight === row;
              });
              return topic ? {
                ...topic,
                type: 'topic',
              } : {
                id: `${col}-${row}`,
                type: 'droppable',
              };
            });
          });
        setColsTopics(colsTopics)
      })();
    }
  }, [discipline, open]);

  const onColumnAdd = () => {
    setColsTopics((colTopics) => {
      return [
        ...colTopics,
        colTopics.at(0).map((_, row) => {
          return {
            id: `${colTopics.length}-${row}`,
            type: 'droppable',
          };
        }),
      ];
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event) => {
    const { active } = event;
    setActiveId(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const [activeData, overData] = [active.data.current, over.data.current];

    if (active.id === over.id) {
      // Same item, do nothing
      setActiveId(null);
      return;
    }

    if (
      activeData.col === overData.col
    ) {
      setColsTopics((colsTopics) => {
        const oldIndex = colsTopics.at(activeData.col).findIndex(({ id }) => id === active.id);
        const newIndex = colsTopics.at(activeData.col).findIndex(({ id }) => id === over.id);
        return colsTopics.map((topics, index) => {
          return index === activeData.col ? arrayMove(topics, oldIndex, newIndex) : topics;
        });
      });
    }

    if (
      activeData.col !== overData.col
    ) {
      const topicToMove = colsTopics.at(activeData.col).find((topic) => topic !== null && topic.id === active.id);
      if (overData.type === 'topic') {
        return colsTopics;
      }
      const computedColsTopics = colsTopics.map((topics, col) => {
        if (col === activeData.col) {
          return topics.map((topic, row) => {
            return (topic.id === active.id) ? { id: `${col}-${row}`, type: 'droppable' } : topic;
          });
        }
        if (col === overData.col) {
          return topics.map((topic, row) => {
            return (topic.id === over.id) ? topicToMove : topic;
          });
        }
        return topics;
      });
      setColsTopics(computedColsTopics);
    }
    setActiveId(null);
  }

  const save = async () => {
    const cols = Array(colsTopics.length)
      .fill(true)
      .map((_, col) => col);
    const topics = colsTopics.at(0).reduce((prev, topics, row) => {
      const items = cols
        .filter((col) => {
          const topic = colsTopics.at(col).at(row);
          return topic.type === 'topic';
        })
        .map((col) => {
          const topic = colsTopics.at(col).at(row);
          return {
            topic_id: topic.id,
            group_weight: row,
            topic_weight: col,
          };
        });
      return [
        ...prev,
        ...items,
      ];
    }, []);

    await Http.post('/topics/createOrUpdateHierarchy', topics);
    onSave?.();
    onClose?.();
  };

  const getTopicsLength = () => {
    return colsTopics.reduce((prev, current) => {
        return prev + current.filter((topic) => topic !== null).length;
      }, 0);
  };

  const loadingStyle = { opacity: colsTopics.length === 0 ? .5 : 1 };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose?.();
      }}
      fullScreen
      style={loadingStyle}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => {
            onClose?.();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Hiérarchie
        </Typography>
        <Box ml={2}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            disableElevation
            onClick={save}
          >
            Enregistrer
          </Button>
        </Box>
      </Toolbar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        {colsTopics.length ? (
          <Box>
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: theme.spacing(1) }}
              justifyContent="space-between"
            >
              <Box
                display="flex"
                alignItems="center"
                style={{ gap: theme.spacing(1) }}
              >
                <InfoIcon />
                <Typography>
                  {`La matière ${discipline} comprend ${getTopicsLength()} sujets.`}
                </Typography>
              </Box>
              <Tooltip title="Ajouter une colonne">
                <IconButton
                  onClick={onColumnAdd}
                >
                  <AddCircleIcon
                    style={{
                      width: '40px',
                      height: '40px',
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              display="flex"
              style={{ gap: '12px' }}
              mt={1}
              alignItems="center"
            >
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                {colsTopics.map((colTopics, col) => {
                  return (
                    <Box
                      key={col}
                      style={{
                        backgroundColor: theme.palette.grey[200],
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                      }}
                      p={1}
                      pt={0}
                    >
                      <SortableContext
                        items={colTopics}
                        // strategy={verticalListSortingStrategy}
                      >
                        {colTopics.map((topic, row) => {
                          return (
                            <TopicSortableItem
                              key={topic.id}
                              id={topic.id}
                              data={{
                                col,
                                row,
                                type: topic.type,
                              }}
                            >
                              <TopicCard
                                topic={topic}
                                order={row + 1}
                              />
                            </TopicSortableItem>
                          )
                        })}
                      </SortableContext>
                    </Box>
                  );
                })}
                <DragOverlay>
                  {activeId ? (
                    <TopicCard
                      topic={colsTopics.reduce((prev, topics) => {
                        if (prev === null) {
                          const topic = topics.find((topic) => topic !== null && topic.id === activeId);
                          return topic ?? null;
                        }
                        return prev;
                      }, null)}
                    />
                  ) : null}
                </DragOverlay>
              </DndContext>
            </Box>
          </Box>
        ) : (
          <Typography>Chargement...</Typography>
        )}
      </DialogContent>
    </Dialog>
  )
};

export default TopicHierarchyDialog;
