import { Fragment, useContext } from 'react';
import { Box, Button/*, Tooltip*/, Tooltip, withTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import { JwtContext } from 'App';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import isPast from 'date-fns/isPast';
import addHours from 'date-fns/addHours';
import { Alert } from '@material-ui/lab';
import intervalToDuration from 'date-fns/intervalToDuration';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
// import isFuture from 'date-fns/isFuture';
// import subHours from 'date-fns/subHours';

const CreateOrUpdateZoomButton = ({
  timetable,
  onClick,
  onInviteClick,
  theme,
}) => {
  const { role } = useContext(JwtContext);
  const isAdmin = role === 'administrator';
  const isTeacher = role === 'teacher';
  const endDate = new Date(timetable.end_date.replace('Z', ''));
  // past = date de fin de réunion + 1h de marge
  const past = isPast(addHours(endDate, 1));

  // const startDate = new Date(timetable.start_date.replace('Z', ''));
  // const future = isFuture(subHours(startDate, 1));

  const disabled = timetable.timetableDisciplines.length === 0;

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ gap: theme.spacing(1) }}>
      {isAdmin ? (
        <Tooltip title={disabled ? 'Merci de saisir une matière' : ''}>
          <div>
            <Button
              fullWidth
              disabled={disabled}
              size="small"
              variant="contained"
              disableElevation
              color="primary"
              endIcon={timetable.meeting_id ? <EditIcon /> : <AddIcon />}
              onClick={() => {
                const topic = [];
                const [start, end] = [
                  new Date(timetable.start_date.replace('Z', '')),
                  new Date(timetable.end_date.replace('Z', '')),
                ];
                if (timetable.timetableDisciplines.length > 0) {
                  topic.push(timetable.timetableDisciplines.at(0).discipline.name);
                  topic.push(` ${format(start, 'ddLLL', { locale: frLocale })}`);
                }
                if (timetable.teacher) {
                  topic.push(` (${timetable.teacher.first_name.charAt(0).toUpperCase()}`);
                  topic.push(`${timetable.teacher.last_name.charAt(0).toUpperCase()}`);
                  const duration = intervalToDuration({
                    start,
                    end,
                  });
                  if (duration.hours > 0 || duration.minutes > 0) {
                    topic.push(` `);
                  }
                  if (duration.hours > 0) {
                    topic.push(`${duration.hours}h`);
                  }
                  if (duration.minutes > 0) {
                    topic.push(`${duration.minutes}`.padStart(2, 0));
                  }
                  topic.push(`)`);
                }
                // if (timetable.teacher_custom_name) {
                //   topic.push(timetable.teacher_custom_name);
                // }

                onClick({
                  defaultTopic: topic.join(''),
                  timetableId: timetable.id,
                  startDate: timetable.start_date,
                  endDate: timetable.end_date,
                  zoomUser: timetable.zoom_user,
                  meetingId: timetable.meeting_id,
                });
              }}
            >
              {timetable.meeting_id ? 'Éditer' : 'Ajouter'}
            </Button>
          </div>
        </Tooltip>
      ) : null}
      {timetable.meeting_id ? (
        <Fragment>
          <Button
            size="small"
            variant="contained"
            disableElevation
            color="secondary"
            endIcon={<MeetingRoomIcon fontSize="small" />}
            onClick={() => {
              onInviteClick({
                  meetingId: timetable.meeting_id,
              });
            }}
          >
            Inviter
          </Button>
          {!past ? (
            // <Tooltip title={future ? 'Vous pouvez démarrer la réunion 1h avant sa date de début' : ''}>
            //   <div>
                <Button
                  startIcon={<PlayCircleOutlineIcon />}
                  component="a"
                  href={`https://zoom.us/s/${timetable.meeting_id}`}
                  target="_blank"
                  style={{
                    background: theme.palette.success.dark,
                    color: theme.palette.getContrastText(theme.palette.success.dark),
                    // opacity: future ? .5 : 1,
                  }}
                  variant="contained"
                  size="large"
                  // disabled={future}
                  fullWidth
                >
                  Démarrer
                </Button>
            //   </div>
            // </Tooltip>
          ) : null}
        </Fragment>
      ) : isTeacher ? (
        <Alert size="small" severity="info">Pas de réunion planifiée</Alert>
      ) : null}
    </Box>
  );
};

export default withTheme(CreateOrUpdateZoomButton);
