import {
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import CloseIcon from '@material-ui/icons/Close';
import { JsonView, darkStyles as defaultStyles } from 'react-json-view-lite';

const useStyles = makeStyles((theme) => tableStyles(theme));

const JsonNotificationDialog = ({
  onClose,
  open,
  data,
}) => {
  const classes = {
    ...useStyles(),
  };
  if (!data) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Notification IPN payzen
        </Typography>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent
        style={{
          padding: 0
        }}
      >
        <JsonView
          data={data}
          shouldExpandNode={(level) => level < 2}
          style={defaultStyles}
        />
      </DialogContent>
    </Dialog>
  )
};

export default JsonNotificationDialog;
