import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  InputBase,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import ErrorSnackbar from 'components/Snackbar/Error';
import { styles } from './NewsDialogCreate.css';
import TextEditor from 'components/TextEditor/TextEditor';
import Http from 'services/Http';

const initialState = () => ({
  success: '',
  error: [],
  openError: false,
  title: '',
  html: '',
  news: {},
  trainings: [],
  selectedTrainings: [],
});

class NewsDialogCreate extends Component {
  state = initialState();

  resetState() {
    this.setState(initialState());
  }

  async componentDidMount() {
    const trainings = await Http.get('/trainings/list');
    const updateMode = !!this.props.news.id;
    this.setState({
      updateMode,
      news: { ...this.props.news },
      trainings: trainings.data,
      selectedTrainings: updateMode ? this.props.news.trainings.map(({ id }) => id) : [],
    });
  }

  onNewsSubmit = async () => {
    const { news } = this.props;
    const { html, title } = this.state.news;
    const { updateMode, selectedTrainings } = this.state;
    const params = {
      html,
      title,
      ...updateMode ? { id: news.id } : null,
      trainingIds: selectedTrainings,
    };
    const success = `L'actualité a été ${updateMode ? 'mise à jour' : 'créée'}`;
    const axiosVerb = updateMode ? Http.put : Http.post;
    try {
      await axiosVerb('/news', params);
      this.resetState();
      this.props.onCreate({
        success,
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  onDialogClose = () => {
    this.resetState();
    this.props.onClose();
  }

  handleTitle = ({ target }) => {
    this.setState(prevState => ({
      news: { ...prevState.news, title: target.value },
    }));
  }

  handleHtml = (html) => {
    this.setState(prevState => ({
      news: { ...prevState.news, html },
    }));
  }

  handleToggle = (training) => {
    const { selectedTrainings } = this.state;
    const currentIndex = selectedTrainings.findIndex(id => training.id === id);
    const newSelectedTrainings = [...selectedTrainings];
    if (currentIndex === -1) {
      newSelectedTrainings.push(training.id);
    } else {
      newSelectedTrainings.splice(currentIndex, 1);
    }
    this.setState({
      selectedTrainings: newSelectedTrainings,
    });
  }

  render() {
    const { classes, fullScreen, open, news } = this.props;
    const {
      error,
      openError,
      selectedTrainings,
      trainings,
      updateMode,
    } = this.state;
    return (
      <Dialog
        open={open}
        onClose={this.onDialogClose}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            {updateMode ? 'Modifier' : 'Ajouter'} une actualité
          </Typography>
          <IconButton
            color="inherit"
            onClick={this.onDialogClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent className={classes.dialog}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <InputBase
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                placeholder="Titre"
                value={this.state.news.title || news.title || ''}
                onChange={this.handleTitle}
              />
            </Grid>
            <Grid item xs={12}>
              <TextEditor
                onChange={html => this.handleHtml(html)}
                value={news.html || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  color="inherit"
                  variant="subtitle1"
                >
                  Formations concernées
                </Typography>
                <FormControlLabel
                  label={`Tout ${selectedTrainings.length === trainings.length ? 'décocher' : 'cocher'}`}
                  control={
                    <Checkbox
                      checked={selectedTrainings.length === trainings.length}
                      onChange={({ target }) => {
                        if (target.checked) {
                          this.setState({ selectedTrainings: trainings.map(({ id }) => id)});
                        } else {
                          this.setState({ selectedTrainings: [] });
                        }
                      }}
                    />
                  }
                />
              </Box>
              <List dense className={classes.list}>
                {trainings.map(training => (
                  <ListItem
                    className={classes.listItem}
                    key={training.id}
                    button
                    onClick={() => this.handleToggle(training)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={selectedTrainings.includes(training.id)}
                          inputProps={{ 'aria-labelledby': `training-checkbox-${training.id}` }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={`training-checkbox-${training.id}`}
                        primary={training.title}
                      />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ErrorSnackbar
            open={openError}
            onClose={() => this.setState({ openError: false })}
            message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
          />
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={this.onNewsSubmit}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

NewsDialogCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default withMobileDialog()(withStyles(styles)(
  NewsDialogCreate
));
