export const styles = theme => ({
  noTableBorder: {
    '& .MuiTableCell-root': {
      border: 0,
    },
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '95%',
    padding: '0',
    listStyle: 'none',
  },
  shared: {
    outline: 'none',
    position: 'relative', /* Setup a relative container for our psuedo elements */
    // maxWidth: '325px',
    // maxWidth: '50vw',
    padding: '10px 20px',
    lineHeight: '24px',
    wordWrap: 'break-word', /* Make sure the text wraps to multiple lines if long */
    borderRadius: '25px',
    '&:before': {
      width: '20px',
    },
    '&:after': {
      width: '26px',
      backgroundColor: 'white', /* All tails have the same bg cutout */
    },
  
    '&:before, &:after': {
      position: 'absolute',
      bottom: 0,
      height: '25px', /* height of our bubble "tail" - should match the border-radius above */
      content: "''",
    },
  },
  sent: {
    alignSelf: 'flex-end',
    color: 'white',
    background: '#0b93f6',
    '&:before': {
      right: '-7px',
      backgroundColor: '#0b93f6',
      borderBottomLeftRadius: '16px 14px',
    },
    '&:after': {
      right: '-26px',
      borderBottomLeftRadius: '10px',
    },
  },
  received: {
    alignSelf: 'flex-start',
    color: 'black',
    background: 'red',
    '&:before': {
      left: '-7px',
      backgroundColor: 'red',
      borderBottomRightRadius: '16px 14px',
    },
    '&:after': {
      left: '-26px',
      borderBottomRightRadius: '10px',
    },
  }
});
