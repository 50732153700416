import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@material-ui/core';
import { withStylesProps } from 'components/helpers';
import DoneIcon from '@material-ui/icons/Done';

const CustomButton = withStylesProps((theme, props) => ({
    root: {
        marginRight: theme.spacing(1),
        textTransform: 'none',
        minWidth: 'auto',
    },
    label: {
        whiteSpace: 'nowrap',
    }
}))(Button);

const FilterButton = ({ children, icon, filterBy, onClick, sortState = undefined, decorator = (c) => c }) => {
    const Button = (
        <CustomButton
            onClick={() => onClick(filterBy, sortState)}
            variant="contained"
            size="small"
            disableElevation
            startIcon={icon ? <DoneIcon /> : null}
        >
            {children}
        </CustomButton>
    );
    return decorator(Button);
};

FilterButton.propTypes = {
    icon: PropTypes.bool,
    filterBy: PropTypes.object,
    onClick: PropTypes.func,
    title: PropTypes.string,
    decorator: PropTypes.func,
};

class FilterButtonGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedIndex: props.selectedIndex,
        };
    }

    render() {
        const { children, filter, updateSortState, style, label } = this.props;
        const { selectedIndex } = this.state;
        return (
            <Box ml={2} display="flex" alignItems="center" flexGrow={1} style={style ?? null}>
                {label ? (
                    <Typography variant="subtitle2" style={{ marginRight: '10px' }}>{label}</Typography>
                ) : null}
                {
                    React.Children.map(children, (child, index) => React.cloneElement(child, {
                        onClick: (filterBy, sortState) => {
                            if (Array.isArray(sortState) && sortState.length === 2) {
                                // @see Reglements.js for use case
                                updateSortState(sortState);
                                setTimeout(() => {
                                    filter(filterBy);
                                }, 0);
                            } else {
                                filter(filterBy);
                            }
                            this.setState({ selectedIndex: index });
                        },
                        icon: index === selectedIndex ? true : false,
                    }))
                }
            </Box>
        );
    }
}

FilterButtonGroup.propTypes = {
    filter: PropTypes.func,
    selectedIndex: PropTypes.number,
    style: PropTypes.object,
};

export { FilterButton, FilterButtonGroup };