import { makeStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles((theme) => {
  return {
    fieldInputDense: {
      paddingTop: theme.spacing(1 * .5),
      paddingBottom: theme.spacing(1 * .5),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      fontSize: theme.typography.caption.fontSize,
    },
    searchIcon: {
      width: theme.spacing(6),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    field: {
      borderRadius: theme.shape.borderRadius,
      color: 'inherit',
      backgroundColor: alpha(theme.palette.common.black, 0.05),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.1),
      },
    },
    fieldOutlined: {
      borderRadius: theme.shape.borderRadius,
      color: 'inherit',
      backgroundColor: theme.palette.custom.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: alpha(theme.palette.common.black, 0.2),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.05),
      },
    },
    fieldInput: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(6),
    },
  };
});
