import { Box, Chip } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const SearchResultChart = ({
  chartData,
}) => {
  return (

    <ResponsiveContainer width="95%" height={300}>
      <BarChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend
          content={
            <Box display="flex">
              {chartData.map(({ xAxisOver, name }) => {
                return (
                  <Box
                    key={name}
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    style={{ gap: '8px' }}
                    m={1}
                    alignItems="center"
                  >
                    {Array.isArray(xAxisOver) ? (
                      xAxisOver.map((label) => <Chip
                        key={label}
                        label={label}
                        size="small"
                      />)
                    ) : xAxisOver}
                  </Box>
                );
              })}
            </Box>
          }
        />
        <Bar
          dataKey="pv"
          name="Etudiants"
          fill="#8884d8"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SearchResultChart;
