import { alpha } from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
  dialog: {
    padding: 0,
  },
  title: {
    flex: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.default,
  },
  chip: {
    borderRadius: '3px',
    backgroundColor: theme.palette.common.white,
    margin: theme.spacing(0.5),
  },
  field: {
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
    color: 'inherit',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    padding: theme.spacing(1),
  },
  fieldInput: {
    padding: 0,
  },
  button: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  label: {
    width: 300,
  },
});
