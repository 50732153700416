import { alpha } from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
  button: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(.5),
  },
  field: {
    display: 'block',
    borderRadius: theme.shape.borderRadius,
    color: 'inherit',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
  },
  fieldInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create('width'),
  },
});
