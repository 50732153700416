import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  withStyles,
  Fade,
  Zoom,
  InputBase,
} from '@material-ui/core';
import isEqual from 'lodash.isequal';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import classNames from 'classnames';

import { tableStyles } from 'styles/datatable.css';
import { styles } from './Reglements.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import Http from 'services/Http';
import { FilterButton, FilterButtonGroup } from 'components/Datagrid/FilterButtons';
import { formatAmount } from 'components/Orders/OrdersPayments';
import { mapPaymentsLabels } from 'components/Orders/OrdersPaymentsItem';
import ReglementsReferencesDialog from './ReglementsReferencesDialog';
import ReceiptIcon from '@material-ui/icons/Receipt';

export const sponsoredOrder = [
  { label: 'NON', value: 0 },
  { label: 'CPF', value: 1 },
  { label: 'POLE EMPLOI', value: 2 },
];

const sortableAndFilterableCells = [
  {
    cellInfo: { name: 'customer_id', label: 'Id Étudiant' },
    filtersInfo: [{ filterBy: 'studentId', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student_name', label: 'Prénom' },
    filtersInfo: [{ filterBy: 'studentName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student_familyName', label: 'Nom' },
    filtersInfo: [{ filterBy: 'studentFamilyName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'year', label: `Année` },
    filtersInfo: [{ filterBy: 'year', placeholder: `Ex : ${new Date().getFullYear()},${new Date().getFullYear() + 1}` }],
  },
  {
    cellInfo: { name: 'exam_name', label: 'Concours' },
    filtersInfo: [{
      checkbox: {
        tooltip: 'Uniquement les élèves qui répondent à toute la sélection',
      },
      type: 'autocomplete',
      multiple: true,
      filterBy: 'examName',
      placeholder: '',
      fetcher: async () => {
        const { data } = await Http.get('/exams/list');
        return data.map(({ title }) => title);
      },
    }],
  },
  {
    cellInfo: { name: 'training_name', label: 'Formation' },
    filtersInfo: [{
      type: 'autocomplete',
      filterBy: 'trainingName',
      placeholder: '',
      fetcher: async () => {
        const { data } = await Http.get('/trainings?publishedOnly=false');
        return data.rows.map(({ title }) => title);
      },
    }],
  },
  {
    cellInfo: { name: 'total', label: 'Montant' },
    filtersInfo: [{ filterBy: 'total', placeholder: '' }],
  },
  {
    cellInfo: { name: 'created_at', label: 'Date commande' },
    filtersInfo: [
      { filterBy: 'dateFrom', placeholder: 'Début', type: 'datePicker' },
      { filterBy: 'dateTo', placeholder: 'Fin', type: 'datePicker' },
    ],
  },
  {
    cellInfo: { name: 'sponsored', label: 'Sponsorisé' },
    filtersInfo: [{
      filterBy: 'sponsored',
      placeholder: 'Tous',
      type: 'select',
      items: [...sponsoredOrder.map(({ label }) => label), 'Sponsorisés uniquement'],
    }],
  },
];

const sortableAndFilterableCellsVentiles = [
  {
    cellInfo: { name: 'customer_id', label: 'Id Étudiant' },
    filtersInfo: [{ filterBy: 'studentId', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student_name', label: 'Prénom' },
    filtersInfo: [{ filterBy: 'studentName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'student_familyName', label: 'Nom' },
    filtersInfo: [{ filterBy: 'studentFamilyName', placeholder: '' }],
  },
  {
    cellInfo: { name: 'year', label: `Année` },
    filtersInfo: [{ filterBy: 'year', placeholder: `Ex : ${new Date().getFullYear()},${new Date().getFullYear() + 1}` }],
  },
  {
    cellInfo: { name: 'amount', label: 'Montant' },
    filtersInfo: [{ filterBy: 'total', placeholder: '' }],
  },
  {
    cellInfo: { name: 'reference', label: 'Référence' },
    filtersInfo: [{ filterBy: 'reference', placeholder: '' }],
  },
  {
    cellInfo: { name: 'paymentType', label: 'Paiement' },
    filtersInfo: [{
      filterBy: 'paymentType',
      placeholder: 'Tous',
      type: 'select',
      items: ['CB', 'CHEQUE', 'AUTRE'],
    }],
  },
  {
    cellInfo: { name: 'bank', label: 'Banque' },
    filtersInfo: [{
      type: 'autocomplete',
      filterBy: 'bankName',
      placeholder: '',
      fetcher: async () => {
        const { data } = await Http.get('/orders/banks');
        return data.map(({ name }) => name);
      },
    }],
  },
  {
    cellInfo: { name: 'date', label: 'Date ventilation' },
    filtersInfo: [
      { filterBy: 'dateFrom', placeholder: 'Début', type: 'datePicker' },
      { filterBy: 'dateTo', placeholder: 'Fin', type: 'datePicker' },
    ],
  },
  {
    cellInfo: { name: 'sponsored', label: 'Sponsorisé' },
    filtersInfo: [{
      filterBy: 'sponsored',
      placeholder: 'Tous',
      type: 'select',
      items: [...sponsoredOrder.map(({ label }) => label), 'Sponsorisés uniquement'],
    }],
  },
];

const SelectionMode = {
  FROM_ZERO: 'FROM_ZERO',
  FROM_ALL: 'FROM_ALL',
};

const initialState = () => {
  return {
    error: '',
    openError: false,
    openSuccess: false,
    polycopieId: null,
    polycopieLabel: '',
    success: '',
    selectAll: false,
    // 2 modes :
    // check all => store unchecked checkboxes
    // "from 0" => store added
    selectionMode: SelectionMode.FROM_ZERO,
    rowsTofilter: [],
    rowsToSave: [],
    loadingOpacity: false,
    updateStatusDialog: false,
    studentId: null,
    editPaymentsReferences: false,
    updatingReference: false,
  };
};
class Reglements extends Component {
  state = initialState();

  onReferenceSubmit = async (event) => {
    event.preventDefault();
    const el = event.currentTarget.querySelector('input[name^="input_reference_"]');
    const updateValue = [
      {
        reference: el.value,
        id: el.name.split('_').at(-1),
      }
    ];
    this.setState({ updatingReference: true });
    try {
      await Http.post(`/reglements/updatePaymentsReferences`, updateValue);
      this.setState({
        openSuccess: true,
        success: `Référence ${updateValue[0].reference} enregistrée`,
      });
    } catch (e) {
      this.setState({
        openSuccess: true,
        success: "Une erreur s'est produite lors de l'enregistrement de la référence",
      });
    } finally {
      this.setState({ updatingReference: false });
    }
  };

  onReferenceDialogSubmit = async (reference) => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      await Http.post('/reglements/updatePaymentsReferencesBySelection', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: 10000000,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
        reference,
      });
      this.setState({
        openSuccess: true,
        success: `Références ${reference} enregistrées`,
        editPaymentsReferences: false,
      });
    } catch (e) {
      this.setState({
        openSuccess: true,
        success: "Une erreur s'est produite lors de l'enregistrement de la référence",
        editPaymentsReferences: false,
      });
    }
    this.setState({ loadingOpacity: false });
    this.props.reload();
  }

  downloadBuffer = (buffer, filename) => {
    const url = URL.createObjectURL(new Blob([buffer.data]));
    const link = global.document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    global.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  csvExport = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      const prefix = this.props.filterState.ventiles === '1' ? 'reglements' : 'inscriptions';
      const buffer = await Http.post('/reglements/csvExport', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: 10000000,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      }, {
        responseType: 'blob',
      });
      this.downloadBuffer(buffer, `${prefix}-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`);
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  }

  xlsExport = async () => {
    this.setState({ loadingOpacity: true });
    const { selectionMode, rowsTofilter, rowsToSave } = this.state;
    try {
      const prefix = this.props.filterState.ventiles === '1' ? 'reglements' : 'inscriptions';
      const buffer = await Http.post('/reglements/xlsExport', {
        // Mimic HOC request
        params: {
          offset: 0,
          limit: 10000000,
          ...this.props.filterState,
        },
        // Send save/filter info
        selection: {
          selectionMode,
          rowsTofilter,
          rowsToSave,
        },
      }, {
        responseType: 'blob',
      });
      this.downloadBuffer(buffer, `${prefix}-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`);
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
    this.setState({ loadingOpacity: false });
  }

  componentDidUpdate(prevProps) {
    const filtersDidUpdate = isEqual(prevProps.filterState, this.props.filterState) === false;
    if (filtersDidUpdate) {
      this.setState(initialState());
    }
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    this.setState({
      selectAll: checked,
      rowsTofilter: [],
      rowsToSave: [],
      selectionMode: checked ? SelectionMode.FROM_ALL : SelectionMode.FROM_ZERO,
    });
  };

  isChecked = (rowToCompare) => {
    return (this.state.selectionMode === SelectionMode.FROM_ZERO)
      ? this.state.rowsToSave.findIndex((row) => isEqual(row, rowToCompare)) !== -1
      : this.state.rowsTofilter.findIndex((row) => isEqual(row, rowToCompare)) === -1
  };

  getSelectedRows = () => {
    const { count } = this.props;
    return this.state.selectionMode === SelectionMode.FROM_ZERO
      ? this.state.rowsToSave.length
      : count - this.state.rowsTofilter.length;
  };

  onSelectOne = ({ target }) => {
    const { value } = target;
    const { rows } = this.props;
    const rowsToSave = [...this.state.rowsToSave];
    const rowsTofilter = [...this.state.rowsTofilter];
    const { selectionMode } = this.state;
    if (selectionMode === SelectionMode.FROM_ZERO) {
      const index = rowsToSave.findIndex((row) => isEqual(row, rows[value]));
      if (index !== -1) {
        rowsToSave.splice(index, 1);
      } else {
        rowsToSave.push(rows[value]);
      }
    } else if (selectionMode === SelectionMode.FROM_ALL) {
      const index = rowsTofilter.findIndex((row) => isEqual(row, rows[value]));
      if (index !== -1) {
        rowsTofilter.splice(index, 1);
      } else {
        rowsTofilter.push(rows[value]);
      }
    }
    this.setState({
      rowsTofilter,
      rowsToSave,
    });
  };

  render() {
    const {
      classes,
      count,
      filter,
      filterState,
      loading,
      renderPagination,
      renderSortableAndFilterable,
      rows,
      extra,
      updateSortState,
    } = this.props;

    const {
      error,
      loadingOpacity,
      openError,
      openSuccess,
      selectAll,
      success,
      editPaymentsReferences,
      updatingReference,
    } = this.state;

    const ventiles = (filterState.ventiles ?? '0') === '1';
    const cheques = (filterState.paymentType ?? '0') === '1';
    const loadingStyle = { opacity: (loading || loadingOpacity) ? .5 : 1 };

    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        <ReglementsReferencesDialog
          open={editPaymentsReferences}
          onClose={() => {
            this.setState({
              editPaymentsReferences: false,
            });
          }}
          onSubmit={this.onReferenceDialogSubmit}
        />
        <Toolbar>
          <Box display="flex" style={{ gap: '6px' }} pr={2}>
            <FilterButtonGroup
              updateSortState={(sortState) => {
                updateSortState(...sortState);
              }}
              filter={(filterBy) => filter(filterBy, true)}
              selectedIndex={parseInt(filterState.ventiles ?? '0', 10)}
            >
              <FilterButton sortState={['created_at', 'desc']} filterBy={{ ventiles: '0' }}>Inscriptions</FilterButton>
              <FilterButton sortState={['date', 'desc']} filterBy={{ ventiles: '1' }}>Règlements</FilterButton>
            </FilterButtonGroup>
          </Box>
          <Fade in={this.getSelectedRows() > 0}>
            <Box
              flexGrow="1"
              display="flex"
              alignItems="center"
              style={{ gap: '6px' }}
            >
              <Box flexGrow=".75">
                <LinearProgress
                  variant="determinate"
                  value={this.getSelectedRows() * 100 / count}
                  classes={{
                    root: classes.linearProgress,
                  }}
                />
              </Box>
              <Typography>
                {`${this.getSelectedRows()} / ${count} règlement${this.getSelectedRows() > 1 ? 's' : ''} sélectionné${this.getSelectedRows() > 1 ? 's' : ''}`}
              </Typography>
              <Box style={{ display: 'flex', gap: '6px' }}>
                <Button
                  size="small"
                  variant="outlined"
                  color="default"
                  disableElevation
                  onClick={this.csvExport}
                >
                  CSV
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="default"
                  disableElevation
                  onClick={this.xlsExport}
                >
                  XLS
                </Button>
              </Box>
              {(ventiles && cheques) ? (
                <Box>
                  <Button
                    size="small"
                    variant="outlined"
                    color="default"
                    disableElevation
                    onClick={() => {
                      this.setState({
                        editPaymentsReferences: !editPaymentsReferences,
                      });
                    }}
                    endIcon={<ReceiptIcon fontSize="small" />}
                  >
                    Références
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Fade>
          <Zoom
            in={!!extra?.total && !loading}
            appear
            timeout={{
              appear: 1000,
              enter: 1000,
              exit: 200,
            }}
          >
            <Typography variant="h5">
              {extra?.total ? formatAmount(extra.total) : '-'}
            </Typography>
          </Zoom>
        </Toolbar>

        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />

        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Table size="small" className={classNames(classes.table, classes.denseTable)}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox onChange={this.onSelectAllClick} checked={selectAll} />
              </TableCell>
              {
                (ventiles
                  ? sortableAndFilterableCellsVentiles
                  : sortableAndFilterableCells
                ).map(({ cellInfo, filtersInfo }) => renderSortableAndFilterable(cellInfo, filtersInfo))
              }
              {
                ventiles
                  ? (<TableCell>Commentaire</TableCell>)
                  : null
              }
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, index) => {
              return ventiles ? (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      value={index}
                      onChange={this.onSelectOne}
                      checked={this.isChecked(r)}
                      name="cb_selection"
                    />
                  </TableCell>
                  <TableCell>{r.order?.customer?.id}</TableCell>
                  <TableCell>{r.order?.customer?.first_name ?? '-'}</TableCell>
                  <TableCell>{r.order?.customer?.last_name ?? '-'}</TableCell>
                  <TableCell>{r.order?.orderItems.at(0) ? r.order.orderItems.at(0).annee_comptable : null}</TableCell>
                  <TableCell>{formatAmount(r.amount)}</TableCell>
                  <TableCell>
                    {
                      r.paymentType === 'cheque-several'
                        ? (
                          <form onSubmit={this.onReferenceSubmit}>
                            <InputBase
                              key={r.reference}
                              disabled={updatingReference}
                              defaultValue={r.reference}
                              name={`input_reference_${r.id}`}
                              classes={{
                                root: classes.field,
                                input: classes.fieldInput,
                              }}
                              placeholder="Référence"
                            />
                          </form>
                        )
                        : r.reference
                    }
                  </TableCell>
                  <TableCell>{mapPaymentsLabels[r.paymentType]}</TableCell>
                  <TableCell style={{ minWidth: '200px' }}>{r.bank?.name ?? ''}</TableCell>
                  <TableCell>{format(new Date(r.date), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                  <TableCell>{sponsoredOrder[r.order?.sponsored ?? 0].label}</TableCell>
                  <TableCell>{r.comment ?? ''}</TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      value={index}
                      onChange={this.onSelectOne}
                      checked={this.isChecked(r)}
                    />
                  </TableCell>
                  <TableCell>{r.customer_id ?? '-'}</TableCell>
                  <TableCell>{r.customer?.first_name ?? '-'}</TableCell>
                  <TableCell>{r.customer?.last_name ?? '-'}</TableCell>
                  <TableCell>{r.orderItems.at(0) ? r.orderItems.at(0).annee_comptable : null}</TableCell>
                  <TableCell>{r.orderItems.map((oi) => oi.training?.exam?.title).join(' - ')}</TableCell>
                  <TableCell>{r.orderItems.map((oi) => oi.training?.title).join(' - ')}</TableCell>
                  <TableCell>{formatAmount(r.total)}</TableCell>
                  <TableCell>{r.created_at ? format(new Date(r.created_at), 'dd MMM yyyy', { locale: frLocale }) : null}</TableCell>
                  <TableCell>{sponsoredOrder[r.sponsored ?? 0].label}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }

}

Reglements.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(Reglements, 'reglements', 'created_at', 'desc')
);
