import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';

const CollisionLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={props.to} {...props} />
));

const IconButtonRouter = ({ children, ...props }) => (
  <IconButton
    component={CollisionLink}
    {...props}
  >
    {children}
  </IconButton>
);

export default IconButtonRouter;
