export const styles = theme => ({
  landscapeContainer: {
    paddingTop: '56.25%',
    position: 'relative',
    width: '100%',
  },
  landscape: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  card: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    border: `1px solid transparent`,
    transition: theme.transitions.create(
      ['border'],
      { duration: theme.transitions.duration.standard, delay: theme.transitions.duration.shortest }
    ),
  },
  cardSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  cardActions: {
    justifyContent: 'space-between',
    width: '100%',
  },
  subtitle1: {
    fontWeight: 500,
    lineHeight: 1.2,
  },
});
