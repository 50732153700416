const drawerWidth = 240;

export const styles = theme => ({
  '@keyframes envLabelEffect': {
    "0%": {
      opacity: 0,
      textShadow: '0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073, 0 0 70px #e60073',
      transform: "translateY(-25%)"
    },
    "100%": {
      opacity: 1,
      textShadow: '0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6',
      transform: "translateY(25%)"
    }
  },
  envBox: {
    animation: `1500ms ${theme.transitions.easing.easeInOut} infinite alternate $envLabelEffect`,
    position: 'absolute',
    top: '22px',
    left: '0px',
    border: '1px solid #6a7a8c',
    padding: '2px',
    zIndex: 100000,
    borderRadius: '4px',
  },
  envTypography: {
    fontSize: '12px',
    lineHeight: '12px',
    color: 'yellow',
  },
  root: {
    display: 'flex',
  },
  // TOOLBAR styles
  toolbar: {
    paddingRight: 24,
    color: theme.palette.text.primary,
  },
  toolbarTitle: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  toolbarAvatar: {
    borderRadius: 0,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
  },
  // APPBAR styles
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: {
    minHeight: 50,
  },
  // MENU styles
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  // SIDEBAR styles
  sidebar: {
    height: '100vh',
    backgroundColor: theme.palette.custom.sidebar,
    // these next lines are hack because we have a "dark" sidebar for a "light" theme
    '& *': {
      color: theme.palette.primary.contrastText,
    },
    '& button:hover': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '& ul li:hover': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '& ul a:hover': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
    '& hr': {
      backgroundColor: 'rgba(0,0,0,.2)',
    },
  },
  sidebarTitle: {
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  sidebarIcon: {
    padding: theme.spacing(1),
  },
  // DRAWER styles
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: theme.palette.custom.sidebar,
    border: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  // MAIN CONTENT styles
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // paddingTop: 0,
    // marginTop: '35px',
    // height: 'calc(100vh - 35px)',
    // overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
});
