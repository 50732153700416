import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Orders.css';
import startOfDay from 'date-fns/startOfDay';
import { isEqual } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

class OrderRetractation extends Component {
    render() {
        const { classes, post } = this.props;
        const alertStyle = {
            root: classes.alertRoot,
            message: classes.alertMessage,
            icon: classes.alertIcon,
        };
        const { created_at, renonce_droit_retractation } = post;
        if (renonce_droit_retractation === null) {
            // Orders done before the retractation feature
            return (
                <Alert
                    variant="filled"
                    icon={false}
                    severity="info"
                    classes={alertStyle}
                >
                    Non communiqué
                </Alert>
            );
        }
        if (renonce_droit_retractation) {
            return (
                <Alert
                    variant="filled"
                    severity="success"
                    classes={alertStyle}
                >
                    Exclusion
                </Alert>
            );
        }
        const datePlus15days = addDays(startOfDay(utcToZonedTime(created_at, 'Etc/UTC')), 15);
        const severity = isEqual(startOfDay(new Date()), datePlus15days) || isAfter(startOfDay(new Date()), datePlus15days) ? 'success' : 'error';
        return (
            <Alert
                variant="filled"
                severity={severity}
                classes={alertStyle}
            >
                Actif - Fin le<br/>
                {format(datePlus15days, 'dd/MM/y', { locale: frLocale })}
            </Alert>
        );
    }
}

OrderRetractation.propTypes = {
    classes: PropTypes.object.isRequired,
    post: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
    ...styles(theme),
    ...tableStyles(theme),
}))(OrderRetractation);
