import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    AppBar,
    Box,
    Paper,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './TopicDetails.css';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import TopicHome from 'components/TopicHome/TopicHome';
import TopicHomeworks from 'components/TopicHomeworks/TopicHomeworks';
import TopicDialogNote from '../TopicDialogNote/TopicDialogNote';

class TopicDetails extends Component {
    state = {
        dialogNote: false,
        error: '',
        openError: false,
        success: '',
        openSuccess: false,
        topic: null,
        tab: 0,
    };

    async componentDidMount() {
        const { topicId } = this.props.match.params;
        const { data } = await Http.get(`/topics/${topicId}`);
        this.setState({
            topic: data,
        });
    }

    reload = async () => {
        const { data } = await Http.get(`/topics/${this.state.topic.id}`);
        this.setState({
            topic: data,
        });
    }

    onTabChange = (_, tab) => {
        this.setState({ tab });
    }

    affect = async ({ discipline_id }) => {
        try {
            await Http.post(`/disciplines`,
                {
                  user_id: this.state.student.id,
                  discipline_id,
                  name: 'fake',
                }
            );
            this.setState({
                success: 'La matière a été affectée à cet étudiant(e)',
                openSuccess: true,
            });
            this.reload();
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
            });
        }
    }

    render() {
        const { dialogNote, error, openError, openSuccess, success, tab, topic } = this.state;

        if (!topic) {
            return 'En cours de chargement...'
        }

        return (
            <Paper>
                <Box pt={2} pb={3}>
                    <ErrorSnackbar
                        message={error}
                        onClose={() => this.setState({ openError: false })}
                        open={openError}
                    />
                    <SuccessSnackbar
                        message={success}
                        onClose={() => this.setState({ openSuccess: false })}
                        open={openSuccess}
                    />
                    <Box pb={2}>
                        <Typography variant="h6">{topic.name}</Typography>
                        <Typography variant="subtitle1">{topic.discipline ? topic.discipline.name : 'Aucune matière associée'}</Typography>
                    </Box>
                    {dialogNote && 
                        <TopicDialogNote
                            topic={topic}
                            onClose={() => this.setState({ dialogNote: false })}
                            onSuccess={(success) => this.setState({
                                success,
                                openSuccess: true,
                            }, () => {
                                this.reload();
                            })}
                        />}
                    <AppBar position="static" color="default" elevation={0}>
                        <Tabs value={tab} onChange={this.onTabChange}>
                            <Tab label="Stats" />
                            <Tab label="Copies & Corrections" />
                        </Tabs>
                    </AppBar>
                    {tab === 0 && <TopicHome
                        data={topic}
                        showTab={tab => this.onTabChange(null, tab)}
                        openTopicDialogNote={() => this.setState({ dialogNote: true })}
                    />}
                    {tab === 1 && <TopicHomeworks data={topic} reload={this.reload} />}
                </Box>
            </Paper>
        );
    }
}

TopicDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopicDetails);
