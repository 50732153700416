export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  formControl: {
    display: 'block',
    width: '100%',
  },
  selectEmpty: {
    width: '100%',
  },
  dialog: {
    padding: theme.spacing(3, 3, 1, 3),
  },
  title: {
    flex: 1,
  },
  label: {
    display: 'block',
    paddingBottom: theme.spacing(1),
  },
  radio: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
  },
  list: {
    columnCount: 2,
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
  paperScrollPaper: {
    minHeight: '95vh',
  },
  fullWidthFormControlLabel: {
    width: '100%',
    height: '100%',
    '& .MuiFormControlLabel-label': {
      width: '100%',
      height: '100%',
    },
  },
});
