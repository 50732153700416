import React, { Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import DomainIcon from '@material-ui/icons/Domain';
import EmailIcon from '@material-ui/icons/Email';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EuroIcon from '@material-ui/icons/Euro';
import FaceIcon from '@material-ui/icons/Face';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MovieIcon from '@material-ui/icons/Movie';
import AttachmentIcon from '@material-ui/icons/Attachment';
import MessageIcon from '@material-ui/icons/Message';
import SchoolIcon from '@material-ui/icons/School';
import SettingsIcon from '@material-ui/icons/Settings';
import MicIcon from '@material-ui/icons/Mic';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DvrIcon from '@material-ui/icons/Dvr';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CodeIcon from '@material-ui/icons/Code';
import PaymentIcon from '@material-ui/icons/Payment';
import { isSuperUser } from 'components/Dashboard/Dashboard';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import format from 'date-fns/format';
import TableChartIcon from '@material-ui/icons/TableChart';
import BallotIcon from '@material-ui/icons/Ballot';

const styles = theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const menu = [
  ['news', 'timetable', 'timetable/calendar', 'videos', 'documents', 'topics', 'rattrapages', 'podcasts'],
  ['orders', 'polycopies', 'trainings', 'sessions', 'teachers', 'students', 'homeworks'],
  ['search', 'statistics', 'reglements', 'payzen', 'logs'],
  ['settings'],
];


const getStorageOpen = (idFromRouter) => {
  const value = localStorage.getItem('crmMenuOpen');
  const open = JSON.parse(value ?? '[]');
  return !open.includes(idFromRouter)
    ? [...open, idFromRouter]
    : open
    ;
};

const setStorageOpen = (open) => {
  localStorage.setItem('crmMenuOpen', JSON.stringify(open));
  return open;
};

const SidebarMenu = ({ classes, user, location }) => {
  const { pathname } = location;

  const [open, setOpen] = useState(getStorageOpen(menu.findIndex((paths) => {
    return paths.some((path) => {
      return pathname.includes(path);
    });
  })));

  const toggleMenu = (id) => {
    const index = open.findIndex((openId) => {
      return openId === id;
    });
    if (index === -1) {
      setOpen((state) => setStorageOpen([...state, id]));
    } else {
      setOpen((state) => setStorageOpen(state.filter((openId) => openId !== id)));
    }
  };

  const visibleMenu = (id) => {
    return open.some((openId) => openId === id);
  };

  const isAdmin = user.role === 'administrator';
  const isTeacher = user.role === 'teacher';

  return (
    <List dense>
      {isAdmin ? (
        <Fragment>
          <ListItem button onClick={() => {
            toggleMenu(0);
          }}>
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="Espace réservé" />
            {visibleMenu(0) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={visibleMenu(0)} timeout="auto" unmountOnExit>
            <List dense component="div" disablePadding>
              <ListItem button component={Link} to="/news" className={classes.nested}>
                <ListItemIcon>
                  <MessageIcon />
                </ListItemIcon>
                <ListItemText primary="Actualités" />
              </ListItem>
              <ListItem button component={Link} to={`/timetable?filter=startDate_${format(new Date(), 'yyyy-MM-dd')}`} className={classes.nested}>
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="EDT : liste" />
              </ListItem>
              <ListItem button component={Link} to={`/timetable/calendar/${format(new Date(), 'yyyy-MM-dd')}`} className={classes.nested}>
                <ListItemIcon>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText primary="EDT : calendrier" />
              </ListItem>
              <ListItem button component={Link} to="/videos" className={classes.nested}>
                <ListItemIcon>
                  <MovieIcon />
                </ListItemIcon>
                <ListItemText primary="Vidéos" />
              </ListItem>
              <ListItem button component={Link} to="/documents" className={classes.nested}>
                <ListItemIcon>
                  <AttachmentIcon />
                </ListItemIcon>
                <ListItemText primary="Documents" />
              </ListItem>
              <ListItem button component={Link} to="/topics" className={classes.nested}>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Sujets" />
              </ListItem>
              <ListItem button component={Link} to="/rattrapages" className={classes.nested}>
                <ListItemIcon>
                  <MovieIcon />
                </ListItemIcon>
                <ListItemText primary="Rattrapages" />
              </ListItem>
              <ListItem button component={Link} to="/podcasts" className={classes.nested}>
                <ListItemIcon>
                  <MicIcon />
                </ListItemIcon>
                <ListItemText primary="Podcasts" />
              </ListItem>
            </List>
          </Collapse>
        </Fragment>
      ) : null}

      <ListItem button onClick={() => {
        toggleMenu(1);
      }}>
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        <ListItemText primary="Administration" />
        {visibleMenu(1) ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={visibleMenu(1)} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {isAdmin ? (
            <Fragment>
              <ListItem button component={Link} to="/orders" className={classes.nested}>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Commandes" />
              </ListItem>
              <ListItem button component={Link} to="/polycopies" className={classes.nested}>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="Polycopiés" />
              </ListItem>
              <ListItem button component={Link} to="/trainings" className={classes.nested}>
                <ListItemIcon>
                  <BookmarkIcon />
                </ListItemIcon>
                <ListItemText primary="Formations" />
              </ListItem>
              <ListItem button component={Link} to="/sessions" className={classes.nested}>
                <ListItemIcon>
                  <DomainIcon />
                </ListItemIcon>
                <ListItemText primary="Sessions" />
              </ListItem>
              <ListItem button component={Link} to="/students" className={classes.nested}>
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText primary="Étudiants" />
              </ListItem>
              <ListItem button component={Link} to="/teachers?filter=status_green" className={classes.nested}>
                <ListItemIcon>
                  <SchoolIcon />
                </ListItemIcon>
                <ListItemText primary="Professeurs" />
              </ListItem>
            </Fragment>
          ) : null}
          {isAdmin ? (
            <ListItem button component={Link} to="/proofreading" className={classes.nested}>
              <ListItemIcon>
                <BallotIcon />
              </ListItemIcon>
              <ListItemText primary="Paiements corrigés" />
            </ListItem>
          ) : null}
          {isAdmin || isTeacher ? (
            <ListItem button component={Link} to="/homeworks?filter=corrige_0" className={classes.nested}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Copies" />
            </ListItem>
          ) : null}
          {isTeacher ? (
            <Fragment>
              <ListItem button component={Link} to={`/timetable?filter=startDate_${format(new Date(), 'yyyy-MM-dd')}`} className={classes.nested}>
                <ListItemIcon>
                  <TableChartIcon />
                </ListItemIcon>
                <ListItemText primary="EDT : liste" />
              </ListItem>
              <ListItem button component={Link} to={`/timetable/calendar/${format(new Date(), 'yyyy-MM-dd')}`} className={classes.nested}>
                <ListItemIcon>
                  <CalendarIcon />
                </ListItemIcon>
                <ListItemText primary="EDT : calendrier" />
              </ListItem>
            </Fragment>
          ) : null}
        </List>
      </Collapse>

      {isAdmin ? (
        <Fragment>
          <ListItem button onClick={() => {
            toggleMenu(2);
          }}>
            <ListItemIcon>
              <DataUsageIcon />
            </ListItemIcon>
            <ListItemText primary="Base de données" />
            {visibleMenu(2) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={visibleMenu(2)} timeout="auto" unmountOnExit>
            <List dense component="div" disablePadding>
              <ListItem button component={Link} to="/search" className={classes.nested}>
                <ListItemIcon>
                  <QueryBuilderIcon />
                </ListItemIcon>
                <ListItemText primary="Recherche" />
              </ListItem>
              <ListItem button component={Link} to="/statistics" className={classes.nested}>
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary="Statistiques" />
              </ListItem>
              <ListItem button component={Link} to="/reglements" className={classes.nested}>
                <ListItemIcon>
                  <EuroIcon />
                </ListItemIcon>
                <ListItemText primary="Règlements" />
              </ListItem>
              <ListItem button component={Link} to="/payzen?filter=state_1---processed_0&limit=50&page=0&sortBy=id&direction=desc" className={classes.nested}>
                <ListItemIcon>
                  <PaymentIcon />
                </ListItemIcon>
                <ListItemText primary="Paiements refusés" />
              </ListItem>
              {isSuperUser(user.login) ? (
                <ListItem button component={Link} to="/logs" className={classes.nested}>
                  <ListItemIcon>
                    <DvrIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logs" />
                </ListItem>
              ) : null}
            </List>
          </Collapse>
        </Fragment>
      ) : null}

      {isAdmin ? (
        <Fragment>
          <ListItem button onClick={() => {
            toggleMenu(3);
          }}>
            <ListItemIcon>
              <CodeIcon />
            </ListItemIcon>
            <ListItemText primary="Configuration" />
            {visibleMenu(3) ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={visibleMenu(3)} timeout="auto" unmountOnExit>
            <List dense component="div" disablePadding>
              <ListItem button component={Link} to="/settings" className={classes.nested}>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Paramètres" />
              </ListItem>
            </List>
          </Collapse>
        </Fragment>
      ) : null}
    </List>
  );
};

SidebarMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(SidebarMenu));
