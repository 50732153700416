import { alpha } from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
  dense: {
    padding: 0,
    fontSize: theme.typography.caption.fontSize, 
  },
  tagSizeSmall: {
    fontSize: theme.typography.caption.fontSize,
    padding: 0,
    margin: '1px',
  },
  popper: {
    minWidth: '220px',
    '& .MuiAutocomplete-option': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  fieldInputDense: {
    '& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root': {
      paddingTop: theme.spacing(1 * .5),
      paddingBottom: theme.spacing(1 * .5),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      fontSize: theme.typography.caption.fontSize,
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.05),
      },
    },
  },
});
