import { Box, IconButton, List, ListItem, Paper, Tooltip, Typography, withTheme } from '@material-ui/core';
import DomainIcon from '@material-ui/icons/Domain';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const DisciplinesList = ({
  role,
  theme,
  timetable,
  onEditDisciplines,
  onDeleteDiscipline,
}) => {
  const isAdmin = role === 'administrator';
  const { timetableDisciplines } = timetable;
  return (
    timetableDisciplines.length > 0 ? (
      <Paper
        style={{
          backgroundColor: theme.palette.background.default,
          padding: `${theme.spacing(.5)}px ${theme.spacing(2)}px`,
          flex: 1,
        }}
      >
        <List dense disablePadding>
          {timetableDisciplines.map(({ id, discipline, discipline_custom_name, session, description }, index) => {
            const shortenedSessionName = session ? session.name.split(' - ').filter((value, key) => key !== 0).join(' - ') : 'Toutes les sessions';
            return (
              <ListItem
                key={`${session?.id ?? 'no-session'}-${discipline.id}`}
                dense
                disableGutters
                divider={index + 1 !== timetableDisciplines.length}
              >
                <Box display="flex" style={{ gap: theme.spacing(1) }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ gap: theme.spacing(1) }}
                  >
                    <ListAltIcon fontSize="small" />
                    <Typography style={{ fontSize: '11px', fontWeight: 'bold' }}>
                      {`${discipline.name}`}
                    </Typography>
                    <DomainIcon fontSize="small" />
                    <Typography style={{ fontSize: '11px', fontWeight: session ? 'inherit' : 'bold' }}>
                      {`${shortenedSessionName}`}
                    </Typography>
                    {(
                      (description ?? '').length > 0
                      || (discipline.public_name ?? '').length > 0
                      || (discipline_custom_name ?? '').length > 0
                    ) ? (
                      <Tooltip
                        title={
                          <Box display="flex" flexDirection="column">
                            <Typography>{`Nom public : ${(discipline.public_name ?? '').length > 0 ? discipline.public_name : discipline.name}`}</Typography>
                            <Typography>{`Nom spécifique : ${discipline_custom_name ?? '-'}`}</Typography>
                            <Box display="flex">
                              <Typography>{`Description : `}</Typography>
                              <Box dangerouslySetInnerHTML={{ __html: description ?? '-' }} />
                            </Box>
                          </Box>
                        }
                      >
                        <HelpIcon color={(discipline_custom_name ?? '').length > 0 ? 'secondary' : 'primary'} style={{ width: '16px' }} />
                      </Tooltip>
                    ) : null}
                  </Box>
                  <Box display="flex">
                    {isAdmin ? (
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        onClick={() => {
                          onEditDisciplines({
                            timetableId: timetable.id,
                            discipline,
                            discipline_custom_name,
                            description,
                          });
                        }}
                      >
                        <EditIcon style={{ width: '16px' }} />
                      </IconButton>
                    ) : null}
                    {isAdmin ? (
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => {
                          onDeleteDiscipline(id);
                        }}
                      >
                        <DeleteIcon style={{ width: '16px' }} />
                      </IconButton>
                    ) : null}
                  </Box>
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Paper >
    ) : null
  );
};

export default withTheme(DisciplinesList);
