import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './RattrapageDialog.css';
import DeleteIcon from '@material-ui/icons/Delete';

const StudentList = ({
  classes,
  remove,
  setDate,
  students,
}) => {
  return (
    <List
      className={classes.root}
      dense
    >
      {students.map(s => {
        const labelId = `checkbox-list-label-right-${s.id}-${new Date().getTime()}`;
        return (
          <ListItem
            key={labelId}
            role={undefined}
            dense
          >
            <ListItemIcon onClick={() => remove(s.id)}>
              <IconButton
                color="secondary"
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemText
              className={classNames(classes.flexBasis100, classes.flexGrow1)}
              id={labelId}
              primary={`${s.last_name.toUpperCase()} ${s.first_name}`}
            />
            <ListItemText
              className={classes.flexGrow2}
            >
              <ReactDatePicker
                selected={s ? new Date(s.available_at) : null}
                onChange={(date) => { setDate(date, s.id); }}
                placeholderText="Disponible le"
                dateFormat="dd/MM/yyyy"
                value={s ? new Date(s.available_at) : new Date()}
                withPortal
              />
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

StudentList.propTypes = {
  classes: PropTypes.object.isRequired,
  remove: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  students: PropTypes.array,
};

export default withStyles(styles)(StudentList);