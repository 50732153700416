import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Fade,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Sessions.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import SessionDialogCreate from 'components/SessionDialogCreate/SessionDialogCreate';
import SessionDialogDiscipline from 'components/SessionDialogDiscipline/SessionDialogDiscipline';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import FilterInput from 'components/Datagrid/FilterInput';
import SessionDialogMeta from 'components/SessionDialogMeta/SessionDialogMeta';
import SessionDialogMerge from 'components/SessionDialogMerge/SessionDialogMerge';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import TrainingStatus from 'components/Trainings/TrainingStatus';

const sortableCells = [
  { name: 'id', label: '#' },
  { name: 'name', label: 'Nom' },
  { name: 'ref', label: 'Référence ISP' },
];

class Sessions extends Component {
  state = {
    selectAll: false,
    selectedIds: [],
    openSessionDialog: false,
    openDisciplineDialog: false,
    openMetaDialog: false,
    openMergeSessionDialog: false,
    editSessionId: false,
  }

  onOpenMergeSessionDialog = () => {
    this.setState({ openMergeSessionDialog: true });
  };

  onCloseMergeSessionDialog = () => {
    this.props.reload();
    this.setState({ openMergeSessionDialog: false });
  };

  onOpenSessionDialog = () => {
    this.setState({ openSessionDialog: true });
  };

  onCloseSessionDialog = () => {
    this.setState({ openSessionDialog: false });
  };

  onOpenDisciplineDialog = () => {
    this.setState({ openDisciplineDialog: true });
  };

  onCloseDisciplineDialog = () => {
    this.props.reload();
    this.setState({ openDisciplineDialog: false });
  };

  onOpenMetaDialog = () => {
    this.setState({ openMetaDialog: true });
  };

  onCloseMetaDialog = () => {
    this.props.reload();
    this.setState({ openMetaDialog: false });
  };

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? this.props.rows.map(({ id }) => String(id)) : [];
    this.setState({ selectAll: checked, selectedIds });
  }

  onSelectOne = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
      selectedIds.push(value);
    } else {
      const index = selectedIds.indexOf(value);
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({ selectedIds });
  }

  onEditRef = async (evt, id) => {
    if (evt.key === 'Enter') {
      await Http.put(`/sessions/${id}`, { ref: evt.target.value });
    }
  }

  onSessionNameSubmit = async (event) => {
    const { editSessionId } = this.state;
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const [id, name] = [editSessionId, formData.get('sessionName').trim()];
    await Http.put(`/sessions/${id}`, { name });
    this.setState({ editSessionId: false });
    this.props.reload();
  }

  render() {
    const { filterState, filter, classes, loading, rows, renderPagination, renderSortCell, renderFilter } = this.props;
    const { editSessionId, selectAll, selectedIds } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <Fade in={selectedIds.length > 0}>
            <Box mr={2}>
              <Badge color="primary" badgeContent={selectedIds.length}>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={this.onOpenDisciplineDialog}
                >
                  Affecter aux matières
                </Button>
              </Badge>
            </Box>
          </Fade>
          <Fade in={selectedIds.length > 0}>
            <Box>
              <Badge color="primary" badgeContent={selectedIds.length}>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={this.onOpenMetaDialog}
                >
                  Métadonnées
                </Button>
              </Badge>
            </Box>
          </Fade>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.onOpenMergeSessionDialog}
            >
              Fusionner une session
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.onOpenSessionDialog}
            >
              Créer une session
            </Button>
          </Box>
          <SessionDialogMeta
            open={this.state.openMetaDialog}
            onClose={this.onCloseMetaDialog}
            selectedIds={selectedIds}
          />
          <SessionDialogDiscipline
            open={this.state.openDisciplineDialog}
            onClose={this.onCloseDisciplineDialog}
            selectedIds={selectedIds}
          />
          <SessionDialogCreate
            open={this.state.openSessionDialog}
            onClose={this.onCloseSessionDialog}
          />
          {this.state.openMergeSessionDialog ? (
            <SessionDialogMerge
              open={this.state.openMergeSessionDialog}
              onClose={this.onCloseMergeSessionDialog}
            />
          ) : null}
          {renderFilter('Filtrer par session')}
          <FilterInput
            filter={filter}
            filterBy="training"
            placeholder="Filtrer par formation"
            defaultValue={filterState.training ?? ''}
          />
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left"><Checkbox onChange={this.onSelectAllClick} checked={selectAll} /></TableCell>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell>Métadonnées</TableCell>
              <TableCell>Métadonnées suppl.</TableCell>
              <TableCell>Etudiants</TableCell>
              <TableCell>Tronc commun</TableCell>
              <TableCell>Matières suppl.</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(n => (
              <TableRow key={n.id}>
                <TableCell>
                  <Checkbox
                    value={String(n.id)}
                    onChange={this.onSelectOne}
                    checked={selectedIds.includes(String(n.id))} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {n.id}
                </TableCell>
                <TableCell>
                    {(editSessionId && n.id === editSessionId) ? (
                      <form onSubmit={this.onSessionNameSubmit}>
                        <Box
                          display="flex"
                          style={{ gap: '4px' }}
                          mb={1}
                        >
                          <InputBase
                            classes={{
                              root: classes.field,
                              input: classes.fieldInput,
                            }}
                            defaultValue={n.name}
                            name="sessionName"
                            fullWidth
                            style={{ flex: 1 }}
                          />
                          <Tooltip title="Enregisrer">
                            <IconButton type="submit">
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Annuler">
                            <IconButton onClick={() => {
                              this.setState({ editSessionId: false });
                            }}>
                              <CancelIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </form>
                    ) : (
                      <Box
                        display="flex"
                        style={{ gap: '4px' }}
                        alignItems="flex-start"
                      >
                        <Typography variant="inherit">{n.name}</Typography>
                        <Tooltip title="Renommer">
                          <IconButton
                            style={{ padding: '0' }}
                            onClick={() => {
                              this.setState({
                                editSessionId: n.id,
                              })
                            }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ gap: '6px' }}
                  >
                    <Typography
                      variant="caption"
                      style={{
                        opacity: .5,
                      }}
                    >
                      {n.title}
                    </Typography>
                    <TrainingStatus status={n.status} />
                  </Box>
                </TableCell>
                <TableCell>
                  <InputBase
                    classes={{
                      root: classes.field,
                      input: classes.fieldInput,
                    }}
                    defaultValue={n.ref}
                    onKeyPress={evt => this.onEditRef(evt, n.id)}
                  />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    className={classes.chip}
                    size="small"
                    label={n.training_meta_count}
                  />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    className={classes.chip}
                    size="small"
                    label={n.session_meta_count}
                  />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    className={classes.chip}
                    size="small"
                    color="primary"
                    label={n.student_count}
                  />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    className={classes.chip}
                    size="small"
                    label={n.training_discipline_count}
                  />
                </TableCell>
                <TableCell align="center">
                  <Chip
                    className={classes.chip}
                    size="small"
                    label={n.session_discipline_count}
                  />
                </TableCell>
                <TableCell>
                  <ButtonRouter
                    variant="contained"
                    size="small"
                    label="Détail"
                    to={`/sessions/${n.id}`}
                    disableElevation
                  />
                </TableCell>
              </TableRow>
            ))}
            {rows.length ? (
              <TableRow>
                <TableCell colSpan="6" />
                <TableCell align="center">
                  <Typography variant="button">{rows.reduce((prev, current) => prev + current.student_count, 0)}</Typography>
                </TableCell>
                <TableCell colSpan="3" />
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

Sessions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(Sessions, 'sessions', 'id', 'desc')
);
