export const styles = theme => ({
    landscapeContainer: {
        paddingTop: '56.25%',
        position: 'relative',
        width: '100%',
        minWidth: '400px',
        '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        }
    },
    thumbnail: {
        maxHeight: '50px',
        width: 'auto',
    },
});
