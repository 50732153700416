import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import HUE from '@material-ui/core/colors/teal';
import { Delete, Launch } from '@material-ui/icons';
import React from 'react';
import { BookmarkListProps } from './List';

type TableListProps = Pick<BookmarkListProps, 'bookmarks' | 'scope'> & {
  handleGoto: (url: string) => void
  handleDelete: (url: string) => void
}
export default function TableList({
  bookmarks,
  scope,
  handleGoto,
  handleDelete,
  ...props
}: TableListProps) {

  const url = window.location.toString();

  const clickStyle: React.CSSProperties = {
    cursor: 'pointer',
  };

  return <Table size="small">
    <TableHead>
      <TableRow>
        <TableCell component={'th'} scope="row" width="40%">
          Nom
        </TableCell>
        <TableCell component={'th'}>
          Descriptif
        </TableCell>
        <TableCell component={'th'} align='center' width={'150px'}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {bookmarks.map(book => {
        return <TableRow
          key={book.id}
          style={{
            backgroundColor: book.url === url ? HUE[50] : '',
          }}
        >
          <TableCell onClick={e => handleGoto(book.url)} style={clickStyle} >{book.name}</TableCell>
          <TableCell onClick={e => handleGoto(book.url)} style={clickStyle} >{(book.desc ?? '').split('\n').map((line, idx) => <React.Fragment key={idx}>{line}<br /></React.Fragment>)}</TableCell>
          <TableCell align='center'>
            <Tooltip title="Charger la page">
              <IconButton
                onClick={e => handleGoto(book.url)}
                size='small'
              >
                <Launch />
              </IconButton>
            </Tooltip>
            <Tooltip title="Retirer le favori">
              <IconButton color='secondary'
                onClick={e => handleDelete(book.url)}
                size='small'
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>;
      })}
    </TableBody>
  </Table>;

}