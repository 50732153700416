import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SaveIcon from '@material-ui/icons/Save';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Metas.css';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import Http from 'services/Http';
import { ordersInvoiceConfig } from 'components/Orders/OrdersInvoice';

const sortableCells = [
  { name: 'id', label: '#', isString: false },
  { name: 'key', label: 'Clé', isString: true },
];

const metasReducer = (prev, { id, key }) => { prev[`key-${id}`] = { id, key }; return prev; };

class Metas extends Component {
  state = {
    error: '',
    openError: false,
    success: '',
    openSuccess: false,
    selectAll: false,
    metas: (this.props.data && this.props.data.metas)
      ? this.props.data.metas.reduce(metasReducer, {})
      : {},
    deleteDialog: false,
    deleteMeta: null,
  }

  componentDidMount() {
    if (this.props.data && this.props.data.metas) {
      this.props.setData(this.props.data.metas);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && this.props.data.metas && prevProps.data && prevProps.data.metas) {
      const { data: { metas } } = this.props;
      const { data: { metas: prevMetas } } = prevProps;
      if (metas.length !== prevMetas.length) {
        this.props.setData(metas);
        this.setState({
          metas: metas.reduce(metasReducer, {}),
        });
      }
    }
  }

  // DIALOGS
  onOpenDeleteDialog = (deleteMeta) => {
    this.setState({
      deleteDialog: true,
      deleteMeta,
    });
  }

  onCloseDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      deleteMeta: null,
    });
  }

  // CRUD
  onMetaEdit = async (payload) => {
    try {
      let filter;
      switch (this.props.context) {
        case 'training':
          filter = { training_id: this.props.data.id };
          break;
        case 'session':
          filter = { session_id: this.props.data.id };
          break;
        default:
          filter = null;
          break;
      }
      if (filter) {
        await Http.put(
          `/meta/${payload.id}`,
          { ...filter, ...payload }
        );
        this.setState({
          success: `La métadonnée a été mise à jour pour ${this.props.messages.add}`,
          openSuccess: true,
        });
        this.props.reload();
      }
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  onEditSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    this.onMetaEdit(Object.fromEntries(formData.entries()));
  }

  onCreateSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    this.onMetaCreate(Object.fromEntries(formData.entries()));
    event.currentTarget.reset();
  }

  onMetaCreate = async (payload) => {
    try {
      let filter;
      switch (this.props.context) {
        case 'training':
          filter = { training_id: this.props.data.id };
          break;
        case 'session':
          filter = { session_id: this.props.data.id };
          break;
        case 'sessions':
          filter = { session_ids: this.props.data.ids };
          break;
        default:
          filter = null;
          break;
      }
      if (filter) {
        const { data } = await Http.post(
          `/meta`,
          { ...filter, ...payload }
        );
        this.setState({
          success: `La métadonnée "${data.key}" a été ajoutée à ${this.props.messages.add}`,
          openSuccess: true,
        });
        this.props.reload();
      }
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  onMetaDelete = async () => {
    if (this.state.deleteMeta) {
      try {
        const { data } = await Http.delete(`/meta/${this.state.deleteMeta}`);
        this.setState({
          success: `La métadonnée "${data.key}" a été supprimée de ${this.props.messages.delete}`,
          openSuccess: true,
          deleteDialog: false,
          deleteMeta: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          deleteMeta: null,
        });
      }
    }
  }

  render() {
    const { classes, messages, rows, renderSortCell, renderFilter } = this.props;
    const { deleteDialog, error, openError, openSuccess, success } = this.state;

    return (
      <div className={classes.root}>
        <ConfirmationDialog
          open={deleteDialog}
          title="Attention"
          message={`Êtes-vous sûr de vouloir supprimer cette métadonnée pour ${messages.delete} ?`}
          onClose={this.onCloseDeleteDialog}
          onConfirm={this.onMetaDelete}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar>
          <div className={classes.message}>
            {messages.info ? (
              <Fragment>
                <ErrorIcon className={classes.icon} />
                <span>{messages.info}</span>
              </Fragment>
            ) : null}
          </div>
          {renderFilter('Filtrer par clé', 'key')}
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(n => (
              <TableRow key={n.id}>
                <TableCell component="th" scope="row">
                  {n.id}
                </TableCell>
                <TableCell>
                  <form onSubmit={this.onEditSubmit}>
                    <input type="hidden" name="id" value={n.id} />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      style={{ gap: '6px' }}
                    >
                      <InputBase
                        inputProps={{
                          list: 'available-keys',
                          autoComplete: 'off',
                        }}
                        defaultValue={n.key}
                        name="key"
                        classes={{
                          root: classes.field,
                          input: classes.fieldInput,
                        }}
                        placeholder="Nouvelle clé"
                      />
                      <InputBase
                        defaultValue={n.value}
                        name="value"
                        classes={{
                          root: classes.field,
                          input: classes.fieldInput,
                        }}
                        placeholder="Nouvelle valeur"
                      />
                      <IconButton
                        type="submit"
                      >
                        <SaveIcon />
                      </IconButton>
                    </Box>
                  </form>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => this.onOpenDeleteDialog(n.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <form onSubmit={this.onCreateSubmit}>
          <Box pt={2} display="flex" alignItems="center" justifyContent="center" style={{ gap: '6px' }}>
            <InputBase
              inputProps={{
                list: 'available-keys',
                autoComplete: 'off',
              }}
              name="key"
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              placeholder="Nouvelle clé"
            />
            <InputBase
              name="value"
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              placeholder="Nouvelle valeur"
            />
            <IconButton
              color="secondary"
              type="submit"
            >
              <AddCircleIcon />
            </IconButton>
          </Box>
        </form>
        <Box m={1} display="flex" justifyContent="center" alignItems="center" style={{ gap: '6px' }}>
          <Typography variant="inherit">Clés disponibles</Typography>
          {ordersInvoiceConfig.map(({ key, title }) => {
            return (
              <Chip
                key={key}
                size="small"
                label={`${key} (${title})`}
              />
            );
          })}
          <datalist id="available-keys">
            {ordersInvoiceConfig.map(({ key, title }) => {
              return (
                <option key={key} value={key} />
              );
            })}
          </datalist>
        </Box>
      </div>
    );
  }
}

Metas.propTypes = {
  classes: PropTypes.object.isRequired,
  context: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  withLocalSortAndFilter(Metas, 'id')
);
