import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './StudentDetails.css';
import StudentHome from 'components/StudentHome/StudentHome';
import StudentSessions from 'components/StudentSessions/StudentSessions';
import StudentHomeworks from 'components/StudentHomeworks/StudentHomeworks';
import StudentVideos from 'components/StudentVideos/StudentVideos';
import Disciplines from 'components/Disciplines/Disciplines';
import DisciplineDialogAffectRadio from 'components/DisciplineDialogAffectRadio/DisciplineDialogAffectRadio';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import StudentPolycopies from 'components/StudentPolycopies/StudentPolycopies';
import StudentComments from 'components/StudentComments/StudentComments';
import StudentOrders from 'components/StudentOrders/StudentOrders';
import axios from 'axios';

export const studentTabs = [
  { value: 'profil', label: 'Profil' },
  { value: 'orders', label: 'Commandes' },
  { value: 'sessions', label: 'Sessions' },
  { value: 'disciplines', label: 'Matières' },
  { value: 'videos', label: 'Vidéos' },
  { value: 'homeworks', label: 'Copies' },
  { value: 'polycopies', label: 'Polycopiés' },
  { value: 'comments', label: 'Commentaires' },
];

class StudentDetails extends Component {
  state = {
    error: '',
    openError: false,
    success: '',
    openSuccess: false,
    dialog: false,
    student: null,
    blockUi: false,
  };

  source = null;

  loadTab = async (tab) => {
    const { studentId } = this.props.match.params;
    if (this.source) {
      this.source.cancel(`Operation /users/students/${studentId}?tabs=${tab} canceled by the user.`);
    }
    this.setState({ blockUi: true });
    try {
      this.source = axios.CancelToken.source();
      const { data } = await Http.get(`/users/students/${studentId}?tabs=${tab}`, {
        cancelToken: this.source.token,
      });
      this.setState({
        student: data,
        blockUi: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel(`Operation canceled by componentWillUnmount lifecycle method.`);
    }
  }

  async componentDidUpdate (prevProps) {
    if (prevProps.match.params.tab !== this.props.match.params.tab/* && this.state.blockUi === false*/) {
      this.reload();
    }
  }

  async componentDidMount() {
    this.reload();
  }

  reload = async () => {
    this.loadTab(this.props.match.params.tab);
  }

  onTabChange = async (_, tab) => {
    const { studentId } = this.props.match.params;
    this.props.history.push(`/students/${studentId}/${tab}`);
    this.loadTab(tab);
  }

  openDialog = () => {
    this.setState({ dialog: true });
  }

  closeDialog = () => {
    this.setState({ dialog: false });
  }

  affect = async ({ discipline_id }) => {
    try {
      await Http.post(`/disciplines`,
        {
          user_id: this.state.student.id,
          discipline_id,
          name: 'fake',
        }
      );
      this.setState({
        success: 'La matière a été affectée à cet étudiant(e)',
        openSuccess: true,
        dialog: false,
      });
      this.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
        dialog: false,
      });
    }
  }

  render() {
    const { blockUi, dialog, error, openError, openSuccess, student, success } = this.state;
    const { tab } = this.props.match.params;

    if (!student) {
      return 'En cours de chargement...'
    }

    return (
      <Paper>
        <Backdrop
          open={blockUi}
          style={{ zIndex: 999999 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box pt={2} pb={3}>
          <ErrorSnackbar
            message={error}
            onClose={() => this.setState({ openError: false })}
            open={openError}
          />
          <SuccessSnackbar
            message={success}
            onClose={() => this.setState({ openSuccess: false })}
            open={openSuccess}
          />
          <Box pb={2}>
            <Typography variant="h6">
              {student.first_name} {student.last_name}
            </Typography>
          </Box>
          <AppBar position="static" color="default" elevation={0}>
            <Tabs
              value={tab}
              onChange={this.onTabChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {studentTabs.map(({ value, label }) => {
                return (
                  <Tab
                    key={value}
                    value={value}
                    label={label}
                  />
                );
              })}
            </Tabs>
          </AppBar>
          {!blockUi ? (
            <Fragment key={tab}>
              {tab === 'profil' ? (
                <StudentHome data={student} reload={this.reload} />
              ) : tab === 'orders' ? (
                <StudentOrders
                  history={this.props.history}
                  match={this.props.match}
                  data={student}
                  reload={this.reload}
                />
              ) : tab === 'sessions' ? (
                <StudentSessions data={student} reload={this.reload} />
              ) : tab === 'homeworks' ? (
                <StudentHomeworks data={student} reload={this.reload} />
              ) : tab === 'videos' ? (
                <StudentVideos data={student} reload={this.reload} />
              ) : tab === 'disciplines' ? (
                <Box>
                  {dialog ? (
                    <DisciplineDialogAffectRadio
                      open={dialog}
                      onClose={this.closeDialog}
                      onAffect={this.affect}
                    />
                  ) : null}
                  <Disciplines
                    data={{ id: student.id, disciplines: student.disciplines }}
                    context="student"
                    reload={this.reload}
                    messages={{
                      add: 'cet étudiant(e)',
                      delete: 'cet étudiant(e)',
                    }}
                  />
                  <Box pt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={this.openDialog}
                    >
                      Affecter à une matière
                    </Button>
                  </Box>
                </Box>
              ) : tab === 'polycopies' ? (
                <StudentPolycopies
                  data={student}
                  reload={this.reload}
                  goToProfileTab={() => {
                    this.props.history.push(`/students/${this.props.match.params.studentId}/profil`);
                  }}
                />
              ) : tab === 'comments' ? (
                <StudentComments data={student} reload={this.reload} />
              ) : null}
            </Fragment>
            ) : null}
        </Box>
      </Paper>
    );
  }
}

StudentDetails.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StudentDetails);
