import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  NativeSelect,
  Radio,
  RadioGroup,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorSnackbar from 'components/Snackbar/Error';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './DisciplineDialogAffectRadio.css';
import WorkIcon from '@material-ui/icons/Work';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DisciplineDialogAffectRadio extends Component {
  state = {
    allSessions: [],
    trainingList: [],
    sessionList: [],
    training: null,
    session: null,
    discipline: '',
    error: [],
    openError: false,
    disciplines: [],
  }

  async componentDidMount() {
    const trainings = await Http.get('/trainings/list?include=discipline');
    const sessions = await Http.get('/sessions/list?include=discipline');
    this.setState({
      trainingList: trainings.data,
      allSessions: sessions.data,
    });
    const { multiple, selectedDisciplineIds } = this.props;
    if (multiple
      && selectedDisciplineIds
      && selectedDisciplineIds.length > 0
    ) {
      const { trainingList, allSessions } = this.state;
      const disciplines = selectedDisciplineIds.map((disciplineId) => {
        const disciplineFromTraining = trainingList?.filter(({ disciplines }) => {
          return disciplines.some(({ id })=> id === disciplineId);
        }).at(0)?.disciplines?.find(({ id })=> id === disciplineId);
        const disciplineFromSession = allSessions?.filter(({ disciplines }) => {
          return disciplines.some(({ id })=> id === disciplineId);
        }).at(0)?.disciplines?.find(({ id })=> id === disciplineId);;
        return disciplineFromTraining ?? disciplineFromSession;
      });
      this.setState({ disciplines })
    }
  }

  resetState() {
    this.setState({
      training: null,
      sessionList: [],
      session: null,
      discipline: '',
      error: [],
    });
  }

  changeTraining = ({ target }) => {
    const value = parseInt(target.value, 10);
    if (value) {
      const { title, disciplines } = this.state.trainingList.find((o) => o.id === value);
      const sessionList = this.state.allSessions.filter(s => s.training_id === value);
      this.setState({
        training: { id: value, title, disciplines },
        sessionList,
        session: null,
        discipline: '',
      });
    } else {
      this.resetState();
    }
  }

  changeSession = ({ target }) => {
    const value = parseInt(target.value, 10);
    if (value) {
      const { title, disciplines } = this.state.sessionList.find((o) => o.id === value);
      this.setState({
        session: { id: value, title, disciplines },
        discipline: '',
      });
    } else {
      this.setState({
        session: null,
        discipline: '',
      });
    }
  }

  selectDisciplineMultiple = (disciplineIdMaybeString) => {
    const { session, training } = this.state;
    const disciplineId = parseInt(disciplineIdMaybeString, 10);
    const disciplineFromTraining = training?.disciplines?.find(({ id }) => {
      return id === disciplineId;
    });
    const disciplineFromSession = session?.disciplines?.find(({ id }) => {
      return id === disciplineId;
    });
    const selected = disciplineFromTraining ?? disciplineFromSession;
    this.setState({
      disciplines: [
        ...this.state.disciplines.filter(({ id }) => id !== selected.id),
        selected,
      ],
    });
  };

  selectDiscipline = (event) => {
    event.persist();
    const { multiple } = this.props;
    if (multiple) {
      this.selectDisciplineMultiple(event.target.value);
    } else {
      this.setState({ discipline: event.target.value });
    }
  }

  affectDiscipline = async () => {
    const { multiple } = this.props;
    const { discipline, disciplines } = this.state;
    if ((multiple && disciplines.length === 0) || (!multiple && !discipline)) {
      this.setState({
        openError: true,
        error: ['Veuillez choisir une discipline'],
      });
    } else {
      const discipline_id = this.state.discipline;
      this.resetState();
      this.props.onAffect(multiple
          ? { disciplineIds: disciplines.map(({ id }) => id), disciplines }
          : { discipline_id },
      );
    }
  }

  closeDialog = () => {
    this.resetState();
    this.props.onClose();
  }

  render() {
    const { classes, open, multiple } = this.props;
    const {
      discipline,
      error,
      openError,
      trainingList,
      training,
      sessionList,
      session,
      disciplines,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <ErrorSnackbar
          open={openError}
          onClose={() => this.setState({ openError: false })}
          message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
        />

        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={this.closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            {`Affecter à une ${multiple ? '(ou des)' : ''} matière${multiple ? '(s)' : ''}`}
          </Typography>
          <Button
            onClick={this.affectDiscipline}
            color="secondary"
            variant="contained"
            disableElevation
          >
            Affecter
          </Button>
        </Toolbar>

        <DialogContent className={classes.dialog}>
          <FormGroup className={classes.formGroup}>
            <FormControl className={classes.formControl} required>
              <InputLabel shrink htmlFor="training">
                Formation
              </InputLabel>
              <NativeSelect
                onChange={this.changeTraining}
                input={<Input name="training" />}
                className={classes.selectEmpty}
              >
                <option value="">Choix</option>
                {trainingList && trainingList.map((training) => (
                  <option key={training.id} value={training.id}>{training.title}</option>
                ))}
              </NativeSelect>
            </FormControl>

            <FormControl className={classes.formControl} required>
              <InputLabel shrink htmlFor="session">
                Session
              </InputLabel>
              <NativeSelect
                onChange={this.changeSession}
                input={<Input name="session" />}
                className={classes.selectEmpty}
              >
                <option value="">Choix</option>
                {sessionList && sessionList.map((session) => (
                  <option key={session.id} value={session.id}>{session.name}</option>
                ))}
              </NativeSelect>
            </FormControl>
          </FormGroup>

          {training && (
            <FormGroup className={classes.formGroupBis}>
              <FormControl className={classes.formControl}>
                <FormLabel className={classes.label}>
                  Tronc commun
                </FormLabel>
                {training.disciplines.length ? (
                  <RadioGroup
                    aria-label="Discipline"
                    name="discipline1"
                    className={classes.group}
                    value={discipline}
                    onChange={this.selectDiscipline}
                  >
                    <Grid container spacing={0}>
                      {training.disciplines.map((d) => (
                        <Grid
                          key={`training-${d.id}`}
                          xs={6}
                          item
                        >
                          <FormControlLabel
                            control={<Radio className={classes.radio} />}
                            value={String(d.id)}
                            label={d.name}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                ) : (
                  <Box mt={1}>
                    <Typography
                      color="error"
                    >
                      Aucune matière n'est définie pour le tronc commun.<br />
                      Merci d'en ajouter une depuis la liste des sessions / le détail d'une session.
                    </Typography>
                  </Box>
                )}
              </FormControl>
            </FormGroup>
          )}

          {session && (
            <FormGroup className={classes.formGroup}>
              <FormControl className={classes.formControl}>
                <FormLabel>
                  Matières spécifiques à la session
                </FormLabel>
                {session.disciplines.length ? (
                  <RadioGroup
                    aria-label="Discipline"
                    name="discipline2"
                    className={classes.group}
                    value={discipline}
                    onChange={this.selectDiscipline}
                  >
                    <Grid container spacing={0}>
                      {session.disciplines.map((d) => (
                        <Grid
                          key={`session-${d.id}`}
                          xs={6}
                          item
                        >
                          <FormControlLabel
                            control={<Radio className={classes.radio} />}
                            value={String(d.id)}
                            label={d.name}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </RadioGroup>
                ) : (
                  <Box mt={1}>
                    <Button
                      onClick={() => {
                        const url = `${process.env.REACT_APP_CRM_URL}/sessions/${session.id}`;
                        window.open(url);
                      }}
                      className={classes.flatButton}
                      color="primary"
                      variant="contained"
                    >
                      Ajouter une matière spécifique
                    </Button>
                  </Box>
                )}
              </FormControl>
            </FormGroup>
          )}
          {multiple && disciplines.length > 0 ? (
            <Box
              p={2}
              display="flex"
              flexWrap="wrap"
              style={{ gap: '10px' }}
            >
              {disciplines.map((d) => {
                return (
                  <Chip
                    key={d.id}
                    variant="outlined"
                    onDelete={() => {
                      this.setState({
                        disciplines: disciplines.filter(({ id }) => id !== d.id),
                      });
                    }}
                    icon={<WorkIcon />}
                    label={d.name}
                  />
                )
              })}
            </Box>
          ) : null}
        </DialogContent>
      </Dialog>
    );
  }
}

DisciplineDialogAffectRadio.propTypes = {
  classes: PropTypes.object.isRequired,
  onAffect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  selectedDisciplineIds: PropTypes.arrayOf(PropTypes.number),
};

export default withStyles(styles)(
  DisciplineDialogAffectRadio
);
