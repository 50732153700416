import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogContent,
    FormControl,
    Input,
    InputLabel,
    NativeSelect,
    Slide,
    Toolbar,
    Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './SessionDialogAffect.css';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SessionDialogAffect extends Component {
    state = {
        allSessions: [],
        trainingList: [],
        sessionList: [],
        training: null,
        session: null,
        success: '',
        error: [],
        openSuccess: false,
        openError: false,
    }

    async componentDidMount() {
        const trainings = await Http.get('/trainings/list');
        const sessions = await Http.get('/sessions/list');
        this.setState({
            trainingList: trainings.data,
            allSessions: sessions.data,
        });
    }

    resetState() {
        this.setState({
            sessionList: [],
            training: null,
            session: null,
            success: '',
            error: [],
        });
    }

    changeTraining = ({ target }) => {
        const value = parseInt(target.value, 10);
        if (value) {
          const { title } = this.state.trainingList.find((o) => o.id === value);
          const sessionList = this.state.allSessions.filter(s => s.training_id === value);
          this.setState({
            training: { id: value, title },
            sessionList,
          });
        } else {
          this.resetState();
        }
    }

    selectSession = (event) => {
        event.persist();
        this.setState({ session: event.target.value });
    }

    affectSession = async () => {
        const { session } = this.state;
        try {
            const { data } = await Http.post(`/sessions/${session}/students/${this.props.studentId}`);
            this.setState({
                openSuccess: true,
                success: data.created ? 'Session affectée' : 'Session déjà affectée',
                error: [],
            });
            this.props.onAffect();
        } catch ({ data }) {
            this.setState({
                openError: true,
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                success: '',
            });
        }
    }

    closeDialog = () => {
        this.resetState();
        this.props.onClose();
    }

    render() {
        const { classes, open } = this.props;
        const {
            error,
            openError,
            openSuccess,
            success,
            sessionList,
            trainingList,
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.closeDialog}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <SuccessSnackbar
                    open={openSuccess}
                    onClose={() => this.setState({ openSuccess: false })}
                    message={success}
                />
                <ErrorSnackbar
                    open={openError}
                    onClose={() => this.setState({ openError: false })}
                    message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
                />

                <Toolbar className={classes.toolbar}>
                    <IconButton
                        className={classes.closeButton}
                        edge="start"
                        color="inherit" 
                        onClick={this.closeDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        color="inherit"
                        variant="h6"
                        className={classes.title}
                    >
                        Affecter à une session
                    </Typography>
                    <Button
                        onClick={this.affectSession}
                        color="inherit"
                    >
                        Affecter
                    </Button>
                </Toolbar>

                <DialogContent className={classes.dialog}>
                    <FormControl className={classes.formControl} required>
                        <InputLabel shrink htmlFor="training">
                            Formation
                        </InputLabel>
                        <NativeSelect
                            onChange={this.changeTraining}
                            input={<Input name="training" />}
                            className={classes.selectEmpty}
                        >
                            <option value="">Choix</option>
                            {trainingList && trainingList.map((training) => (
                                <option key={training.id} value={training.id}>{training.title}</option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                    
                    <FormControl className={classes.formControl} required>
                        <InputLabel shrink htmlFor="session">
                            Session
                        </InputLabel>
                        <NativeSelect
                            onChange={this.selectSession}
                            input={<Input name="session" />}
                            className={classes.selectEmpty}
                        >
                            <option value="">Choix</option>
                            {sessionList && sessionList.map((session) => (
                                <option key={session.id} value={session.id}>{session.name}</option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                </DialogContent>
            </Dialog>
        );
    }
}

SessionDialogAffect.propTypes = {
    classes: PropTypes.object.isRequired,
    onAffect: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    studentId: PropTypes.number.isRequired,
};

export default withStyles(styles)(
    SessionDialogAffect
);
