export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  linearProgress: {
    height: theme.spacing(2),
    borderRadius: 5,
  },
  tableHead: {
    background: 'rgba(0, 0, 0, .02)',
  },
  denseTable: {
    '& td, & th': {
      padding: theme.spacing(.75),
      paddingRight: theme.spacing(.5),
      paddingLeft: theme.spacing(.5),
    }
  },
});
