import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    Tooltip,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './TopicHomeworks.css';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';

const sortableCells = [
    { name: 'id', label: '#' },
    { name: 'uploaded_at', label: 'Date d\'ajout' },
];

class TopicHomeworks extends Component {
    state = {}

    componentDidMount() {
        this.props.setData(this.props.data.homework);
    }

    componentDidUpdate(prevProps) {
        const { data: { homework } } = this.props;
        const { data: { homework: prevHomework } } = prevProps;
        if (homework.length !== prevHomework.length) {
            this.props.setData(homework);
        }
    }

    render() {
        const { classes, rows, renderSortCell, renderFilter } = this.props;
        return (
            <div className={classes.root}>
                <Toolbar className={classes.toolbar}>
                    <div className={classes.title}></div>
                    {renderFilter('Filtrer par étudiant', 'student.login')}
                </Toolbar>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {sortableCells.map(cell => renderSortCell(cell))}
                            <TableCell>Étudiant</TableCell>
                            <TableCell>Professeur</TableCell>
                            <TableCell align="center">Copie</TableCell>
                            <TableCell align="center">Correction</TableCell>
                            <TableCell align="center">Note</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(n => (
                            <TableRow key={n.id}>
                                <TableCell component="th" scope="row">
                                    {n.id}
                                </TableCell>
                                <TableCell>{format(new Date(n.uploaded_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                                <TableCell>
                                    {n.student ? `${n.student.first_name} ${n.student.last_name}` : 'Aucun(e)' }
                                </TableCell>
                                <TableCell>
                                    {n.teacher ? `${n.teacher.first_name} ${n.teacher.last_name}` : 'Aucun(e)' }
                                </TableCell>
                                <TableCell align="center">
                                    {n.url ? (
                                    <a
                                        href={`${process.env.REACT_APP_API_URL}/uploads/homeworks${n.url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                        src={`/icons/${n.extension}.png`}
                                        width="32"
                                        height="32"
                                        alt={n.extension}
                                        />
                                    </a>
                                    ) : (
                                    <Tooltip title="Sans copie">
                                        <ErrorIcon color="error" />
                                    </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    {n.corrige_url ? (
                                    <a
                                        href={`${process.env.REACT_APP_API_URL}/uploads/homeworks${n.corrige_url}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                        src={`/icons/${n.corrige_extension}.png`}
                                        width="32"
                                        height="32"
                                        alt={n.corrige_extension}
                                        />
                                    </a>
                                    ) : (
                                    <Tooltip title="Sans correction">
                                        <ErrorIcon color="error" />
                                    </Tooltip>
                                    )}
                                </TableCell>
                                <TableCell align="center">
                                    <Typography
                                        variant="subtitle2"
                                        color="secondary"
                                    >
                                        {n.note} / 20
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

TopicHomeworks.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
    ...styles(theme),
    ...tableStyles(theme),
}))(
    withLocalSortAndFilter(TopicHomeworks, 'name')
);
