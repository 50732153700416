import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  FormControlLabel,
  IconButton,
  InputBase,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import CloseIcon from '@material-ui/icons/Close';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import { formatAmount } from 'components/Orders/OrdersPayments';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Http from 'services/Http';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import endOfMonth from 'date-fns/endOfMonth';
import subDays from 'date-fns/subDays';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TransactionsSummary } from './TransactionsDialog';
import EuroIcon from '@material-ui/icons/Euro';
import AddIcon from '@material-ui/icons/AddCircle';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme) => tableStyles(theme));

const PaymentStateDialog = ({
  onClose,
  open,
  orderPaymentState,
  openSnackbar,
  onSuccess,
}) => {
  const classes = {
    ...useStyles(),
  };
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState();
  const formRef = useRef();
  const [showForm, setShowForm] = useState(true);
  const [sendMail, setSendMail] = useState(false);

  // useEffect(() => {
  //   if (orderPaymentState.sumOfValidTransactionsIsEqualToOrderTotal) {
  //     setShowForm(false);
  //   }
  // }, [orderPaymentState.sumOfValidTransactionsIsEqualToOrderTotal]);

  const computedPaymentState = orderPaymentState ?
    [
      ...orderPaymentState.paidTransactions.map(({ amount, transactionDetails }) => {
        return {
          amount,
          date: transactionDetails.cardDetails.expectedCaptureDate,
          status: 'paid',
        };
      }),
      ...orderPaymentState.installmentOptions.map((row) => {
        return {
          ...row,
          amount: row?.amount ?? 0,
          status: 'debt',
        }
      }),
    ] : [];

  const [payments, setPayments] = useState(computedPaymentState);
  const paidSum = (orderPaymentState?.paidTransactions ?? []).reduce((prev, current) => {
    return prev + current.amount;
  }, 0);

  const updateTotal = useCallback(() => {
    const form = document.getElementById('payments-form');
    if (form !== null) {
      const formData = new FormData(form);
      const total = [
        paidSum,
        ...Array.from(formData.getAll('amount')).map((amount) => {
          return parseFloat(amount.length === 0 ? 0 : amount) * 100;
        }),
      ].reduce((prev, current) => prev + current, 0);
      setTotal(total);
    }
  }, [paidSum]);

  useEffect(() => {
    updateTotal();
  }, [updateTotal, payments.length]);

  const initialSum = computedPaymentState.reduce((prev, current) => prev + current.amount, 0);
  useEffect(() => {
    setTotal(initialSum);
  }, [initialSum]);

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(document.getElementById('payments-form'));
    const customInstallmentOptions = formData.getAll('date').map((date, key) => {
      return {
        date: `${date}T12:00:00+00:00`,
        amount: parseFloat(formData.getAll('amount').at(key)) * 100,
      };
    });
    setLoading(true);
    const { data } = await Http.post('/payzen/getPaymentLink', {
      orderId: orderPaymentState.order.id,
      customInstallmentOptions,
      sendMail,
    });
    setLoading(false);
    if (data.errorMessage) {
      openSnackbar(`Payzen a répondu : ${data.errorMessage} ${data.detailedErrorMessage}`, 'error');
    } else {
      try {
        await navigator.clipboard.writeText(data.paymentURL);
        openSnackbar('Invitation copiée !', 'success');
      } catch (err) {
        openSnackbar(`Une erreur est survenue, voici quand même le lien souhaité : ${data.paymentURL}`, 'error');
      } finally {
        onSuccess?.();
        onClose();
      }
    }
  }

  if (!orderPaymentState) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="lg"
      fullWidth
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Liste des mensualités
        </Typography>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        {!showForm ? (
          <Box
            display="flex"
            style={{ gap: theme.spacing(2) }}
            flexDirection="column"
            my={2}
          >
            <Alert
              variant="filled"
              color="success"
            >
              <AlertTitle>Pas de dette</AlertTitle>
              <Button
                disableElevation
                color="secondary"
                variant="contained"
                onClick={() => setShowForm(true)}
              >
                Afficher quand même le formulaire</Button>
            </Alert>
            <TransactionsSummary transactions={orderPaymentState.transactions} />
          </Box>
        ) : (
          <Box
            display="flex"
            style={{ gap: theme.spacing(2) }}
            flexDirection="column"
            my={2}
          >
            <form
              onSubmit={onSubmit}
              id="payments-form"
              ref={formRef}
            >
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Mensualité n°</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Montant</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    payments.map(({ date, amount, status }, key) => {
                      const styles = {
                        color: status === 'paid' ? theme.palette.success.main : theme.palette.error.main,
                        fontWeight: status === 'paid' ? 'inherit' : 'bold',
                        fontStyle: status === 'paid' ? 'italic' : 'normal',
                      };
                      const interval = {
                        min: `${String(date).substring(0, 8)}01`,
                        max: formatInTimeZone(subDays(endOfMonth(new Date(date)), 1), 'Europe/Paris', 'yyyy-MM-dd'),
                      };

                      const isFirstDebtRow = key === payments.findIndex(({ status }) => status === 'debt');
                      const isLastDebtRow = key === payments.findLastIndex(({ status }) => status === 'debt');

                      return (
                        <TableRow key={date}>
                          <TableCell>
                            <Box
                              style={{
                                display: 'flex',
                                gap: theme.spacing(1),
                                alignItems: 'center',
                              }}
                            >
                              <ReceiptIcon style={styles} />
                              <Typography variant="h6" style={styles}>
                                {`${key + 1}`.padStart(2, '0')}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell style={styles}>
                            <Box
                              style={{
                                display: 'flex',
                                gap: theme.spacing(1),
                                alignItems: 'center',
                              }}
                            >
                              {status === 'paid' ? (
                                formatInTimeZone(date, 'Europe/Paris', 'dd/MM/yyyy HH:mm')
                              ) : (
                                <Fragment>
                                  <InputBase
                                    inputProps={{
                                      type: 'date',
                                      // this min/max was too restricticve for an admin mode
                                      // min: interval.min,
                                      // max: interval.max,
                                      required: true,
                                    }}
                                    name="date"
                                    classes={{
                                      root: classes.field,
                                      input: classes.fieldInput,
                                    }}
                                    defaultValue={String(date).substring(0, 10)}
                                  />
                                  <Typography variant="caption">
                                    {`(${formatInTimeZone(new Date(interval.min), 'Europe/Paris', 'dd/MM/yyyy')} - ${formatInTimeZone(new Date(interval.max), 'Europe/Paris', 'dd/MM/yyyy')})`}
                                  </Typography>
                                </Fragment>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell style={styles}>
                            <Box
                              style={{
                                display: 'flex',
                                gap: theme.spacing(1),
                                alignItems: 'center',
                              }}
                            >
                              {status === 'paid'
                                ? 'payé'
                                : status === 'debt'
                                  ? 'nouvelle mensualité'
                                  : 'nouvelle mensualité (ajout manuel)'
                              }
                              {status === 'paid' ? (
                                <CheckCircleIcon />
                              ) : (
                                <ErrorIcon />
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="right" style={styles}>
                            <Box
                              style={{
                                display: 'flex',
                                gap: theme.spacing(1),
                                alignItems: 'center',
                              }}
                            >
                              {status === 'paid'
                                ? formatAmount(amount / 100)
                                : (
                                  <Box
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: theme.spacing(1),
                                    }}
                                  >
                                    <InputBase
                                      onChange={() => {
                                        updateTotal();
                                      }}
                                      inputProps={{
                                        type: 'number',
                                        step: 1,
                                        min: 0,
                                        required: true,
                                      }}
                                      name="amount"
                                      classes={{
                                        root: classes.field,
                                        input: classes.fieldInput,
                                      }}
                                      defaultValue={amount / 100}
                                      style={{ width: 100 }}
                                    />
                                    <EuroIcon />
                                    <Tooltip title="Supprimer la dernière mensualité">
                                      <IconButton
                                        onClick={() => {
                                          setPayments((state) => {
                                            return state.filter((_, index) => index !== key);
                                          });
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                    <Fade in={!isFirstDebtRow}>
                                      <Tooltip title="Monter">
                                        <IconButton
                                          onClick={() => {
                                            setPayments((state) => {
                                              const [sourceIndex, targetIndex] = [key, key - 1];
                                              const [source, target] = [state.at(key), state.at(key - 1)];
                                              return state.map((element, k) => {
                                                if (sourceIndex === k) {
                                                  return target;
                                                } else if (targetIndex === k) {
                                                  return source;
                                                }
                                                return element;
                                              });
                                            });
                                          }}
                                        >
                                          <ArrowUpwardIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Fade>
                                    <Fade in={!isLastDebtRow}>
                                      <Tooltip title="Descendre">
                                        <IconButton
                                          onClick={() => {
                                            setPayments((state) => {
                                              const [sourceIndex, targetIndex] = [key, key + 1];
                                              const [source, target] = [state.at(key), state.at(key + 1)];
                                              return state.map((element, k) => {
                                                if (sourceIndex === k) {
                                                  return target;
                                                } else if (targetIndex === k) {
                                                  return source;
                                                }
                                                return element;
                                              });
                                            });
                                          }}
                                        >
                                          <ArrowDownwardIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Fade>
                                  </Box>
                                )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Box
                        style={{
                          display: 'flex',
                          gap: theme.spacing(1),
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="caption">Total</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: total === orderPaymentState.order?.total ?? 0 / 100 ? theme.palette.success.main : theme.palette.error.main,
                          }}
                        >
                          {`${formatAmount((total ?? 0) / 100)}`}
                        </Typography>
                        <Typography variant="caption">/</Typography>
                        <Typography
                          variant="body1"
                          style={{
                            color: theme.palette.success.main,
                          }}
                        >
                          {`${formatAmount(orderPaymentState.order?.total ?? 0 / 100)}`}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </form>
          </Box>
        )}

      </DialogContent>
      {showForm ? (
        <DialogActions>
          <Button
            disableElevation
            disabled={loading}
            variant="contained"
            color="primary"
            type="button"
            size="small"
            startIcon={<AddIcon />}
            onClick={() => {
              setPayments((state) => {
                return [
                  ...state,
                  {
                    amount: 0,
                    date: format(
                      addMonths(
                        new Date(payments.length > 0 ? payments.at(-1).date : computedPaymentState.at(-1).date),
                        1,
                      ),
                      'yyyy-MM-dd',
                    ),
                    status: 'debt',
                  },
                ];
              });
            }}
          >
            Ajouter une mensualité
          </Button>
          <Button
            disableElevation
            disabled={loading}
            variant="contained"
            color="secondary"
            type="submit"
            form="payments-form"
            size="small"
            startIcon={<PaymentIcon />}
          >
            Générer un lien de paiement
          </Button>
          <FormControlLabel
            label={sendMail
              ? `Envoi de mail à l'étudiant`
              : (<Typography style={{ textDecoration: 'line-through', opacity: .5 }}>Ne pas envoyer de mail</Typography>)
            }
            control={
              <Switch
                checked={sendMail}
                onChange={() => {
                  setSendMail(!sendMail);
                }}
              />
            }
          />
        </DialogActions>
      ) : null}
    </Dialog >
  )
};

export default PaymentStateDialog;
