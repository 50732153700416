import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormLabel,
  IconButton,
  InputBase,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Timetable/Timetable.css';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => tableStyles(theme));
const timetableStyles = makeStyles((theme) => styles(theme));

const CustomTeacherDialog = ({
  open,
  onClose,
  timetable,
  onSubmit,
}) => {
  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...timetableStyles(),
  };

  const onInternalSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    onSubmit(Object.fromEntries(formData.entries()));
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Nom de professeur personnalisé
        </Typography>
        <Box ml={2}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            disableElevation
            type="submit"
            form="edit-custom-teacher-form"
          >
            Enregistrer
          </Button>
        </Box>
      </Toolbar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <form onSubmit={onInternalSubmit} id="edit-custom-teacher-form">
          <input type="hidden" name="id" value={timetable?.id} />
          <Box
            display="flex"
            style={{ gap: theme.spacing(2) }}
            // alignItems="flex-start"
            flexDirection="column"
            px={2}
            py={3}
          >
            <Box
              display="flex"
              style={{ gap: theme.spacing(2), minWidth: '200px' }}
              alignItems="center"
            >
              <FormLabel style={{ minWidth: '200px' }}>Nom de professeur personnalisé</FormLabel>
              <InputBase
                inputProps={{
                  autoComplete: 'off',
                }}
                name="teacherCustomName"
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                placeholder="Nom"
                defaultValue={timetable?.teacher_custom_name ?? ''}
              />
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog >
  );
};

export default CustomTeacherDialog;
