import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
  Toolbar,
  Switch,
  FormControlLabel,
  Collapse,
} from '@material-ui/core';
import classNames from 'classnames';

import { tableStyles } from 'styles/datatable.css';
import { styles } from './Logs.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import { formatInTimeZone } from 'date-fns-tz';
import { JSONTree } from 'react-json-tree';
import { Alert } from '@material-ui/lab';

export const jsonTreeTheme = {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  base00: '#272822',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  base09: '#fd971f',
  base0A: '#f4bf75',
  base0B: '#a6e22e',
  base0C: '#a1efe4',
  base0D: '#66d9ef',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};

const sortableAndFilterableCells = [
  {
    cellInfo: { name: 'id', label: '#' },
    filtersInfo: [{ filterBy: 'id', placeholder: '' }],
  },
  {
    cellInfo: { name: 'date', label: 'Date' },
    filtersInfo: [
      { filterBy: 'dateFrom', placeholder: 'Début', type: 'datePicker' },
      { filterBy: 'dateTo', placeholder: 'Fin', type: 'datePicker' },
    ],
  },
  {
    cellInfo: { name: 'user', label: 'Utilisateur' },
    filtersInfo: [{ filterBy: 'user', placeholder: '' }],
  },
  {
    cellInfo: { name: 'log', label: 'Log' },
    filtersInfo: [{ filterBy: 'log', placeholder: '' }],
  },
];

class Logs extends Component {

  state = {
    loading: false,
    verbose: false,
  };

  renderLog = (logString) => {
    const { verbose } = this.state;
    const log = JSON.parse(logString);

    const suffix = log.metadata?.identifier ? ' (DETAIL)' : '';
    return (
      <Box
        p={1}
        display="flex"
        flexDirection="column"
        style={{ gap: '8px' }}
      >
        {log.method === 'DELETE' ? (
          <Alert severity="error">{`SUPPRESSION${suffix}`}</Alert>
        ) : null}
        {log.method === 'POST' ? (
          <Alert severity="warning">{`INSERTION/MAJ${suffix}`}</Alert>
        ) : null}
        {log.method === 'PUT' ? (
          <Alert severity="info">{`MAJ${suffix}`}</Alert>
        ) : null}
        {log.method === 'GET' ? (
          <Alert severity="success">{`LECTURE${suffix}`}</Alert>
        ) : null}
        <Collapse in={verbose}>
          <JSONTree
            data={log}
            invertTheme
            theme={jsonTreeTheme}
            shouldExpandNodeInitially={() => true}
            hideRoot
          />
        </Collapse>
      </Box>
    );
  };

  render() {
    const {
      classes,
      loading,
      renderPagination,
      renderSortableAndFilterable,
      rows,
    } = this.props;
    const { verbose } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        <Toolbar>
          <FormControlLabel
            label={verbose ? 'Afficher le détail des logs' : 'Masquer le détail des logs'}
            control={
              <Switch
                checked={verbose}
                onChange={() => {
                  this.setState((state) => {
                    console.log(state)
                    return {
                      verbose: !state.verbose,
                    };
                  });
                }}
              />
            }
          />
        </Toolbar>
        <Table size="small" className={classNames(classes.tableWithPadding, classes.table, classes.denseTable)}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {
                sortableAndFilterableCells
                  .map(({ cellInfo, filtersInfo }) => {
                    return renderSortableAndFilterable(cellInfo, filtersInfo);
                  })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, index) => {
              return (
                <TableRow key={r.id}>
                  <TableCell padding="checkbox">{r.id}</TableCell>
                  <TableCell>
                    {formatInTimeZone(r.date, 'Europe/Paris', 'dd/MM/yyyy HH:mm:ss')}
                  </TableCell>
                  <TableCell>{`${r.user.last_name} ${r.user.first_name}`}</TableCell>
                  <TableCell>{this.renderLog(r.log)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    )
  }
}

Logs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(Logs, 'logs', 'id', 'desc')
);
