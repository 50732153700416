import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormLabel,
  IconButton,
  InputBase,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Timetable/Timetable.css';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { Fragment, useEffect, useState } from 'react';
import TextEditor from 'components/TextEditor/TextEditor';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => tableStyles(theme));
const timetableStyles = makeStyles((theme) => styles(theme));

const EditDisciplineDialog = ({
  open,
  onClose,
  discipline,
  onSubmit,
  discipline_custom_name,
  description,
  role,
}) => {
  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...timetableStyles(),
  };

  const [html, setHtml] = useState();

  useEffect(() => {
    if (description) {
      setHtml(description);
    }
  }, [description]);

  const onInternalSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    if (!formData.has('description')) {
      formData.set('description', html ?? '');
    }
    onSubmit(Object.fromEntries(formData.entries()));
  };

  if (!discipline) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Éditer {discipline.name}
        </Typography>
        <Box ml={2}>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            disableElevation
            type="submit"
            form="edit-discipline-form"
          >
            Enregistrer
          </Button>
        </Box>
      </Toolbar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <form onSubmit={onInternalSubmit} id="edit-discipline-form">
          <Box
            display="flex"
            style={{ gap: theme.spacing(2) }}
            // alignItems="flex-start"
            flexDirection="column"
            px={2}
            py={3}
          >
            <input type="hidden" name="name" value={discipline.name} />
            <input type="hidden" name="id" value={discipline.id} />
            {role === 'teacher' ? (
              <input type="hidden" name="public_name" value={discipline.public_name ?? ''} />
            ) : (
              <Fragment>
                <Alert severity="warning">{`Attention : le nom public remplace le nom technique dans l'intégralité du calendrier pour ${discipline.name}`}</Alert>
                <Box
                  display="flex"
                  style={{ gap: theme.spacing(2), minWidth: '200px' }}
                  alignItems="center"
                >
                  <FormLabel style={{ minWidth: '200px' }}>Nom public</FormLabel>
                  <InputBase
                    inputProps={{
                      autoComplete: 'off',
                    }}
                    name="public_name"
                    classes={{
                      root: classes.field,
                      input: classes.fieldInput,
                    }}
                    placeholder="Nom public"
                    defaultValue={discipline.public_name ?? ''}
                  />
                </Box>
                <Divider />
              </Fragment>
            )}
            <Box
              display="flex"
              style={{ gap: theme.spacing(2) }}
              alignItems="center"
            >
              <FormLabel style={{ minWidth: '200px' }}>Nom public spécifique</FormLabel>
              <InputBase
                name="discipline_custom_name"
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                inputProps={{
                  autoComplete: 'off',
                }}
                placeholder="Nom public spécifique"
                defaultValue={discipline_custom_name ?? ''}
              />
            </Box>
            {role === 'teacher' ? (
              <input type="hidden" name="description" value={description ?? ''} />
            ) : (
              <Fragment>
                <Divider />
                <Box
                  display="flex"
                  style={{ gap: theme.spacing(2) }}
                  alignItems="flex-start"
                >
                  <FormLabel style={{ minWidth: '200px' }}>
                    Plus d'informations
                    <Tooltip
                      title="Ces détails n'apparaîtront qu'aux élèves ayant accès à cette matière"
                    >
                      <HelpIcon fontSize="small" style={{ marginLeft: theme.spacing(1) }} />
                    </Tooltip>
                  </FormLabel>
                  <TextEditor
                    onChange={(html) => {
                      setHtml(html);
                    }}
                    value={html ?? ''}
                  />
                </Box>
              </Fragment>
            )}
          </Box>
        </form>
      </DialogContent>
    </Dialog >
  );
};

export default EditDisciplineDialog;
