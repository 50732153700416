import {
  Box,
  Grid,
  Switch
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Http from 'services/Http';
import { styles } from './TeacherPayment.css';

const TeacherPayment = ({ data: { id, teacherInfos, teacherHomework } }) => {
  const width = 200;
  const { payment_mod = 'S' } = { ...teacherInfos }

  const paid_at = teacherHomework && Array.isArray(teacherHomework) && teacherHomework.length > 0 ? teacherHomework.at(0).paid_at : null;

  const [successSb, setSuccessSb] = useState({
    message: '',
    open: false,
  });
  const [errorSb, setErrorSb] = useState({
    message: '',
    open: false,
  });

  const [payMod, setPayMod] = useState(payment_mod);

  const handleSwitchChange = (e) => {
    const newPayMod = e.target.checked ? 'F' : 'S';
    setPayMod(newPayMod);
    setSuccessSb(p => ({
      ...p,
      message: 'Sauvegarde en cours',
      open: true,
    }));
    Http.put(`/users/teacher/info/${id}`, {
      payment_mod: newPayMod,
    })
      .then((data) => {
        setSuccessSb(p => ({
          ...p,
          message: 'Sauvegarde effectuée',
        }));
      }).catch((data) => {
        if ('statusText' in data) {
          setErrorSb(p => ({
            ...p,
            open: true,
            message: `Erreur ${data.statusText}`,
          }));
        }

      })
  }

  return (
    <Box textAlign="left" px={3} pt={3}>
      <SuccessSnackbar
        message={successSb.message}
        onClose={() => setSuccessSb(p => ({ ...p, open: false }))}
        open={successSb.open}
      />
      <ErrorSnackbar
        message={errorSb.message}
        onClose={() => setErrorSb(p => ({ ...p, open: false }))}
        open={errorSb.open}
      />
      <Box display="flex">
        <Box width={width} fontWeight={500}>Mode de paiement :</Box>
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item>S</Grid>
          <Grid item>
            <Switch
              checked={payMod !== 'S'}
              onChange={handleSwitchChange}
            />
          </Grid>
          <Grid item>F</Grid>
        </Grid>

      </Box>
      <Box display="flex">
        <Box width={width} fontWeight={500}>Dernier paiement :</Box>
        <Box style={{ textAlign: 'left' }}>{paid_at ? format(new Date(paid_at), 'dd/MM/yyyy') : 'Jamais'}</Box>
      </Box>
    </Box>
  );
}

TeacherPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeacherPayment);
