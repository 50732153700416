// module.exports because it has to be isomorphic
module.exports = (rules, role, action, params) => {
    const permissions = rules[role];
    if (!permissions) {
        return false;
    }

    const { statics, dynamics } = permissions;

    if (statics && statics.includes(action)) {
        return true;
    }

    if (dynamics) {
        const dynamicPermission = dynamics[action];

        if (!dynamicPermission) {
            return false;
        }

        return dynamicPermission(params);
    }

    return false;
};
