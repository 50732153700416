import { Box, Button, CircularProgress, FormControlLabel, IconButton, InputBase, Paper, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Toolbar, Typography, withStyles } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialogTs';
import { FilterButton, FilterButtonGroup } from 'components/Datagrid/FilterButtons';
import { formatAmount } from 'components/Orders/OrdersPayments';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Http from 'services/Http';
import { tableStyles } from 'styles/datatable.css';
import ExportMenu from './Parts/ExportMenu';
import { FrozenButton } from './Parts/FrozeButton';
import { useStyles } from './Proofreading.css';
import { theme } from 'theme';
import SaveIcon from '@material-ui/icons/Save';

export const formatThousand = (n: number) => new Intl.NumberFormat('fr-FR').format(n);

export const downloadBuffer = (buffer: AxiosResponse, filename: string) => {
  const url = URL.createObjectURL(new Blob([buffer.data]));
  const link = global.document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  global.document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};


export const exportXls = async (date: Date, teacher_id: number = 0) => {
  try {
    const buffer = await Http.post(
      '/proofreading/exportXls',
      {
        date,
        teacher_id,
      },
      {
        responseType: 'blob',
      },
    );
    downloadBuffer(buffer, `paiement-copies-${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`);
  } catch ({ data }) {
    throw data;
  }
};

type FreezeStates = Array<[id: number, total: number]>

const askForFreeze = async (freezeStates: FreezeStates) => {
  await Http.patch('/proofreading/freeze', {
    freezeStates,
  });
};

const sortableCells = [
  { name: 'last_name', label: 'Nom' },
  { name: 'first_name', label: 'Prénom' },
];
const sortableCells2 = [
  { name: 'total', label: 'Historique' },
  { name: 'to_be_payed', label: 'Encours' },
  { name: 'payment_copies_nb', label: 'Bloqué' },
];

type GetPendingPaymentsType = {
  count: number;
  rows: {
    teacher_id?: number
    payment_mod?: 'S' | 'F'
    total?: number
    to_be_payed?: number
    oldest_unpayed?: Date
    newest_unpayed?: Date
    price_per_copy?: number
    to_be_payed_price?: number
    first_name?: string
    last_name?: string
    email?: string
    payment_copies_nb?: number
    last_paid_at?: Date | null
    proofreading_comment: string | null
  }[]
}

type ProofreadingProps<T = GetPendingPaymentsType['rows']> = {
  filter: (newFilter: string, merge: boolean) => Promise<void>
  filterState: any
  renderSortCell: (cell: any) => React.ReactNode
  updateSortState: (sortBy: string, direction: 'asc' | 'desc') => void
  loading: boolean
  rows: T
  reload: () => void,
  renderPagination: () => React.ReactNode;
  classes: any;
}

const Proofreading = (props: ProofreadingProps) => {

  const classes = useStyles();

  const {
    classes: commonClasses,
    filter,
    filterState,
    // loading,
    // renderPagination,
    renderSortCell,
    // renderFilter,
    reload,
    // updateSortState,
    loading: propLoading,
    rows,
  } = props;

  const [confirmStates, setConfirmStates] = useState({
    id: 0,
    open: false,
    total: 0,
    max: 0,
    person: '',
  });

  const [paidIds, setPaidIds] = useState<number[]>([]);
  const [loading, setLoading] = useState(propLoading);
  const [onlyInteresting, setOnlyInteresting] = useState(true);

  const [snackbar, setSnackbar] = useState<{
    message?: React.ReactNode
    type: 'success' | 'error'
  }>({
    message: undefined,
    type: 'success',
  });

  const [fieldValues, setFieldValues] = useState({
    date: format(new Date(), 'yyyy-MM-dd'),
    comment: '',
  });

  useEffect(() => {
    setLoading(false);
    setPaidIds([]);
  }, [rows]);

  useEffect(() => {
    setLoading(propLoading);
  }, [propLoading]);

  const loadingStyle = { opacity: (loading || loading) ? .5 : 1 };
  const desiredRows = rows.filter(r => onlyInteresting === false || r.to_be_payed > 0);

  const handleFiger = (st: boolean) => async () => {

    setLoading(true);
    const freezeStates = [];
    desiredRows.forEach(({ teacher_id, to_be_payed, payment_copies_nb }) => {
      if (st === true) {
        if ((payment_copies_nb ?? 0) === 0) {
          freezeStates.push([teacher_id, to_be_payed]);
        }
      } else {
        if ((payment_copies_nb ?? 0) > 0) {
          freezeStates.push([teacher_id, 0]);
        }
      }
    });

    if (freezeStates.length) {
      await askForFreeze(freezeStates);
    }

    setLoading(false);
    reload();

  };

  const handleSingleFrozenClick = (id: number, total: number) => async (st: boolean) => {
    setLoading(true);
    const _total = st ? total : 0;
    await askForFreeze([[id, _total]]);
    reload();
    setLoading(false);
  };

  // const frozenStates = (allFrozenStates && typeof allFrozenStates === 'object' ? allFrozenStates[payment_mod] : []) as FrozeItem[];
  const frozenStates = desiredRows.map(({ teacher_id, payment_copies_nb }) => ({
    id: teacher_id,
    total: payment_copies_nb,
  }));

  const isFrozen = (id: number) => {
    return frozenStates.some(i => i.id === id && i.total > 0);
  };

  const hasFrozen = frozenStates.some(fs => fs.total > 0);
  const allFrozen = !desiredRows.some(fs => fs.payment_copies_nb === 0);

  const handleFieldChange: React.ChangeEventHandler = ({ target }) => {
    if (target && target instanceof HTMLInputElement) {
      const { name, value } = target;

      if (['date', 'comment'].includes(name)) {
        setFieldValues(p => ({
          ...p,
          [name]: value
        }));
      }

    }
  };

  const upConfirmStates = <T extends keyof typeof confirmStates>(key: T, val: (typeof confirmStates)[T]) => {
    setConfirmStates(p => ({
      ...p,
      [key]: val
    }));
  };

  const processPayment = () => {
    const {
      id,
      total
    } = confirmStates;
    upConfirmStates('open', false);

    if (id > 0 && total > 0) {
      setLoading(true);
      Http.patch<any, { data: { id: number } }>(`/proofreading/pay/${id}/${total}`, {
        ...fieldValues
      }).then(() => {
        // setPaidIds(p => [...p, id]);
      }).finally(() => {
        reload();
        setLoading(false);
      });
    }


  };

  const handlePayClick = (id: number, copyNb: number): React.MouseEventHandler => (e) => {

    const concerned = rows.find(r => r.teacher_id === id);

    if (concerned) {
      setConfirmStates(p => ({
        ...p,
        id,
        open: true,
        total: copyNb,
        max: copyNb,
        person: `${concerned.first_name} ${concerned.last_name}`
      }));

    }
  };

  const handleExcel = async (date: Date) => {
    // setLoading(true);
    setSnackbar(p => ({
      ...p,
      type: 'success',
      message: <><CircularProgress size={30} style={{ marginRight: '1em' }} /> Génération en cours à partir du {format(date, 'dd/MM/yy')}</>
    }));
    await exportXls(date).then(() => {
      setSnackbar(p => ({
        ...p,
        type: 'success',
        message: 'Fichier disponible'
      }));
    }).catch(e => {
      setSnackbar(p => ({
        ...p,
        type: 'error',
        message: `Erreur: ${e?.message}`
      }));
    }).finally(() => {
      // setLoading(false);
    });
  };

  const {
    paymentMod = 'S',
  } = { ...filterState };

  return <Paper style={loadingStyle}>
    <SuccessSnackbar
      message={snackbar.message ?? ''}
      onClose={() => setSnackbar(p => ({ ...p, message: undefined }))}
      open={snackbar.type === 'success' && snackbar.message !== undefined}
    />
    <ErrorSnackbar
      message={snackbar.message ?? ''}
      onClose={() => setSnackbar(p => ({ ...p, message: undefined }))}
      open={snackbar.type === 'error' && snackbar.message !== undefined}
    />
    <ConfirmationDialog
      open={confirmStates.open}
      title="Paiement"
      disableYes={confirmStates.total > confirmStates.max || confirmStates.total <= 0}
      message={<>
        <Box style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          margin: '2em',
          alignItems: 'center'
        }}>
          <Typography>Confirmez vous le paiement de</Typography>
          <TextField
            type='number'
            inputProps={{
              min: 0,
              max: confirmStates.max,
              style: { textAlign: 'center' }
            }}
            value={confirmStates.total}
            onChange={(e) => upConfirmStates('total', Number(e.target.value))}
          />
          <Typography>
            copies à {confirmStates.person} ?
          </Typography>
        </Box>
      </>}
      onClose={() => upConfirmStates('open', false)}
      onConfirm={() => { processPayment(); }}
    />
    <Toolbar>
      <Box display="flex" style={{ gap: '6px' }} pr={2}>
        <FilterButtonGroup
          updateSortState={(sortState: any[]) => {
            // updateSortState(...sortState);
          }}
          filter={(filterBy) => filter(filterBy, true)}
          selectedIndex={((filterState.paymentMod ?? 'S') === 'S' ? 0 : 1)}
        >
          <FilterButton filterBy={{ paymentMod: 'S' }}>Salaire</FilterButton>
          <FilterButton filterBy={{ paymentMod: 'F' }}>Facture</FilterButton>
        </FilterButtonGroup>
      </Box>
      <Box>
        <FormControlLabel
          label={onlyInteresting ? 'Uniquement les concernés' : 'Tous'}
          control={<Switch onChange={() => setOnlyInteresting(p => !p)} checked={onlyInteresting} />}
        />
      </Box>
      {paymentMod === 'S' ? <Box>
        <Button disabled={allFrozen} onClick={handleFiger(true)}>Tout bloquer</Button>
        <Button disabled={!hasFrozen} onClick={handleFiger(false)}>Tout libérer</Button>
      </Box> : undefined}
      <Box display="flex" flexGrow={1} />
      <Box style={{
        display: hasFrozen ? 'flex' : 'none',
        flexDirection: 'row',
        gap: '1em',
      }}>
        <FormControlLabel
          style={{ gap: '4px' }}
          label={'Infos comptables'}
          labelPlacement='start'
          control={<>
            <TextField type="text" placeholder='Commentaire' value={fieldValues.comment} name='comment' onChange={handleFieldChange} />
            {/* <TextField
              type='date'
              placeholder='Date'
              value={fieldValues.date} name='date' onChange={handleFieldChange} /> */}
          </>}
        />
      </Box>
      <Box style={{
        flexShrink: 1,
        marginLeft: '1em',
      }}>
        <ExportMenu onClick={(date) => handleExcel(date)} />
      </Box>
    </Toolbar>

    {/*<Table size="small" className={classNames(classes.table, classes.denseTable)}>*/}
    <Table>
      <TableHead>
        <TableRow>
          {sortableCells.map(cell => renderSortCell(cell))}
          <TableCell>Email</TableCell>
          {sortableCells2.filter(cell => paymentMod === 'S' || cell.name !== 'payment_copies_nb').map(cell => renderSortCell(cell))}
          <TableCell>Total</TableCell>
          <TableCell>Dernier Paiement</TableCell>
          <TableCell>Commentaire</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          desiredRows.map(({
            teacher_id,
            last_name,
            first_name,
            email,
            total,
            to_be_payed,
            payment_copies_nb,
            price_per_copy,
            last_paid_at,
            proofreading_comment,
          }) => {
            // const frozenState = frozenStates.find(fs => fs.id === teacher_id);

            const isPaid = paidIds.includes(teacher_id);

            const encours_amount = (paymentMod === 'S' ? payment_copies_nb : to_be_payed) * price_per_copy;

            return (
              <TableRow key={`${teacher_id}`
              }>
                <TableCell title={`#${teacher_id}`}>{last_name}</TableCell>
                <TableCell>{first_name}</TableCell>
                <TableCell>{email}</TableCell>
                <TableCell>{formatThousand(total)}</TableCell>
                <TableCell>{formatThousand(to_be_payed)}</TableCell>
                {paymentMod === 'S' ? <TableCell>{formatThousand(payment_copies_nb ?? 0)}</TableCell> : undefined}
                <TableCell
                  title={`Prix par copie ${formatAmount(price_per_copy)}`}
                >{formatAmount(encours_amount)}</TableCell>
                <TableCell>{last_paid_at !== null ? format(new Date(last_paid_at), 'dd/MM/yy') : 'Jamais'}</TableCell>
                <TableCell>
                  <form
                    onSubmit={async (event) => {
                      setLoading(true);
                      event.preventDefault();
                      const formData = new FormData(event.currentTarget);
                      formData.set('teacher_id', String(teacher_id));
                      try {
                        await Http.put('/proofreading/comment', Object.fromEntries(formData.entries()));
                        setSnackbar({
                          type: 'success',
                          message: 'Commentaire enregistré',
                        });
                      } catch (e) {
                        setSnackbar({
                          type: 'error',
                          message: 'Enregistrement du commentaire impossible',
                        });
                      }
                      setLoading(false);
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(1),
                      }}
                    >
                      <InputBase
                        name="proofreading_comment"
                        classes={{
                          root: commonClasses.field,
                          input: commonClasses.fieldInput,
                        }}
                        defaultValue={proofreading_comment ?? ''}
                        placeholder="Commentaire"
                        multiline
                      />
                      <IconButton
                        type="submit"
                      >
                        <SaveIcon />
                      </IconButton>
                    </Box>
                  </form>
                  {/* // <Tooltip title={proofreading_comment ?? ''}>
                  //   <Typography
                  //     style={{
                  //       fontSize: 'inherit',
                  //       whiteSpace: 'nowrap',
                  //       overflow: 'hidden',
                  //       textOverflow: 'ellipsis',
                  //       width: '100%',
                  //       maxWidth: '200px',
                  //     }}
                  //   >
                  //     {proofreading_comment}
                  //   </Typography>
                  // </Tooltip> */}
                </TableCell>
                <TableCell align='center'>
                  {paymentMod === 'S' ? <>
                    {isPaid ?
                      <Button
                        variant='contained'
                        disableRipple={true}
                        className={classes.buttonPayed}
                      >Payé</Button>
                      :
                      <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: '.5em'
                      }}>
                        {isFrozen(teacher_id) ?
                          <Button
                            variant='outlined'
                            color='primary'
                            // disabled={payCod !== 'none'}
                            onClick={handlePayClick(teacher_id, payment_copies_nb)}
                          >Payer</Button> : undefined
                        }
                        <FrozenButton
                          frozen={isFrozen(teacher_id)}
                          onClick={handleSingleFrozenClick(teacher_id, to_be_payed)}
                        />
                      </Box>}

                  </> : <>
                    <Button
                      variant='outlined'
                      color='secondary'
                      // disabled={payCod !== 'none'}
                      onClick={handlePayClick(teacher_id, to_be_payed)}
                    >Payer</Button>
                  </>}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
    {props.renderPagination()}
  </Paper>;

};

// @ts-expect-error 
export default withStyles(tableStyles)(
  withSortAndPagination(Proofreading, 'proofreading/pending', 'last_name', 'asc')
);