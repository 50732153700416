import Http from 'services/Http';

export const parseDates = (openDateDialog) => {
  const dates = {
    startDate: {
      dateString: undefined,
      hourMinutes: undefined,
    },
    endDate: {
      dateString: undefined,
      hourMinutes: undefined,
    },
  };

  if (openDateDialog) {
    dates.startDate = {
      dateString: openDateDialog?.startDate ? openDateDialog?.startDate.replace('T', ' ').split(' ').at(0) : undefined,
      hourMinutes: openDateDialog?.startDate ? openDateDialog?.startDate.replace('T', ' ').split(' ').at(1)?.substring(0, 5) : undefined,
    };
    dates.endDate = {
      dateString: openDateDialog?.endDate ? openDateDialog?.endDate.replace('T', ' ').split(' ').at(0) : dates.startDate?.dateString,
      hourMinutes: openDateDialog?.endDate ? openDateDialog?.endDate.replace('T', ' ').split(' ').at(1)?.substring(0, 5) : dates.startDate?.hourMinutes,
    };
  }
  return dates;
};

export const saveDate = async (openDateDialog) => {
  if (openDateDialog.id) {
    await Http.put(`/timetable/${openDateDialog.id}`, openDateDialog);
  } else {
    await Http.post(`/timetable`, openDateDialog);
  }
  return true;
};


export const hasAffectedVideos = (timetable) => {
  const { timetableDisciplines } = timetable;
  return (timetableDisciplines ?? []).length > 0 && timetableDisciplines.every(({ video_id }) => video_id !== null);
};

export const hasEveryDisciplineDescription = (timetable) => {
  const { timetableDisciplines } = timetable;
  return (timetableDisciplines ?? []).length > 0 && timetableDisciplines.every(({ description }) => {
    return description && description.length > 0;
  });
};

export const hasSomeDisciplineDescription = (timetable) => {
  const { timetableDisciplines } = timetable;
  return (timetableDisciplines ?? []).length > 0 && timetableDisciplines.some(({ description }) => {
    return description && description.length > 0;
  });
};

export const hasRegisteredTeacher = (timetable) => timetable?.teacher !== null;

export const hasCustomTeacher = (timetable) => (timetable?.teacher_custom_name ?? '').length > 0;

const toRGB = (s) => {
  var hash = 0;
  if (s.length === 0) return hash;
  for (let i = 0; i < s.length; i++) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  var rgb = [0, 0, 0];
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 255;
    rgb[i] = value;
  }
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};

export const getColorsFromText = (text, getContrastText) => {
  const backgroundColor = toRGB(text);
  return {
    backgroundColor,
    color: getContrastText(backgroundColor),
  };
};
