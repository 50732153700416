import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles as styles } from 'styles/datatable.css';
import {
  Box,
  Button,
  InputBase,
  Paper,
  Slider,
  Typography,
} from '@material-ui/core';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';

const videoViewMarks = [...Array(10).keys()].map(value => ({ value: value + 1, label: `${value + 1}` }));
const teacherDaysInfoMarks = [...Array(9).keys()].map(value => ({ value: value + 2, label: `${value + 2}` }));
const teacherDaysAlertMarks = max => [...Array(max).keys()].map(value => ({ value: value + 1, label: `${value + 1}` }));
class Settings extends Component {
  state = {
    error: '',
    openError: false,
    openSuccess: false,
    settings: null,
    success: '',
  };

  async componentDidMount() {
    const { data } = await Http.get('/settings');
    this.setState({
      settings: data,
    });
  }

  updateSetting = async ({ id, value }) => {
    try {
      await Http.put(`/settings/${id}`, { value });
      this.setState({
        success: 'Paramètre modifié avec succès',
        openSuccess: true,
        dialog: false,
        settings: Object.values(this.state.settings).reduce((prev, current) => {
          return {
            ...prev,
            [current.name]: {
              ...current,
              value: id === current.id ? value : current.value,
            },
          };
        }, {}),
      });

    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
        dialog: false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { error, openError, openSuccess, settings, success } = this.state;
    return (
      <Paper elevation={1} className={classes.root}>
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        {(settings && settings.max_video_views) ? (
          <Box display="flex" pt={7} pb={2} px={3}>
            <Box whiteSpace="nowrap" textAlign="left" pr={5} minWidth={350}>
              <Typography id="discrete-slider" gutterBottom>
                {settings.max_video_views.label}
              </Typography>
            </Box>
            <Box width={300}>
              <Slider
                defaultValue={parseInt(settings.max_video_views.value, 10)}
                getAriaValueText={value => `${value}`}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="on"
                step={1}
                min={1}
                max={10}
                marks={videoViewMarks}
                onChange={(_, value) => this.updateSetting({ id: settings.max_video_views.id, value })}
              />
            </Box>
          </Box>
        ) : null}
        {(settings && settings.teacher_days_info) ? (
          <Box display="flex" pt={7} pb={2} px={3}>
            <Box whiteSpace="nowrap" textAlign="left" pr={5} minWidth={350}>
              <Typography id="discrete-slider" gutterBottom>
                {settings.teacher_days_info.label}
              </Typography>
            </Box>
            <Box width={300}>
              <Slider
                defaultValue={parseInt(settings.teacher_days_info.value, 10)}
                getAriaValueText={value => `${value}`}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="on"
                step={1}
                min={2}
                max={10}
                marks={teacherDaysInfoMarks}
                onChange={(_, value) => this.updateSetting({ id: settings.teacher_days_info.id, value })}
              />
            </Box>
          </Box>
        ) : null}
        {(settings && settings.teacher_days_alert) ? (
          <Box display="flex" pt={7} pb={2} px={3}>
            <Box whiteSpace="nowrap" textAlign="left" pr={5} minWidth={350}>
              <Typography id="discrete-slider" gutterBottom>
                {settings.teacher_days_alert.label}
              </Typography>
            </Box>
            <Box width={300}>
              <Slider
                defaultValue={Math.min(
                  parseInt(settings.teacher_days_alert.value, 10),
                  parseInt(settings.teacher_days_info.value, 10) - 1
                )}
                getAriaValueText={value => `${value}`}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="on"
                step={1}
                min={1}
                max={parseInt(settings.teacher_days_info.value, 10) - 1}
                marks={teacherDaysAlertMarks(parseInt(settings.teacher_days_info.value, 10) - 1)}
                onChange={(_, value) => this.updateSetting({ id: settings.teacher_days_alert.id, value })}
              />
            </Box>
          </Box>
        ) : null}
        {(settings && settings.words_search && settings.words_replace) ? (
          <Box
            py={2}
            px={3}
          >
            <Box
              display="flex"
              style={{
                gap: '12px'
              }}
            >
              <Box flex={1} display="flex" flexDirection="column" alignItems="flex-start" style={{ gap: '6px' }}>
                <Typography>{settings.words_search.label}</Typography>
                <InputBase
                  style={{
                    width: '100%',
                    lineHeight: '3ch',
                    backgroundImage: 'linear-gradient(transparent, transparent calc(3ch - 1px), #ddd 0px)',
                    backgroundSize: '100% 3ch',
                  }}
                  name="words_search"
                  classes={{
                    root: classes.field,
                    input: classes.fieldInput,
                  }}
                  defaultValue={settings.words_search.value}
                  placeholder={settings.words_search.label}
                  multiline
                  inputProps={{
                    style: { textAlign: 'right' },
                    spellcheck: 'false',
                  }}
                />
              </Box>
              <Box flex={1} display="flex" flexDirection="column" alignItems="flex-start" style={{ gap: '6px' }}>
                <Typography>{settings.words_replace.label}</Typography>
                <InputBase
                  style={{
                    width: '100%',
                    lineHeight: '3ch',
                    backgroundImage: 'linear-gradient(transparent, transparent calc(3ch - 1px), #ddd 0px)',
                    backgroundSize: '100% 3ch',
                  }}
                  name="words_replace"
                  classes={{
                    root: classes.field,
                    input: classes.fieldInput,
                  }}
                  defaultValue={settings.words_replace.value}
                  placeholder={settings.words_replace.label}
                  multiline
                  inputProps={{
                    spellcheck: 'false',
                  }}
                />
              </Box>

            </Box>
            <Box mt={2}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  const words_search = document.querySelector('textarea[name="words_search"]').value;
                  const words_replace = document.querySelector('textarea[name="words_replace"]').value;
                  this.updateSetting({
                    id: settings.words_search.id,
                    value: words_search,
                  });
                  this.updateSetting({
                    id: settings.words_replace.id,
                    value: words_replace,
                  });
                }}
              >
                Enregister les mots
              </Button>
            </Box>

          </Box>
        ) : null}
        {settings && settings.video_categories ?
          <Box display="flex" pt={7} pb={2} px={3} width={"30rem"}>
            <Box flex={1} display="flex" flexDirection="column" alignItems="flex-start" style={{
              gap: '6px'
            }}>
              <Typography>{settings.video_categories.label}</Typography>
              <Typography variant='caption'>Une ligne par catégorie|couleur fond|couleur text</Typography>
              <InputBase
                style={{
                  width: '100%',
                  lineHeight: '3ch',
                  backgroundImage: 'linear-gradient(transparent, transparent calc(3ch - 1px), #ddd 0px)',
                  backgroundSize: '100% 3ch',
                }}
                name="video_categories"
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                defaultValue={settings.video_categories.value}
                placeholder={settings.video_categories.label}
                multiline
                inputProps={{
                  style: { textAlign: 'right' },
                  spellcheck: 'false',
                }}
              />
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => {
                  const value = document.querySelector('textarea[name="video_categories"]').value;
                  this.updateSetting({
                    id: settings.video_categories.id,
                    value,
                  });
                }}
              >
                Enregister les catégories
              </Button>
            </Box>
          </Box>

          : undefined}
      </Paper>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
