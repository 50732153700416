export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  smallCard: {
    padding: `${theme.spacing(1)}px`,
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  draggable: {
    cursor: 'grab',
  },
  grey: {
    backgroundColor: theme.palette.grey[300],
  },
});
