import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogContent, DialogActions, Typography, withStyles, Card, CardHeader, CardContent, withTheme, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import Http from 'services/Http';
import { Skeleton } from '@material-ui/lab';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ErrorSnackbar from 'components/Snackbar/Error';

export const ordersInvoiceConfig = [
  { key: 'fact_objet', title: 'Objet de la formation' },
  { key: 'fact_session', title: 'Session' },
  { key: 'fact_annee_scolaire', title: 'Année scolaire' },
];

class OrdersInvoiceCard extends Component {
  render() {
    const { handler, order, title, icon, invoiceOrCertificate, fileType } = this.props;

    return (
      <Card>
        <CardHeader
          title={
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: '8px' }}
              mb={1}
            >
              {icon}
              <Typography>{title}{fileType ? ` (${fileType})` : ''}</Typography>
            </Box>
          }
          style={{ paddingBottom: 0, paddingTop: '8px' }}
        />
        <CardContent style={{ paddingTop: 0 }}>
          <Box
            display="flex"
            style={{
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handler(invoiceOrCertificate, undefined, fileType)}
            >
              Document complet
            </Button>
            {order.orderItems.length > 1
              ? order.orderItems.map(({ id, product_name }) => {
                return (
                  <Button
                    key={`${encodeURIComponent(title)}-${id}`}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handler(invoiceOrCertificate, id, fileType)}
                  >
                    {product_name}
                  </Button>
                );
              }) : null}
          </Box>
        </CardContent>
      </Card>
    );
  }
}

class OrdersInvoice extends Component {

  state = {
    loadingPreview: false,
    order: null,
    blob: null,
    openError: false,
    errorMessage: '',
    fileType: 'pdf',
  }

  async getOrder() {
    const orderId = this.props.order.id;
    const { data } = await Http.get(`/orders/${orderId}`);
    this.setState({ order: data });
  }

  componentDidMount() {
    this.getOrder();
  }

  downloadBuffer = (buffer, filename) => {
    const url = URL.createObjectURL(new Blob([buffer.data]));
    const link = global.document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    global.document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  exportInvoice = async (orderId, orderItemId, invoiceOrCertificate, fileType) => {
    this.setState({
      errorMessage: '',
      openError: false,
    });
    try {
      const buffer = await Http.post(
        '/orders/exportInvoice',
        { orderId, orderItemId, invoiceOrCertificate, fileType },
        { responseType: 'blob' },
      );
      return buffer;
    } catch (e) {
      // Convert blob to string then parse json
      const { message } = JSON.parse(await e.data.text());
      this.setState({
        errorMessage: `Erreur API : ${message}`,
        openError: true,
      });
      throw e;
    }
  };

  onDownload = (invoiceOrCertificate, orderItemId, fileType) => async (event) => {
    event.preventDefault();
    const orderId = this.props.order.id;
    try {
      const buffer = await this.exportInvoice(orderId, orderItemId, invoiceOrCertificate, fileType);
      const { data: fileName } = await Http.post('/orders/exportInvoiceFilename', { orderId, invoiceOrCertificate, fileType });
      this.downloadBuffer(buffer, fileName);
    } catch (e) { }
  }

  onPreview = (invoiceOrCertificate, orderItemId) => async () => {
    this.setState({ loadingPreview: true, blob: null });
    const orderId = this.props.order.id;
    try {
      const buffer = await this.exportInvoice(orderId, orderItemId, invoiceOrCertificate);
      this.setState({
        loadingPreview: false,
        blob: URL.createObjectURL(
          new Blob([buffer.data], {
            type: 'application/pdf',
          }),
        ),
      });
    } catch (e) { }
  };

  render() {
    const { fileType, openError, errorMessage, blob, loadingPreview, order } = this.state;

    if (!order) {
      return (
        <Box p={2}>
          <Typography>Chargement...</Typography>
        </Box>
      );
    }
    const { close, theme } = this.props;
    return (
      <Fragment>
        <ErrorSnackbar
          message={errorMessage}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <DialogContent
          style={{
            backgroundColor: theme.palette.background.default,
            paddingTop: theme.spacing(2),
          }}
        >
          <Box
            display="flex"
            style={{
              flexDirection: 'row',
              gap: theme.spacing(2),
              minHeight: '75vh',
            }}
          >
            <Box
              display="flex"
              style={{
                flexDirection: 'column',
                gap: theme.spacing(2),
              }}
            >

              <Box>
                <FormControl
                  component="div"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <FormLabel component="legend">Format</FormLabel>
                  <RadioGroup
                    aria-label="format"
                    name="fileType"
                    value={fileType}
                    onChange={(event) => {
                      this.setState({fileType: event.target.value });
                    }} 
                    style={{ flexDirection: 'row' }}
                  >
                    <FormControlLabel labelPlacement="start" value="pdf" control={<Radio />} label="pdf" />
                    <FormControlLabel labelPlacement="start" value="word" control={<Radio />} label="word" />
                  </RadioGroup>
                </FormControl>
              </Box>

              <OrdersInvoiceCard
                invoiceOrCertificate="invoice"
                handler={this.onDownload}
                order={order}
                title="Télécharger une facture"
                icon={<CloudDownloadIcon />}
                fileType={fileType}
              />
              <OrdersInvoiceCard
                invoiceOrCertificate="invoice"
                handler={this.onPreview}
                order={order}
                title="Prévisualiser une facture"
                icon={<VisibilityIcon />}
              />
              <OrdersInvoiceCard
                invoiceOrCertificate="certificate"
                handler={this.onDownload}
                order={order}
                title="Télécharger un certificat de scolarité"
                icon={<CloudDownloadIcon />}
                fileType={fileType}
              />
              <OrdersInvoiceCard
                invoiceOrCertificate="certificate"
                handler={this.onPreview}
                order={order}
                title="Prévisualiser un certificat de scolarité"
                icon={<VisibilityIcon />}
              />
            </Box>
            <Box flex="1.25">
              {loadingPreview ? (
                <Skeleton
                  variant="rect"
                  width="100%"
                  height="99%"
                />
              ) : blob ? (
                <iframe
                  type="application/pdf"
                  src={blob}
                  title="preview pdf"
                  style={{
                    width: '100%',
                    height: '99%',
                    border: '1px solid #aaa',
                  }}
                />
              ) : (
                <Box
                  style={{
                    width: '100%',
                    height: '99%',
                    border: '1px solid #aaa',
                  }}
                  width="100%"
                  height="99%"
                />
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {blob ? (
            <Button
              size="small"
              variant="outlined"
              onClick={() => this.setState({ blob: null })}
            >
              Fermer la preview
            </Button>
          ) : null}
          <Button
            size="small"
            variant="outlined"
            onClick={close}
          >
            Retour
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

OrdersInvoice.propTypes = {
  close: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default withTheme(withStyles((theme) => ({
  ...tableStyles(theme),
}))(
  OrdersInvoice
));
