export const styles = theme => ({
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    error: {
        backgroundColor: 'red',
    },
    success: {
        backgroundColor: 'green',
    },
    icon: {
        marginRight: theme.spacing(2),
    }
});