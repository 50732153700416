import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, InputBase, Paper, Slider, Toolbar, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Timetable/Timetable.css';
import CloseIcon from '@material-ui/icons/Close';
import DateTimeInput from './DateTimeInput';

const useStyles = makeStyles((theme) => tableStyles(theme));
const timetableStyles = makeStyles((theme) => styles(theme));

const CloneDialog = ({
  open,
  onClose,
  timetableIds,
  onSave,
}) => {
  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...timetableStyles(),
  };
  const [numberOfClones, setNumberOfClones] = useState();
  const [minutesToAdd, setMinutesToAdd] = useState(0);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Cloner une ligne de l'emploi du temps
        </Typography>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent>
        <Box
          style={{
            display: 'flex',
            gap: theme.spacing(2),
            flexDirection: 'column',
          }}
        >
          <Box style={{
            margin: theme.spacing(3),
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(4),
            gap :'30px',
            alignItems: 'center',
            display: 'flex',
          }}>
            <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1) }}>
              <Typography>Minutes</Typography>
              <InputBase
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                inputProps={{
                  type: 'number',
                  size: 3,
                  min: 0,
                  max: 1140,
                }}
                defaultValue="0"
                onChange={evt => setMinutesToAdd(parseInt(evt.currentTarget.value, 10))}
                placeholder="Minutes"
              />
            </Box>
            <Slider
              valueLabelDisplay="on"
              onChange={(_, value) => {
                setNumberOfClones(value);
              }}
              value={numberOfClones ?? 1}
              step={1}
              marks={Array(12).fill(true).map((_, n) => {
                return {
                  value: n + 1,
                  label: `${n + 1} clone${n > 0 ? 's' : ''}`,
                };
              })}
              max={12}
              min={1}
            />
          </Box>
          <form id="date-clones-form">
            <Box
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
              }}
            >

              {Array(numberOfClones ?? 1).fill(true).map((_, cloneIndex) => {
                return (
                  <Paper
                    key={cloneIndex}
                    style={{
                      padding: `${theme.spacing(2)}px`,
                      backgroundColor: theme.palette.background.default,
                      flex: '0 1 30%',
                    }}
                  >
                    <Typography variant="h6">{`Clone n°${cloneIndex + 1}`}</Typography>
                    <DateTimeInput
                      onStartChange={({ hour, minutes }, { setHourEnd, setMinutesEnd }) => {
                        const addMinutes = minutesToAdd % 60;
                        const addHours = Math.floor(minutesToAdd / 60);
                        setHourEnd(hour + addHours);
                        setMinutesEnd(minutes + addMinutes);
                      }}
                    />
                  </Paper>
                )
              })}
            </Box>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            const formData = new FormData(document.getElementById('date-clones-form'));
            const startDates = formData.getAll('startDate').filter(Boolean);
            const endDates = formData.getAll('endDate').filter(Boolean);
            const dates = startDates.map((startDate, index) => {
              return { startDate, endDate: endDates.at(index) };
            })
            onSave({
              timetableIds,
              numberOfClones: dates.length,
              dates,
            });
          }}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default CloneDialog;
