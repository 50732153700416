import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import classNames from 'classnames';
import { JwtContext } from '../../App';
import { utcToZonedTime } from 'date-fns-tz';

import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import { styles } from './StudentComments.css';
import { tableStyles } from 'styles/datatable.css';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import StudentCommentsDialog from './StudentCommentsDialog';
import Http from 'services/Http';
import isEqual from 'lodash.isequal';

const sortableCells = [
  { name: 'id', label: 'id' },
];

const initialState = () => {
  return {
    error: '',
    openError: false,
    openSuccess: false,
    success: '',
    loading: false,
    openDialog: false,
    comment: null,
  };
};

class StudentComments extends Component {
  state = initialState();

  async componentDidMount() {
    this.setState({ loading: true });
    this.props.setData(this.props.data.studentComments);
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps) {
    const { data: { studentComments } } = this.props;
    const { data: { studentComments: prevStudentComments } } = prevProps;
    if (isEqual(studentComments, prevStudentComments) === false) {
        this.props.setData(this.props.data.studentComments);
    }
}

  onSubmit = async (updatedOrNewComment) => {
    await Http.post('/users/insertOrUpdateComment', updatedOrNewComment);
    this.closeDialog();
    this.props.reload();
  };

  closeDialog = () => {
    this.setState({
      openDialog: false,
      comment: null,
    });
  }

  render() {
    const {
      classes,
      renderSortCell,
      rows,
    } = this.props;
    const {
      comment,
      loading,
      openDialog,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };
    return (
      <JwtContext.Consumer>
        {(user) => {
          return (
            <Paper elevation={0} className={classes.root} style={loadingStyle}>
              <StudentCommentsDialog
                open={openDialog}
                onClose={this.closeDialog}
                onSubmit={this.onSubmit}
                comment={comment}
              />
              <Box
                m={3}
                style={{
                  position: 'fixed',
                  bottom: 0,
                  right: 0,
                  zIndex: 1200,
                }}
              >
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={() => {
                      this.setState({
                        openDialog: true,
                        comment: {
                          id: undefined,
                          commenter_id: user.id,
                          user_id: this.props.data.id,
                        },
                      });
                    }}
                >
                    Ajouter un commentaire
                </Button>
              </Box>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    {sortableCells.map(cell => renderSortCell(cell))}
                    <TableCell style={{ width: '95%' }}>Commentaire</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((p) => {
                    const canEdit = user.id === p.commenter.id;
                    return (
                      <TableRow className={classes.noTableBorder} key={`${p.id}`}>
                        <TableCell>{p.id}</TableCell>
                        <TableCell>
                          <Box className={classes.messageContainer}>
                            <Box
                              onClick={() => {
                                this.setState({
                                  openDialog: true,
                                  comment: {
                                    id: p.id,
                                    comment: p.comment,
                                    commenter_id: user.id,
                                    user_id: p.user.id,
                                  },
                                });
                              }}
                              className={
                                classNames({
                                  [classes.shared]: true,
                                  [classes.sent]: user.id === p.commenter.id,
                                  [classes.received]: user.id !== p.commenter.id,
                                })
                              }
                              style={{ cursor: canEdit ? 'zoom-in' : 'not-allowed' }}
                              dangerouslySetInnerHTML={{ __html: p.comment.replaceAll(/\n/mg, '<br />') }}
                            />
                          </Box>
                          <Box
                            display="flex"
                            justifyContent={user.id === p.commenter.id ? 'flex-end' : 'flex-start'}
                            maxWidth="95%"
                            style={{ gap: '6px', opacity: .5 }}
                          >
                            <Typography variant="caption">{`${p.commenter.first_name} ${p.commenter.last_name}`}</Typography>
                            <Typography variant="caption">- {format(utcToZonedTime(p.date, 'Etc/UTC'), 'dd MMM yyyy HH:mm:ss', { locale: frLocale })}</Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          );
        }}
      </JwtContext.Consumer>

    );
  }
}

StudentComments.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default withStyles((theme) => {
  return {
    ...styles(theme),
    ...tableStyles(theme),
  }
})(
  withLocalSortAndFilter(StudentComments, 'id')
);
