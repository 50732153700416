import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Toolbar,
    Typography,
    withMobileDialog,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './TagDialogAffect.css';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';

class TagDialogAffect extends Component {
    state = {
        error: '',
        openError: false,
        success: '',
        openSuccess: false,
        selectedPodcasts: [],
        tagsWithCount: [],
    }

    computeState() {
      const { selectedIds } = this.props;
      this.getTags(selectedIds);
    }

    componentDidMount() {
      this.computeState();
    }

    async getTags(selectedIds) {
        const { data: { rows: podcasts } } = await Http.get(`/podcasts/?ids=${selectedIds}&offset=0&limit=${selectedIds.length}`);
        const selectedPodcasts = podcasts.map(podcast => ({ id: podcast.id, name: podcast.name }));
        const total = podcasts.length;
        const tagsWithCount = this.props.tags.map(tag => {
            const count = podcasts.reduce((prev, { tags }) => prev + (tags.find(t => t.tag_id === tag.id) === undefined ? 0 : 1), 0);
            return {
                ...tag,
                label: `${count} / ${total}`,
                color: count === 0 ? 'secondary' : (count === total ? 'primary' : 'default'),
                variant: count !== 0 && count !== total ? 'outlined' : 'default',
            };
        });

        this.setState({
            selectedPodcasts,
            tagsWithCount,
        });
    }

    onCloseDialog = () => {
        this.props.onClose();
    }

    onTagUnassign = async (id, name) => {
        try {
            await Http.delete(
                `/podcasts/tags/${id}`,
                { data: this.props.selectedIds },
            );
            await this.props.reload();
            this.setState({
                success: `Le tag "${name}" a été supprimé des podcasts sélectionnés`,
                openSuccess: true,
            });
            this.computeState();
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
            });
        }
    }

    onTagAssign = async (id, name) => {
        try {
            await Http.put(
                `/podcasts/tags/${id}`,
                this.props.selectedIds,
            );
            await this.props.reload();
            this.setState({
                success: `Le tag "${name}" a été ajouté aux podcasts sélectionnés`,
                openSuccess: true,
            });
            this.computeState();
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
            });
        }
    }

    render() {
        const {
            classes,
            fullScreen,
            open,
        } = this.props;
        const {
            error,
            openError,
            openSuccess,
            success,
            tagsWithCount,
            selectedPodcasts,
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.onCloseDialog}
                fullScreen={fullScreen}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <ErrorSnackbar
                    message={error}
                    onClose={() => this.setState({ openError: false })}
                    open={openError}
                />
                <SuccessSnackbar
                    message={success}
                    onClose={() => this.setState({ openSuccess: false })}
                    open={openSuccess}
                />

                <Toolbar className={classes.toolbar}>
                    <IconButton
                        className={classes.closeButton}
                        edge="start"
                        color="inherit"
                        onClick={this.onCloseDialog}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        color="inherit"
                        variant="h6"
                        className={classes.title}
                    >
                        Affecter les tags aux {selectedPodcasts.length} podcasts
                    </Typography>
                </Toolbar>

                <DialogContent className={classes.dialog}>
                    {tagsWithCount.map(({ color, id, label, name, variant }) => (
                        <Box mb={1} key={id}>
                            <Box display="inline-block" className={classes.label}>
                                {name}
                            </Box>
                            <Chip
                                color={color}
                                label={label}
                                size="small"
                                variant={variant}
                            />
                            <Button
                                className={classes.button}
                                variant="outlined"
                                size="small"
                                onClick={() => this.onTagAssign(id, name)}
                            >   
                                Affecter à tous
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => this.onTagUnassign(id, name)}
                            >
                                Enlever à tous
                            </Button>
                        </Box>
                    ))}
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <Box className={classes.chips}>
                        {selectedPodcasts.map(({ id, name }) => (
                            <Chip key={id} className={classes.chip} size="small" label={name} />
                        ))}
                    </Box>
                </DialogActions>
            </Dialog>
        );
    }
}

TagDialogAffect.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    selectedIds: PropTypes.array.isRequired,
};

export default withMobileDialog()(withStyles(styles)(
    TagDialogAffect
));
