import React, { Component, createContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import 'react-datepicker/dist/react-datepicker.css';

import Dashboard from 'components/Dashboard/Dashboard';
import { theme } from './theme';
import './App.css';

export const JwtContext = createContext(null);

class App extends Component {
  state = {
    jwtData: null,
  };
  
  setJwtData = (jwtData) => {
    this.setState({ jwtData });
  };

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <JwtContext.Provider value={this.state.jwtData}>
            <div className="App">
              <Dashboard setJwtData={this.setJwtData}></Dashboard>
            </div>
          </JwtContext.Provider>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
