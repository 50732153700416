import { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, withTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import addHours from 'date-fns/addHours';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import isSameYear from 'date-fns/isSameYear';
import CalendarCustomEventWrapper from './CalendarCustomEventWrapper';

const CalendarCustomDateCellWrapper = ({
  children,
  onClick,
  value,
  events,
  theme,
  isAdmin,
  view,
}) => {
  const [viewAll, setViewAll] = useState(false);
  const isSameMonthYearDay = (currentDate, date) => {
    return isSameDay(currentDate, date)
      && isSameMonth(currentDate, date)
      && isSameYear(currentDate, date);
  };

  const dateCellEvents = events.filter(({ data }) => {
    return isSameMonthYearDay(
      new Date(data.start_date.replace('Z', '')),
      value,
    );
  });

  // could be better with toSorted but lack of browser compatiblity
  dateCellEvents.sort((a, b) => {
    return `${a.start.getTime()}`.localeCompare(`${b.start.getTime()}`);
  });

  const style = {
    display: 'flex',
    flex: 1,
    borderLeft: '1px solid #DDD',
    position: 'relative',
  };

  return (
    <div style={style}>
      <Dialog
        open={viewAll}
        onClose={() => setViewAll(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Affichage de {dateCellEvents.length} entrées</DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            {dateCellEvents.map((event) => {
              return (
                <CalendarCustomEventWrapper
                  key={event.id}
                  onClick={onClick}
                  event={event}
                />
              )
            })}
          </Box>
        </DialogContent>
      </Dialog>

      {children}
      {dateCellEvents.length > (view === 'month' ? 4 : 0) ? (
        <Box
          style={view === 'month' ? {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            zIndex: 1000,
            background: 'rgba(255, 255, 255, .9)',
            padding: theme.spacing(1),
          } : {
            position: 'absolute',
            right: 5,
            bottom: 5,
            zIndex: 1000,
          }}
        >
          <Button
            disableElevation
            fullWidth
            variant="contained"
            size="small"
            onClick={() => setViewAll(true)}
            startIcon={<VisibilityIcon />}
          >
            {`Afficher (${dateCellEvents.length})`}
          </Button>
        </Box>
      ) : null}
      {isAdmin ? (
        <Box
          style={{
            position: 'absolute',
            top: -5,
            left: 0,
            zIndex: 100,
          }}
        >
          <Tooltip title="Créer une entrée">
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                onClick({
                  data: {
                    start_date: addHours(value, 10).toISOString(),
                    end_date: addHours(value, 11).toISOString(),
                  },
                }, 'openDateDialog');
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : null}
    </div>
  )
};

export default withTheme(CalendarCustomDateCellWrapper);
