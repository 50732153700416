import React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';

const Pagination = ({ count, limit, page, paginate }) => (
    <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
        component="div"
        labelRowsPerPage="Lignes par page"
        labelDisplayedRows={({ from, to, count }) => `${from} à ${to} sur ${count}`}
        count={count}
        onPageChange={(event, changePage) => { paginate(changePage, false); }}
        page={page}
        rowsPerPage={limit}
        onRowsPerPageChange={(event) => { paginate(0, event.target.value); }}
    />
);

Pagination.propTypes = {
    count: PropTypes.number,
    limit: PropTypes.number,
    page: PropTypes.number,
    paginate: PropTypes.func,
};

Pagination.defaultProps = {
    count: 0,
    limit: 0,
    page: 0,
    paginate: (event, page) => console.log(event, page),
};

export default Pagination;
