import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, FormControlLabel, IconButton, InputBase, NativeSelect, Switch, Toolbar, Typography, makeStyles, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import Http from 'services/Http';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Timetable/Timetable.css';
import CloseIcon from '@material-ui/icons/Close';
import { JSONTree } from 'react-json-tree';
import { jsonTreeTheme } from 'components/Logs/Logs';

const useStyles = makeStyles((theme) => tableStyles(theme));
const timetableStyles = makeStyles((theme) => styles(theme));

const ZoomDialog = ({
  open,
  onClose,
  zoomUser,
  meetingId,
  onSave,
  defaultTopic,
}) => {
  const theme = useTheme();
  const classes = {
    ...useStyles(),
    ...timetableStyles(),
  };
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();
  const [meeting, setMeeting] = useState();
  const [topic, setTopic] = useState(defaultTopic);
  const [verbose, setVerbose] = useState(false);

  const loading = users.length === 0;

  useEffect(() => {
    const loadUsers = async () => {
      const { data } = await Http.get('/timetable/zoomListUsers');
      setUsers(data.users.sort((a, b) => {
        return a.display_name.localeCompare(b.display_name);
      }));
    };
    loadUsers();
  }, []);

  useEffect(() => {
    if (meetingId) {
      const loadMeeting = async () => {
        const { data } = await Http.get(`/timetable/zoomGetMeeting/${meetingId}`);
        setMeeting(data);
        setTopic(data?.topic ?? '')
      };
      loadMeeting();
    }
  }, [meetingId]);

  useEffect(() => {
    if (users.length > 0) {
      setUser(zoomUser);
    }
  }, [zoomUser, users.length]);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Conférence zoom
        </Typography>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent>
        <Box
          style={{
            display: 'flex',
            gap: theme.spacing(2),
            flexDirection: 'column',
            opacity: loading ? .25 : 1,
          }}
        >
          {meetingId ? (
            <FormControlLabel
              label={verbose ? 'Afficher la réponse API' : 'Masquer la réponse API'}
              control={
                <Switch
                  checked={verbose}
                  onChange={() => {
                    setVerbose(!verbose);
                  }}
                />
              }
            />
          ) : null}
          {meetingId && meeting ? (
            <Collapse in={verbose}>
              <JSONTree
                data={meeting}
                invertTheme
                theme={jsonTreeTheme}
                shouldExpandNodeInitially={() => false}
                hideRoot
              />
            </Collapse>
          ) : null}
          <Box
            style={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <Typography style={{ width: 200 }}>Utilisateur zoom</Typography>
            <NativeSelect
              style={{
                width: '100%',
              }}
              value={user ?? '0'}
              onChange={(event) => {
                setUser(event.target.value);
              }}
              name={`zoomUserId`}
            >
              <option value="0">Utilisateur zoom</option>
              {users.map(({ display_name, email, id }) => {
                return (
                  <option
                    key={id}
                    value={id}
                  >
                    {`${display_name} ${email}`}
                  </option>
                );
              })}
            </NativeSelect>
          </Box>
          <Box
            style={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <Typography style={{ width: 200 }}>Sujet</Typography>
            <InputBase
              style={{ width: '100%' }}
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              placeholder="Sujet"
              value={topic ?? 'Réunion Zoom'}
              onChange={(event) => {
                setTopic(event.target.value);
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!user || !topic}
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => {
            onSave({
              zoomUser: user,
              topic,
            });
          }}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );

};

export default ZoomDialog;
