import { Box, Dialog, DialogTitle, makeStyles } from '@material-ui/core';
import { styles } from 'components/Timetable/Timetable.css';

const timetableStyles = makeStyles((theme) => styles(theme));

const VideoPreview = ({
  onClose,
  embed,
}) => {
  const classes = timetableStyles();
  return (
    <Dialog
      open
      onClose={onClose}
    >
      <DialogTitle>Aperçu vidéo</DialogTitle>
      <Box px={3} pb={3}>
        <div
          className={classes.landscapeContainer}
          dangerouslySetInnerHTML={{ __html: embed }}
        />
      </Box>
    </Dialog>
  );
}

export default VideoPreview;
