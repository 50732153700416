import { Button, MenuItem, Menu as MuiMenu } from '@material-ui/core';
import { useState } from 'react';

const Menu = ({
  id = 'vmenu',
  title = '',
  items = [],
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        aria-controls={id}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {title.length ? title : 'Ouvrir'}
      </Button>
      <MuiMenu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item) => {
          return (
            <MenuItem
              key={item.label}
              onClick={() => {
                item.onClick();
                handleClose();
              }}>
                {item.label}
              </MenuItem>
          );
        })}
      </MuiMenu>
    </div>
  );
}

export default Menu;
