export default function Kbd({ children,
  style = undefined
}) {
  return <kbd style={{
    backgroundColor: '#eee',
    borderRadius: '3px',
    border: '1px solid #b4b4b4',
    boxShadow: `0 1px 1px rgba(0, 0, 0, 0.2),
    0 .25em 0 0 rgba(255, 255, 255, 0.7) inset`,
    color: '#333',
    display: 'inline-block',
    fontSize: '0.85em',
    fontWeight: '700',
    lineHeight: '1',
    padding: '.25em .5em',
    // marginLeft: '.5em',
    whiteSpace: 'nowrap',
    '&>kbd': {
      borderRadius: '.3em',
      padding: '1px 2px 0',
      border: '1px solid black',
    },
    ...style,

  }}>{children}</kbd>
}