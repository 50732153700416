import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import SchoolIcon from '@material-ui/icons/School';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import { styles } from './SessionHome.css';

class SessionHome extends Component {

    render() {
        const { classes, data, showTab } = this.props;

        return (
            <Box textAlign="left" px={3} pt={3}>
                <div className={classes.dashboard}>
                    <div className={classes.dashboardItem} onClick={() => showTab(1)}>
                        <FaceIcon fontSize="inherit" />
                        <Typography variant="h5">Étudiants</Typography>
                        <Typography variant="h4" color="primary">{data.students.length}</Typography>
                    </div>
                    <div className={classes.dashboardItem} onClick={() => showTab(2)}>
                        <SchoolIcon fontSize="inherit" />
                        <Typography variant="h5">Matières</Typography>
                        <Typography variant="h4" color="primary">{data.training ? data.training.disciplines.length : 0}</Typography>
                        <Typography variant="caption" className={classes.caption}>tronc commun</Typography>
                    </div>
                    <div className={classes.dashboardItem} onClick={() => showTab(3)}>
                        <SchoolIcon fontSize="inherit" />
                        <Typography variant="h5">Matières</Typography>
                        <Typography variant="h4" color="primary">{data.disciplines.length}</Typography>
                        <Typography variant="caption" className={classes.caption}>custom</Typography>
                    </div>
                </div>
                <div className={classes.buttons}>
                    <ButtonRouter
                        className={classes.spacedButton}
                        title="Retour"
                        size="small"
                        color="secondary"
                        variant="contained"
                        label="Retour"
                        to={'/sessions'}
                        disableElevation
                    />
                </div>
            </Box>
        );
    }
}

SessionHome.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    showTab: PropTypes.func.isRequired,
};

export default withStyles(styles)(SessionHome);
