import { Button } from '@material-ui/core';

export const FrozenButton = ({
  frozen = false,
  onClick = (isFrozen: boolean) => { },
}) => {

  const handleClick = () => {
    onClick(!frozen);
  };

  return <Button
    onClick={handleClick}
    color={frozen ? 'secondary' : 'default'}
  >
    {frozen ? 'Libérer' : 'Bloquer'}
  </Button>;
};