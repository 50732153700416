import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Fade,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import UploadDialog from 'components/UploadDialog/UploadDialog';
import TopicEditDialog from 'components/TopicEditDialog/TopicEditDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import FilterInput from 'components/Datagrid/FilterInput';
import IconButtonRouter from 'components/IconButtonRouter/IconButtonRouter';
import Http from 'services/Http';
import DateDialog from 'components/Dialogs/DateDialog';
import TopicHierarchyDialog from 'components/TopicHierarchyDialog/TopicHierarchyDialog';
import { utcToZonedTime } from 'date-fns-tz';
import isPast from 'date-fns/isPast';
import { Alert } from '@material-ui/lab';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import DisciplineDialogAffectRadio from 'components/DisciplineDialogAffectRadio/DisciplineDialogAffectRadio';

const sortableCells = [
  { name: 'id', label: '#' },
  { name: 'name', label: 'Nom' },
  { name: 'uploaded_at', label: 'Date d\'ajout' },
];

const isPastDate = (dateStringOrUndefined) => {
  return dateStringOrUndefined ? isPast(new Date(dateStringOrUndefined)) : false;
};

class Topics extends Component {
  state = {
    error: '',
    openError: false,
    success: '',
    openSuccess: false,
    topicId: null,
    topic: null,
    disciplineDialog: false,
    deleteTopicDialog: false,
    selectAll: false,
    selectedIds: [],
    editTopicDialog: false,
    uploadCorrigeTypeDialog: false,
    deleteCorrigeTypeDialog: false,
    limitDateDialog: null,
    topicHierarchyDialog: null,
    deleteHierarchyDialog: null,
    deleteLimitDateDialog: null,
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? this.props.rows.map(({ id }) => String(id)) : [];
    this.setState({ selectAll: checked, selectedIds });
  }

  onSelectOne = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
      selectedIds.push(value);
    } else {
      const index = selectedIds.indexOf(value);
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({ selectedIds });
  }

  // DIALOGS
  closeDisciplineDialog = () => {
    this.setState({
      disciplineDialog: false,
      topicId: null,
    });
  }

  // CRUD
  deleteCorrigeType = async () => {
    if (this.state.topicId) {
      try {
        await Http.delete(`/topics/corrigeType/${this.state.topicId}`);
        this.setState({
          success: 'Le corrigé type a été supprimé',
          openSuccess: true,
          deleteCorrigeTypeDialog: false,
          topicId: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteCorrigeTypeDialog: false,
          topicId: null,
        });
      }
    }
  };

  deleteTopic = async () => {
    if (this.state.topicId) {
      try {
        await Http.delete(`/topics/${this.state.topicId}`);
        this.setState({
          success: 'Le sujet a été supprimé',
          openSuccess: true,
          deleteTopicDialog: false,
          topicId: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteTopicDialog: false,
          topicId: null,
        });
      }
    }
  }

  deleteHierarchy = async () => {
    if (this.state.deleteHierarchyDialog) {
      const { discipline } = this.state.deleteHierarchyDialog;
      try {
        await Http.delete(`/topics/deleteHierarchy/${discipline}`);
        this.setState({
          success: 'La hiérarchie a été supprimée',
          openSuccess: true,
          deleteHierarchyDialog: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteHierarchyDialog: null,
        });
      }
    }
  };

  saveTopic = () => {
    const success = this.state.topic ? 'Sujet mis à jour' : 'Sujet créé';
    this.setState({
      success,
      openSuccess: true,
      editTopicDialog: false,
      topic: null,
    });
    this.props.reload();
  }

  uploadCorrigeTypeCompleted = () => {
    this.setState({
      success: 'Corrigé type déposé sur le serveur',
      openSuccess: true,
      uploadCorrigeTypeDialog: false,
      topicId: null,
    });
    this.props.reload();
  }

  affectDisciplines = async ({ disciplineIds: discipline_ids }) => {
    const { selectedIds, topicId } = this.state;
    const topic_ids = topicId ? [topicId] : selectedIds;
    try {
      await Http.put('/topics', { discipline_ids, topic_ids });
      this.setState({
        success: 'Les affectations de matière ont été mises à jour',
        openSuccess: true,
        disciplineDialog: false,
        topicId: null,
        selectedIds: [],
      });
      this.props.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
        disciplineDialog: false,
        topicId: null,
      });
    }
  }

  bulkUpdateLimitDate = async (selectedIds, date) => {
    const promises = selectedIds.map((id) => {
      return Http.put('/topics/updateLimitDate', {
        id,
        date,
      });
    });
    await Promise.all(promises);
    this.setState({
      limitDateDialog: null,
      deleteLimitDateDialog: null,
    });
    this.props.reload();
  };

  render() {
    const {
      classes,
      filter,
      filterState,
      loading,
      renderPagination,
      renderSortCell,
      renderFilter,
      rows,
    } = this.props;
    const {
      deleteTopicDialog,
      disciplineDialog,
      error,
      openError,
      openSuccess,
      selectAll,
      selectedIds,
      success,
      topicId,
      topic,
      editTopicDialog,
      uploadCorrigeTypeDialog,
      deleteCorrigeTypeDialog,
      limitDateDialog,
      topicHierarchyDialog,
      deleteHierarchyDialog,
      deleteLimitDateDialog,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        <TopicHierarchyDialog
          open={topicHierarchyDialog !== null}
          discipline={topicHierarchyDialog?.discipline ?? undefined}
          onClose={() => {
            this.setState({ topicHierarchyDialog: null });
          }}
          onSave={() => this.props.reload()}
        />
        <DateDialog
          dateString={limitDateDialog?.date}
          onClose={() => {
            this.setState({ limitDateDialog: null });
          }}
          onConfirm={(date) => {
            this.bulkUpdateLimitDate(limitDateDialog.id ? [limitDateDialog.id] : limitDateDialog.selectedIds, date);
          }}
          open={limitDateDialog !== null}
          yesDateFormatString='E dd/MM/yyyy'
          title="Date limite (journée incluse)"
          description={limitDateDialog?.selectedIds?.length
            ? (
              <Alert severity="info">
                {`Cette modification portera sur ${limitDateDialog?.selectedIds?.length} sujet${limitDateDialog?.selectedIds?.length > 1 ? 's' : ''}`}
              </Alert>
            ) : null
          }
          fieldLabel="Date : "
        />
        <ConfirmationDialog
          open={deleteLimitDateDialog !== null}
          title="Attention"
          message={deleteLimitDateDialog?.id
            ? "Êtes-vous sûr de vouloir supprimer cette date ?"
            : deleteLimitDateDialog?.selectedIds
              ? `Êtes-vous sûr de vouloir supprimer cette date ? Cette modification portera sur ${deleteLimitDateDialog?.selectedIds?.length} sujet${deleteLimitDateDialog?.selectedIds?.length > 1 ? 's' : ''}`
              : ''}
          onClose={() => this.setState({
            deleteLimitDateDialog: null,
          })}
          onConfirm={() => this.bulkUpdateLimitDate(deleteLimitDateDialog?.id ? [deleteLimitDateDialog?.id] : deleteLimitDateDialog?.selectedIds, null)}
        />
        <ConfirmationDialog
          open={deleteHierarchyDialog !== null}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer cette hiérarchie ?"
          onClose={() => this.setState({
            deleteHierarchyDialog: null,
          })}
          onConfirm={this.deleteHierarchy}
        />
        <ConfirmationDialog
          open={deleteCorrigeTypeDialog}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer ce corrigé type ?"
          onClose={() => this.setState({
            deleteCorrigeTypeDialog: false,
            topicId: null,
          })}
          onConfirm={this.deleteCorrigeType}
        />
        <ConfirmationDialog
          open={deleteTopicDialog}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer ce sujet ?"
          onClose={() => this.setState({
            deleteTopicDialog: false,
            topicId: null,
          })}
          onConfirm={this.deleteTopic}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <Fade in={selectedIds.length > 0}>
            <Box
              display="flex"
              style={{ gap: '16px' }}
            >
              <Badge color="primary" badgeContent={selectedIds.length}>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={() => this.setState({ disciplineDialog: true })}
                >
                  Affecter aux matières
                </Button>
              </Badge>
              <Badge color="primary" badgeContent={selectedIds.length}>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    this.setState({
                      limitDateDialog: {
                        selectedIds,
                      },
                    });
                  }}
                >
                  Ajouter une date limite
                </Button>
              </Badge>
              <Badge color="primary" badgeContent={selectedIds.length}>
                <Button
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    this.setState({
                      deleteLimitDateDialog: {
                        selectedIds,
                      },
                    });
                  }}
                >
                  Supprimer une date limite
                </Button>
              </Badge>
            </Box>
          </Fade>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => this.setState({
                editTopicDialog: true,
                topicId: null,
              })}
            >
              Ajouter un sujet
            </Button>
          </Box>
          {disciplineDialog ? (
            <DisciplineDialogAffectRadio
              multiple
              selectedIds={selectedIds}
              onClose={this.closeDisciplineDialog}
              onAffect={this.affectDisciplines}
              open
            />
          ) : null}
          <TopicEditDialog
            open={editTopicDialog}
            onClose={() => this.setState({
              editTopicDialog: false,
              topic: null,
            })}
            onSave={this.saveTopic}
            topic={topic}
          />
          <UploadDialog
            context="topics"
            open={uploadCorrigeTypeDialog}
            onClose={() => this.setState({
              uploadCorrigeTypeDialog: false,
              topicId: null,
            })}
            onUploadCompleted={this.uploadCorrigeTypeCompleted}
            singleUpload
            endpoint="upload-corrige-type"
            params={{ id: topicId }}
          />
          <FilterInput
            filter={filter}
            filterBy="discipline"
            placeholder="Filter par matière"
            defaultValue={filterState.discipline}
          />
          {renderFilter('Filtrer par nom')}
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left"><Checkbox onChange={this.onSelectAllClick} checked={selectAll} /></TableCell>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell>Date limite</TableCell>
              <TableCell>Hiérarchie</TableCell>
              <TableCell>Fichier</TableCell>
              <TableCell>Corrigé type</TableCell>
              <TableCell>Note ISP</TableCell>
              <TableCell>Matière</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(t => (
              <TableRow
                key={t.id}
                style={{
                  background: isPastDate(t.limit_date) ? 'rgba(255, 0, 0, .05)' : 'inherit'
                }}
              >
                <TableCell>
                  <Checkbox
                    value={String(t.id)}
                    onChange={this.onSelectOne}
                    checked={selectedIds.includes(String(t.id))} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {t.id}
                </TableCell>
                <TableCell>
                  {t.name || 'Aucune'}
                </TableCell>
                <TableCell>{format(utcToZonedTime(t.uploaded_at, 'Etc/UTC'), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                <TableCell>
                  {t.limit_date ? (
                    <Box display="flex" alignItems="center" style={{ gap: '2px' }}>
                      <Button
                        variant="outlined"
                        size="small"
                        endIcon={<EditIcon fontSize="small" />}
                        onClick={() => {
                          this.setState({
                            limitDateDialog: {
                              id: t.id,
                              date: t.limit_date,
                            },
                          });
                        }}
                      >
                        <Typography variant="caption">
                          {format(utcToZonedTime(t.limit_date, 'Etc/UTC'), 'dd/MM/yyyy', { locale: frLocale })}
                        </Typography>
                      </Button>
                      <IconButton
                        size="small"
                        onClick={() => {
                          this.setState({
                            deleteLimitDateDialog: {
                              id: t.id,
                            },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <IconButton
                      size="small"
                      disableElevation
                      onClick={() => {
                        this.setState({
                          limitDateDialog: {
                            id: t.id,
                          },
                        });
                      }}
                    >
                      <CalendarIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{ gap: '2px' }}
                  >
                    {!t.discipline ? (
                      <Typography
                        variant="caption"
                        color="error"
                      >
                        Pas de matière
                      </Typography>
                    ) : t.topicHierarchy !== null && t.topicHierarchy !== undefined ? (
                      <Fragment>
                        {/* <IconButton
                          disabled={!t.discipline}
                          size="small"
                          onClick={() => {
                            this.setState({
                              topicHierarchyDialog: {
                                discipline: t.discipline.name,
                              },
                            });
                          }}
                        >
                          <EditIcon />
                        </IconButton> */}
                        <Button
                          variant="outlined"
                          disabled={!t.discipline}
                          size="small"
                          onClick={() => {
                            this.setState({
                              topicHierarchyDialog: {
                                discipline: t.discipline.name,
                              },
                            });
                          }}
                          startIcon={<FitnessCenterIcon />}
                          endIcon={<EditIcon />}
                        >
                          {`${t.topicHierarchy.topic_weight + 1}.${t.topicHierarchy.group_weight + 1}`}
                        </Button>
                        <IconButton
                          size="small"
                          onClick={() => this.setState({
                            deleteHierarchyDialog: {
                              discipline: t.discipline.name,
                            },
                          })}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Fragment>
                    ) : (
                      <IconButton
                        disabled={!t.discipline}
                        onClick={() => {
                          this.setState({
                            topicHierarchyDialog: {
                              discipline: t.discipline.name,
                            },
                          });
                        }}
                      >
                        <AddIcon size="small" />
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  {t.url ? (
                    <a
                      href={`${process.env.REACT_APP_API_URL}/uploads/topics${t.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`/icons/${t.extension}.png`}
                        width="32"
                        height="32"
                        alt={t.extension}
                      />
                    </a>
                  ) : 'Aucun'}
                </TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{ gap: '2px' }}
                  >
                    {t.corrige_type_url ? (
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <a
                          href={`${process.env.REACT_APP_API_URL}/uploads/topics${t.corrige_type_url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`/icons/${t.corrige_type_extension}.png`}
                            width="32"
                            height="32"
                            alt={t.corrige_type_extension}
                          />
                        </a>
                        <IconButton
                          color="secondary"
                          size="small"
                          onClick={() => this.setState({
                            deleteCorrigeTypeDialog: true,
                            topicId: t.id,
                          })}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Tooltip title="Ajouter un corrigé type">
                        <IconButton
                          size="small"
                          onClick={() => this.setState({
                            uploadCorrigeTypeDialog: true,
                            topicId: t.id,
                          })}
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </TableCell>
                <TableCell>{t.ispAverage !== null && `${t.ispAverage} / 20`}</TableCell>
                <TableCell>{t.discipline ? t.discipline.name : 'Aucune'}</TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{ gap: '2px' }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      disableElevation
                      onClick={() => this.setState({
                        disciplineDialog: true,
                        topicId: t.id,
                      })}
                    >
                      Matière
                    </Button>
                    <IconButtonRouter
                      size="small"
                      to={`/topics/${t.id}`}
                    >
                      <AssignmentIcon />
                    </IconButtonRouter>
                    <IconButton
                      size="small"
                      onClick={() => this.setState({
                        editTopicDialog: true,
                        topic: t,
                      })}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => this.setState({
                        deleteTopicDialog: true,
                        topicId: t.id,
                      })}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

Topics.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(tableStyles)(
  withSortAndPagination(Topics, 'topics', 'uploaded_at', 'desc')
);
