import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Polycopies/Polycopies.css';
import Http from 'services/Http';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => tableStyles(theme));
const polycopiesStyles = makeStyles((theme) => styles(theme));

const maxLengths = {
  address1: 35,
  address2: 35,
  student_city: 38,
  student_first_name: 29,
  student_last_name: 35,
};
const AddressInput = ({ value, label, keyName, setAddresses, studentId }) => {
  const classes = {
    ...useStyles(),
    ...polycopiesStyles(),
  };
  const theme = useTheme();
  return (
    <Box>
      <InputBase
        style={{
          width: '100%',
          flex: 1,
          border: `1px solid ${value.length > maxLengths[keyName] ? theme.palette.error.main : theme.palette.success.main}`,
        }}
        classes={{
          root: classes.field,
          input: classes.fieldInput,
        }}
        value={value}
        placeholder={label}
        onChange={(event) => {
          setAddresses((state) => {
            return state.map((student) => {
              return (student.student_id === studentId)
                ? {
                  ...student,
                  [`${keyName}`]: event.target.value,
                } : student;
            });
          })
        }}
      />
      <Typography
        variant="caption"
        style={{
          color: value.length > maxLengths[keyName] ? theme.palette.error.main : theme.palette.success.main,
          display: 'flex',
          gap: theme.spacing(1),
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {`${value.length} / ${maxLengths[keyName]}`}
        {value.length > maxLengths[keyName] ? <ThumbDownIcon fontSize="inherit" /> : <ThumbUpIcon fontSize="inherit" />}
      </Typography>
    </Box>
  );
};

const InvalidAddressesDialog = ({
  onClose,
  open,
  invalidAddresses,
  onSuccess,
}) => {

  const classes = {
    ...useStyles(),
    ...polycopiesStyles(),
  };
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState(invalidAddresses);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await Http.put(`/users/bulkUpdateStudentShippingAddresses`, addresses);
      onSuccess();
      onClose();
    } catch (e) {
      console.log(e)
    }
    setLoading(false);
  };

  const loadingStyle = { opacity: loading ? .5 : 1 };

  if (!invalidAddresses || addresses.length === 0) {
    return null;
  }

  const valid = addresses.every((student) => {
    return Object.keys(maxLengths).every((key) => {
      return student[key].length <= maxLengths[key];
    });
  });

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="lg"
      fullWidth
      style={loadingStyle}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Adresses en erreur
        </Typography>
        <Box>
          <Button
            color="secondary"
            variant="contained"
            disabled={!valid}
            onClick={onSubmit}
          >
            Enregistrer et exporter
          </Button>
        </Box>
      </Toolbar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <Box>
          <Alert severity="info">
            Attention il y a des données trop longues incompatibles avec Coliship.
          </Alert>
        </Box>
        <Box
          display="flex"
          style={{ gap: theme.spacing(2) }}
          flexDirection="column"
          my={2}
        >
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Prénom</TableCell>
                <TableCell>Adresse 1</TableCell>
                <TableCell>Adresse 2</TableCell>
                <TableCell>Ville</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invalidAddresses.map(({ student_id }) => {
                const { address1, address2, student_city, student_last_name, student_first_name } = addresses.find(({ student_id: id }) => student_id === id) //?? { address1: '--', address2: '--' };
                return (
                  <TableRow key={student_id}>
                    <TableCell>
                      <AddressInput
                        value={student_last_name}
                        label="Nom"
                        keyName="student_last_name"
                        setAddresses={setAddresses}
                        studentId={student_id}
                      />
                    </TableCell>
                    <TableCell>
                      <AddressInput
                        value={student_first_name}
                        label="Prénom"
                        keyName="student_first_name"
                        setAddresses={setAddresses}
                        studentId={student_id}
                      />
                    </TableCell>
                    <TableCell>
                      <AddressInput
                        value={address1}
                        label="Adresse 1"
                        keyName="address1"
                        setAddresses={setAddresses}
                        studentId={student_id}
                      />
                    </TableCell>
                    <TableCell>
                      <AddressInput
                        value={address2}
                        label="Adresse 2"
                        keyName="address2"
                        setAddresses={setAddresses}
                        studentId={student_id}
                      />
                    </TableCell>
                    <TableCell>
                      <AddressInput
                        value={student_city}
                        label="Ville"
                        keyName="student_city"
                        setAddresses={setAddresses}
                        studentId={student_id}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </Dialog>
  )
};

export default InvalidAddressesDialog;
