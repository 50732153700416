import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slider,
    Typography,
    withMobileDialog,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './TopicDialogNote.css';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';

const NoteCard = ({ classes, title, note, color }) => (
    <Card className={classes.card}>
        <CardContent>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <Typography className={classes.cardNote} color={color}>
                    {title}
                </Typography>
                <Typography className={classes.cardNote} color={color}>
                    {note} / 20
                </Typography>
            </Box>
        </CardContent>
    </Card>
);

const createMarks = [...Array(21).keys()].map(value => ({ value, label: `${value}` }));

class TopicDialogNote extends React.Component {
    state = {
        error: [],
        openError: false,
        ispAverage: 0,
    }

    resetState() {
        this.setState({
            error: [],
            openError: false,
            ispAverage: 0,
        });
    }

    save = async () => {
        const { id } = this.props.topic;
        const { ispAverage } = this.state;
        try {
            await Http.put(`/topics/updateOneAverage/${id}`, { ispAverage });
            this.resetState();
            this.props.onClose();
            this.props.onSuccess(ispAverage ? 'Note mise jour' : 'La note est invisible côté étudiant');
        } catch ({ data }) {
            this.setState({
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                openError: true,
            });
        }
    }

    closeDialog = () => {
        this.resetState();
        this.props.onClose();
    }

    render() {
        const { classes, topic } = this.props;
        const { error, openError } = this.state;

        return (
            <Dialog
                open
                onClose={this.closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle id="alert-dialog-title">Notes</DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        component="div"
                    >
                        <Box display="flex" flexDirection="row">
                            <NoteCard color="error" note={topic.min} classes={classes} title="Minimale" />
                            <NoteCard color="primary" note={topic.max} classes={classes} title="Maximale" />
                            <NoteCard color="textPrimary" note={topic.average} classes={classes} title="Moyenne" />
                        </Box>
                    </DialogContentText>
                    <DialogContentText component="div">
                        <Box display="flex" flexDirection="row">
                            <Card className={classNames([classes.card, classes.padding])}>
                                <CardContent>
                                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                        <Typography variant="h5" className={classes.cardNote} color="textSecondary" gutterBottom>
                                            Pondération ISP
                                        </Typography>
                                        <Slider
                                            defaultValue={parseFloat(topic.ispAverage || 0)}
                                            getAriaValueText={value => `${value}`}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="on"
                                            step={0.5}
                                            min={0}
                                            max={20}
                                            marks={createMarks}
                                            onChange={(_, ispAverage) => this.setState({ ispAverage })}
                                        />
                                        <Typography variant="caption" className={classes.cardNote} color="textSecondary">
                                            Une note de 0/20 signifie que la moyenne réelle sera affichée aux élèves, et non celle surchargée par ISP. 
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ErrorSnackbar
                        open={openError}
                        onClose={() => this.setState({ openError: false })}
                        message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
                    />
                    <Button onClick={this.closeDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={this.save} color="primary">
                        Sauvegarder
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

TopicDialogNote.propTypes = {
    topic: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

TopicDialogNote.defaultProps = {
    topic: {},
    onClose: () => {},
    onSuccess: () => {},
};

export default withMobileDialog()(withStyles(styles)(
    TopicDialogNote
));
