import { Box, Fade, IconButton, InputBase, Typography } from '@material-ui/core';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ReactDatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { forwardRef, useEffect, useState, useRef } from 'react';
import { tableStyles } from 'styles/datatable.css';
import { makeStyles } from '@material-ui/core/styles';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import fr from 'date-fns/locale/fr';
import isValid from 'date-fns/isValid';
import { Alert } from '@material-ui/lab';
import { differenceInSeconds, format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { formatInTimeZone } from 'date-fns-tz';
registerLocale('fr', fr);
setDefaultLocale('fr');

const useStyles = makeStyles((theme) => tableStyles(theme));

const CustomDateInput = forwardRef(({
  onClick,
}, ref) => {
  return (
    <IconButton onClick={onClick}>
      <CalendarIcon fontSize="small" />
    </IconButton>
  );
});

const DateTimeDialog = ({
  open,
  onClose,
  onConfirm,
  dateString,
  title,
  description = '',
  hourMinutesStart,
  hourMinutesEnd,
}) => {
  const [date, setDate] = useState(new Date());
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(format(date, 'dd/MM/yyyy'));
  const [validDate, setValidDate] = useState(true);
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hourEnd, setHourEnd] = useState(0);
  const [minutesEnd, setMinutesEnd] = useState(0);
  const inputRef = useRef();
  const timeoutRef = useRef();
  const startDate = `${format(date, 'yyyy-MM-dd')} ${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
  const endDate = `${format(date, 'yyyy-MM-dd')} ${String(hourEnd).padStart(2, '0')}:${String(minutesEnd).padStart(2, '0')}:00`;

  useEffect(() => {
    if (dateString) {
      const date = new Date(dateString);
      setDate(date);
      setInputValue(format(date, 'dd/MM/yyyy'));
    }
  }, [dateString]);

  useEffect(() => {
    if (hourMinutesStart) {
      const [h, m] = hourMinutesStart.split(':');
      setHour(h);
      setMinutes(m);
    }
  }, [hourMinutesStart]);

  useEffect(() => {
    if (hourMinutesEnd) {
      const [h, m] = hourMinutesEnd.split(':');
      setHourEnd(h);
      setMinutesEnd(m);
    }
  }, [hourMinutesEnd]);

  // Focus for better editing XP
  useEffect(() => {
    if (open) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        if (inputRef.current) {
          try {
            inputRef.current.querySelector('input[name="hour"]').focus();
          } catch (e) {
            inputRef.current.querySelector('input[name="date"]').focus();
          }
        }
      }, 250)
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [open]);

  const onCloseHandler = () => {
    onClose?.();
  };

  const onConfirmHandler = async () => {
    onConfirm?.(
      startDate,
      endDate,
    );
  };

  const formatDate = (d) => {
    return formatInTimeZone(d, 'Europe/Paris', 'E dd/MM/yyyy HH:mm', { locale: frLocale });
  };

  const durationInSeconds = differenceInSeconds(new Date(endDate), new Date(startDate));
  const intervalNotLongEnough = durationInSeconds < 600;

  return (
    <ConfirmationDialog
      disableYes={intervalNotLongEnough}
      yesLabel={`OUI (${formatDate(startDate)} - ${String(hourEnd).padStart(2, '0')}:${String(minutesEnd).padStart(2, '0')})`}
      open={open}
      title={title}
      message={
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: '16px' }}
          component="div"
          ref={inputRef}
        >
          <Box
            display="flex"
            alignItems="center"
            style={{ gap: '8px' }}
          >
            <Typography style={{ width: '80px' }}>Date</Typography>
            <InputBase
              name="date"
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              placeholder="dd/mm/yyyy"
              onChange={(event) => {
                const v = event.target.value;
                const [day, month, year] = v.split('/');
                const hms = format(new Date(), 'HH:mm:ss');
                const date = new Date(`${year}-${month}-${day} ${hms}`);
                const validDate = isValid(date);
                setValidDate(validDate);
                if (validDate) {
                  setDate(date);
                }
                setInputValue(v);
              }}
              value={inputValue}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  onConfirmHandler();
                }
              }}
            />
            <ReactDatePicker
              withPortal
              selected={date}
              onChange={(date) => {
                date.setHours(new Date().getHours());
                date.setMinutes(new Date().getMinutes());
                date.setSeconds(0);
                setDate(date);
                setInputValue(format(date, 'dd/MM/yyyy'));
              }}
              dateFormat="dd/MM/yyyy"
              customInput={
                <CustomDateInput />
              }
            />
          </Box>
          <Fade in={!validDate} appear unmountOnExit>
            <Alert variant="filled" severity="error">Date invalide</Alert>
          </Fade>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            style={{ gap: '8px' }}
          >
            <Typography style={{ width: '80px' }}>Début</Typography>
            <InputBase
              name="hour"
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              inputProps={{
                maxLength: 2,
                style: { width: '20px' },
              }}
              placeholder="Heure"
              onChange={(event) => {
                const value = parseInt(event.target.value.length ? event.target.value : 0, 10);
                if (value < 24) {
                  setHour(value);
                }
              }}
              value={hour}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  onConfirmHandler();
                }
              }}
            />
            <Typography>{`Heure${hour > 1 ? 's' : ''}`}</Typography>
            <InputBase
              name="minutes"
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              inputProps={{
                maxLength: 2,
                style: { width: '20px' },
              }}
              placeholder="Minute"
              onChange={(event) => {
                const value = parseInt(event.target.value.length ? event.target.value : 0, 10);
                if (value < 60) {
                  setMinutes(value);
                }
              }}
              value={minutes}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  onConfirmHandler();
                }
              }}
            />
            <Typography>{`Minute${minutes > 1 ? 's' : ''}`}</Typography>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            style={{ gap: '8px' }}
          >
            <Typography style={{ width: '80px' }}>Fin</Typography>
            <InputBase
              name="hourEnd"
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              inputProps={{
                maxLength: 2,
                style: { width: '20px' },
              }}
              placeholder="Heure"
              onChange={(event) => {
                const value = parseInt(event.target.value.length ? event.target.value : 0, 10);
                if (value < 24) {
                  setHourEnd(value);
                }
              }}
              value={hourEnd}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  onConfirmHandler();
                }
              }}
            />
            <Typography>{`Heure${hourEnd > 1 ? 's' : ''}`}</Typography>
            <InputBase
              name="minutesEnd"
              classes={{
                root: classes.field,
                input: classes.fieldInput,
              }}
              inputProps={{
                maxLength: 2,
                style: { width: '20px' },
              }}
              placeholder="Minute"
              onChange={(event) => {
                const value = parseInt(event.target.value.length ? event.target.value : 0, 10);
                if (value < 60) {
                  setMinutesEnd(value);
                }
              }}
              value={minutesEnd}
              onKeyPress={({ key }) => {
                if (key === 'Enter') {
                  onConfirmHandler();
                }
              }}
            />
            <Typography>{`Minute${minutesEnd > 1 ? 's' : ''}`}</Typography>
          </Box>
          
          {intervalNotLongEnough ? (
            <Alert severity="error" variant="filled">La durée entre les dates de début et de fin doit être supérieure à 10 minutes.</Alert>
          ) : null}

          {description ? (
            typeof description === 'string' ? (
              <Typography>
                {description}
              </Typography>
            ) : description
          ) : null}
        </Box>
      }
      onClose={onCloseHandler}
      onConfirm={onConfirmHandler}
    />
  );
};

export default DateTimeDialog;
