export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
  dialog: {
    padding: theme.spacing(3, 3, 2, 3),
  },
  title: {
    flex: 1,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    borderRadius: '3px',
    backgroundColor: theme.palette.common.white,
  },
  button: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  label: {
    width: 200,
  },
  actions: {
    padding: theme.spacing(1, 2),
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.background.default,
  },
});
