import { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { registerLocale, setDefaultLocale } from  'react-datepicker';
import { alpha, Box, Button, IconButton, styled } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);
setDefaultLocale('fr');

const ButtonColor = styled(Button)(({ theme }) => ({
  whiteSpace: 'nowrap',
  borderColor: alpha(theme.palette.common.black, 0.2),
  color: 'inherit',
  fontWeight: 'normal',
  fontSize: 14,
  textTransform: 'none',
  width: 105,
  marginBottom: '4px',
  paddingBottom: 0,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.black, 0.05),
  },
}));

const ButtonDatePicker = forwardRef(({ filter, filterBy, placeholder, value, onClick }, ref) => (
  <Box sx={{display: 'flex', alignItems: 'center'}}>
    <ButtonColor
      ref={ref}
      size="small"
      variant="outlined"
      onClick={onClick}
    >
      {value || placeholder}
    </ButtonColor>
    {value ? (
      <IconButton
        size="small"
        onClick={() => filter({ [filterBy]: '' }, true)}
      >
        <HighlightOffIcon />
      </IconButton>
    ): null}
  </Box>
));

const FilterDatePicker = ({ defaultValue, filter, filterBy, placeholder }) => (
  <ReactDatePicker
    selected={defaultValue ? new Date(defaultValue) : null}
    onChange={(date) => filter({ [filterBy]: date ? format(date, 'yyyy-MM-dd') : null }, true)}
    placeholderText={placeholder}
    dateFormat="dd/MM/yyyy"
    value={defaultValue ? format(new Date(defaultValue), 'dd MMM yyyy', { locale: fr }) : ''}
    customInput={<ButtonDatePicker
      filter={filter}
      filterBy={filterBy}
    />}
  />
);

export default FilterDatePicker;
