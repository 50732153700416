import { useContext, useState } from 'react';
import Http from 'services/Http';
import { Box, Drawer, Table, TableBody, TableCell, TableRow, Typography, withTheme } from '@material-ui/core';
import { formatHoursMinutes, formatLongDate } from './TimetableAcl';
import { JwtContext } from 'App';
import DisciplinesList from './DisciplinesList';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import EditDisciplineDialog from './EditDisciplineDialog';
import CreateOrUpdateDisciplinesButton from './CreateOrUpdateDisciplinesButton';
import DisciplineSessionDialog from './DisciplineSessionDialog';
import CreateOrUpdateZoomButton from './CreateOrUpdateZoomButton';
import ZoomDialog from './ZoomDialog';
import ZoomInviteDialog from './ZoomInviteDialog';
import CreateOrUpdateReplayButton from './CreateOrUpdateReplayButton';
import VideoDialogCreate from 'components/VideoDialogCreate/VideoDialogCreate';
import VideoPreview from './VideoPreview';


const CalendarActionDrawer = ({
  onClose,
  open,
  timetable,
  theme,
  notifySuccess,
  setLoading,
  setSnackbar,
}) => {
  const { role } = useContext(JwtContext);
  const [deleteId, setDeleteId] = useState(null);
  const [timetableDisciplineDialog, setTimetableDisciplineDialog] = useState(null);
  const [disciplineSessionDialogAffect, setDisciplineSessionDialogAffect] = useState(null);
  const [zoomDialog, setZoomDialog] = useState(null);
  const [zoomInviteDialog, setZoomInviteDialog] = useState(null);
  const [videoDialog, setVideoDialog] = useState(null);
  const [videoViewDialog, setVideoViewDialog] = useState(null);

  const isAdmin = role === 'administrator';

  const deleteTimetableDisciplines = async () => {
    setLoading(true);
    await Http.delete(`/timetable/deleteOneTimetableDiscipline/${deleteId}`);
    notifySuccess('Matière supprimée !', false);
    setDeleteId(null);
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
    >
      {videoViewDialog !== null ? (
        <VideoPreview
          embed={videoViewDialog?.embed}
          onClose={() => {
            setVideoViewDialog(null);
          }}
        />
      ) : null}
      {videoDialog !== null ? (
        <VideoDialogCreate
          open
          onClose={() => {
            setVideoDialog(null);
          }}
          onCreate={async ({ bulkCreateVideoData }) => {
            setLoading(true);
            if (bulkCreateVideoData.length !== 1) {
              return setSnackbar({
                message: `Merci de ne pas sélectionner plus d'une vidéo`,
                type: 'error',
              });
            }
            await Http.post('/timetable/updateTimetableDisciplineVideo', {
              video: bulkCreateVideoData.at(0),
              timetableId: videoDialog.timetableId,
            });
            notifySuccess('Vidéo affectée !', false);
            setVideoDialog(null);
          }}
          disableEndpoint
        />
      ) : null}
      <EditDisciplineDialog
        role={role}
        description={timetableDisciplineDialog?.description}
        open={timetableDisciplineDialog !== null}
        discipline={timetableDisciplineDialog?.discipline}
        discipline_custom_name={timetableDisciplineDialog?.discipline_custom_name}
        onClose={() => setTimetableDisciplineDialog(null)}
        onSubmit={async (data) => {
          setLoading(true);
          await Http.put('/timetable/updateTimetableDisciplineCustomName', {
            ...data,
            disciplineId: data.id,
            timetableId: timetableDisciplineDialog.timetableId,
          });
          notifySuccess('Matière mise à jour !', false);
          setTimetableDisciplineDialog(null);
        }}
      />
      <ConfirmationDialog
        open={deleteId !== null}
        title="Attention"
        message={`Êtes-vous sûr de vouloir supprimer cette matière ?`}
        onClose={() => setDeleteId(null)}
        onConfirm={() => {
          deleteTimetableDisciplines(deleteId);
        }}
      />
      {disciplineSessionDialogAffect !== null ? (
        <DisciplineSessionDialog
          open
          onClose={() => {
            setDisciplineSessionDialogAffect(null);
          }}
          onSubmit={async (disciplineSessionIds) => {
            setLoading(true);
            await Http.post('/timetable/createTimetableDisciplines', {
              timetableIds: [timetable.id],
              disciplineSessionIds: disciplineSessionIds,
            });
            notifySuccess('Matière(s) enregistrée(s) !', false);
            setDisciplineSessionDialogAffect(null);
          }}
          initialCart={disciplineSessionDialogAffect?.selectedDisciplineIds ?? []}
        />
      ) : null}
      {zoomDialog !== null ? (
        <ZoomDialog
          open
          defaultTopic={zoomDialog.defaultTopic}
          onClose={() => {
            setZoomDialog(null);
          }}
          zoomUser={zoomDialog.zoomUser}
          onSave={async ({ zoomUser, topic }) => {
            setLoading(true);
            await Http.post('/timetable/zoomCreateMeeting', {
              timetableId: zoomDialog.timetableId,
              zoomUser,
              topic,
            });
            notifySuccess('Réunion enregistrée !', false);
            setZoomDialog(null);
          }}
          meetingId={zoomDialog?.meetingId}
        />
      ) : null}
      {zoomInviteDialog ? (
        <ZoomInviteDialog
          meetingId={zoomInviteDialog.meetingId}
          onClose={() => {
            setZoomInviteDialog(null);
          }}
          openSnackbar={(message, type) => {
            setSnackbar({ message, type });
          }}
        />
      ) : null}
      <Box
        style={{
          padding: theme.spacing(2),
          minWidth: '20vw',
          maxWidth: '80vw',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell><strong>Date</strong></TableCell>
              <TableCell>
                {`${formatLongDate(timetable.start_date, true)} ${formatHoursMinutes(timetable.start_date)} ${formatHoursMinutes(timetable.end_date)}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Matières</strong></TableCell>
              <TableCell>
                <Box
                  style={{
                    display: 'flex',
                    gap: theme.spacing(1),
                  }}
                >
                  {isAdmin ? (
                    <CreateOrUpdateDisciplinesButton
                      timetable={timetable}
                      onClick={setDisciplineSessionDialogAffect}
                      verbose
                    />
                  ) : null}
                  <DisciplinesList
                    role={role}
                    onDeleteDiscipline={setDeleteId}
                    onEditDisciplines={setTimetableDisciplineDialog}
                    timetable={timetable}
                  />
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Professeur</strong></TableCell>
              <TableCell>
                <Typography>
                  {
                    timetable?.teacher
                      ? `${timetable?.teacher?.last_name} ${timetable?.teacher?.first_name}`
                      : '-'
                  }
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Détails</strong></TableCell>
              <TableCell>
                <Typography dangerouslySetInnerHTML={{ __html: timetable?.description ?? '-' }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Zoom</strong></TableCell>
              <TableCell>
                <CreateOrUpdateZoomButton
                  onClick={setZoomDialog}
                  onInviteClick={setZoomInviteDialog}
                  timetable={timetable}
                />
              </TableCell>
            </TableRow>
            {isAdmin ? (
              <TableRow>
                <TableCell><strong>Replay</strong></TableCell>
                <TableCell>
                  <CreateOrUpdateReplayButton
                    timetable={timetable}
                    onClick={(params) => {
                      setVideoDialog(params);
                    }}
                    onVideoDialogClick={(params) => {
                      setVideoViewDialog(params);
                    }}
                    openSnackbar={(message, type) => {
                      setSnackbar({ message, type });
                    }}
                  />
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </Box>
    </Drawer>
  );
};

export default withTheme(CalendarActionDrawer);
