import { Badge, IconButton, Popover, SvgIcon, Tooltip } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Http from 'services/Http';
import List, { Bookmark, BookmarkListProps } from './List';

type StateSetter<T> = React.Dispatch<React.SetStateAction<T>>

const ALLOWED_SCOPES = [
  'reglements',
];

type BookmarksProps = {
  display?: boolean
  scope?: string | 'auto'
}

/** Path is pathname without start slash */
const getPathName = () => {
  return typeof window === 'object' && 'location' in window ? window.location.pathname.substring(1) : undefined;
};

const addBookmark = (formData: FormData) => {
  const datas = Object.fromEntries(formData);
  const { scope } = datas;
  return Http.post(
    `/bookmark/${scope}`,
    {
      ...datas,
    });
};

const fetchBookmarks = async (scope: string, setter: StateSetter<Bookmark[]>) => {
  const { data } = await Http.get<Bookmark[]>(`/bookmark/${scope}`);
  setter(data);
};

const statBookmark = async (scope: string, url: string) => {
  Http.patch<Bookmark[]>(`/bookmark/${scope}`, {
    url,
  });
};

const BookmarkInner = ({ scope }: { scope: string }) => {

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);

  useEffect(() => {
    fetchBookmarks(scope, setBookmarks);
  }, [scope]);

  const onSumbit: BookmarkListProps['onSubmit'] = async (formData) => {
    await addBookmark(formData);
    await fetchBookmarks(scope, setBookmarks);
  };

  const onGoto = (url: string) => {
    statBookmark(scope, url);
    window.location.assign(url);
  };


  return <div>
    <Tooltip title={`Vos filtres favoris${bookmarks.length > 0 ? ` (${bookmarks.length})` : ''}`}>
      <IconButton
        onClick={() => {
          setPopoverOpen(true);
        }}
        ref={buttonRef}
      >
        <Badge badgeContent={bookmarks.length} color="secondary">
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px"><g><path d="M0,0h24 M24,24H0" fill="none" /><path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" /><path d="M0,0h24v24H0V0z" fill="none" /></g></svg>
          </SvgIcon>
        </Badge>
      </IconButton>
    </Tooltip>
    <Popover
      open={popoverOpen}
      anchorEl={buttonRef.current}
      onClose={() => setPopoverOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          overflow: 'hidden',
          height: '100%',
          width: '50%',
        }
      }}
    >
      <List
        bookmarks={bookmarks}
        scope={scope}
        onSubmit={onSumbit}
        onGoto={onGoto}
      />
    </Popover>
  </div>;

};


export default function UserBookmarks({
  display,
  scope = 'auto',
  ...props
}: BookmarksProps) {

  const innerScope = scope === undefined || scope === 'auto' ? getPathName() : scope;


  if (display === undefined) {
    if (!ALLOWED_SCOPES.includes(innerScope)) {
      return null;
    }
  }

  if (innerScope === undefined) return null;



  return <BookmarkInner scope={innerScope} />;
}