import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

const ConfirmationDialog = React.memo<{
  message: React.ReactNode
  onClose: () => void
  onConfirm: () => void
  open: boolean
  title: string
  yesLabel?: string
  noLabel?: string
  disableYes?: boolean
  disableNo?: boolean
    }>(({
      message,
      onClose,
      onConfirm,
      open,
      title,
      yesLabel = 'Oui',
      noLabel = 'Non',
      disableYes = false,
      disableNo = false,
    }) => (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          {typeof message === 'string' ? (
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          ) : message}
        </DialogContent>
        <DialogActions>
          <Button disabled={disableNo} onClick={onClose} color="primary">
            {noLabel}
          </Button>
          <Button disabled={disableYes} onClick={onConfirm} color="primary" autoFocus>
            {yesLabel}
          </Button>
        </DialogActions>
      </Dialog>
    ));

export default ConfirmationDialog;
