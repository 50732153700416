import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import { styles } from 'components/Polycopies/Polycopies.css';
import Http from 'services/Http';
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EmailIcon from '@material-ui/icons/Email';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => tableStyles(theme));
const polycopiesStyles = makeStyles((theme) => styles(theme));

const PolycopieDialog = ({
  onClose,
  open,
  studentId,
  reload,
}) => {
  const classes = {
    ...useStyles(),
    ...polycopiesStyles(),
  };
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [disciplines, setDisciplines] = useState([]);
  const [discipline, setDiscipline] = useState(null);
  const [polycopies, setPolycopies] = useState([]);
  const [selectedPolycopies, setSelectedPolycopies] = useState([]);

  useEffect(() => {
    if (open) {
      (async () => {
        const { data } = await Http.get('/disciplines/autocomplete');
        setDisciplines(data.rows);
      })();
    }
  }, [open]);

  const onDisciplineSelect = async (event) => {
    const value = event.target.value;
    const discipline = disciplines.find((d) => {
      return d.name === value;
    });
    if (discipline) {
      setLoading(true);
      setDiscipline(discipline);
      const { data } = await Http.get('/polycopies/getListPolycopiesByDisciplineId', {
        params: {
          id: discipline.id,
        },
      });
      // reduce to unique polycopies
      // const polycopies = data.rows.reduce((prev, current) => {
      //   const exists = prev.some((p) => {
      //     return p.polycopie_id === current.polycopie_id;
      //   });
      //   return exists
      //     ? prev
      //     : [
      //       ...prev,
      //       current,
      //     ];
      // }, []);
      setSelectedPolycopies([]);
      setPolycopies(data);
      setLoading(false);
    }
  };

  const onDisciplineReset = () => {
    document.querySelector('input[name="discipline"]').value = '';
    setDiscipline(null);
    setPolycopies([]);
    setSelectedPolycopies([]);
  };

  const toggleAllPolycopies = () => {
    setSelectedPolycopies((state) => {
      return (state.length === polycopies.length)
        ? []
        : polycopies.map(({ id }) => id);
    });
  };

  const toggleOnePolycopie = (polycopieId) => () => {
    setSelectedPolycopies((state) => {
      return state.includes(polycopieId)
        ? state.filter((id) => polycopieId !== id)
        : [...state, polycopieId];
    });
  };

  const onSubmit = async () => {
    const payload = {
      user_id: studentId,
      polycopie_ids: selectedPolycopies,
      discipline_id: discipline.id,
    };
    await Http.post('/polycopies/bulkCreatePolycopieManual', payload);
    onDisciplineReset();
    onClose();
    reload();
  };

  const loadingStyle = { opacity: loading ? .5 : 1 };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      maxWidth="md"
      fullWidth
      style={loadingStyle}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={() => {
            onClose();
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Attribuer un polycopié
        </Typography>
        <Box ml={2}>
          <Button
            disabled={discipline === null || selectedPolycopies.length === 0}
            size="small"
            variant="contained"
            color="secondary"
            disableElevation
            onClick={onSubmit}
          >
            Attribuer à l'élève
          </Button>
        </Box>
      </Toolbar>
      <DialogContent
        style={{ backgroundColor: theme.palette.background.default }}
      >
        <Box
          display="flex"
          style={{ gap: theme.spacing(2) }}
          flexDirection="column"
          my={2}
        >
          <Card>
            <CardHeader
              style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}
              subheader={
                <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                  <EmailIcon fontSize="small" />
                  <Typography>Matières</Typography>
                </Box>
              }
            />
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              style={{ gap: theme.spacing(1) }}
              my={1}
              mx={2}
            >
              <InputBase
                fullWidth
                inputProps={{
                  list: 'available-disciplines',
                  autoComplete: 'off',
                }}
                name="discipline"
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                placeholder="Matière"
                onChange={onDisciplineSelect}
                defaultValue={discipline ? discipline.name : ''}
              />
              <IconButton onClick={onDisciplineReset}>
                <HighlightOffIcon />
              </IconButton>
              <datalist id="available-disciplines">
                {disciplines.map(({ name, id }) => {
                  return (
                    <option
                      key={`${name}-${id}`}
                      value={name}
                    />
                  );
                })}
              </datalist>
            </Box>
          </Card>
          {polycopies.length > 0 ? (
            <Card>
              <CardHeader
                style={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) }}
                avatar={
                  <Checkbox
                    onClick={toggleAllPolycopies}
                    checked={selectedPolycopies.length === polycopies.length}
                  />
                }
                subheader={
                  <Box display="flex" justifyContent="flex-start" alignItems="center" style={{ gap: '18px' }}>
                    <ListAltIcon fontSize="small" />
                    <Typography>Polycopiés</Typography>
                  </Box>
                }
              />
              <Divider />
              <Box>
                <List
                  dense
                  component="div"
                  role="list"
                >
                  {polycopies.map(({
                    id,
                    name,
                  }) => {
                    const labelId = `polycopie-${id}`;
                    return (
                      <ListItem
                        role="listitem"
                        button
                        onClick={toggleOnePolycopie(id)}
                        key={labelId}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={selectedPolycopies.includes(id)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Card>
          ) : null}
        </Box>
      </DialogContent>
    </Dialog>
  )
};

export default PolycopieDialog;
