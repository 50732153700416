import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Fade,
    IconButton,
    InputBase,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './FilterInput.css';

const FilterInput = ({
    classes,
    defaultValue,
    filter,
    filterBy,
    placeholder,
    merge,
    variant = 'normal',
    outlined = false,
    style = null,
    inputBaseProps = undefined,
}) => {

    const {
        inputProps,
        ...restInputBaseProps
    } = { ...inputBaseProps }

    if (variant === 'dense') {
        return (
            <Box
                key={`${filterBy}-${defaultValue}`}
                position="relative"
                display="flex"
            >
                <InputBase
                    {...restInputBaseProps}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    classes={{
                        root: outlined ? classes.fieldOutlined : classes.field,
                        input: classes.fieldInputDense,
                    }}
                    onKeyPress={evt => {
                        if (evt.key === 'Enter') {
                            filter({ [filterBy]: evt.target.value }, merge);
                        }
                    }}
                    inputProps={{
                        ...inputProps,
                        enterKeyHint: 'search',
                        inputMode: 'search',
                    }}
                />
                <Fade
                    in={defaultValue.length > 0}
                    style={{
                        width: defaultValue.length === 0 ? '0px' : 'auto'
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={() => filter({ [filterBy]: '' }, merge)}
                    >
                        <HighlightOffIcon />
                    </IconButton>
                </Fade>
            </Box>
        );
    }
    return (
        <Box position="relative" ml={1} style={style ?? null}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                {...restInputBaseProps}
                defaultValue={defaultValue}
                placeholder={placeholder}
                classes={{
                    root: classes.field,
                    input: classes.fieldInput,
                }}
                onKeyPress={evt => {
                    if (evt.key === 'Enter') {
                        filter({ [filterBy]: evt.target.value }, merge);
                    }
                }}
                inputProps={{
                    ...inputProps,
                    enterKeyHint: 'search',
                    inputMode: 'search',
                }}
            />
        </Box>
    );
};

FilterInput.defaultProps = {
    defaultValue: '',
    variant: 'normal',
    outlined: false,
};

FilterInput.propTypes = {
    defaultValue: PropTypes.string,
    filter: PropTypes.func.isRequired,
    filterBy: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(['normal', 'dense']),
    outlined: PropTypes.bool,
    inputBaseProps: PropTypes.any,
};

export default withStyles(styles)(FilterInput);
