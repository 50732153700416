import { useDraggable } from '@dnd-kit/core';
import { Box } from '@material-ui/core';
import classNames from 'classnames';
import useStyles from 'components/SessionDialogMerge/MergeDnd.css';

const DraggableSession = (props) => {
  const { isDragging, attributes, listeners, setNodeRef/*, transform*/ } = useDraggable({
    id: props.id,
    data: props.data,
  });
  // const style = transform ? {
  //   transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  // } : undefined;
  const classes = useStyles();

  return (
    <Box
      ref={setNodeRef}
      // style={style}
      {...listeners}
      {...attributes}
      className={classNames(
        classes.draggableSession,
        {
          [classes.draggableSessionDragging]: isDragging,
        },
      )}
    >
      {props.children}
    </Box>
  );
};

export default DraggableSession;
