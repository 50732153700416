import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
    //   light: '#757ce8',
      main: '#1976d2',
    //   dark: '#002884',
    //   contrastText: '#fff',
    },
    secondary: {
    //   light: '#ff7961',
      main: '#fa5838',
    //   dark: '#ba000d',
    //   contrastText: '#000',
    },
    text: {
      primary: '#6a7a8c',
    //   secondary: string;
    //   disabled: string;
    //   hint: string;
    },
    // divider: string;
    background: {
      default: '#f2f4f5',
      // paper: string;
    },
    // action: {
    //   active: string;
    //   hover: string;
    //   hoverOpacity: number;
    //   selected: string;
    //   disabled: string;
    //   disabledBackground: string;
    // },
    custom: {
      sidebar: '#233242',
      sidebarHover: 'rgba(255, 255, 255, 0.2)',
      white: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Rubik',
      'sans-serif',
    ].join(','),
  },
});
