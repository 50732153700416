import { Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogContent, IconButton, Toolbar, Tooltip, Typography, makeStyles, useTheme } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useEffect, useRef, useState } from 'react';
import Http from 'services/Http';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Payzen.css';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => tableStyles(theme));
const usePayzenStyles = makeStyles((theme) => styles(theme));

const Unnotifieds = ({ openSnackbar, reload }) => {
  const intervalRef = useRef(false);
  const secondsRef = useRef(9);
  // const [seconds, setSeconds] = useState(9);
  const [unnotifieds, setUnnotifieds] = useState([]);
  const [showProblems, setShowProblems] = useState(false);
  const classes = {
    ...useStyles(),
    ...usePayzenStyles(),
  };
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const importOrder = async (id, orderId) => {
    setLoading(true);
    try {
      await Http.post('/payzen/createIpnFromPayzenEmail', { mailId: id, orderId });
      openSnackbar(`Commande ${orderId} importée`, 'success');
      reload();
      setShowProblems(false);
    } catch (e) {
      openSnackbar(e.data.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const moveMail = async (id) => {
    setLoading(true);
    await Http.post('/payzen/moveMail', { mailId: id });
    openSnackbar(`Mail déplacé`, 'success');
    reload();
    setShowProblems(false);
    setLoading(false);
  };

  useEffect(() => {
    const getUnnotifieds = async () => {
      try {
        const result = await Http.get('/payzen/getPayzenEmails');
        setUnnotifieds(result.data.unnotifieds);
      } catch (e) {}
    };
    const tick = () => {
      if (secondsRef.current === 0) {
        clearInterval(intervalRef.current);
        getUnnotifieds();
        secondsRef.current = 9;
        intervalRef.current = setInterval(tick, 1000 * 60);
      } else {
        secondsRef.current--;
      }
      // setSeconds(secondsRef.current);
    };

    if (!intervalRef.current) {
      intervalRef.current = setInterval(tick, 1000 * 60);
      getUnnotifieds();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, []);

  if (showProblems) {
    return (
      <Dialog
        open
        onClose={() => {
          setShowProblems(false);
        }}
        maxWidth="md"
        fullWidth
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            Détails des anomalies
          </Typography>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={() => {
              setShowProblems(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent
        // style={{ backgroundColor: theme.palette.background.default }}
        >
          <Box style={{ display: 'flex', gap: theme.spacing(2), flexDirection: 'column' }}>
            {unnotifieds.map(({ id, bodyContent, orderId }) => {
              return (
                <Card key={orderId} style={{ backgroundColor: 'rgb(242, 244, 245)', }}>
                  <CardHeader title={orderId} />
                  <CardContent>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'rgb(242, 244, 245)',
                      }}
                      className={classes.forceColorBlack}
                      dangerouslySetInnerHTML={{ __html: bodyContent }}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() => {
                        const url = `${process.env.REACT_APP_CRM_URL}/orders/${orderId}`;
                        window.open(url);
                      }}
                      color="primary"
                    >
                      Ouvrir la commande
                    </Button>
                    <Button
                      onClick={() => {
                        importOrder(id, orderId);
                      }}
                      color="primary"
                      disabled={loading}
                    >
                      Importer la commande
                      {loading ? ' - En cours...' : ''}
                    </Button>
                    <Tooltip title="Ranger la commande dans le dossier des mails traités sans l'importer">
                      <Button
                        onClick={() => {
                          moveMail(id);
                        }}
                        color="primary"
                        disabled={loading}
                      >
                        Ignorer la commande
                        {loading ? ' - En cours...' : ''}
                      </Button>
                    </Tooltip>
                  </CardActions>
                </Card>
              )
            })}
          </Box>
        </DialogContent>
      </Dialog>
    )
  }

  return unnotifieds.length ? (
    <Alert
      severity="warning"
      variant="filled"
      style={{
        margin: theme.spacing(2)
      }}
    >
      <AlertTitle>
        {`${unnotifieds.length} anomalie${unnotifieds.length > 1 ? 's' : ''}`}
        <Button
          onClick={() => setShowProblems((value) => !value)}
          color="primary"
          variant="contained"
          size="small"
          style={{ marginLeft: theme.spacing(2) }}
        >
          {showProblems ? `Masquer` : `Afficher`}
        </Button>
      </AlertTitle>
    </Alert>
  ) : null;
};

export default Unnotifieds;
