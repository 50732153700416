import React from 'react';
import PropTypes from 'prop-types';

import rules from './rules';
import check from './check';

const Can = ({
    action,
    data,
    no,
    role,
    yes,
}) => (
    check(rules, role, action, data) ? yes() : no()
);

Can.propTypes = {
    action: PropTypes.string,
    data: PropTypes.shape(),
    no: PropTypes.func,
    role: PropTypes.string,
    yes: PropTypes.func,
};

Can.defaultProps = {
    no: () => <div>FORBIDDEN</div>,
    yes: () => null,
};

export default Can;