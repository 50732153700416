import axios from 'axios';
import { clearAccessToken, getAccessToken } from './AuthService';

const myAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    common: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin',
    },
  },
});

myAxios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (axios.isCancel(error)) {
    return Promise.reject(error);
  }
  if (error.response.status === 401) {
    clearAccessToken();
  }
  return Promise.reject(error.response);
});

myAxios.interceptors.request.use((request) => {
  const token = getAccessToken();
  if (token) {
    request.headers.authorization = `Bearer ${token}`;
  }
  return request;
}, (error) => {
  return Promise.reject(error);
});

export default myAxios;
