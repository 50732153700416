import { Box, Chip, withStyles } from "@material-ui/core";
import { styles } from './Trainings.css';

export const trainingStatuses = [
  { value: 'publish', label: 'Visible' },
  { value: 'draft', label: 'Fermée' },
  { value: 'private', label: 'Cachée' },
  { value: 'trash', label: 'Corbeille' },
];

export const trainingProductStatuses = [
  {
    trainingStatus: 'publish',
    productStatus: 'publish',
    label: 'Formation visible + Inscriptions ouvertes',
  },
  {
    trainingStatus: 'publish',
    productStatus: undefined,
    label: 'Formation visible + Inscriptions fermées (pas de produit)',
  },
  {
    trainingStatus: 'publish',
    productStatus: 'draft',
    label: 'Formation visible + Inscriptions fermées',
  },
  {
    trainingStatus: 'private',
    productStatus: 'draft',
    label: 'Formation cachée + Inscriptions fermées',
  },
  {
    trainingStatus: 'private',
    productStatus: 'private',
    label: 'Formation cachée + Produit caché',
  },
  {
    trainingStatus: 'draft',
    productStatus: 'draft',
    label: 'Formation fermée + Inscriptions fermées',
  },
  {
    trainingStatus: 'draft',
    productStatus: undefined,
    label: 'Formation fermée + Inscriptions fermées (pas de produit)',
  },
];

export const mapStatusToLabel = (status) => {
  const valueLabel = trainingStatuses.find(({ value }) => {
    return status === value;
  });
  return valueLabel ? valueLabel.label : `${status ?? 'inconnu'}`;
};

export const mapTrainingAndProductStatusToLabel = (trainingStatus, productStatus) => {
  const valueLabel = trainingProductStatuses.find((trainingProductStatus) => {
    return trainingProductStatus.trainingStatus === trainingStatus
      && trainingProductStatus.productStatus === productStatus;
  });
  return valueLabel
    ? valueLabel.label
    : `⚠️ Formation ${mapStatusToLabel(trainingStatus)} + produit ${mapStatusToLabel(productStatus)}`;
};

const TrainingProductStatusUnstyled = ({ trainingStatus, productStatus, classes }) => {
  return (
    <Box>
      <Chip
        label={mapTrainingAndProductStatusToLabel(trainingStatus, productStatus)}
        variant="outlined"
        size="small"
        className={['draft', 'trash'].includes(trainingStatus) ? classes.chipError : classes.chipSuccess}
      />
    </Box>
  );
};

export const TrainingProductStatus = withStyles((theme) => ({
  ...styles(theme),
}))(TrainingProductStatusUnstyled);

const TrainingStatus = ({ status, classes }) => {
  return (
    <Chip
      label={mapStatusToLabel(status)}
      variant="outlined"
      size="small"
      className={['draft', 'trash'].includes(status) ? classes.chipError : classes.chipSuccess}
    />
  );
};

export default withStyles((theme) => ({
  ...styles(theme),
}))(TrainingStatus);
