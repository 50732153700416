import { useContext } from 'react';
import { JwtContext } from 'App';
import Forbidden from 'shared/Can/Forbidden';
import TimetableAcl from './TimetableAcl';
// import { addHours, format } from 'date-fns';
// import { getTimezoneOffset } from 'date-fns-tz';

// Access control
const Timetable = (props) => {
  const { role } = useContext(JwtContext);
  return (role === 'teacher' || role === 'administrator')
    ? (
        <TimetableAcl
          {...props}
          role={role}
        />
      )
    : <Forbidden />
  ;
};

export default Timetable;
