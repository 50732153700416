import { Box, Typography, useTheme } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import Http from 'services/Http';
import InfoIcon from '@material-ui/icons/Info';

const TeacherToBePaid: FC = () => {
  const theme = useTheme();
  const [toBePaid, setToBePaid] = useState<number>();
  useEffect(() => {
    const load = async () => {
      const { data } = await Http.get('/homeworks/toBePaid');
      setToBePaid(data);
    };
    load();
  }, []);

  return (!toBePaid)
    ? null
    : (
      <Box
        style={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: theme.spacing(1),
        }}
      >
        <InfoIcon />
        <Typography
          variant="subtitle2"
        >
          {`Copie${toBePaid > 1 ? 's' : ''} corrigée${toBePaid > 1 ? 's' : ''} non payée${toBePaid > 1 ? 's' : ''} à ce jour : ${toBePaid}`}
        </Typography>
      </Box>
    );
};

export default TeacherToBePaid;
