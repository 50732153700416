import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const CollisionLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} to={props.to} {...props} />
));

const ButtonRouter = ({ label, ...props }) => (
  <Button
    component={CollisionLink}
    {...props}
  >
    {label}
  </Button>
);

export default ButtonRouter;
