import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    Hidden,
    IconButton,
    Input,
    InputLabel,
    NativeSelect,
    Slide,
    Toolbar,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { withStyles } from '@material-ui/core/styles';
import Http from 'services/Http';
import { styles } from './SessionDialogCreate.css';

const PARENT_KEY = 'parent';
const PRODUCT_ATTR_KEY = '_product_attributes';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class SessionDialogCreate extends Component {
    state = {
        allProducts: [],
        examList: [],
        trainingList: [],
        productList: [],
        variationList: [],
        exam: null,
        training: null,
        product: null,
        variation: null,
        success: '',
        error: [],
        openSuccess: false,
        openError: false,
    }

    async componentDidMount() {
        const exams = await Http.get('/exams/list');
        const trainings = await Http.get('/trainings/list');
        const products = await Http.get('/products/list');
        this.setState({
            examList: exams.data,
            trainingList: trainings.data,
            allProducts: products.data,
        });
    }

    handleChange = (name, key = null) => ({ target }) => {
        const value = name !== 'variations' ? parseInt(target.value, 10) : target.value;
        const obj = name !== 'variations' ? this.state[`${name}List`].find((o) => o.id === value) : null;
        switch (name) {
            case 'training':
                const exam = this.state.examList.find(e => e.id === obj.exam_id) || null;
                this.setState({
                    exam,
                    training: value ? { id: value, title: obj ? obj.title : '' } : null,
                    productList: this.state.allProducts
                        .filter((p) => {
                            const meta = (p.postMeta || []).find((m) => m.key === PARENT_KEY);
                            return meta && meta.value === value;
                        }),
                    variationList: [],
                    product: null,
                    variation: null,
                });
                break;
            case 'product':
                const meta = obj ? (obj.postMeta || []).find((m) => m.key === PRODUCT_ATTR_KEY) : null;
                const variation = meta ? meta.value.reduce((prev, cur) => {
                    prev[cur.key] = '';
                    return prev;
                }, {}) : null;
                this.setState({
                    product: value ? { post_id: value, title: obj ? obj.title : '' } : null,
                    variationList: meta ? meta.value : [],
                    variation,
                });
                break;
            case 'variations':
                this.setState(state => ({
                    variation: {
                        ...state.variation,
                        [key]: value,
                    },
                }));
                break;
            default:
                break;
        }
    };

    onSessionCreate = async () => {
        const { exam, training, product, variation } = this.state;
        try {
            const { data } = await Http.post('/sessions', { exam, training, product, variation, manually: true });
            this.setState({
                openSuccess: true,
                success: data.created ? 'Session créée' : 'Session déjà existante',
                error: [],
            });
        } catch ({ data }) {
            this.setState({
                openError: true,
                error: data ? Object.values(data) : ['Une erreur est survenue'],
                success: '',
            });
        }
    }

    onDialogClose = () => {
        this.setState({
            trainingList: [],
            productList: [],
            variationList: [],
            exam: null,
            training: null,
            product: null,
            variation: null,
            success: '',
            error: [],
        });
        this.props.onClose();
    }

    render() {
        const { classes, open } = this.props;
        const {
            error,
            openError,
            openSuccess,
            productList,
            success,
            trainingList,
            variationList,
        } = this.state;

        return (
            <Dialog
                open={open}
                onClose={this.onDialogClose}
                TransitionComponent={Transition}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="md"
            >
                <SuccessSnackbar
                    open={openSuccess}
                    onClose={() => this.setState({ openSuccess: false })}
                    message={success}
                />
                <ErrorSnackbar
                    open={openError}
                    onClose={() => this.setState({ openError: false })}
                    message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
                />

                <Toolbar className={classes.toolbar}>
                    <IconButton
                        className={classes.closeButton}
                        edge="start" 
                        color="inherit" 
                        onClick={this.onDialogClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography
                        color="inherit"
                        variant="h6"
                        className={classes.title}
                    >
                        Ajouter une session
                    </Typography>
                    <Button
                        onClick={this.onSessionCreate}
                        variant="contained"
                        color="secondary"
                        disableElevation
                    >
                        Créer
                    </Button>
                </Toolbar>

                <DialogContent className={classes.dialog}>
                    <FormControl className={classes.formControl} required>
                        <InputLabel shrink htmlFor="training">
                            Formation
                        </InputLabel>
                        <NativeSelect
                            onChange={this.handleChange('training')}
                            input={<Input name="training" />}
                            className={classes.selectEmpty}
                        >
                            <option value="">Choix</option>
                            {trainingList && trainingList.map((training) => (
                                <option key={training.id} value={training.id}>{training.title}</option>
                            ))}
                        </NativeSelect>
                    </FormControl>
                       
                    <FormControl className={classes.formControl} required>
                        <InputLabel shrink htmlFor="product">
                            Produit Woo
                        </InputLabel>
                        <NativeSelect
                            onChange={this.handleChange('product')}
                            input={<Input name="product" />}
                            className={classes.selectEmpty}
                        >
                            <option value="">Choix</option>
                            {productList && productList.map((product) => (
                                <option key={product.id} value={product.id}>{product.title}</option>
                            ))}
                        </NativeSelect>
                    </FormControl>

                    {variationList.length > 0 && (
                        <Fragment>
                            <Hidden mdUp>
                                <Divider className={classes.divider} />
                            </Hidden>
                            {variationList.map((variation) => (
                                <FormControl
                                    key={variation.key}
                                    className={classes.formControl}
                                    required
                                >
                                    <InputLabel shrink htmlFor={variation.key}>
                                        {variation.name}
                                    </InputLabel>
                                    <NativeSelect
                                        onChange={this.handleChange('variations', variation.key)}
                                        input={<Input name={variation.key} />}
                                        className={classes.selectEmpty}
                                    >
                                        <option value="">Choix</option>
                                        {variation.values.map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            ))}
                        </Fragment>
                    )}
                </DialogContent>
            </Dialog>
        );
    }
}

SessionDialogCreate.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(
    SessionDialogCreate
);
