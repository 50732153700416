// import { forwardRef } from 'react';
import { alpha, Box, FormControl, IconButton, InputBase, MenuItem, Select, withStyles } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const CustomSelect = withStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.background.paper,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: alpha(theme.palette.common.black, 0.2),
    fontSize: 14,
    padding: '5px 9px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
  },
}))(InputBase);

const FilterSelect = ({ defaultValue, filter, filterBy, items, placeholder }) => (
  <FormControl variant="outlined">
    <Box sx={{display: 'flex'}}>
      <Select
        value={defaultValue}
        displayEmpty
        input={<CustomSelect />}
        onChange={(evt) => filter({ [filterBy]: evt.target.value }, true)}
      >
        <MenuItem value="">{placeholder}</MenuItem>
        {items.map((item, index) => (
            <MenuItem key={item} value={`${index}`}>{item}</MenuItem>
        ))}
      </Select>
      {defaultValue ? (
        <IconButton
          size="small"
          onClick={() => filter({ [filterBy]: '' }, true)}
        >
          <HighlightOffIcon />
        </IconButton>
      ): null}
    </Box>
  </FormControl>
);

export default FilterSelect;
