import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, DialogActions, DialogContent, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
import { tableStyles } from 'styles/datatable.css';
import Http from 'services/Http';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import { formatInTimeZone } from 'date-fns-tz';
import classNames from 'classnames';

const sortableCells = [
  { name: 'commentId', label: '#', isString: false },
  { name: 'commentDate', label: 'Date', isString: true },
  { name: 'commentContent', label: 'Message', isString: true },
];

class OrdersTransactions extends Component {

  state = {
    banks: [],
    createBankInProgress: false,
    paymentsTotal: 0,
    rows: [],
    loading: true,
    openConfirmDelete: false,
  }

  async componentDidMount() {
    const { order } = this.props;
    const { data } = await Http.get(`/orders/getOrderTransactions/${order.id}`);
    this.props.setData(data);
  }

  render() {
    const { close, classes, renderSortCell, rows } = this.props;

    return (
      <Fragment>
        <DialogContent>
          {rows.length === 0 ? (
            <Box my={2}>
              <Typography>Chargement...</Typography>
            </Box>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  {sortableCells.map(cell => renderSortCell(cell))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(n => (
                  <TableRow
                    className={
                      classNames({
                        [classes.redRow]: n.commentContent.startsWith('Action refusée'),
                        [classes.greenRow]: n.commentContent.startsWith('Action réalisée avec succès') || n.commentContent.endsWith('to Processing.'),
                      })
                    }
                    key={n.commentId}
                  >
                    <TableCell component="th" scope="row">
                      {n.commentId}
                    </TableCell>
                    <TableCell>
                      {formatInTimeZone(n.commentDateGmt, 'Europe/Paris', 'dd/MM/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell>{n.commentContent}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
        <DialogActions>
          <Box mt={1} display="flex" alignItems="flex-end" justifyContent="space-between" style={{ gap: '12px' }}>
            <Box display="flex" justifyContent="flex-end" style={{ gap: '10px' }}>

              <Button
                size="small"
                variant="outlined"
                onClick={close}
              >
                Retour
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </Fragment>
    );
  }
}

OrdersTransactions.propTypes = {
  close: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
}))(
  withLocalSortAndFilter(OrdersTransactions, 'commentId', 'desc')
);
