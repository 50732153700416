import { Fragment } from 'react';
import { Box, Card, CardContent, Chip, Typography, makeStyles, useTheme } from '@material-ui/core';
import { styles } from './TopicHierarchyDialog.css';
import classNames from 'classnames';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { utcToZonedTime } from 'date-fns-tz';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useTopicHierarchyDialogStyles = makeStyles((theme) => styles(theme));

const TopicCard = ({ topic, order = '-', hideDiscipline = true }) => {
  const classes = useTopicHierarchyDialogStyles();
  const theme = useTheme();

  const { type } = topic;

  return (
    <Box mt={1}>
      <Card
        className={classNames(
          classes.draggable,
        )}
      >
        <CardContent className={classNames(
          classes.smallCard,
          { [classes.grey]: type === 'droppable', }
        )}>
          <Box
            display="flex"
            style={{ gap: theme.spacing(2) }}
            alignItems="center"
            justifyContent={type === 'droppable' ? 'center' : 'flex-start'}
          >
            {type === 'droppable' ? <AddCircleIcon /> : null}
            <Chip
              label={type === 'droppable' ? '' : order}
              className={classNames({
                [classes.hidden]: type === 'droppable',
              })}
              style={{ width: type === 'droppable' ? 0 : 'auto'}}
            />
            {type !== 'droppable' ? (
              <Fragment>
                {hideDiscipline === false ? (
                  <Typography variant="caption">
                    {topic.discipline.name}
                  </Typography>
                ) : null}
                <Typography variant="body1">
                  {topic?.name ?? ''}
                </Typography>
                <Typography variant="caption">{topic?.id ?? ''}</Typography>
                {topic.limit_date ? (
                  <Chip
                    size="small"
                    label={`Date limite ≤ ${format(utcToZonedTime(topic.limit_date, 'Etc/UTC'), 'dd MMM yyyy HH:mm', { locale: frLocale })}`}
                  />
                ) : null}
              </Fragment>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
};

export default TopicCard;
