import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import get from 'lodash.get';
import {
  Box,
  Button,
  Chip,
  LinearProgress,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './StudentVideos.css';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import Http from 'services/Http';
import axios from 'axios';

const sortableCells = [
  { name: 'id', label: '#' },
  { name: 'video.inserted_at', label: 'Date d\'ajout', isString: true },
  { name: 'video.discipline.training.title', label: 'Formation', isString: true },
  { name: 'video.discipline.name', label: 'Matière', isString: true },
];

class StudentVideos extends Component {
  state = {
    error: '',
    openError: false,
    openSuccess: false,
    success: '',
    mostRecentTrainings: [],
    trainingIdFilter: 0,
  }

  source = null;

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel(`Operation canceled by componentWillUnmount lifecycle method.`);
    }
  }

  async componentDidMount() {
    this.props.setData(this.props.data.videoStudents);
    // get related trainings ordered by newest creation date
    const trainingIds = [...new Set(this.props.data.videoStudents.map(({ video }) => {
      return video.discipline.training.id;
    }))];
    if (this.source) {
      this.source.cancel(`Operation canceled by the user.`);
    }
    this.source = axios.CancelToken.source();
    const { data: mostRecentTrainings } = await Http.get(`/trainings/mostRecent?ids=${trainingIds}`, {
      cancelToken: this.source.token,
    });
    this.setState({
      mostRecentTrainings,
      trainingIdFilter: mostRecentTrainings.length > 1 ? mostRecentTrainings.at(0).id : 0,
    });
  }

  // CRUD
  resetViews = async (id) => {
    try {
      await Http.put(`/videoStudent/${id}`, { views: 0 });
      this.setState({
        success: 'Le nombre de vues a été mis à jour pour la vidéo',
        openSuccess: true,
      });
      await this.props.reload();
      this.props.setData(this.props.data.videoStudents);
    } catch ({ data }) {
      this.setState({
        error: get(data, 'message', 'Une erreur est survenue'),
        openError: true,
      });
    }
  }

  render() {
    const { classes, rows, renderSortCell, renderFilter } = this.props;
    const { error, openError, openSuccess, success, mostRecentTrainings, trainingIdFilter } = this.state;
    const { allVideos } = this.props.data;

    const filteredRows = rows.filter(({ video }) => {
      return trainingIdFilter === 0 ? true : video.discipline.training.id === trainingIdFilter;
    });
    const filteredAllVideos = allVideos.filter(({ training_id }) => {
      return trainingIdFilter === 0 ? true : training_id === trainingIdFilter;
    });

    return (
      <div>
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar>
          <Box
            display="flex"
            flex="1"
            justifyContent="flex-end"
            alignContent="center"
            style={{ gap: '6px' }}
          >
            {mostRecentTrainings.length > 1 ? (
              <NativeSelect
                value={trainingIdFilter}
                className={classes.selectInput}
                onChange={(event) => {
                  this.setState({
                    trainingIdFilter: parseInt(event.currentTarget.value, 10),
                  })
                }}
              >
                {[
                  { id: 0, title: 'Tout afficher' },
                  ...mostRecentTrainings,
                ].map(({ id, title }) => {
                  return (
                    <option
                      key={id}
                      value={id}
                    >
                      {title}
                    </option>
                  );
                })}
              </NativeSelect>
            ) : null}
            {renderFilter('Filtrer par matière', 'video.discipline.name')}
            {renderFilter('Filtrer par vidéo', 'video.name')}
          </Box>
        </Toolbar>
        {filteredRows.length ? (
          <Box style={{ gap: '6px' }} display="flex" flexDirection="column" alignItems="flex-start" mb={6}>
            <Box m={1} ml={2}>
              <Typography variant="h6">
                {`${filteredRows.length} vidéo${filteredRows.length > 1 ? 's' : ''} vue${filteredRows.length > 1 ? 's' : ''}  / ${filteredAllVideos.length} vidéo${filteredAllVideos.length > 1 ? 's' : ''}`}
              </Typography>
              <Box display="flex" alignItems="center">
                <Box width="100%" mr={1}>
                  <LinearProgress variant="determinate" value={Math.round( filteredRows.length / filteredAllVideos.length * 100 )} />
                </Box>
                <Box minWidth={35}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                  >
                    {`${Math.round( filteredRows.length / filteredAllVideos.length * 100 )}%`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  {sortableCells.map(cell => renderSortCell(cell))}
                  <TableCell>Nom</TableCell>
                  <TableCell>Info</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Vues</TableCell>
                  <TableCell align="center">Dernière vue</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.map((v) => {
                  return (
                    <TableRow key={v.id}>
                      <TableCell>{v.id}</TableCell>
                      <TableCell>{format(new Date(v.video.inserted_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                      <TableCell>{v.video.discipline && v.video.discipline.training ? v.video.discipline.training.title : 'Aucune'}</TableCell>
                      <TableCell>{v.video.discipline ? v.video.discipline.name : 'Aucune'}</TableCell>
                      <TableCell>{v.video.name}</TableCell>
                      <TableCell>
                        {v.video.description ? (
                          <Tooltip title={v.video.description}>
                            <HelpIcon fontSize="small" color="primary" />
                          </Tooltip>
                        ) : null}
                      </TableCell>
                      <TableCell align="center">
                        <img
                          className={classes.thumbnail}
                          src={v.video.picture}
                          alt=""
                          style={{
                            minHeight: '50px',
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Chip
                          className={classes.chip}
                          size="small"
                          variant="outlined"
                          color="primary"
                          label={v.views}
                        />
                      </TableCell>
                      <TableCell align="center">{v.last_view ? format(new Date(v.last_view), 'dd MMM yyyy', { locale: frLocale }) : '-'}</TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={() => this.resetViews(v.id)}
                        >
                          Reset
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        ) : null}
        <Box style={{ gap: '18px' }} display="flex" flexDirection="column" alignItems="flex-start">
          <Box m={1} ml={2}>
            <Typography variant="h6">
              {`Affichage de ${filteredAllVideos.length} / ${allVideos.length} vidéo${allVideos.length > 1 ? 's' : ''}`}
            </Typography>
          </Box>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Date d'ajout</TableCell>
                <TableCell>Formation</TableCell>
                <TableCell>Matière</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Info</TableCell>
                <TableCell align="center">Image</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAllVideos.map((v) => {
                return (
                  <TableRow key={v.video_id}>
                    <TableCell>{v.video_id}</TableCell>
                    <TableCell>{format(new Date(v.video_inserted_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                    <TableCell>{v.training_title ? v.training_title : 'Aucune'}</TableCell>
                    <TableCell>{v.discipline_name ? v.discipline_name : 'Aucune'}</TableCell>
                    <TableCell>{v.video_name}</TableCell>
                    <TableCell>
                      {v.video_description ? (
                        <Tooltip title={v.video_description}>
                          <HelpIcon fontSize="small" color="primary" />
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    <TableCell align="center">
                      <img
                        className={classes.thumbnail}
                        src={v.video_picture}
                        alt=""
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </div>
    );
  }

}

StudentVideos.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withLocalSortAndFilter(StudentVideos, 'inserted_at', 'desc')
);
