import { alpha } from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
    button: {
        marginRight: theme.spacing(1),
        padding: theme.spacing(.5),
    },
    card: {
        margin: theme.spacing(1),
        flex: 1,
    },
    cardNote: {
        padding: theme.spacing(1),
    },
    field: {
        marginLeft: theme.spacing(1),
        display: 'inline-block',
        borderRadius: theme.shape.borderRadius,
        color: 'inherit',
        backgroundColor: alpha(theme.palette.common.black, 0.05),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.1),
        },
        padding: theme.spacing(1),
    },
    fieldInput: {
        padding: theme.spacing(1),
    },
    marginLeft: {
        marginLeft: theme.spacing(1),
    },
    padding: {
        padding: theme.spacing(2),
    }
});
