import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogTitle,
  Fade,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import { tableStyles } from 'styles/datatable.css';
import Http from 'services/Http';
import { styles } from './Podcasts.css';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import TagDialogManage from 'components/TagDialogManage/TagDialogManage';
import TagDialogAffect from 'components/TagDialogAffect/TagDialogAffect';
import TrainingDialogAffect from 'components/TrainingDialogAffect/TrainingDialogAffect';
import ErrorSnackbar from 'components/Snackbar/Error';
import FilterInput from 'components/Datagrid/FilterInput';
import SuccessSnackbar from 'components/Snackbar/Success';
import VideoDialogCreate from 'components/VideoDialogCreate/VideoDialogCreate';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';

const sortableCells = [
  { name: 'id', label: '#' },
  { name: 'inserted_at', label: 'Date d\'ajout' },
];

class Podcasts extends Component {
  state = {
    createVideoDialog: false,
    deleteDialog: false,
    deleteVideo: null,
    error: '',
    localLoading: true,
    openError: false,
    openSuccess: false,
    previewVideo: '',
    selectAll: false,
    selectedIds: [],    
    showVideoDialog: false,
    success: '',
    trainingDialog: false,
    tagDialog: false,
    tags: [],
    trainings: [],
    podcastTrainings: [],
    manageTagDialog: false, // global tags management
  }

  async componentDidMount() {
    const tags = await this.loadTags(false);
    const trainings = await this.loadTrainings(false);
    const podcastTrainings = await this.loadPodcastTrainings(false);
    this.setState({
      tags,
      trainings,
      podcastTrainings,
      localLoading: false,
    });
  }

  openTagDialog = (selectedIds = null) => {
    this.setState(state => ({
      tagDialog: true,
      selectedIds: selectedIds || state.selectedIds,
    }));
  }
  
  closeTagDialog = () => {
    this.setState({ tagDialog: false });
  }

  openManageTagDialog = () => {
    this.setState({ manageTagDialog: true });
  }

  closeManageTagDialog = () => {
    this.setState({ manageTagDialog: false });
  }

  openTrainingDialog = () => {
    this.setState({ trainingDialog: true });
  }
  
  closeTrainingDialog = () => {
    this.setState({ trainingDialog: false });
  }

  openCreateVideoDialog = () => {
    this.setState({ createVideoDialog: true });
  }

  closeCreateVideoDialog = () => {
    this.setState({ createVideoDialog: false });
  }

  openShowVideoDialog = (previewVideo) => {
    this.setState({
      previewVideo,
      showVideoDialog: true,
    });
  }

  closeShowVideoDialog = () => {
    this.setState({
      previewVideoId: 0,
      showVideoDialog: false,
    });
  }

  openDeleteDialog = (deleteVideo) => {
    this.setState({
      deleteDialog: true,
      deleteVideo,
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      deleteVideo: null,
    });
  }

  selectAllPodcasts = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? this.props.rows.map(({ id }) => String(id)) : [];
    this.setState({ selectAll: checked, selectedIds });
  }

  selectOnePodcast = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
        selectedIds.push(value);
    } else {
        const index = selectedIds.indexOf(value);
        if (index !== -1) {
            selectedIds.splice(index, 1);
        }
    }
    this.setState({ selectedIds });
  }

  onCreate = ({ success }) => {
    this.setState({
      success,
      openSuccess: true,
      createVideoDialog: false,
    });
    this.props.reload();
  };

  deleteVideo = async () => {
    if (this.state.deleteVideo) {
      try {
        await Http.delete(`/podcasts/${this.state.deleteVideo}`);
        this.setState({
          success: 'Le podcast a été supprimé',
          openSuccess: true,
          deleteDialog: false,
          deleteVideo: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          deleteVideo: null,
        });
      }
    }
  }

  loadTags = async (setInState = true) => {
    try {
      const { data } = await Http.get('/tags');
      if (setInState) {
        this.setState({ tags: data });
      }
      return data;
    } catch (_err) {
      // silent error
    }
  }

  loadTrainings = async (setInState = true) => {
    try {
      const { data } = await Http.get('/trainings/list');
      if (setInState) {
        this.setState({ trainings: data });
      }
      return data;
    } catch (_err) {
      // silent error
    }
  }

  loadPodcastTrainings = async (setInState = true) => {
    try {
      const { data } = await Http.get('/podcasts/trainings');
      if (setInState) {
        this.setState({ podcastTrainings: data });
      }
      return data;
    } catch (_err) {
      // silent error
    }
  }

  affectTrainings = async (training_ids) => {
    try {
      await Http.put(`/podcasts/trainings`, training_ids);
      const podcastTrainings = await this.loadPodcastTrainings(false);
      this.setState({
        success: 'Les formations ayant accès aux podcasts ont été mises à jour',
        openSuccess: true,
        trainingDialog: false,
        podcastTrainings,
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
        trainingDialog: false,
      });
    }
  }

  render() {
    const {
      classes,
      filter,
      filterState,
      loading,
      reload,
      renderPagination,
      renderSortCell,
      renderFilter,
      rows,
    } = this.props;
    const {
      createVideoDialog,
      deleteDialog,
      error,
      localLoading,
      openError,
      openSuccess,
      previewVideo,
      selectAll,
      selectedIds,
      showVideoDialog,
      success,
      manageTagDialog,
      tags,
      trainings,
      podcastTrainings,
      tagDialog,
      trainingDialog,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };

    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        {showVideoDialog && previewVideo ? (
          <Dialog
            open={showVideoDialog}
            onClose={this.closeShowVideoDialog}
          >
            <DialogTitle>Aperçu podcast</DialogTitle>
            <Box px={3} pb={3}>
              <div
                className={classes.landscapeContainer}
                dangerouslySetInnerHTML={{ __html: previewVideo }}
              />
            </Box>
          </Dialog>) : null
        }
        <ConfirmationDialog
          open={deleteDialog}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer ce podcast ?"
          onClose={this.closeDeleteDialog}
          onConfirm={this.deleteVideo}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <Fade in={selectedIds.length > 0}>
            <Badge color="primary" badgeContent={selectedIds.length}>
              <Button
                size="small"
                variant="contained"
                disableElevation
                onClick={() => this.openTagDialog()}
              >
                Affecter à un tag
              </Button>
            </Badge>
          </Fade>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.openTrainingDialog}
            >
              Formations
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.openManageTagDialog}
            >
              Tags
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.openCreateVideoDialog}
            >
              Ajouter un podcast
            </Button>
          </Box>
          {!localLoading && (
            <Fragment>
              <TagDialogManage
                open={manageTagDialog}
                tags={tags}
                loadTags={this.loadTags}
                reload={reload}
                onClose={this.closeManageTagDialog}
              />
              <TagDialogAffect
                key={selectedIds.join('-')}
                open={tagDialog}
                selectedIds={selectedIds}
                tags={tags}
                reload={reload}
                onClose={this.closeTagDialog}
              />
              <TrainingDialogAffect
                open={trainingDialog}
                trainings={trainings}
                podcastTrainings={podcastTrainings}
                onClose={this.closeTrainingDialog}
                onAffect={this.affectTrainings}
              />
            </Fragment>
          )}
          <VideoDialogCreate
            open={createVideoDialog}
            onClose={this.closeCreateVideoDialog}
            onCreate={this.onCreate}
            bulkCreateEndpoint="/podcasts/bulkCreate"
            context={{
              one: 'un podcast',
              some: 'des podcasts',
              the: 'le podcast',
              them: 'les podcasts',
            }}
          />
          <FilterInput
            filter={filter}
            filterBy="tag"
            placeholder="Filtrer par tag"
            defaultValue={filterState.tag}
          />
          {renderFilter('Filtrer par podcast')}
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left"><Checkbox onChange={this.selectAllPodcasts} checked={selectAll} /></TableCell>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell>Nom</TableCell>
              <TableCell align="center">Info</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(p => (
              <TableRow key={p.id}>
                <TableCell>
                  <Checkbox
                    value={String(p.id)}
                    onChange={this.selectOnePodcast}
                    checked={selectedIds.includes(String(p.id))} />
                </TableCell>
                <TableCell>{p.id}</TableCell>
                <TableCell>{format(new Date(p.inserted_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                <TableCell>
                  {p.name}
                  <Box display="flex">
                    {p.tags.map(({ tag }) => (
                      <Box
                        key={tag.id}
                        pr={1}
                      >
                        <Chip
                          label={tag.name}
                          size="small"
                        />
                      </Box>
                    ))}
                  </Box>
                </TableCell>
                <TableCell align="center">
                    {p.description ? (
                      <Tooltip title={p.description}>
                        <HelpIcon fontSize="small" />
                      </Tooltip>
                    ) : null}
                </TableCell>
                <TableCell align="center">
                  <img
                    className={classes.thumbnail}
                    src={p.picture}
                    alt=""
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => this.openTagDialog([String(p.id)])}
                  >
                    Tag
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    onClick={() => this.openShowVideoDialog(p.embed)}
                  >
                    Voir
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => this.openDeleteDialog(p.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

Podcasts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(Podcasts, 'podcasts', 'inserted_at', 'desc')
);
