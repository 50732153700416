import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Tooltip,
  Box,
  NativeSelect,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './StudentHomeworks.css';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import Http from 'services/Http';
import axios from 'axios';

const sortableCells = [
  { name: 'id', label: '#', isString: false },
  { name: 'uploaded_at', label: 'Date d\'ajout', isString: true },
  { name: 'topic.discipline.training.title', label: 'Formation', isString: true },
  { name: 'topic.discipline.name', label: 'Matière', isString: true },
  { name: 'topic.name', label: 'Sujet', isString: true },
  { name: 'teacher.first_name', label: 'Professeur', isString: true },
];

class StudentHomeworks extends Component {
  state = {
    mostRecentTrainings: [],
    trainingIdFilter: 0,
  }

  source = null;

  componentWillUnmount() {
    if (this.source) {
      this.source.cancel(`Operation canceled by componentWillUnmount lifecycle method.`);
    }
  }

  async componentDidMount() {
    this.props.setData(this.props.data.homework);
    // get related trainings ordered by newest creation date
    const trainingIds = [...new Set(this.props.data.homework.map(({ topic }) => {
      return topic.discipline.training.id;
    }))];
    if (this.source) {
      this.source.cancel(`Operation canceled by the user.`);
    }
    this.source = axios.CancelToken.source();
    const { data: mostRecentTrainings } = await Http.get(`/trainings/mostRecent?ids=${trainingIds}`, {
      cancelToken: this.source.token,
    });
    this.setState({
      mostRecentTrainings,
      trainingIdFilter: mostRecentTrainings.length > 1 ? mostRecentTrainings.at(0).id : 0,
    });
  }

  componentDidUpdate(prevProps) {
    const { data: { homework } } = this.props;
    const { data: { homework: prevHomework } } = prevProps;
    if (homework.length !== prevHomework.length) {
      this.props.setData(homework);
    }
  }

  render() {
    const { classes, rows, renderSortCell, renderFilter } = this.props;
    const { mostRecentTrainings, trainingIdFilter } = this.state;
    const filteredRows = rows.filter(({ topic }) => {
      return trainingIdFilter === 0 ? true : topic.discipline.training.id === trainingIdFilter;
    });
    return (
      <div className={classes.root}>
        <Toolbar>
          <Box
            display="flex"
            flex="1"
            justifyContent="flex-end"
            alignContent="center"
            style={{ gap: '6px' }}
          >
            {mostRecentTrainings.length > 1 ? (
              <NativeSelect
                value={trainingIdFilter}
                className={classes.selectInput}
                onChange={(event) => {
                  this.setState({
                    trainingIdFilter: parseInt(event.currentTarget.value, 10),
                  })
                }}
              >
                {[
                  { id: 0, title: 'Tout afficher' },
                  ...mostRecentTrainings,
                ].map(({ id, title }) => {
                  return (
                    <option
                      key={id}
                      value={id}
                    >
                      {title}
                    </option>
                  );
                })}
              </NativeSelect>
            ) : null}
            {renderFilter('Filtrer par matière', 'topic.discipline.name')}
            {renderFilter('Filtrer par sujet', 'topic.name')}
          </Box>
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell align="center">Copie</TableCell>
              <TableCell align="center">Correction</TableCell>
              <TableCell align="center">Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map(n => (
              <TableRow key={n.id}>
                <TableCell component="th" scope="row">
                  {n.id}
                </TableCell>
                <TableCell>{format(new Date(n.uploaded_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                <TableCell>{n.topic && n.topic.discipline?.training ? n.topic.discipline?.training.title : 'Aucune'}</TableCell>
                <TableCell>{n.topic && n.topic.discipline ? n.topic.discipline.name : 'Aucune'}</TableCell>
                <TableCell>{n.topic ? n.topic.name : 'Aucun'}</TableCell>
                <TableCell>
                  {n.teacher ? `${n.teacher.first_name} ${n.teacher.last_name}` : 'Aucun(e)'}
                </TableCell>
                <TableCell align="center">
                  {n.url ? (
                    <a
                      href={`${process.env.REACT_APP_API_URL}/uploads/homeworks${n.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`/icons/${n.extension}.png`}
                        width="32"
                        height="32"
                        alt={n.extension}
                      />
                    </a>
                  ) : (
                    <Tooltip title="Sans copie">
                      <ErrorIcon color="error" />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center">
                  {n.corrige_url ? (
                    <a
                      href={`${process.env.REACT_APP_API_URL}/uploads/homeworks${n.corrige_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`/icons/${n.extension}.png`}
                        width="32"
                        height="32"
                        alt={n.extension}
                      />
                    </a>
                  ) : (
                    <Tooltip title="Sans corrigé">
                      <ErrorIcon color="error" />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="subtitle2"
                    color="primary"
                  >
                    {n.note} / 20
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

StudentHomeworks.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  withLocalSortAndFilter(StudentHomeworks, 'name')
);
