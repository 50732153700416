import {
  IconButton, InputAdornment,
  TextField, withStyles
} from "@material-ui/core";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { styles } from './StudentTags.css';

import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import Http from 'services/Http';



const StudentTagsCreation = ({
  tooltip = undefined,
  label = 'Tags étudiants',
  none = 'Aucun',
  onSubmit = (tags) => { },
}) => {

  const [tags, setTags] = useState('');
  const pId = (Math.random() + 1).toString(36).substring(2);

  /**
 *  @type {[string[], any]}
 */
  const [existingTags, setExistingTags] = useState([]);
  const getBulkTags = async (tag) => {
    Http.get(
      `/users/bulk/tags`,
    ).then(({ data }) => {
      if (Array.isArray(data)) {
        setExistingTags(data);
      }
    });
    ;
  };

  useEffect(() => {
    getBulkTags();
  }, [])

  const innerSubmit = () => {
    if (tags.trim().length > 0) {
      onSubmit(tags)
      setTags('');
    }
  }

  return (<>
    <TextField
      size='small'
      variant='outlined'
      label={label}
      value={tags}
      onChange={({ target }) => setTags(target?.value ?? '')}
      onKeyDown={(event) => {
        event.stopPropagation();
        const { key } = event;
        if (key === 'Enter') {
          innerSubmit();
        }
        return false;
      }}
      inputProps={{
        list: pId,

      }}
      InputProps={{
        endAdornment: (<InputAdornment position='end'>
          <IconButton
            disabled={(tags ?? '').trim().length === 0}
            onClick={innerSubmit} edge="end"
            title={tooltip}
          >
            <LocalOfferOutlinedIcon />
          </IconButton>
        </InputAdornment>)
      }}
    />
    <AvailableTagsDatalist id={pId} none={none} existingTags={existingTags} />
  </>)
}

export const AvailableTagsDatalist = ({ id,
  none = 'Aucun',
  existingTags }) => {
  return <datalist id={id}>
    <option>{none}</option>
    {existingTags.map((tag, i) => <option key={i}>{tag}</option>)}
  </datalist>
}

AvailableTagsDatalist.propTypes = {
  id: PropTypes.string.isRequired,
  none: PropTypes.string,
  existingTags: PropTypes.arrayOf(PropTypes.string).isRequired,
}


export default withStyles((theme) => ({
  ...styles(theme),
}))(StudentTagsCreation);

