export const styles = theme => ({
    buttonBadge: {
        whiteSpace: 'nowrap',
    },
    buttonContainer: {
        padding: theme.spacing(1),
        display: 'inline-flex',
        background: theme.palette.grey['200'],
        borderRadius: theme.spacing(1),
        paddingLeft: 0,
        marginBottom: theme.spacing(1),
    },
    button: {
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    buttonCopie: {
        width: 110,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },
    link: {
        textDecoration: 'none',
    },
    rowWithoutBorder: {
        '& td, & th': {
            border: 0,
        },
    },
});
