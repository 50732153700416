import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import TrafficLight from 'components/TrafficLight/TrafficLight';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import Http from 'services/Http';
import RichTextDialog from './RichTextDialog';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ErrorIcon from '@material-ui/icons/Error';
import FilterInput from 'components/Datagrid/FilterInput';
import { FilterButton, FilterButtonGroup } from 'components/Datagrid/FilterButtons';

const sortableCells = [
  { name: 'id', label: '#' },
  { name: 'registeredAt', label: 'Inscription' },
  { name: 'first_name', label: 'Prénom' },
  { name: 'last_name', label: 'Nom' },
];

export const getTeacherStatusTitle = (teacherStatus, prefix = '') => {
  return teacherStatus === 'red'
    ? `${prefix}Indisponible`
    : teacherStatus === 'green'
      ? `${prefix}Disponible`
      : teacherStatus === 'orange'
        ? `${prefix}Incertain`
        : `${prefix}Indéfini`;
};

export const getTeacherIsLateTitle = (teacherIsLate) => {
  return !teacherIsLate || teacherIsLate === 'no'
    ? 'RAS'
    : 'En retard';
};

class Teachers extends Component {

  state = {
    snackbar: null,
    commentDialog: null,
    isLoading: false,
  };

  updateTeacherStatus = (id, teacher_id, status) => {
    return async () => {
      this.setState({ isLoading: true });
      await Http.post('/users/updateOrCreateTeacherInfos', {
        id,
        teacher_id,
        status,
      });
      this.openSnackbar('Statut modifié !', 'success');
      this.props.reload();
      this.setState({ isLoading: false });
    };
  };

  updateTeacherIsLate = (id, teacher_id) => {
    return async (event) => {
      this.setState({ isLoading: true });
      await Http.post('/users/updateOrCreateTeacherInfos', {
        id,
        teacher_id,
        is_late: event.target.value,
      });
      this.openSnackbar('Mode "en retard" modifié !', 'success');
      this.props.reload();
      this.setState({ isLoading: false });
    };
  };

  updateTeacherComment = async (id, teacher_id, comment) => {
    this.setState({ isLoading: true });
    await Http.post('/users/updateOrCreateTeacherInfos', {
      id,
      teacher_id,
      comment,
    });
    this.openSnackbar('Commentaire modifié !', 'success');
    this.props.reload();
    this.setState({ commentDialog: null, isLoading: false });
  };

  openSnackbar = (message, type) => {
    this.setState({
      snackbar: {
        message,
        type,
      }
    });
  };

  render() {
    const { classes, loading, rows, renderPagination, renderSortCell, renderFilter } = this.props;
    const { isLoading, snackbar, commentDialog } = this.state;
    const loadingStyle = { opacity: (loading || isLoading) ? .5 : 1 };
    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        {commentDialog !== null ? (
          <RichTextDialog
            title="Commentaire"
            open
            close={() => {
              this.setState({ commentDialog: null });
            }}
            save={(html) => {
              this.updateTeacherComment(commentDialog.id, commentDialog.teacher_id, html);
            }}
            initialValue={commentDialog?.html ?? ''}
          />
        ) : null}
        <ErrorSnackbar
          message={snackbar?.message ?? ''}
          onClose={() => this.setState({ snackbar: null })}
          open={snackbar?.type === 'error'}
        />
        <SuccessSnackbar
          message={snackbar?.message ?? ''}
          onClose={() => this.setState({ snackbar: null })}
          open={snackbar?.type === 'success'}
        />
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <FilterInput
              filter={this.props.filter}
              filterBy="comment"
              placeholder="Filter par commentaire"
              defaultValue={this.props.filterState.comment ?? ''}
            />
            {renderFilter('Filtrer par nom')}
            <FilterButtonGroup
              label="Statut"
              style={{ marginLeft: 0 }}
              filter={(filterBy) => this.props.filter(filterBy, true)}
              selectedIndex={!this.props.filterState?.status
                ? 0
                : this.props.filterState?.status === 'red'
                  ? 1
                  : this.props.filterState?.status === 'orange'
                    ? 2
                    : this.props.filterState?.status === 'green'
                      ? 3
                      : 0
              }
            >
              <FilterButton filterBy={{ status: '' }}>{getTeacherStatusTitle()}</FilterButton>
              <FilterButton filterBy={{ status: 'red' }}>{getTeacherStatusTitle('red')}</FilterButton>
              <FilterButton filterBy={{ status: 'orange' }}>{getTeacherStatusTitle('orange')}</FilterButton>
              <FilterButton filterBy={{ status: 'green' }}>{getTeacherStatusTitle('green')}</FilterButton>
            </FilterButtonGroup>
            <FilterButtonGroup
              label="En retard"
              style={{ marginLeft: 0 }}
              filter={(filterBy) => this.props.filter(filterBy, true)}
              selectedIndex={!this.props.filterState?.isLate
                ? 0
                : this.props.filterState?.isLate === 'yes'
                  ? 1
                  : 2
              }
            >
              <FilterButton filterBy={{ isLate: '' }}>Indifférent</FilterButton>
              <FilterButton filterBy={{ isLate: 'yes' }}>{getTeacherIsLateTitle('yes')}</FilterButton>
              <FilterButton filterBy={{ isLate: 'no' }}>{getTeacherIsLateTitle('no')}</FilterButton>
            </FilterButtonGroup>
          </Box>
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell align="left">Email</TableCell>
              <TableCell align="center">Statut</TableCell>
              <TableCell>Commentaire</TableCell>
              <TableCell>En retard</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((n) => {
              const teacherStatus = n.teacherInfos?.status;
              const teacherStatusTitle = getTeacherStatusTitle(teacherStatus, 'Statut : ');
              return (
                <TableRow key={n.id}>
                  <TableCell component="th" scope="row">
                    {n.id}
                  </TableCell>
                  <TableCell>{format(new Date(n.registeredAt), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                  <TableCell>{n.first_name}</TableCell>
                  <TableCell>{n.last_name}</TableCell>
                  <TableCell>
                    <Box display="flex" style={{ gap: '10px', alignItems: 'center' }}>
                      <Tooltip title={n.email} interactive>
                        <HelpIcon fontSize="small" />
                      </Tooltip>
                      <Tooltip title={`Copier le mail ${n.email}`}>
                        <IconButton
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(n.email);
                              this.openSnackbar('Email copié !', 'success');
                            } catch (err) {
                              this.openSnackbar('Une erreur est survenue', 'error');
                            }
                          }}
                        >
                          <FileCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={teacherStatusTitle} placement="top-start">
                      <Box>
                        <TrafficLight
                          RedOn={teacherStatus === 'red'}
                          GreenOn={teacherStatus === 'green'}
                          YellowOn={teacherStatus === 'orange'}
                          Size={20}
                          onRedClick={this.updateTeacherStatus(n.teacherInfos?.id, n.id, 'red')}
                          onGreenClick={this.updateTeacherStatus(n.teacherInfos?.id, n.id, 'green')}
                          onYellowClick={this.updateTeacherStatus(n.teacherInfos?.id, n.id, 'orange')}
                        />
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" style={{ flexDirection: 'column', gap: '10px', alignItems: 'flex-start' }}>
                      <Box dangerouslySetInnerHTML={{ __html: n.teacherInfos?.comment ?? '-' }} />
                      <Button
                        disableElevation
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          this.setState({
                            commentDialog: {
                              html: n.teacherInfos?.comment ?? '',
                              id: n.teacherInfos?.id,
                              teacher_id: n.id,
                            },
                          });
                        }}
                      >
                        Modifier
                      </Button>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <RadioGroup
                      aria-label="isLate"
                      name="is_late"
                      className={classes.group}
                      value={n.teacherInfos?.is_late ?? null}
                      onChange={this.updateTeacherIsLate(n.teacherInfos?.id, n.id)}
                    >
                      <FormControlLabel
                        control={<Radio className={classes.radio} />}
                        value="yes"
                        label={
                          <Tooltip title="Le professeur sera alerté de son retard dans son espace réservé">
                            <Box display="flex" style={{ gap: '6px', alignItems: 'center' }}>
                              <Typography variant="inherit">{getTeacherIsLateTitle('yes')}</Typography>
                              <ErrorIcon style={{  color: 'red' }} />
                            </Box>
                          </Tooltip>
                        }
                      />
                      <FormControlLabel
                        control={<Radio className={classes.radio} />}
                        value="no"
                        label={
                          <Box display="flex" style={{ gap: '6px', alignItems: 'center' }}>
                            <Typography variant="inherit">{getTeacherIsLateTitle('no')}</Typography>
                            <CheckBoxIcon style={{  color: 'green' }} />
                          </Box>
                        }
                      />
                    </RadioGroup>
                  </TableCell>
                  <TableCell align="center">
                    <ButtonRouter
                      variant="contained"
                      size="small"
                      label="Détail"
                      to={`/teachers/${n.id}`}
                      disableElevation
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

Teachers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(tableStyles)(
  withSortAndPagination(Teachers, 'users/teachers', 'last_name', 'asc')
);
