import { Box, Button, ButtonProps, useTheme } from '@material-ui/core';
import Menu from '../../Menu/Menu';

const defaultButtonProps: NonNullable<MenuItem['buttonProps']> = {
  size: 'small',
  variant: 'outlined',
  color: 'default',
  disableElevation: true,
};

type MenuItem = {
  label: string
  buttonProps?: ButtonProps<'button'> | null
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => unknown,
}

export const defaultExportItems: Array<MenuItem> = [
  {
    label: 'COLISHIP',
    buttonProps: undefined,
  },
  {
    label: 'EMAILS',
    buttonProps: undefined,
  },
  {
    label: 'XLS',
    buttonProps: undefined,
  },
  {
    label: 'STICKERS',
    buttonProps: undefined,
  },
  {
    label: 'ENVOYER',
    buttonProps: {
      ...defaultButtonProps,
      variant: 'contained',
    },
  },
  {
    label: 'ANNULER',
    buttonProps: {
      ...defaultButtonProps,
      variant: 'contained',
      color: 'primary',
    },
  },
  {
    label: 'PAS ENVOYER',
    buttonProps: {
      ...defaultButtonProps,
      variant: 'contained',
      color: 'secondary',
    },
  },
];

type ExportMenuButtonsProps = {
  items: Array<MenuItem>
  onClick: (event: React.MouseEvent<HTMLButtonElement>, labelId: MenuItem['label']) => unknown
}

export const ExportMenu = ({
  items,
  onClick,
}: ExportMenuButtonsProps) => {

  const theme = useTheme();

  const innerOnClick = (labelId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick(event, labelId);
  };

  const castedItems = items.map(({ label, onClick: itemOnClick, buttonProps }) => {
    itemOnClick = itemOnClick ?? innerOnClick(label);
    return {
      label,
      onClick: itemOnClick,
      buttonProps: buttonProps === undefined ? defaultButtonProps : buttonProps,
    } as MenuItem;
  });

  return <Box
    pl={1}
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      flex: 1,
      [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
        '& .asMenu': {
          display: 'none',
        },
        '& .asButtons': {
          display: 'flex',
          gap: theme.spacing(1),
        },
      },
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        '& .asMenu': {
          display: 'flex',
        },
        '& .asButtons': {
          display: 'none',
        },
      },
    }}
  >
    <Box className='asMenu'>
      <Menu
        title="Actions"
        items={castedItems}
      />
    </Box>
    <Box className='asButtons'>
      {castedItems.map((item) => {
        return (
          <Button
            key={item.label}
            {...item.buttonProps}
            onClick={item.onClick}
          >
            {item.label}
          </Button>
        );
      })}
    </Box>
  </Box>;
};