import { Box, Button, Collapse, LinearProgress, TextField, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import TableList from './TableList';


export type Bookmark = {
  id: number
  url: string
  name: string
  desc: string
}

export type BookmarkListProps = {
  bookmarks: Bookmark[]
  scope: string
  onSubmit: (formData: FormData) => Promise<unknown>
  onGoto: (url: string) => void
}
export default function List({
  bookmarks,
  scope,
  onSubmit,
  onGoto,
  ...props
}: BookmarkListProps) {

  const t = useTheme();
  const url = window.location.toString();
  const [intent, setIntent] = useState('update');
  const [addNew, setAddNew] = useState(false);
  const getDefaultFocusItem = useCallback(() =>
    bookmarks.find(bm => bm.url === url) ?? {
      id: 0,
      name: '',
      desc: null,
      url,
    }, [url, bookmarks]);

  const [status, setStatus] = useState<'idle' | 'submitting' | 'error' | 'success'>('idle');

  const [focusedItem, setFocusedItem] = useState<Bookmark>(getDefaultFocusItem());

  useEffect(() => {
    setFocusedItem(getDefaultFocusItem());
  }, [bookmarks, getDefaultFocusItem]);

  const submitForm = (formData: FormData) => {
    setStatus('submitting');
    onSubmit(formData)
      .then(_ => {
        setStatus('success');
        setAddNew(false);
      })
      .catch(_ => setStatus('error'));
  };

  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    const { target } = e;

    if (target && target instanceof HTMLFormElement) {
      const fd = new FormData(target);
      fd.append('intent', intent);
      submitForm(fd);
    }

    e.preventDefault();
    return false;
  };

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setIntent(e.currentTarget.value);
  };

  const handleDelete = (url: string) => {
    const fd = new FormData();
    fd.append('intent', 'delete');
    fd.append('url', url);
    fd.append('scope', scope);
    submitForm(fd);
  };

  const hasAlready = bookmarks.some(bm => bm.url === url);

  const inputStyle = {
    backgroundColor: t.palette.common.white
  };

  return <Box style={{
    padding: '1em',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  }}>
    <Typography variant='h5'>Vos favoris</Typography>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '.3em',
      maxHeight: '100%',
      overflow: 'hidden',
    }}>
      <div style={{
        flexGrow: '1',
        overflow: 'auto',
      }}>
        <div style={{
          paddingBlock: '1em',
        }}>
          {addNew === false ? <Button
            onClick={e => setAddNew(true)}
            variant={'contained'} color="primary" disabled={hasAlready}>{hasAlready ? 'Déjà défini' : 'Nouveau'}</Button>
            : <Button
              onClick={e => setAddNew(false)}
              variant={'contained'} color="default" >Annuler</Button>}
        </div>
        <Collapse in={addNew}>
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1em',
              minWidth: '45%',
              backgroundColor: t.palette.grey[100],
              padding: '1.2em',
              borderRadius: '.2em',
            }}
            onSubmit={handleFormSubmit}
            onReset={e => setFocusedItem(getDefaultFocusItem())}

          >
            <input type="hidden" name="scope" defaultValue={scope} />
            <TextField
              size='small'
              variant='outlined'
              label={'URL'}
              name="url"
              required={true}
              defaultValue={focusedItem.url}
              style={inputStyle}
            />
            <TextField
              size='small'
              variant='outlined'
              label={'Titre'}
              name="name"
              required={true}
              defaultValue={focusedItem.name}
              style={inputStyle}

            />
            <TextField
              size='small'
              variant='outlined'
              multiline={true}
              label={'Description'}
              name="desc"
              minRows={'5'}
              defaultValue={focusedItem.desc ?? ''}
              style={inputStyle}

            />

            <Button type='submit' variant='contained' color='primary' name='intent' value="create" onClick={handleClick}>Créer</Button>
          </form>
          <hr style={{ marginBlock: '2em' }} />
        </Collapse>
        {bookmarks.length === 0 ?
          <Typography variant='h6'>Aucun favoris</Typography>
          :
          <TableList
            bookmarks={bookmarks}
            scope={scope}
            handleGoto={onGoto}
            handleDelete={handleDelete}
          />
        }
      </div>
    </div>
    <div style={{
      // border: '1px solid red',
      lineHeight: 1,
      minHeight: '1em',
    }}>
      {status === 'submitting' && <LinearProgress />}
      {/* {status === 'success' && <>Mise à jour effectuée</>} */}
      {status === 'error' && <>Erreur 😢</>}
    </div>
  </Box >;

}