import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const styles = _theme => ({
  root: {
    padding: 0,
  },
});

class SidebarUser extends Component {
  render() {
    const { classes, user } = this.props;

    return (
      <List dense className={classes.root}>
        <ListItem button component="li">
          <ListItemAvatar>
            <Avatar alt={user.login} src={`https://ui-avatars.com/api/?name=${user.login}&length=4&font-size=0.25&background=850e0e&color=fff&size=128`} />
          </ListItemAvatar>
          <ListItemText primary={user.login} />
        </ListItem>
      </List>
    );
  }
}

SidebarUser.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarUser);
