import { Box, Button, withTheme } from '@material-ui/core';
import MovieIcon from '@material-ui/icons/Movie';
import WarningIcon from '@material-ui/icons/Warning';

const CreateOrUpdateReplayButton = ({
  theme,
  timetable,
  onVideoDialogClick,
  openSnackbar,
  onClick,
}) => {
  const { timetableDisciplines } = timetable;
  const hasAffectedVideos = timetableDisciplines.length > 0 && timetableDisciplines.every(({ video_id }) => video_id !== null);
  return (
    <Box display="flex" style={{ gap: theme.spacing(1) }} alignItems="center" justifyContent="center">
      <Button
        size="small"
        variant="contained"
        disableElevation
        color={hasAffectedVideos ? 'primary' : 'secondary'}
        endIcon={<MovieIcon />}
        startIcon={hasAffectedVideos
          ? null
          : <WarningIcon />
        }
        onClick={() => {
          if (timetableDisciplines.length === 0) {
            openSnackbar(`Merci d'affecter préalablement une ou des matières`, 'error');
          } else {
            onClick({
              timetableId: timetable.id,
            });
          }
        }}
      >
        {timetable?.video?.name ?? 'Affecter'}
      </Button>
      {hasAffectedVideos ? (
        <Button
          color="default"
          disableElevation
          variant="contained"
          size="small"
          onClick={() => {
            onVideoDialogClick({
              embed: timetableDisciplines.at(0).video.embed,
            });
          }}
        >
          Voir
        </Button>
      ) : null}
    </Box>
  );
};

export default withTheme(CreateOrUpdateReplayButton);
