import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Box,
  Button,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  withStyles,
  Typography,
  Chip,
  Tooltip,
  withTheme,
} from '@material-ui/core';
import { tableStyles as styles } from 'styles/datatable.css';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import { utcToZonedTime } from 'date-fns-tz';
import OrderRetractation from 'components/Orders/OrderRetractation';
import { mapStatusToColor } from 'components/Orders/Orders';
import { mapStatusLabel } from 'components/Orders/Orders';
import OrdersDialog, { sponsoredOrder } from 'components/OrdersDialog/OrdersDialog';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ProductCard from 'components/ProductCard/ProductCard';
import { formatAmount } from 'components/Orders/OrdersPayments';
import { Shipment } from 'components/Shipment/Shipment';
import Http from 'services/Http';
import PaymentStateDialog from 'components/Payzen/PaymentStateDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import PaymentIcon from '@material-ui/icons/Payment';
import { formatInTimeZone } from 'date-fns-tz';

const sortableCells = [
  { name: 'id', label: 'ID', isString: false },
];

const initialState = () => {
  return {
    error: '',
    openError: false,
    openSuccess: false,
    success: '',
    loading: false,
    selectAll: false,
    polycopiesIds: [],
    statusDialog: null,
    doNotSend: false,
    setSentStateDialog: null,
    orderId: null,
    orderPaymentState: null,
    snackbar: null,
  };
};

class StudentOrders extends Component {
  state = initialState();

  async componentDidMount() {
    this.loadPolycopies();
  }

  openSnackbar = (message, type) => {
    this.setState({
      snackbar: {
        message,
        type,
      }
    });
  };

  loadPolycopies = async () => {
    this.props.setData(this.props.data.orders);
  }

  render() {
    const {
      classes,
      renderSortCell,
      rows,
      match,
      theme,
    } = this.props;
    const {
      loading,
      orderPaymentState,
      snackbar,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };
    const { orderId, view, studentId } = match.params;
    return (
      <Paper elevation={0} className={classes.root} style={loadingStyle}>
        <ErrorSnackbar
          message={snackbar?.message ?? ''}
          onClose={() => this.setState({ snackbar: null })}
          open={snackbar?.type === 'error'}
        />
        <SuccessSnackbar
          message={snackbar?.message ?? ''}
          onClose={() => this.setState({ snackbar: null })}
          open={snackbar?.type === 'success'}
        />
        {orderPaymentState !== null ? (
          <PaymentStateDialog
            open={orderPaymentState !== null}
            onClose={() => {
              this.setState({ orderPaymentState: null });
            }}
            orderPaymentState={orderPaymentState}
            openSnackbar={this.openSnackbar}
          />
        ) : null}
        <OrdersDialog
          open={orderId !== undefined}
          onClose={() => {
            this.props.history.push(`/students/${studentId}/orders`);
          }}
          onCreate={() => { }}
          // below props from router
          history={this.props.history}
          orderId={orderId}
          view={view}
          baseUrl={`/students/${studentId}/orders`}
        />
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell>Date</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>Subventionné</TableCell>
              <TableCell>Produit</TableCell>
              <TableCell>Rétractation</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((order) => {
              const sponsored = sponsoredOrder.find(({ value }) => {
                return value === (order.sponsored ?? 0);
              }) ?? sponsoredOrder.at(0);
              return (
                <TableRow key={order.id}>
                  <TableCell>{order.id}</TableCell>
                  <TableCell>
                    {
                      format(
                        utcToZonedTime(order.created_at, 'Etc/UTC'),
                        'dd MMM yyyy à HH:mm',
                        { locale: frLocale },
                      )
                    }
                  </TableCell>
                  <TableCell>
                    <Box
                      display="flex"
                      style={{ gap: '8px' }}
                      alignItems="center"
                    >
                      <Chip
                        size="small"
                        style={{
                          backgroundColor: mapStatusToColor[order.status]
                        }}
                        label={mapStatusLabel[order.status]}
                      />
                      {
                        (order.status === 'wc-completed' && order.completed_at)
                          ? (
                            <Typography variant="caption">
                              {
                                ` le ${format(
                                  utcToZonedTime(order.completed_at, 'Etc/UTC'),
                                  'dd/MM/yyyy HH:mm',
                                  { locale: frLocale },
                                )}`
                              }
                            </Typography>
                          )
                          : null
                      }
                      <Shipment shipment={order.polycopies} />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color={sponsored.value === 0 ? 'primary' : 'secondary'}
                      label={sponsored.label}
                    />
                  </TableCell>
                  <TableCell>
                    <Box style={{ marginTop: '-6px' }}>
                      {order.orderItems.map(orderItem => (
                        <ProductCard variant="caption" key={`${order.id}-${orderItem.id}`} {...orderItem} />
                      ))}
                      <Typography
                        variant="caption"
                      >
                        {`${order.payment_title ?? ''}${order.payment_method === 'cheque-several' ? ` (${order.payment_periods} x)` : ''} ${formatAmount(order.total)}`}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <OrderRetractation post={order} />
                  </TableCell>
                  <TableCell>
                    <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={<ShoppingCartIcon fontSize="small" />}
                        endIcon={<OpenInNewIcon fontSize="small" />}
                        style={{
                          minWidth: 100,
                        }}
                        onClick={() => {
                          this.props.history.push(`/students/${studentId}/orders/${order.id}`);
                        }}
                      >
                        {`#${order.id}`}
                      </Button>
                      <Tooltip title={`Échéancier ${order.id}`}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={async () => {
                            this.setState({ loading: true });
                            try {
                              const { data } = await Http.get(`/payzen/getInstallmentOptionsFromOrderId/${order.id}`);
                              this.setState({ orderPaymentState: data });
                            } catch (e) {
                              this.openSnackbar(e?.data?.message ?? 'Erreur inconnue');
                            } finally {
                              this.setState({ loading: false });
                            }
                          }}
                        >
                          <Box
                            display="flex"
                            style={{
                              gap: theme.spacing(1),
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src="/payzen-logo.png"
                              alt=""
                              style={{ height: '20px', with: 'auto' }}
                            />
                            {order.payment_order_created_at ? (
                              <Typography
                                variant="caption"
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: (theme.spacing(1)),
                                  backgroundColor: order.validPaymentLink ? theme.palette.warning.main : theme.palette.error.main,
                                  color: theme.palette.getContrastText(order.validPaymentLink ? theme.palette.warning.main : theme.palette.error.main),
                                  padding: `1px ${theme.spacing(1)}px`,
                                  borderRadius: '6px',
                                }}
                              >
                                <PaymentIcon fontSize="inherit" />
                                Lien paiement {formatInTimeZone(new Date(order.payment_order_created_at), 'Etc/Utc', 'dd/MM/yyyy HH:mm')}
                                {order.validPaymentLink ? null : <strong>(expiré)</strong>}
                              </Typography>
                            ) : null}
                          </Box>
                        </Button>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

StudentOrders.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
};

export default withTheme(withStyles(styles)(
  withLocalSortAndFilter(StudentOrders, 'id', 'desc')
));
