import React from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
    Box,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './TeacherHome.css';
import { getTeacherIsLateTitle, getTeacherStatusTitle } from 'components/Teachers/Teachers';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ErrorIcon from '@material-ui/icons/Error';

const TeacherHome = ({ data: { email, first_name, last_name, login, registeredAt, teacherInfos } }) => (
    <Box textAlign="left" px={3} pt={3}>
        <Box display="flex">
            <Box width={120} fontWeight={500}>Prénom :</Box>
            <Box>{first_name}</Box>
        </Box>
        <Box display="flex">
            <Box width={120} fontWeight={500}>Nom :</Box>
            <Box>{last_name}</Box>
        </Box>
        <Box display="flex">
            <Box width={120} fontWeight={500}>Email :</Box>
            <Box>{email}</Box>
        </Box>
        <Box display="flex">
            <Box width={120} fontWeight={500}>Pseudo :</Box>
            <Box>{login}</Box>
        </Box>
        <Box display="flex">
            <Box width={120} fontWeight={500}>Inscrit(e) le :</Box>
            <Box>{format(new Date(registeredAt), 'dd MMMM yyyy', { locale: frLocale })}</Box>
        </Box>
        {teacherInfos ? (
            <Box>
                <Box display="flex">
                    <Box width={120} fontWeight={500}>Statut :</Box>
                    <Box>{getTeacherStatusTitle(teacherInfos.status)}</Box>
                </Box>
                <Box display="flex">
                    <Box width={120} fontWeight={500}>Commentaire :</Box>
                    <Box dangerouslySetInnerHTML={{ __html: teacherInfos?.comment ?? '-' }} />
                </Box>
                <Box display="flex">
                    <Box width={120} fontWeight={500}>En retard :</Box>
                    <Box>
                        {teacherInfos.is_late === 'yes' ? (
                            <Box display="flex" style={{ gap: '6px', alignItems: 'center' }}>
                                <Typography variant="inherit">{getTeacherIsLateTitle('yes')}</Typography>
                                <ErrorIcon />
                            </Box>
                        ) : (
                            <Box display="flex" style={{ gap: '6px', alignItems: 'center' }}>
                                <Typography variant="inherit">{getTeacherIsLateTitle('no')}</Typography>
                                <CheckBoxIcon />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        ) : null}
    </Box>
);

TeacherHome.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeacherHome);
