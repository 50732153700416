import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  withStyles,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { tableStyles } from 'styles/datatable.css';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
class OrderAddress extends Component {

  state = {
    openSuccess: false,
    openError: false,
    message: '',
  };

  onSubmit = async (event) => {
    event.preventDefault();
    const { kind, student } = this.props;
    const formData = new FormData(document.getElementById(`${kind}-form`));
    const endpoint = `/users/student/${student.id}/${kind}`;
    try {
      await Http.put(endpoint, Object.fromEntries(formData.entries()));
      this.setState({
        openSuccess: true,
        message: `Les données "${kind} ont bien été enregistrées. La page va s'actualiser.`,
      });
    } catch (e) {
      this.setState({
        openError: true,
        message: `Les données "${kind} n'ont pas pu être enregistrées. La page va s'actualiser.`,
      });
    }
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }

  renderSnackbar() {
    const { openError, openSuccess, message } = this.state;
    return openError ? (
      <ErrorSnackbar
          message={message}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
    ) : openSuccess ? (
        <SuccessSnackbar
          message={message}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
    ) : null;
  }

  render() {
    const { kind, student, classes } = this.props;

    if (kind === 'general') {
      return (
        <form id="general-form" onSubmit={this.onSubmit}>
          {this.renderSnackbar()}
          <Table size="small" key={`${kind}-${student.id}`}>
            <TableBody>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>
                  <Box display="flex" style={{ gap: '6px' }}>
                    <InputBase
                      name="last_name"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.last_name}
                      placeholder="Nom"
                    />
                    <InputBase
                      name="first_name"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.first_name}
                      placeholder="Prénom"
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contact</TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    style={{ gap: '6px' }}
                    flexDirection="row"
                  >
                    <IconButton
                      size="small"
                      href={`mailto: ${student.email}`}
                      target="_blank"
                    >
                      <EmailIcon fontSize="small" />
                    </IconButton>
                    <InputBase
                      name="email"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.email ?? ''}
                      placeholder="Email"
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Box display="flex" justifyContent="space-between" flexDirection="row">
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      type="reset"
                    >
                      Reset
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      type="submit"
                    >
                      Enregistrer
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </form>
      );
    }

    if (kind === 'billing') {
      return (
        <form id="billing-form" onSubmit={this.onSubmit}>
          {this.renderSnackbar()}
          <Table size="small" key={`${kind}-${student.id}`}>
            <TableBody>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>
                  <Box display="flex" style={{ gap: '6px' }}>
                    <InputBase
                      name="billing_last_name"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_last_name}
                      placeholder="Nom"
                    />
                    <InputBase
                      name="billing_first_name"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_first_name}
                      placeholder="Prénom"
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Société</TableCell>
                <TableCell>
                  <InputBase
                    name="billing_company"
                    classes={{
                      root: classes.field,
                      input: classes.fieldInput,
                    }}
                    defaultValue={student.billing_company ?? ''}
                    placeholder="Société"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Adresse</TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    style={{ gap: '6px' }}
                    flexDirection="column"
                  >
                    <InputBase
                      name="billing_address_1"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_address_1 ?? ''}
                      placeholder="Adresse 1"
                    />
                    <InputBase
                      name="billing_address_2"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_address_2 ?? ''}
                      placeholder="Adresse 2"
                    />
                  </Box>
                  <Box
                    display="flex"
                    style={{ gap: '6px' }}
                    flexDirection="row"
                    mt={1}
                  >
                    <InputBase
                      name="billing_postcode"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_postcode ?? ''}
                      placeholder="CP"
                      style={{ maxWidth: '60px' }}
                    />
                    <InputBase
                      name="billing_city"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_city ?? ''}
                      placeholder="Ville"
                      style={{ flex: 1 }}
                    />
                    <InputBase
                      name="billing_country"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_country ?? ''}
                      placeholder="Pays"
                      style={{ maxWidth: '35px' }}
                    />
                  </Box>

                  {/* {(student.billing_address_1 || student.billing_address_2) ? (
                    <Box>
                      {`${student.billing_address_1 ?? ''} ${student.billing_address_2 ?? ''}`}
                      <br />
                      {`${student.billing_postcode ?? ''} ${student.billing_city ?? ''} ${student.billing_country ?? ''}`}
                    </Box>
                  ) : (
                    <Typography variant="caption" style={{ fontStyle: 'italic' }}>Identique expédition</Typography>
                  )} */}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contact</TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    style={{ gap: '6px' }}
                    flexDirection="row"
                  >
                    <IconButton
                      size="small"
                      href={`mailto: ${student.billing_email}`}
                      target="_blank"
                    >
                      <EmailIcon fontSize="small" />
                    </IconButton>
                    <InputBase
                      name="billing_email"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_email ?? ''}
                      placeholder="Email"
                    />
                  </Box>
                  <Box
                    display="flex"
                    style={{ gap: '6px' }}
                    flexDirection="row"
                    mt={1}
                  >
                    <IconButton
                      size="small"
                      href={`tel: ${student.billing_phone}`}
                      target="_blank"
                    >
                      <PhoneIcon fontSize="small" />
                    </IconButton>
                    <InputBase
                      name="billing_phone"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.billing_phone ?? ''}
                      placeholder="Téléphone"
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Box display="flex" justifyContent="space-between" flexDirection="row">
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      type="reset"
                    >
                      Reset
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      type="submit"
                    >
                      Enregistrer
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </form>
      );
    }

    if (kind === 'shipping') {
      return (
        <form id="shipping-form" onSubmit={this.onSubmit}>
          {this.renderSnackbar()}
          <Table size="small" key={`${kind}-${student.id}`}>
            <TableBody>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>
                  <Box display="flex" style={{ gap: '6px' }}>
                    <InputBase
                      name="shipping_last_name"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.shipping_last_name}
                      placeholder="Nom"
                    />
                    <InputBase
                      name="shipping_first_name"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.shipping_first_name}
                      placeholder="Prénom"
                    />
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Société</TableCell>
                <TableCell>
                  <InputBase
                    name="shipping_company"
                    classes={{
                      root: classes.field,
                      input: classes.fieldInput,
                    }}
                    defaultValue={student.shipping_company ?? ''}
                    placeholder="Société"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Adresse</TableCell>
                <TableCell>
                  <Box
                    display="flex"
                    style={{ gap: '6px' }}
                    flexDirection="column"
                  >
                    <InputBase
                      name="shipping_address_1"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.shipping_address_1 ?? ''}
                      placeholder="Adresse 1"
                    />
                    <InputBase
                      name="shipping_address_2"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.shipping_address_2 ?? ''}
                      placeholder="Adresse 2"
                    />
                  </Box>
                  <Box
                    display="flex"
                    style={{ gap: '6px' }}
                    flexDirection="row"
                    mt={1}
                  >
                    <InputBase
                      name="shipping_postcode"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.shipping_postcode ?? ''}
                      placeholder="CP"
                      style={{ maxWidth: '60px' }}
                    />
                    <InputBase
                      name="shipping_city"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.shipping_city ?? ''}
                      placeholder="Ville"
                      style={{ flex: 1 }}
                    />
                    <InputBase
                      name="shipping_country"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      defaultValue={student.shipping_country ?? ''}
                      placeholder="Pays"
                      style={{ maxWidth: '35px' }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Box display="flex" justifyContent="space-between" flexDirection="row">
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      type="reset"
                    >
                      Reset
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      type="submit"
                    >
                      Enregistrer
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </form>
      );
    }

    return (
      null
    );

  }
}

OrderAddress.propTypes = {
  student: PropTypes.object.isRequired,
  kind: PropTypes.oneOf(['general', 'shipping', 'billing']),
};

export default withStyles(tableStyles)(OrderAddress);
