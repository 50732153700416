import { Badge, Button, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';

const CreateOrUpdateDisciplinesButton = ({
  timetable,
  verbose = true,
  onClick,
}) => {
  const { timetableDisciplines } = timetable;
  return (
    <Tooltip title={
      timetableDisciplines.length > 0
        ? `Éditer la liste de matières (${timetableDisciplines.map(({ discipline }) => discipline.name).join(', ')})`
        : ''
    }
    >
      <Badge badgeContent={!verbose ? timetableDisciplines.length : 0} color="primary">
        <Button
          size="small"
          variant="text"
          disableElevation
          color="primary"
          endIcon={timetableDisciplines.length > 0 ? <EditIcon /> : <AddIcon />}
          onClick={() => {
            onClick({
              timetableIds: [timetable.id],
              selectedDisciplineIds: timetableDisciplines.map(({ discipline, session }) => {
                return {
                  discipline_id: discipline.id,
                  session_id: session ? session.id : null,
                  sessionName: session ? session.name : 'Toutes les sessions',
                  disciplineName: discipline.name,
                };
              }),
            });
          }}
        >
          {timetableDisciplines.length > 0 ? '' : 'Ajouter'}
        </Button>
      </Badge>
    </Tooltip >
  );
};

export default CreateOrUpdateDisciplinesButton;
