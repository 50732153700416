import { useEffect, useRef, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputBase,
    makeStyles,
    // withMobileDialog,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { styles } from './UploadDialogWithCustomName.css';
import Http from 'services/Http';
import ErrorSnackbar from 'components/Snackbar/Error';

const UploadDialogWithCustomName = ({
    context,
    existingFile = null,
    endpoint = 'upload',
    onClose,
    onUploadCompleted,
    open,
    singleUpload = false,
}) => {
    const useStyles = makeStyles((theme) => styles(theme));
    const [error, setError] = useState([]);
    const [files, setFiles] = useState([]);
    const [names, setNames] = useState([]);
    const [openError, setOpenError] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [description, setDescription] = useState('');
    const [title, setTitle] = useState('');
    const fileInput = useRef(null);
    const classes = useStyles();

    const resetState = () => {
        setError([]);
        setFiles([]);
        setNames([]);
        setPercentCompleted(0);
    }

    const handleFiles = (event) => {
        event.preventDefault();
        setFiles(files.concat(Array.from(fileInput.current.files)));
        setNames(names.concat(Array.from(fileInput.current.files).map(({ name }) => name )));
    }

    const deleteFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
        setNames(names.filter((_, i) => i !== index));
    }

    const onChangeName = ({ target }, index) => {
        const { value } = target;
        setNames(names.map((currVal, currIndex) => currIndex === index ? value : currVal));
    }

    const uploadDocuments = async () => {
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append('file', file);
            formData.append('name', names[index]);
        });
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setPercentCompleted(percentCompleted);
            },
        }
        try {
            await Http.post(`/${context}/${endpoint}`, formData, config);
            resetState();
            onUploadCompleted();
        } catch ({ data }) {
            setError(data ? Object.values(data) : ['Une erreur est survenue']);
            setOpenError(true);
            setPercentCompleted(0);
        }
    }

    const closeDialog = () => {
        resetState();
        onClose();
    }

    useEffect(() => {
        setTitle(singleUpload ? 'Ajout de document' : 'Ajout de documents');
        setDescription(singleUpload ? 'Choisissez un fichier' : 'Choisissez un ou plusieurs fichiers');
    }, [singleUpload]);

    return (
        <Dialog
            open={open}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    component="div"
                >
                    {files[0]
                        ? files.map((file, index) => (
                            <div key={file.name}>
                                <IconButton
                                    className={classes.button}
                                    onClick={() => deleteFile(index)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <InputBase
                                    classes={{
                                        root: classes.field,
                                        input: classes.fieldInput,
                                    }}
                                    placeholder="Nom"
                                    name="name"
                                    value={names[index]}
                                    onChange={(event) => onChangeName(event, index)}
                                />
                                fichier : {file.name}
                            </div>
                        ))
                        : description
                    }
                </DialogContentText>
                <DialogContentText>
                    <input
                        accept=".xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.pdf"
                        style={{ display: 'none' }}
                        id="button-file"
                        type="file"
                        ref={fileInput}
                        onChange={handleFiles}
                        multiple={!singleUpload}
                    />
                    <label htmlFor="button-file">
                        <Button
                            color="primary"
                            component="span" 
                            variant="contained"
                        >
                            Parcourir
                        </Button>
                    </label>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ErrorSnackbar
                    open={openError}
                    onClose={() => setOpenError(false)}
                    message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
                />
                {existingFile && (
                    <Button onClick={(event) => { event.preventDefault(); global.open(existingFile); }} color="primary">
                        Voir le fichier
                    </Button>
                )}
                <Button onClick={closeDialog} color="primary">
                    Annuler
                </Button>
                <Button onClick={uploadDocuments} color="primary">
                    {percentCompleted ? `${percentCompleted}%` : 'Sauvegarder'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default UploadDialogWithCustomName;
