import { alpha } from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  dialog: {
    padding: theme.spacing(3),
  },
  title: {
    flex: 1,
  },
  field: {
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
    color: 'inherit',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
  },
  fieldInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create('width'),
    width: 230,
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
});
