export const styles = theme => ({
  chip: {
    width: 38,
  },
  chipError: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    fontSize: '11px',
    border: 0,
    height: '18px',
  },
  chipSuccess: {
    color: '#fff',
    backgroundColor: theme.palette.success.dark,
    fontSize: '11px',
    border: 0,
    height: '18px',
  },
});
