import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import get from 'lodash.get';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { tableStyles } from 'styles/datatable.css';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import ButtonRouter from 'components/ButtonRouter/ButtonRouter';
import withLocalSortAndFilter from 'components/withLocalSortAndFilter/withLocalSortAndFilter';
import Http from 'services/Http';

const sortableCells = [
    { name: 'id', label: '#', isString: false },
    { name: 'registeredAt', label: 'Inscription', isString: false },
];

class SessionStudents extends Component {
    state = {
        error: '',
        openError: false,
        success: '',
        openSuccess: false,
        deleteDialog: false,
        deleteStudent: null,
    }

    componentDidMount() {
        this.props.setData(this.props.data.students);
    }

    componentDidUpdate(prevProps) {
        const { data: { students } } = this.props;
        const { data: { students: prevStudents } } = prevProps;
        if (students.length !== prevStudents.length) {
            this.props.setData(students);
        }
    }

    // DIALOGS
    onOpenDeleteDialog = (deleteStudent) => {
        this.setState({
            deleteDialog: true,
            deleteStudent,
        });
    }

    onCloseDeleteDialog = () => {
        this.setState({
            deleteDialog: false,
            deleteStudent: null,
        });
    }

    // CRUD
    onStudentDelete = async () => {
        if (this.state.deleteStudent) {
            try {
                await Http.delete(`/sessions/${this.props.data.id}/students/${this.state.deleteStudent}`);
                this.setState({
                    success: 'L\'étudiant a été supprimé de cette session',
                    openSuccess: true,
                    deleteDialog: false,
                    deleteStudent: null,
                });
                this.props.reload();
            } catch ({ data }) {
                this.setState({
                    error: get(data, 'message', 'Une erreur est survenue'),
                    openError: true,
                    deleteDialog: false,
                    deleteStudent: null,
                });
            }
        }
    }

    render() {
        const { classes, rows, renderSortCell, renderFilter } = this.props;
        const { deleteDialog, error, openError, openSuccess, success } = this.state;

        return (
            <div className={classes.root}>
                <ConfirmationDialog
                    open={deleteDialog}
                    title="Attention"
                    message="Êtes-vous sûr de vouloir supprimer cet étudiant de la session ?"
                    onClose={this.onCloseDeleteDialog}
                    onConfirm={this.onStudentDelete}
                />
                <ErrorSnackbar
                    message={error}
                    onClose={() => this.setState({ openError: false })}
                    open={openError}
                />
                <SuccessSnackbar
                    message={success}
                    onClose={() => this.setState({ openSuccess: false })}
                    open={openSuccess}
                />
                <Toolbar>
                    <div className={classes.title}></div>
                    {renderFilter('Filtrer par nom', 'last_name')}
                </Toolbar>
                <Table size="small" className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {sortableCells.map(cell => renderSortCell(cell))}
                            <TableCell>Prénom</TableCell>
                            <TableCell>Nom</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(n => (
                            <TableRow key={n.id}>
                                <TableCell component="th" scope="row">
                                    {n.id}
                                </TableCell>
                                <TableCell>{format(new Date(n.registeredAt), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                                <TableCell>{n.first_name}</TableCell>
                                <TableCell>{n.last_name}</TableCell>
                                <TableCell>
                                    <ButtonRouter
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        size="small"
                                        label="Détail"
                                        to={`/students/${n.id}`}
                                        disableElevation
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => this.onOpenDeleteDialog(n.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    }
}

SessionStudents.propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
};

export default withStyles(tableStyles)(
    withLocalSortAndFilter(SessionStudents, 'id')
);
