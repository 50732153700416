import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Fade,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';
import { tableStyles } from 'styles/datatable.css';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import DisciplineDialogAffectRadio from 'components/DisciplineDialogAffectRadio/DisciplineDialogAffectRadio';
import ErrorSnackbar from 'components/Snackbar/Error';
import FilterInput from 'components/Datagrid/FilterInput';
import Http from 'services/Http';
import SuccessSnackbar from 'components/Snackbar/Success';
import { styles } from './Videos.css';
import VideoDialogCreate from 'components/VideoDialogCreate/VideoDialogCreate';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';
import VideoCategorySelect from './VideoCategory/VideoCategorySelect';

const sortableCells = [
  { name: 'id', label: '#' },
  { name: 'inserted_at', label: 'Date d\'ajout' },
];

class Videos extends Component {
  state = {
    createVideoDialog: false,
    deleteDialog: false,
    deleteVideo: null,
    disciplineDialog: false,
    error: '',
    openError: false,
    openSuccess: false,
    previewVideo: '',
    selectAll: false,
    selectedIds: [],
    showVideoDialog: false,
    success: '',
    videoCategories: [],
  }

  componentDidMount = () => {
    Http.get('/settings/videoCategories').then(({ data }) => {
      if (data && Array.isArray(data)) {
        this.setState({
          videoCategories: data,
        })
      }
    })
  }

  openDisciplineDialog = () => {
    this.setState({
      disciplineDialog: true,
    });
  }

  openDisciplineDialogForId = (videoId) => {
    this.setState({
      disciplineDialog: true,
      videoId,
    });
  }

  closeDisciplineDialog = () => {
    this.setState({ disciplineDialog: false });
  }

  openCreateVideoDialog = () => {
    this.setState({ createVideoDialog: true });
  }

  closeCreateVideoDialog = () => {
    this.setState({ createVideoDialog: false });
  }

  openShowVideoDialog = (previewVideo) => {
    this.setState({
      previewVideo,
      showVideoDialog: true,
    });
  }

  closeShowVideoDialog = () => {
    this.setState({
      previewVideoId: 0,
      showVideoDialog: false,
    });
  }

  openDeleteDialog = (deleteVideo) => {
    this.setState({
      deleteDialog: true,
      deleteVideo,
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      deleteVideo: null,
    });
  }

  onCreate = ({ success }) => {
    this.setState({
      success,
      openSuccess: true,
      createVideoDialog: false,
    });
    this.props.reload();
  };

  changeVideoCategory = async (id, category) => {
    debugger;
    try {
      await Http.put('/videos/category', { category, video_ids: [id] });

      this.setState({
        success: 'Catégorie mise à jour',
        openSuccess: true,
      });
      this.props.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }


  }

  deleteVideo = async () => {
    if (this.state.deleteVideo) {
      try {
        await Http.delete(`/videos/${this.state.deleteVideo}`);
        this.setState({
          success: 'La vidéo a été supprimée',
          openSuccess: true,
          deleteDialog: false,
          deleteVideo: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          deleteVideo: null,
        });
      }
    }
  }

  onSelectAllClick = ({ target }) => {
    const { checked } = target;
    const selectedIds = checked ? this.props.rows.map(({ id }) => String(id)) : [];
    this.setState({ selectAll: checked, selectedIds });
  }

  onSelectOne = ({ target }) => {
    const { checked, value } = target;
    const selectedIds = [...this.state.selectedIds];
    if (checked) {
      selectedIds.push(value);
    } else {
      const index = selectedIds.indexOf(value);
      if (index !== -1) {
        selectedIds.splice(index, 1);
      }
    }
    this.setState({ selectedIds });
  }

  affectDiscipline = async ({ discipline_id }) => {
    const { selectedIds, videoId } = this.state;
    const video_ids = videoId ? [videoId] : selectedIds;
    try {
      await Http.put('/videos', { discipline_id, video_ids });
      this.setState({
        success: 'Les affectations de matière ont été mises à jour',
        openSuccess: true,
        disciplineDialog: false,
        selectedIds: [],
      });
      this.props.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
        deleteDialog: false,
      });
    }
  }

  render() {
    const {
      classes,
      filter,
      filterState,
      loading,
      renderPagination,
      renderSortCell,
      renderFilter,
      rows,
    } = this.props;
    const {
      createVideoDialog,
      deleteDialog,
      disciplineDialog,
      error,
      openError,
      openSuccess,
      previewVideo,
      selectAll,
      selectedIds,
      showVideoDialog,
      success,
      videoCategories,
    } = this.state;
    const loadingStyle = { opacity: loading ? .5 : 1 };
    return (
      <Paper elevation={1} className={classes.root} style={loadingStyle}>
        {showVideoDialog && previewVideo ? (
          <Dialog
            open={showVideoDialog}
            onClose={this.closeShowVideoDialog}
          >
            <DialogTitle>Aperçu vidéo</DialogTitle>
            <Box px={3} pb={3}>
              <div
                className={classes.landscapeContainer}
                dangerouslySetInnerHTML={{ __html: previewVideo }}
              />
            </Box>
          </Dialog>) : null
        }
        <ConfirmationDialog
          open={deleteDialog}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer cette vidéo ?"
          onClose={this.closeDeleteDialog}
          onConfirm={this.deleteVideo}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <Fade in={selectedIds.length > 0}>
            <Badge color="primary" badgeContent={selectedIds.length}>
              <Button
                size="small"
                variant="contained"
                disableElevation
                onClick={this.openDisciplineDialog}
              >
                Affecter à la matière
              </Button>
            </Badge>
          </Fade>
          <Box ml={2}>
            <Button
              className={classes.spacedButton}
              size="small"
              variant="contained"
              color="primary"
              disableElevation
              onClick={this.openCreateVideoDialog}
            >
              Ajouter une vidéo
            </Button>
          </Box>
          {disciplineDialog ? (
            <DisciplineDialogAffectRadio
              open={disciplineDialog}
              onClose={this.closeDisciplineDialog}
              onAffect={this.affectDiscipline}
            />
          ) : null}
          <VideoDialogCreate
            open={createVideoDialog}
            onClose={this.closeCreateVideoDialog}
            onCreate={this.onCreate}
          />
          <FilterInput
            filter={filter}
            filterBy="discipline"
            placeholder="Filter par matière"
            defaultValue={filterState.discipline}
          />
          {renderFilter('Filtrer par vidéo')}
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left"><Checkbox onChange={this.onSelectAllClick} checked={selectAll} /></TableCell>
              {sortableCells.map(cell => renderSortCell(cell))}
              <TableCell>Formation</TableCell>
              <TableCell>Matière</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell align="center">Info</TableCell>
              <TableCell align="center">Image</TableCell>
              <TableCell align="center">Catégorie</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(n => (
              <TableRow key={n.id}>
                <TableCell>
                  <Checkbox
                    value={String(n.id)}
                    onChange={this.onSelectOne}
                    checked={selectedIds.includes(String(n.id))}
                  />
                </TableCell>
                <TableCell>{n.id}</TableCell>
                <TableCell>{format(new Date(n.inserted_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                <TableCell>{n.discipline && n.discipline.training ? n.discipline.training.title : 'Aucune'}</TableCell>
                <TableCell>{n.discipline ? n.discipline.name : 'Aucune'}</TableCell>
                <TableCell>{n.name}</TableCell>
                <TableCell align="center">
                  {n.description ? (
                    <Tooltip title={n.description}>
                      <HelpIcon fontSize="small" />
                    </Tooltip>
                  ) : null}
                </TableCell>
                <TableCell align="center">
                  <img
                    className={classes.thumbnail}
                    src={n.picture}
                    alt=""
                  />
                </TableCell>
                <TableCell>
                  <VideoCategorySelect
                    category={n.category}
                    categories={videoCategories}
                    onChange={(event) => {
                      this.changeVideoCategory(n.id, event?.target?.value);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    onClick={() => this.openDisciplineDialogForId(n.id)}
                  >
                    Matière
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    disableElevation
                    onClick={() => this.openShowVideoDialog(n.embed)}
                  >
                    Voir
                  </Button>
                </TableCell>
                <TableCell>
                  <Tooltip title={n.dupes > 0 ? `Cette vidéo est liée à un emploi du temps, il n'est pas possible de la supprimer` : ''}>
                    <span>
                      <Badge badgeContent={n.dupes} color="primary">
                        <IconButton
                          disabled={n.dupes > 0}
                          onClick={() => this.openDeleteDialog(n.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Badge>
                    </span>
                  </Tooltip>
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }
}

Videos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(Videos, 'videos', 'inserted_at', 'desc')
);
