import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ProductCard.css';
import { IconButton, InputBase, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { tableStyles } from 'styles/datatable.css';
import Http from 'services/Http';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddBoxIcon from '@material-ui/icons/AddBox';

const OPTION_KEY = 'Options complémentaires';

class ProductCard extends Component {
  onSubmit = async (event) => {
    event.preventDefault();
    const { id, onSuccess, onError } = this.props;
    const formData = new FormData(event.currentTarget);
    const annee_comptable = formData.get('annee_comptable');
    try {
      await Http.put('/orders/orderItem', {
        annee_comptable,
        id,
      });
      onSuccess?.();
    } catch (e) {
      onError?.(e.message);
    }
  }

  render() {
    const { classes, product_name, variation_list, variant, annee_comptable, editable, compact } = this.props;

    return (
      <Box display="flex" alignItems={compact ? 'center' : 'flex-start'}>
        <Typography variant={variant ?? 'body2'}>{product_name}</Typography>
        {(variation_list && !compact) ?
          (
            <Box>
              <List dense style={{ padding: 0 }}>
                {Object.entries(variation_list).map(([key, value]) => {
                  if (key !== OPTION_KEY) {
                    return (
                      <ListItem
                        key={key}
                        variant="caption"
                        className={classes.option}
                        display="block"
                        style={{ margin: 0 }}
                      >
                        <ListItemIcon>
                          <ArrowRightIcon />
                        </ListItemIcon>
                        <ListItemText>
                          {key.split('-').join(' ')} : {value}
                        </ListItemText>
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem key={key} style={{ margin: 0 }}>
                      <ListItemIcon>
                        <AddBoxIcon />
                      </ListItemIcon>
                      <ListItemText>
                      <Typography
                        display="block"
                        key={key}
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: value.replaceAll(' - ', ', ').replaceAll('- ', '') }}
                      />
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Box>) : null
        }
        {(variation_list && compact) ?
          (<Tooltip title={
            <>
              {Object.entries(variation_list).map(([key, value]) => {
                if (key !== OPTION_KEY) {
                  return (
                    <Typography
                      key={key}
                      variant="body2"
                      className={classes.option}
                    >
                      {key.split('-').join(' ')} : {value}
                    </Typography>
                  );
                }
                return (
                  <Typography
                    key={key}
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                );
              })}
            </>
          }>
            <HelpIcon className={classes.help} color="disabled" />
          </Tooltip>) : null
        }
        {editable ? (
          <Box ml={2}>
            <form onSubmit={this.onSubmit}>
              <InputBase
                name="annee_comptable"
                inputProps={{
                  type: 'number',
                }}
                style={{
                  width: '80px',
                }}
                defaultValue={annee_comptable}
                placeholder="Année comptable"
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
              />
              <IconButton type="submit"><SaveIcon fontSize="small" /></IconButton>
            </form>

          </Box>
        ) :
          annee_comptable === null || annee_comptable === ''
            ? (
              <Tooltip title="ATTENTION : cette commande n'a pas d'année comptable">
                <WarningIcon fontSize="small" />
              </Tooltip>
            ) : (
              <Typography style={{ fontStyle: 'italic', marginLeft: '4px' }} variant={variant ?? 'body2'}>{` ${annee_comptable}`}</Typography>
            )
        }
      </Box>
    );
  }
};

ProductCard.defaultProps = {
  compact: true,
};

export default withStyles((theme) => ({
  ...styles(theme),
  ...tableStyles(theme),
}))(
  ProductCard
);