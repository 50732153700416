import { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography, withStyles } from '@material-ui/core';
import TextEditor from 'components/TextEditor/TextEditor';
import { tableStyles } from 'styles/datatable.css';
import CloseIcon from '@material-ui/icons/Close';

class RichTextDialog extends Component {

  state = {
    html : '',
  };

  componentDidMount() {
    this.setState({ html: this.props.initialValue });
  }

  render() {
    const { open, classes, close, save, title } = this.props;
    const { html } = this.state;
    return (
      <Dialog
        open={open}
        onClose={close}
        maxWidth="md"
        fullWidth
      >
        <Toolbar className={classes.toolbar}>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            {title ?? 'Description'}
          </Typography>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={close}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogContent
          style={{
            padding: 0
          }}
        >
          <TextEditor
            onChange={(html) => {
              this.setState({ html });
            }}
            value={html}
          />
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => save(html)}
          >
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

export default withStyles(tableStyles)(
  RichTextDialog
);
