import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  InputBase,
  Slide,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './SessionDialogMeta.css';
import Http from 'services/Http';
import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import SuccessSnackbar from 'components/Snackbar/Success';
import { ordersInvoiceConfig } from 'components/Orders/OrdersInvoice';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SessionDialogMeta extends Component {
  state = {
    error: '',
    openError: false,
    success: '',
    openSuccess: false,
    selectedSessions: [],
    metasWithCount: [],
    hasMixedTraining: false,
    deleteDialog: false,
    deleteMeta: null,
  }

  componentDidUpdate(prevProps) {
    const { selectedIds } = this.props;
    const { selectedIds: prevSelectedIds } = prevProps;
    if (selectedIds[0] && selectedIds.join('-') !== prevSelectedIds.join('-')) {
      this.getMetas(selectedIds);
    }
  }

  reload() {
    const { selectedIds } = this.props;
    this.getMetas(selectedIds);
  }

  async getMetas(selectedIds) {
    const { data: { rows: sessions } } = await Http.get(`/sessions/?ids=${selectedIds}&offset=0&limit=${selectedIds.length}`);
    const selectedSessions = sessions.map(session => ({ id: session.id, name: session.name }));
    const hasMixedTraining = new Set(sessions.map(session => session.training_id)).size > 1;
    let metasWithCount = [];
    if (!hasMixedTraining) {
      const { data: { rows: allMetas } } = await Http.get(`/meta/${sessions[0].training_id}`);
      const total = sessions.length;
      metasWithCount = allMetas.map(meta => {
        const count = sessions.reduce((prev, { metaIds }) => prev + ((metaIds || '').split(',').includes(`${meta.id}`) ? 1 : 0), 0);
        return {
          ...meta,
          label: `${count} / ${total}`,
          color: count === 0 ? 'secondary' : (count === total ? 'primary' : 'default'),
          variant: count !== 0 && count !== total ? 'outlined' : 'default',
        };
      });
    }
    this.setState({
      hasMixedTraining,
      selectedSessions,
      metasWithCount,
    });
  }

  // DIALOGS
  onOpenDeleteDialog = (deleteMeta) => {
    this.setState({
      deleteDialog: true,
      deleteMeta,
    });
  }

  onCloseDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      deleteMeta: null,
    });
  }

  onCloseDialog = () => {
    this.props.onClose();
  }

  // CRUD
  onMetaUnassign = async (id, key) => {
    try {
      await Http.delete(
        `/meta/${id}`,
        { data: { session_ids: this.props.selectedIds } }
      );
      this.setState({
        success: `La métadonnée "${key}" a été supprimée des sessions sélectionnées`,
        openSuccess: true,
      });
      this.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  onMetaAssign = async (payload) => {
    try {
      await Http.post(
        `/meta`,
        {
          session_ids: this.props.selectedIds,
          ...payload,
        },
      );
      this.setState({
        success: `La métadonnée "${payload.key}" a été affectée aux sessions sélectionnées`,
        openSuccess: true,
      });
      this.reload();
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    this.onMetaAssign(Object.fromEntries(formData.entries()));
    event.currentTarget.reset();
  }

  onMetaDelete = async () => {
    if (this.state.deleteMeta) {
      try {
        const { data } = await Http.delete(`/meta/${this.state.deleteMeta}`);
        this.setState({
          success: `La métadonnée "${data.name}" a été supprimée`,
          openSuccess: true,
          deleteDialog: false,
          deleteMeta: null,
        });
        this.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          deleteMeta: null,
        });
      }
    }
  }

  render() {
    const { classes, open } = this.props;
    const {
      error,
      openError,
      openSuccess,
      success,
      metasWithCount,
      selectedSessions,
      hasMixedTraining,
      deleteDialog,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onCloseDialog}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
      >
        <ConfirmationDialog
          open={deleteDialog}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer définitivement cette métadonnée ?"
          onClose={this.onCloseDeleteDialog}
          onConfirm={this.onMetaDelete}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />

        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={this.onCloseDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            Affecter une métadonnée aux {selectedSessions.length} sessions
          </Typography>
        </Toolbar>

        <DialogContent className={classes.dialog}>
          <Box p={1} className={classes.chips}>
            {selectedSessions.map(({ id, name }) => (
              <Chip key={id} className={classes.chip} size="small" label={name} />
            ))}
          </Box>
          {hasMixedTraining ? (
            <Box px={2}>
              <Typography component="div" variant="body1">
                <Box display="flex" bgcolor="error.main" color="error.contrastText" p={2} my={2}>
                  <WarningIcon />&nbsp;&nbsp;Merci de ne sélectionner que des sessions appartenant à la même formation
                </Box>
              </Typography>
            </Box>
          ) : (
            <Box p={2}>
              {metasWithCount.map(({ color, id, label, key, value, variant }) => (
                <Box
                  key={id}
                  display="flex"
                  alignItems="center"
                >
                  <Box flex="1" display="flex" mr={1}>
                    <Chip
                      size="small"
                      label={key}
                      style={{ marginRight: '6px' }}
                    />
                    <Typography>{value}</Typography>
                  </Box>
                  <Chip
                    color={color}
                    label={label}
                    size="small"
                    variant={variant}
                  />
                  <Button
                    className={classes.button}
                    variant="outlined"
                    size="small"
                    onClick={() => this.onMetaAssign({ key, value })}
                  >
                    Affecter à tous
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => this.onMetaUnassign(id, key)}
                  >
                    Enlever à tous
                  </Button>
                  <IconButton
                    color="secondary"
                    className={classes.button}
                    onClick={() => this.onOpenDeleteDialog(id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Box m={1} display="flex" justifyContent="center" alignItems="center" style={{ gap: '6px' }}>
                <Typography variant="inherit">Clés disponibles</Typography>
                {ordersInvoiceConfig.map(({ key, title }) => {
                  return (
                    <Chip
                      key={key}
                      size="small"
                      label={`${key} (${title})`}
                    />
                  );
                })}
                <datalist id="available-keys">
                  {ordersInvoiceConfig.map(({ key, title }) => {
                    return (
                      <option key={key} value={key} />
                    );
                  })}
                </datalist>
              </Box>
              <form onSubmit={this.onSubmit}>
                <Box display="flex" alignItems="center" pt={2}>
                  <Box mr={2}>
                    <InputBase
                      inputProps={{
                        list: 'available-keys',
                        autoComplete: 'off',
                      }}
                      name="key"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      placeholder="Nouvelle clé"
                    />
                  </Box>
                  <Box mr={2}>
                    <InputBase
                      name="value"
                      classes={{
                        root: classes.field,
                        input: classes.fieldInput,
                      }}
                      placeholder="Nouvelle valeur"
                    />
                  </Box>
                  <IconButton
                    color="secondary"
                    type="submit"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Box>
              </form>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

SessionDialogMeta.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedIds: PropTypes.array.isRequired,
};

export default withStyles(styles)(
  SessionDialogMeta
);
