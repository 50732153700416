export const styles = theme => ({
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
  formGroup: {
    padding: theme.spacing(3, 3, 0, 3),
  },
  formGroupBis: {
    padding: theme.spacing(3, 3, 0, 3),
    backgroundColor: theme.palette.background.default,
  },
  formControl: {
    display: 'block',
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  selectEmpty: {
    width: '100%',
  },
  dialog: {
    padding: theme.spacing(0, 0, 3, 0),
  },
  title: {
    flex: 1,
  },
  label: {
    display: 'block',
    paddingBottom: theme.spacing(1),
  },
  radio: {
    paddingTop: theme.spacing(.5),
    paddingBottom: theme.spacing(.5),
  },
  flatButton: {
    textTransform: 'none',
    textAlign: 'left',
    boxShadow: 'none',
    marginBottom: theme.spacing(.5),
  },
});
