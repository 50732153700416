import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import { styles } from './VideoCard.css';

const VideoCard = ({
  classes,
  description,
  index,
  link,
  name,
  openVideo,
  picture,
  selected,
  selectVideo,
}) => (
  <Card className={classNames(
    classes.card,
    { [classes.cardSelected]: selected },
  )}>
    <CardActionArea className={classes.cardActionArea}>
      <div className={classes.landscapeContainer}>
        <img className={classes.landscape} src={picture} alt="" />
      </div>
      <CardContent>
        <Typography className={classes.subtitle1} gutterBottom variant="subtitle1" component="h3">
          {name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {description}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions className={classes.cardActions}>
      <Button
        size="small"
        color="primary"
        onClick={() => openVideo(link)}
      >
        Voir sur Vimeo
      </Button>
      <Button
        size="small"
        color="primary"
          onClick={() => selectVideo(selected, index)}
      >
        {selected ? 'Désélectionner' : 'Sélectionner'}
      </Button>
    </CardActions>
  </Card>
);

VideoCard.propTypes = {
  classes: PropTypes.object.isRequired,
  description: PropTypes.string,
  index: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  openVideo: PropTypes.func.isRequired,
  picture: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  selectVideo: PropTypes.func.isRequired,
}

VideoCard.defaultProps = {
  description: '',
};


export default withStyles(styles)(VideoCard);