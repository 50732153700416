import { alpha } from '@material-ui/core/styles/colorManipulator';

export const styles = theme => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    textAlign: 'left',
    color: theme.palette.error.dark,
  },
  fieldLarge: {
    width: 300,
    display: 'inline-block',
    borderRadius: theme.shape.borderRadius,
    color: 'inherit',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
    padding: theme.spacing(1),
  },
});
