import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorSnackbar from 'components/Snackbar/Error';
import Http from 'services/Http';
import { styles } from './RattrapageDialog.css';
import FilterInput from 'components/Datagrid/FilterInput';
import StudentList from './StudentList';

class RattrapageDialog extends Component {
  state = {
    error: [],
    openError: false,
    rows: [],
    count: 0,
    checkedResults: [],
    affectedStudents: [],
    savedStudents: [],
    searching: false,
  }

  async componentDidMount() {
    this.setState({
      savedStudents: [...this.props.rattrapage.students].map(student => ({
        ...student,
        available_at: student.rattrapageStudent.available_at,
      }))
    });
    // console.log( this.props.rattrapage.students );
  }

  resetState() {
    this.setState({
      error: [],
      openError: false,
      rows: [],
      count: 0,
      checkedResults: [],
      affectedStudents: [],
      savedStudents: [],
    });
  }

  affectCheckedStudents = () => {
    const { rows, checkedResults } = this.state;
    const affectedStudents = checkedResults.map(id => ({
      ...rows.find(student => id === student.id),
      available_at: new Date(),
    }));
    this.setState({
      checkedResults: [],
      affectedStudents: [
        ...this.state.affectedStudents,
        ...affectedStudents.filter((student) => 
          undefined === this.state.affectedStudents.find(({ id }) => student.id === id)
        ),
      ].map(s => ({ ...s, id: s.id })),
    });
  };

  closeDialog = () => {
    this.resetState();
    this.props.onClose();
  }

  searchByStudent = async (filter) => {
    this.setState({ searching: true });
    const { students } = this.props.rattrapage;
    const { data } = await Http.get('/users/students/light', {
      params: {
        limit: 100,
        studentFamilyName: filter.filter,
      }
    });
    const { rows } = data;
    // filter existing students for this rattrapage
    const filteredRows = rows
      .filter(({ id }) => !students.find(({ id: studentId }) => studentId === id))
      .map((row) => {
        return {
          id: row.student_id,
          last_name: row.student_familyName,
          first_name: row.student_name,
          sessions: [],
        };
      });

    this.setState({ count: filteredRows.length, rows: filteredRows, checkedResults: [], searching: false });
  };

  searchBySession = async ({ session }) => {
    this.setState({ searching: true });
    const { students } = this.props.rattrapage;
    const { data } = await Http.get('/sessions/full', {
      params: {
        limit: 100,
        filter: session,
      }
    });
    const rows = data.rows.reduce((prev, current) => {
      return [
        ...prev,
        ...current.students
          .filter((student) =>
            !prev.find(({ id }) => student.id === id)
            && !students.find(({ id }) => id === student.id)
          )
          .map(student => (
            {
              ...student,
              sessions: [{ id: current.id, name: current.name }]
            }
          )),
      ];
    }, []);
    this.setState({
      count: rows.length,
      rows,
      searching: false,
    });
  };

  onSelectStudent = (id) => {
    const checkedResults = [...this.state.checkedResults];
    const index = checkedResults.indexOf(id);
    if (index !== -1) {
      checkedResults.splice(index, 1);
    } else {
      checkedResults.push(id);
    }
    this.setState({ checkedResults });
  };

  onSelectAllStudents = (all) => {
    this.setState({
      checkedResults: all ? this.state.rows.map(({ id }) => id) : [],
    });
  };

  removeAffectedSudent = (id) => {
    this.setState({
      affectedStudents: this.state.affectedStudents.filter(({ id: idToDelete }) => idToDelete !== id),
    });
  };

  setSavedStudentDate = (date, id) => {
    this.setState({
      savedStudents: this.state.savedStudents.map((student) => {
        if (student.id === id) {
          return {
            ...student,
            available_at: date,
            rattrapageStudent: {
              ...student.rattrapageStudent,
              available_at: date,
            },
          };
        }
        return student;
      }),
    });
  };

  setAffectedStudentDate = (date, id) => {
    this.setState({
      affectedStudents: this.state.affectedStudents.map((student) => {
        if (student.id === id) {
          return { ...student, available_at: date };
        }
        return student;
      }),
    });
  };

  saveAffectations = async () => {
    const { affectedStudents, savedStudents } = this.state;
    const { rattrapage } = this.props;
    const { id: rattrapage_id } = rattrapage;
    // update
    const rattrapagesStudentsUpdate = savedStudents.map(({ rattrapageStudent }) => ({
      id: rattrapageStudent.id,
      available_at: rattrapageStudent.available_at,
    }));
    // insert mode
    const rattrapagesStudentsInsert = affectedStudents.map(({ id: user_id, available_at }) => ({
      user_id,
      rattrapage_id,
      available_at,
    }));
    try {
      if (rattrapagesStudentsUpdate.length) {
        await Http.put(
          '/rattrapages/bulkUpdateRattrapageStudent',
          rattrapagesStudentsUpdate,
        );
      }
      if (rattrapagesStudentsInsert.length) {
        await Http.post(
          '/rattrapages/bulkCreateRattrapageStudent',
          rattrapagesStudentsInsert,
        );
      }
      this.resetState();
      this.props.onAffect({
        success: rattrapagesStudentsInsert.length > 1 ? 'Les élèves ont été affectés' : 'L\'élève a été affecté',
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  };

  deleteSavedStudent = async (rattrapageId, userId) => {
    try {
      await Http.delete(`/rattrapages/deleteOneRattrapageStudent/${rattrapageId}/${userId}`);
      this.setState({
        savedStudents: this.state.savedStudents.filter(({ id }) => id !== userId),
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  render() {
    const { classes, fullScreen, open, rattrapage } = this.props;
    const {
      error,
      openError,
      count,
      rows,
      checkedResults,
      affectedStudents,
      savedStudents,
      searching,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.closeDialog}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            edge="start"
            onClick={this.closeDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            Affecter
            <Typography
              color="inherit"
              variant="caption"
              display="block"
            >
              ({rattrapage.name})
            </Typography>
          </Typography>
        </Toolbar>

        <DialogContent className={classes.dialog}>
          <Box
            display="flex"
            mb={2}
          >
            <Typography
              className={classes.spaceRight}
              variant="h6"
              gutterBottom
            >
              Rechercher
            </Typography>
            <FilterInput
              filter={this.searchByStudent}
              filterBy="filter"
              placeholder="Par nom"
            />
            <FilterInput
              filter={this.searchBySession}
              filterBy="session"
              placeholder="Par session"
            />
          </Box>

          <Grid container spacing={2} alignItems="flex-start" style={{ opacity: searching ? .5 : 1 }}>
            <Grid item xs={12}>
              <Card
                className={classes.card}
                elevation={0}
              >
                  <Toolbar
                    className={classes.secondToolbar}
                    variant="dense"
                  >
                    <Box flexGrow={1}>
                      <Typography className={classes.spaceRight} variant="subtitle1">{`${count} résultat${count > 1 ? 's' : ''}`}</Typography>
                    </Box>
                    <Fade in={checkedResults.length > 0}>
                      <Badge
                        anchorOrigin={{vertical:'bottom', horizontal:'left'}}
                        badgeContent={checkedResults.length}
                        color="secondary"
                      >
                        <Button
                          className={classes.spaceRight}
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={this.affectCheckedStudents}
                        >
                          Ajouter
                        </Button>
                      </Badge>
                    </Fade>
                    <Fade in={rows.length > 0}>
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => this.onSelectAllStudents(checkedResults.length !== rows.length)}
                      >
                        {checkedResults.length === rows.length ? 'Tout déselectionner' : 'Tout sélectionner'}
                      </Button>
                    </Fade>
                  </Toolbar>
                  <List
                    className={classes.root}
                    dense
                  >
                    {rows.map(row => {
                      const labelId = `checkbox-list-label-${row.id}`;
                      return (
                        <ListItem
                          key={labelId}
                          role={undefined}
                          dense
                          button 
                          onClick={() => this.onSelectStudent(row.id)}>
                          <ListItemIcon>
                            <Checkbox
                              checked={checkedResults.includes(row.id)}
                              tabIndex={-1}
                              disableRipple
                              size="small"
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            id={labelId}
                            primary={`${row.last_name.toUpperCase()} ${row.first_name}`}
                            secondary={row.sessions.length > 0 ? row.sessions[0].name : ''}
                            secondaryTypographyProps={{ variant: 'caption', display: 'block' }} 
                          />
                        </ListItem>
                      );
                    })}
                  </List>
              </Card>
            </Grid>

            {affectedStudents.length ? (
              <Grid item xs={12}>
                <Card
                  className={classes.card}
                  elevation={0}
                >
                  <Toolbar
                    className={classes.secondToolbar}
                    variant="dense"
                  >
                    <Typography variant="subtitle1">
                      {`${affectedStudents.length} élève${affectedStudents.length > 1 ? 's' : ''} à affecter`}
                    </Typography>
                  </Toolbar>
                  <StudentList
                    remove={this.removeAffectedSudent}
                    setDate={this.setAffectedStudentDate}
                    students={affectedStudents}
                  />
                </Card>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Card
                className={classes.card}
                elevation={0}
              >
                <Toolbar
                  className={classes.secondToolbar}
                  variant="dense"
                >
                  <Box flexGrow={1}>
                    <Typography variant="subtitle1">{rattrapage.name}</Typography>
                  </Box>
                  <Chip
                    color="primary"
                    size="small"
                    label={`${savedStudents.length} élève${savedStudents.length > 1 ? 's' : ''} affecté${savedStudents.length > 1 ? 's' : ''}`}
                  />
                </Toolbar>
                <StudentList
                  remove={(userId) => this.deleteSavedStudent(rattrapage.id, userId)}
                  setDate={this.setSavedStudentDate}
                  students={savedStudents}
                />
              </Card>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <ErrorSnackbar
            open={openError}
            onClose={() => this.setState({ openError: false })}
            message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
          />
          <Button
            onClick={this.saveAffectations}
            color="primary"
          >
            Affecter
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RattrapageDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onAffect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  rattrapage: PropTypes.object.isRequired,
};

export default withMobileDialog()(withStyles(styles)(
  RattrapageDialog
));
