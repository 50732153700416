import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  Grid,
  InputBase,
  IconButton,
  Toolbar,
  Typography,
  withMobileDialog,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MovieIcon from '@material-ui/icons/Movie';
import ErrorSnackbar from 'components/Snackbar/Error';
import Http from 'services/Http';
import { styles } from './VideoDialogCreate.css';
import VideoCard from './VideoCard';

const CustomButton = withStyles((theme) => ({
  label: {
      whiteSpace: 'nowrap',
  },
}))(Button);

const initialState = () => ({
  success: '',
  error: [],
  openError: false,
  search: '',
  videos: { data: [] },
  loading: false,
  selectedVideos: [],
});

class VideoDialogCreate extends Component {
  state = initialState();

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.setState({ selectedVideos: [] });
    }
  }

  resetState() {
    this.setState(initialState());
  }

  onVideoSubmit = async () => {
    const {
      videos,
      selectedVideos,
    } = this.state;

    const bulkCreateVideoData = selectedVideos.map((videoIndex) => {
      const video = videos.data[videoIndex];
      const {
        description,
        embed,
        name,
        picture,
      } = video;
      return {
        description,
        embed,
        name,
        picture,
      };
    });

    if (this.props.disableEndpoint) {
      this.resetState();
      return this.props.onCreate({
        bulkCreateVideoData,
      });
    }

    try {
      const { data } = await Http.post(
        this.props.bulkCreateEndpoint,
        bulkCreateVideoData,
      );
      this.resetState();
      this.props.onCreate({
        success: bulkCreateVideoData.length > 1 ? `${this.props.context.them} ont été créées` : `${this.props.context.the} a été créée`,
        data,
      });
    } catch ({ data }) {
      this.setState({
        error: data ? Object.values(data) : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  handleSearch = ({ target: { value } }) => {
    this.setState({
      search: value,
    });
  }

  fetchApi = async () => {
    const { search } = this.state;
    this.setState({ loading: true });
    try {
      const { data: videos } = await Http.get(`/vimeo/search/${search}`);
      this.setState({
        videos,
        loading: false,
        selectedVideos: [],
      });
    } catch ({ data }) {
      const { developer_message } = data.message ? JSON.parse(data.message) : {};
      this.setState({
        error: developer_message ? [developer_message] : ['Une erreur est survenue'],
        openError: true,
      });
    }
  }

  onDialogClose = () => {
    this.resetState();
    this.props.onClose();
  }

  openVideo = (url) => window.open(url);

  selectVideo = (selected, index) => {
    const { selectedVideos } = this.state;
    this.setState({
      selectedVideos: selected
        ? selectedVideos.filter(
          videoIndex => index !== videoIndex
        ) : [...selectedVideos, index]
    })
  }

  render() {
    const { classes, fullScreen, open, context } = this.props;
    const {
      error,
      loading,
      openError,
      search,
      videos,
      selectedVideos,
    } = this.state;

    return (
      <Dialog
        open={open}
        onClose={this.onDialogClose}
        fullScreen={fullScreen}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <ErrorSnackbar
          open={openError}
          onClose={() => this.setState({ openError: false })}
          message={<div>{error.map((err, index) => (<div key={index}>{err}</div>))}</div>}
        />

        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.closeButton}
            edge="start"
            color="inherit"
            onClick={this.onDialogClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            className={classes.title}
          >
            {`Ajouter ${selectedVideos.length > 1 ? context.some : context.one}`}
          </Typography>
          <Fade in={selectedVideos.length > 0}>
            <CustomButton
              onClick={this.onVideoSubmit}
              color="secondary"
              variant="contained"
              disableElevation
            >
              Créer ({selectedVideos.length})
            </CustomButton>
          </Fade>
        </Toolbar>

        <DialogContent className={classes.dialog}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} md={4}>
              <InputBase
                classes={{
                  root: classes.field,
                  input: classes.fieldInput,
                }}
                placeholder="Recherche Vimeo"
                onChange={this.handleSearch}
                value={search}
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    this.fetchApi();
                  }
                }}
              />
            </Grid>
            <Grid item xs={6} md={8}>
              <Typography
                align="right"
                color="inherit"
                variant="subtitle1"
              >
                {loading && <Fragment>Chargement des données Vimeo</Fragment>}
                {(!loading && videos && videos.data.length) ? <Fragment>Affichage de {videos.data.length} / {videos.total} résultats</Fragment> : null}
                {(!loading && videos && !videos.data.length) ? <Fragment>Aucun résultat</Fragment> : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {videos && videos.data.map((n, index) => {
              const selected = selectedVideos.includes(index);
              return (
                <Grid
                  key={index}
                  xs={4}
                  item
                >
                  <VideoCard
                    selected={selected}
                    {...n}
                    index={index}
                    openVideo={this.openVideo}
                    selectVideo={this.selectVideo}
                  />
                </Grid>
              );
            })}
          </Grid>
        </DialogContent>

        <DialogActions>
          {selectedVideos.length ? (
            <Fade in={selectedVideos.length > 0}>
              <Grid container spacing={1}>
                {selectedVideos.map((videoIndex) => {
                  const { name } = videos.data[videoIndex];
                  return (
                    <Grid
                      item
                      key={videoIndex}
                    >
                      <Chip
                        variant="outlined"
                        size="small"
                        onDelete={() => this.setState({
                          selectedVideos: selectedVideos.filter(index => index !== videoIndex),
                        })}
                        icon={<MovieIcon />}
                        label={name}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Fade>
          ) : null}
        </DialogActions>
      </Dialog>
    );
  }
}

VideoDialogCreate.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  bulkCreateEndpoint: PropTypes.string,
  context: PropTypes.object,
  disableEndpoint: PropTypes.bool,
};

VideoDialogCreate.defaultProps = {
  disableEndpoint: false,
  bulkCreateEndpoint: '/videos/bulkCreate',
  context: {
    one: 'une vidéo',
    some: 'des vidéos',
    the: 'la vidéo',
    them: 'les vidéos',
  },
};

export default withMobileDialog()(withStyles(styles)(
  VideoDialogCreate
));
