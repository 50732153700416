import { Button, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TextEditor from 'components/TextEditor/TextEditor';
import { tableStyles } from 'styles/datatable.css';
import { styles } from './Timetable.css';
import { useState } from 'react';

const useStyles = makeStyles((theme) => tableStyles(theme));
const timetableStyles = makeStyles((theme) => styles(theme));

const DescriptionDialog = ({
  onClose,
  defaultValue,
  onSave,
}) => {
  const classes = {
    ...useStyles(),
    ...timetableStyles(),
  };
  const [html, setHtml] = useState(defaultValue);
  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          color="inherit"
          variant="h6"
          className={classes.title}
        >
          Détails
        </Typography>
        <IconButton
          className={classes.closeButton}
          edge="start"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
      <DialogContent
        style={{
          padding: 0
        }}
      >
        <TextEditor
          onChange={(html) => {
            setHtml(html);
          }}
          value={html ?? ''}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => onSave(html)}
        >
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionDialog;
