import React, { Component } from 'react';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import frLocale from 'date-fns/locale/fr';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { tableStyles } from 'styles/datatable.css';

import ConfirmationDialog from 'components/ConfirmationDialog/ConfirmationDialog';
import ErrorSnackbar from 'components/Snackbar/Error';
import Http from 'services/Http';
import SuccessSnackbar from 'components/Snackbar/Success';
import { styles } from './News.css';
import NewsDialogCreate from 'components/NewsDialogCreate/NewsDialogCreate';
import withSortAndPagination from 'components/withSortAndPagination/withSortAndPagination';

const sortableCells = [
  { name: 'id', label: '#' },
  // { name: 'title', label: 'Titre' },
  { name: 'inserted_at', label: 'Date d\'ajout' },
];


const sortableAndFilterableCells = [
  {
    cellInfo: { name: 'title', label: 'Titre' },
    filtersInfo: [{ filterBy: 'title', placeholder: '' }],
  },
  {
    cellInfo: { name: 'html', label: 'Contenu' },
    filtersInfo: [{ filterBy: 'html', placeholder: '' }],
  },
  {
    cellInfo: { name: 'training_name', label: 'Formation' },
    filtersInfo: [{
      type: 'autocomplete',
      filterBy: 'trainingName',
      placeholder: '',
      fetcher: async () => {
        const { data } = await Http.get('/trainings?publishedOnly=false');
        return data.rows.map(({ title }) => title);
      },
    }],
  },
];

class News extends Component {
  state = {
    deleteDialog: false,
    deleteNews: null,
    error: '',
    openError: false,
    openSuccess: false,
    success: '',
    newsDialogCreate: false,
    news: {},
  }

  openNewsDialogCreate = (news = {}) => {
    this.setState({ newsDialogCreate: true, news });
  }

  onCloseNewsDialogCreate = () => {
    this.setState({ newsDialogCreate: false });
  }

  openDeleteDialog = (deleteNews) => {
    this.setState({
      deleteDialog: true,
      deleteNews,
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
      deleteNews: null,
    });
  }

  onCreate = ({ success }) => {
    this.setState({
      success,
      openSuccess: true,
      newsDialogCreate: false,
    });
    this.props.reload();
  };

  deleteNews = async () => {
    if (this.state.deleteNews) {
      try {
        await Http.delete(`/news/${this.state.deleteNews}`);
        this.setState({
          success: 'L\'actualité a été supprimée',
          openSuccess: true,
          deleteDialog: false,
          deleteNews: null,
        });
        this.props.reload();
      } catch ({ data }) {
        this.setState({
          error: data ? Object.values(data) : ['Une erreur est survenue'],
          openError: true,
          deleteDialog: false,
          deleteNews: null,
        });
      }
    }
  }

  render() {
    const {
      classes,
      renderPagination,
      renderSortCell,
      rows,
      renderSortableAndFilterable,
    } = this.props;
    const {
      deleteDialog,
      error,
      openError,
      openSuccess,
      success,
      news,
    } = this.state;

    return (
      <Paper elevation={1} className={classes.root}>
        <ConfirmationDialog
          open={deleteDialog}
          title="Attention"
          message="Êtes-vous sûr de vouloir supprimer cette actualité ?"
          onClose={this.closeDeleteDialog}
          onConfirm={this.deleteNews}
        />
        <ErrorSnackbar
          message={error}
          onClose={() => this.setState({ openError: false })}
          open={openError}
        />
        <SuccessSnackbar
          message={success}
          onClose={() => this.setState({ openSuccess: false })}
          open={openSuccess}
        />
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.title} variant="h6" color="inherit" />
          <Box ml={2}>
            <Button
              className={classes.spacedButton}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => this.openNewsDialogCreate()}
              disableElevation
            >
              Ajouter une actualité
            </Button>
          </Box>
          {
            this.state.newsDialogCreate ? (
              <NewsDialogCreate
                news={news}
                open={this.state.newsDialogCreate}
                onClose={this.onCloseNewsDialogCreate}
                onCreate={this.onCreate}
              />
            ) : null
          }
        </Toolbar>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              {sortableCells.map(cell => renderSortCell(cell))}
              {sortableAndFilterableCells.map(({ cellInfo, filtersInfo }) => renderSortableAndFilterable(cellInfo, filtersInfo))}
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(n => /*console.log(n) || */(
              <TableRow key={n.id}>
                <TableCell>{n.id}</TableCell>
                <TableCell>{format(new Date(n.inserted_at), 'dd MMM yyyy', { locale: frLocale })}</TableCell>
                <TableCell>
                  <Tooltip title={n.title}>
                    <div className={classes.titleEllipsis}>{n.title}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <Box dangerouslySetInnerHTML={{__html: n.html}} />
                    }
                  >
                    <div className={classes.titleEllipsis}>{n.html.replace(/(<([^>]+)>)/gi, "").replaceAll('&nbsp;', ' ')}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Chip
                    className={classes.chip}
                    size="small"
                    color="primary"
                    label={n.allNewsTrainings.length}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => this.openNewsDialogCreate(n)}
                    disableElevation
                  >
                    Editer
                  </Button>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => this.openDeleteDialog(n.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {renderPagination()}
      </Paper>
    );
  }

}

News.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles((theme) => ({
  ...tableStyles(theme),
  ...styles(theme),
}))(
  withSortAndPagination(News, 'news', 'id', 'desc')
);
