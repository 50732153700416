import { Component, Fragment } from 'react';
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { styles } from './FilterAutocomplete.css';
import { withStyles } from '@material-ui/core/styles';
import isEqual from 'lodash.isequal';
class FilterAutocomplete extends Component {
  state = {
    options: [],
    loading: false,
  };
  async componentDidMount() {
    this.setState({ loading: true });
    const { fetcher } = this.props;
    const options = await fetcher(this.props.filterState);
    this.setState({
      options,
      loading: false,
    });
  }
  async componentDidUpdate(prevProps) {
    // true if the HOC withSortAndPagination has just finished reloading
    const hocDidRefresh = prevProps.hocLoading === true && this.props.hocLoading === false;
    if (isEqual(prevProps.filterState, this.props.filterState) === false || hocDidRefresh) {
      this.setState({ loading: true });
      const { fetcher } = this.props;
      const options = await fetcher(this.props.filterState);
      this.setState({
        options,
        loading: false,
      });
    }
  }
  render() {
    const { loading, options } = this.state;
    const { classes, defaultValue, filter, filterBy, multiple, checkbox, placeholder } = this.props;
    const multipleDefaultValue = defaultValue.split('$$$');
    return (
      <FormControl variant="outlined" fullWidth>
        <Box
          sx={{
            display: 'flex',
            minWidth: multiple ? '140px' : 'auto',
            alignItems: 'center',
          }}
        >
          {checkbox ? (
            <Tooltip title={checkbox.tooltip}>
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    defaultChecked={checkbox.defaultChecked}
                    onChange={(event) => {
                      filter({
                        [`${filterBy}Checked`]: event.currentTarget.checked ? '1' : '0',
                      }, true);
                    }}
                  />
                }
                label={checkbox.label ? <Typography variant="caption">{checkbox.label}</Typography> : null}
              />
            </Tooltip>
          ) : null}
          <Autocomplete
            filterOptions={(options, state) => {
              const { inputValue } = state;
              if (inputValue.length === 0) {
                return options;
              }
              const jokers = inputValue.split(' ');
              if (jokers.length) {
                return options.filter((option) => {
                  return jokers.every((joker) => {
                    return option.toLowerCase().includes(joker.toLowerCase());
                  });
                });
              }
              return options.filter((option) => {
                return option.toLowerCase().includes(inputValue.toLowerCase());
              });
            }}

            multiple={multiple}
            limitTags={1}
            value={multiple
              ? (/*string*/defaultValue.length > 0 && /*Array*/multipleDefaultValue.length > 0)
                ? multipleDefaultValue
                : []
              : options.includes(defaultValue)
                ? defaultValue
                : null}
            options={options}
            disabled={options.length === 0}
            loading={loading}
            fullWidth
            size="small"
            noOptionsText="Aucun résultat, veuillez saisir du texte"
            classes={{
              root: classes.dense,
              popper: classes.popper,
              tagSizeSmall: classes.tagSizeSmall,
            }}
            onChange={(event, value) => {
              filter({ [filterBy]: multiple ? value.join('$$$') : value }, true);
            }}
            loadingText="Chargement..."
            disableClearable
            renderInput={(params) => {
              return (
                <TextField
                  variant="outlined"
                  {...params}
                  classes={{
                    root: classes.fieldInputDense,
                  }}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: placeholder ?? 'A B',
                    endAdornment: (
                      <Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )
            }}
          />
          {(defaultValue && !multiple) ? (
            <IconButton
              size="small"
              onClick={() => filter({ [filterBy]: '' }, true)}
            >
              <HighlightOffIcon />
            </IconButton>
          ) : null}
        </Box>
      </FormControl>
    );
  }
}

export default withStyles(styles)(FilterAutocomplete);
