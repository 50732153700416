import { Chip, withStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import { useState } from "react";
import Http from 'services/Http';
import { styles } from './StudentTags.css';
import StudentTagsCreation from "./StudentTagsCreation";

/**
 * 
 * @param {import("@material-ui/core").ChipProps & {
 *  showDeleteButton?: boolean
 *  none: string
 *  handleDelete?: (tag: string) = void
 * }} props 
 * @returns 
 */
const SingleChip = ({
  showDeleteButton = false,
  label,
  none,
  handleDelete,
  variant,
  ...props
}) => {
  const [isFocued, setIsFocused] = useState(false);

  const canDelete = showDeleteButton && isFocued && label !== none;

  return <Chip
    color={label === none ? 'default' : canDelete ? 'secondary' : 'default'}
    label={label}
    {...props}
    onDelete={canDelete ? () => handleDelete(label) : undefined}
    onClick={_ => setIsFocused(p => !p)}
  />
}

const StudentTagsUnstyled = ({
  classes,
  tags,
  none = 'Aucun',
  userId,
  allowDeletion = false,
  allowCreation = false,
  creationProps = {
    tooltip: undefined,
    label: "Ajouter une/des étiquette(s)"
  },
  onUpdate = (data) => { },
}) => {


  tags = !tags ? [none] : tags;

  const handleCreation = async (tag) => {
    const { data } = await Http.post(
      `/users/bulk/tags`,
      {
        studentIds: [userId],
        tags: tag,
        none,
      },
    )
    onUpdate(data);
  }

  const handleDelete = (tag) => {
    handleCreation(`-${tag}`);
  }

  const canDelete = allowDeletion && userId !== undefined;

  return <div className={classes.root}>
    {(tags ?? [none]).map((tag, idx) => <SingleChip
      key={idx}
      label={tag}
      none={none}
      showDeleteButton={canDelete}
      handleDelete={handleDelete}
    />)}
    {allowCreation && <StudentTagsCreation
      none={none}
      onSubmit={handleCreation}
      {...creationProps}
    />}

  </div>
}

StudentTagsUnstyled.propTypes = {
  classes: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.string),
  none: PropTypes.string,
  userId: PropTypes.number,
  allowDeletion: PropTypes.bool,
  allowCreation: PropTypes.bool,
};

export default withStyles((theme) => ({
  ...styles(theme),
}))(StudentTagsUnstyled);
