export const styles = theme => ({
  stack: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  toolbar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.custom.white,
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
  formControl: {
    display: 'block',
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  selectEmpty: {
    width: '100%',
  },
  dialog: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  title: {
    flex: 1,
  },
  divider: {
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
  },
  denseCard: {
    padding: `${theme.spacing(.5)}px ${theme.spacing(1)}px`,
  }
});
